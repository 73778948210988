import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import FadeInOnScroll from "../../components/atoms/FadeInOnScroll";
import React, {useState} from "react";
import {wait} from "@testing-library/user-event/dist/utils";

export default function DropdownSearchSelect({
                                                 placeholder,
                                                 value,
                                                 onChange,
                                                 InputIcon,
                                                 options,
                                                 onSelect,
                                                 rightIcon,
                                                 selectResName
                                             }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        onChange(e);
        if (newValue.length > 0) {
            setIsDropdownOpen(true);
        } else {
            setIsDropdownOpen(false);
        }
    };

    const handleOptionSelect = (selectedOption) => {
        onSelect(selectResName ? selectedOption.name : selectedOption);
        // setIsDropdownOpen(false); // Close the dropdown
    };

    return (
        <div className='relative w-full'>
            <WrappedTextInput
                type="text"
                placeholder={placeholder}
                className='pl-36 input-light body2 w-full'
                value={value}
                onChange={handleInputChange}
                icon={InputIcon}
                onFocus={() => setIsDropdownOpen(value.length > 0)}
                onBlur={() => wait(200).then(() => {
                    setIsDropdownOpen(false)
                })}
                rightIcon={rightIcon}
            />
            <div className='absolute left-0 right-0 z-10 top-10'>
                <div
                    className={`bg-white rounded-lg popover-style ${isDropdownOpen ? '' : 'hidden'}`}>
                    {options.map((res, i) =>
                        <FadeInOnScroll triggerImmediately delay={10 + (i * 10)}
                                        key={res.id}>
                            <div
                                onClick={() => handleOptionSelect(res)}
                                className='flex flex-row items-center gap-3 py-1 cursor-pointer hover:bg-slate-100 rounded-lg px-2'>
                                {res.logo_url && (
                                    <img
                                        className='h-10 aspect-square border border-slate-200 bg-white rounded-lg object-contain'
                                        src={res.logo_url}
                                    />
                                )}
                                <div className='flex flex-col'>
                                    <p className='body2 text-slate-800 line-clamp-2'>
                                        {res.name}
                                    </p>
                                </div>
                            </div>
                        </FadeInOnScroll>
                    )}
                </div>
            </div>
        </div>
    )
}
