import React, {PureComponent} from "react";
import {connect} from "react-redux";
import PageLeftWrapper from "../wrappers/PageLeftWrapper";
import JobBoardLoading from "../loading/JobBoardLoading";
import {getUserCompanies} from "../../api/student/companies";
import CompanyPeek from "../discover/CompanyPeek";
import TrackedCompanyItem from "../crm/TrackedCompanyItem";
import TableHeader from "../atoms/TableHeader";
import {COMPANY_TRACKER_TABLE_HEADERS} from "../../utils/dummy";
import {Button} from "../atoms/Button";
import SvgEmpty from "../icons/SvgEmpty";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        trackers: state.userReducer.trackers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateTracker: (tracker) => {
            dispatch({
                type: "UPDATE_TRACKER",
                tracker,
            })
        }
    }
}

class TrackCompanies extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            location: null,
            companies: [],
            grid: false,
            companyPreview: {},
        };
    }


    componentDidMount = async () => {
        this.getCompanies();
    }

    getCompanies = async () => {
        const res = await getUserCompanies(this.props.authUser);
        this.setState({companies: res.companies})
    }

    previewCompany = (company) => {
        this.setState({companyPreview: company, companyPeekOpen: true})
    }

    closeCompanyPreview = () => {
        this.setState({companyPeekOpen: false})
    }


    render() {
        const trackers = Object.values(this.props.trackers) || [];
        return (
            <>
                <div className='flex flex-1 overflow-hidden px-5'>
                    <div className='mt-2'>
                        <TableHeader
                            headers={COMPANY_TRACKER_TABLE_HEADERS}
                            selectedSort={null}
                            changeSort={() => null}
                        />
                    </div>
                    <PageLeftWrapper className={'overflow-y-scroll hide-scrollbar'}>
                        {this.state.companiesLoading ?
                            <JobBoardLoading/>
                            :
                            trackers.length === 0 ?
                                <div className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                    <SvgEmpty/>
                                    <p className='text-base mt-2 mb-6'>
                                        No companies saved...
                                    </p>
                                    <Button onClick={() => this.props.changeTab("Search")}>
                                        Find companies
                                    </Button>
                                </div>
                                :
                                <div
                                    className={`flex flex-col gap-0 ${this.state.grid && 'grid grid-cols-3 xl:grid-cols-4 gap-4'}`}>
                                    {trackers.map((item, index) => {
                                        if (item.type === "company") {
                                            const company = item.company || {};
                                            return (
                                                <TrackedCompanyItem
                                                    openCompanyPeek={this.previewCompany}
                                                    key={item.id} item={company} list={!this.state.grid}
                                                    tracker={item}
                                                    authUser={this.props.authUser}
                                                    updateTracker={this.props.updateTracker}/>
                                            )
                                        }
                                        return null 
                                    }
                                    )}
                                </div>
                        }
                    </PageLeftWrapper>
                    <CompanyPeek
                        company={this.state.companyPreview}
                        open={this.state.companyPeekOpen}
                        close={this.closeCompanyPreview}
                        inTracker
                    />
                </div>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackCompanies);
