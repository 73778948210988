import React, { useState, useEffect } from "react";
import Tooltip from "../components/atoms/modals/Tooltip";
import { connect } from "react-redux";

import ClubLogo from "../components-recruiter/atoms/ClubLogo";
import SchoolLogo from "../components-recruiter/atoms/SchoolLogo";
import ProfilePicture from "../components-recruiter/atoms/ProfilePicture";
import SvgLinkedin from "../components/icons/SvgLinkedIn";
import { DocumentIcon } from "@heroicons/react/24/solid";
import Checkbox from "../components/atoms/Checkbox";
import classNames from "classnames";
import { PencilSquareIcon, ArchiveBoxIcon, ArchiveBoxArrowDownIcon, ArchiveBoxXMarkIcon  } from '@heroicons/react/24/solid'
import AlertModal from "../components/atoms/modals/AlertModal";
import AdminEditStudentModal from "./AdminEditStudentModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { verifyUser, unverifyUser, reactivateUser, deactivateUser } from "../api/admin/users";
import { numToMonth } from "../utils/constants";
import * as Collapsible from '@radix-ui/react-collapsible';

import { RowSpacingIcon } from "@radix-ui/react-icons";

function AdminStudentManagementRow({ student = {}, columns, hasCheckbox, onStatusChange, studentClubs }) {
    const authUser = useSelector((state) => state.userReducer.authUser);
    const [isVerified, setIsVerified] = useState(student.manually_verified);
    const clubs = student.clubs || [];
    const education = student.education || [];
    const profileStatus = student.onboarded
        ? student?.profile_status?.full_profile === "complete"
            ? "Profile Complete"
            : "Onboarded"
        : "Account Created";

    useEffect(() => {
        setIsVerified(student.manually_verified);
    }, [student.manually_verified]);

    const handleDeactivateUser = async () => {
        try {
            await toast.promise(deactivateUser(authUser, student.id), {
                pending: "Deactivating student...",
                success: "Student deactivated successfully",
                error: "Failed to deactivate student"
            });
            onStatusChange?.(student.id, true);
        } catch (error) {
            console.error("Error deactivating user:", error);
        }
    }

    const handleReactivateUser = async () => {
        try {
            await toast.promise(reactivateUser(authUser, student.id), {
                pending: "Reactivating student...",
                success: "Student reactivated successfully",
                error: "Failed to reactivate student"
            });
            onStatusChange?.(student.id, false);
        } catch (error) {
            console.error("Error reactivating user:", error);
        }
    }

    const handleVerificationToggle = async () => {
        const newVerificationState = !isVerified;
        setIsVerified(newVerificationState); 

        try {
            const action = !newVerificationState ? unverifyUser : verifyUser;
            await action(authUser, student.id);
           
        } catch (error) {
            toast.error("Error toggling verification");
            console.error("Error toggling verification:", error);
            setIsVerified(!newVerificationState); // Revert on error
        }
    };

    return (
        <div
            className="grid gap-4 items-center p-4 bg-white hover:bg-slate-200/50 rounded-lg"
            style={{
                gridTemplateColumns: hasCheckbox
                    ? `minmax(40px, 60px) ${columns
                          .map((col) => col.width)
                          .join(" ")}`
                    : columns.map((col) => col.width).join(" "),
                minWidth: "fit-content",
            }}
        >
            {columns.map((column) => (
                <div
                    key={column.key}
                    className="overflow-hidden text-ellipsis text-sm text-slate-500"
                >
                    {renderColumnContent(
                        column,
                        student,
                        education,
                        clubs,
                        profileStatus,
                        handleDeactivateUser,
                        handleReactivateUser,
                        handleVerificationToggle,
                        studentClubs,
                        isVerified
                    )}
                </div>
            ))}
        </div>
    );
}

const renderColumnContent = (
    column,
    student,
    education,
    clubs,
    profileStatus,
    handleDeactivateUser,
    handleReactivateUser,
    handleVerificationToggle,
    studentClubs,
    isVerified
) => {
    switch (column.key) {
        case "actions":
            return (
                <div
                    className={classNames(
                        "flex flex-row items-center gap-3",
                        column.isCentered && "justify-center"
                    )}
                >
                    <AdminEditStudentModal student={student} />
                    {student.deactivated ? (
                        <AlertModal
                            title="Reactivate Student"
                            description="Are you sure you want to reactivate this student?"
                            onConfirm={handleReactivateUser}
                        > 
                            <ArchiveBoxArrowDownIcon className="w-6 h-6 text-slate-400 hover:text-green-500 cursor-pointer " />
                        </AlertModal>
                    ) : (
                        <AlertModal
                            title="Archive Student"
                            description="Are you sure you want to archive this student?"
                            onConfirm={handleDeactivateUser}
                        >
                           <ArchiveBoxXMarkIcon className="w-6 h-6 text-slate-400 hover:text-red-400 cursor-pointer" />
                        </AlertModal>
                    )}
                </div>
            );
        case "verification":
            return (
                <div
                    className={classNames(
                        "flex flex-row items-center",
                        column.isCentered && "justify-center"
                    )}
                >
                    <span
                        className="cursor-pointer text-xl"
                    >
                        <Checkbox checked={isVerified} onClick={handleVerificationToggle} />
                    </span>
                </div>
            );
        case "identity":
            return (
                <div
                    className={classNames(
                        "flex flex-row items-center gap-2",
                        column.isCentered && "justify-center"
                    )}
                >
                    <ProfilePicture
                        image={student.profile_pic_url}
                        userID={student.id}
                        size={40}
                        className="border border-slate-200"
                    />
                    <div className="flex flex-col gap-0.5">
                        <p className="text-slate-800 font-semibold">
                            {student.first_name} {student.last_name}
                        </p>
                        <p className="text-xs">{student.email}</p>

                        {student.secondary_email && (
                            <p className="text-xs text-slate-400">
                                Secondary Email: <br />{" "}
                                <span className="text-slate-500">
                                    {student.secondary_email}
                                </span>
                            </p>
                        )}
                        <p className="text-2xs text-slate-400">
                            {student.username}
                        </p>
                    </div>
                </div>
            );
        case "education":
            return (
                <div
                    className={classNames(
                        "flex items-center gap-2",
                        column.isCentered && "justify-center"
                    )}
                >
                    <Tooltip
                        toggleComponent={
                            <SchoolLogo
                                image={education[0]?.logo_url}
                                college={student.college}
                            />
                        }
                        label={student.college || "College"}
                    />
                    <div className="flex flex-col">
                        <p className="text-xs">{student.college}</p>
                        <p className="text-xs">{education[0]?.degree_name}</p>
                        <p className="text-xs">
                            {education[0]?.field_of_study}
                        </p>
                        <p className="text-xs">
                            <span className="text-slate-400">Class of:</span>{" "}
                            {student.grade}
                        </p>
                        <p className="text-xs">
                            <span className="text-slate-400">GPA:</span>{" "}
                            {student.gpa || "N/A"}
                        </p>
                        {student.sports?.length > 0 && (
                            <p className="text-xs">
                                <span className="text-slate-400">Sports:</span>{" "}
                                {student.sports.join(", ")}
                            </p>
                        )}
                    </div>
                </div>
            );
        case "links":
            return (
                <div
                    className={classNames(
                        "flex gap-2 items-center",
                        column.isCentered && "justify-center"
                    )}
                >
                    {student.linkedin && (
                        <a
                            href={student.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:text-blue-700"
                        >
                            <SvgLinkedin />
                        </a>
                    )}
                    {student.resume && (
                        <a
                            href={student.resume}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-slate-500 hover:text-slate-700"
                        >
                            <DocumentIcon className="w-6 h-6" />
                        </a>
                    )}
                </div>
            );
        case "clubs":
            const collapsedMaxClubs = 3;
            return (
                <div className={classNames(
                    "flex flex-col",
                    column.isCentered && "items-center"
                )}>
                    {clubs.length === 0 ? (
                        <div className="w-10 text-xs text-slate-300 text-center flex items-center justify-center aspect-square border-slate-100 bg-slate-50 border rounded-xl">
                            <p>N/A</p>
                        </div>
                    ) : (
                        <Collapsible.Root>
                            <div className="flex flex-col gap-2">
                                {clubs.slice(0, collapsedMaxClubs).map((clubId, index) => (
                                    <ClubItem key={index} clubId={clubId} studentClubs={studentClubs} />
                                ))}
                            </div>

                            {clubs.length > collapsedMaxClubs && (
                                <>
                                    <Collapsible.Content className="flex flex-col gap-2">
                                        {clubs.slice(collapsedMaxClubs).map((clubId, index) => (
                                            <ClubItem key={index + collapsedMaxClubs} clubId={clubId} studentClubs={studentClubs} />
                                        ))}
                                    </Collapsible.Content>

                                    <Collapsible.Trigger asChild>
                                        <button className="flex items-center justify-center gap-1 my-2 text-primary hover:text-primary-dark">
                                            <RowSpacingIcon className="w-3 h-3" />
                                            <span>Toggle {clubs.length - collapsedMaxClubs} Clubs</span>
                                        </button>
                                    </Collapsible.Trigger>
                                </>
                            )}
                        </Collapsible.Root>
                    )}
                </div>
            );
        case "bio":
            return (
                <div
                    className={classNames(
                        "flex flex-col overflow-hidden",
                        column.isCentered && "justify-center"
                    )}
                >
                    <Tooltip
                        label={student.bio || "N/A"}
                        toggleComponent={
                            <p className="text-xs line-clamp-4">
                                {student.bio || "N/A"}
                            </p>
                        }
                    />
                </div>
            );
        case "location":
            return (
                <div
                    className={classNames(
                        "text-xs",
                        column.isCentered && "text-center"
                    )}
                >
                    <p>
                        <span className="text-slate-400">City:</span>{" "}
                        {student.city}
                    </p>
                    <p>
                        <span className="text-slate-400">State:</span>{" "}
                        {student.state}
                    </p>
                </div>
            );
        case "job":
            return (
                <div
                    className={classNames(
                        "flex flex-col text-xs",
                        column.isCentered && "items-center"
                    )}
                >
                    <p>
                        <span className="text-slate-400">Have Job:</span>{" "}
                        {student.have_job ? "Yes" : "No"}
                    </p>
                    <p>
                        <span className="text-slate-400">Job Space:</span>{" "}
                        {student.job_space || "N/A"}
                    </p>
                    <p>
                        <span className="text-slate-400">Job Type:</span>{" "}
                        {student.job_type || "N/A"}
                    </p>
                    <p>
                        <span className="text-slate-400">Seeking Role:</span>{" "}
                        {student.seeking_role ? "Yes" : "No"}
                    </p>
                    <p>
                        <span className="text-slate-400">
                            Viewed Job Suggestions:
                        </span>{" "}
                        {student.viewed_suggested_jobs ? "Yes" : "No"}
                    </p>
                </div>
            );
        case "account":
            return (
                <div
                    className={classNames(
                        "flex flex-col text-xs",
                        column.isCentered && "items-center"
                    )}
                >
                    {student.deactivated ? (
                        <span className="px-2 py-1 rounded bg-slate-100 text-slate-800">
                            Archived
                        </span>
                    ) : (
                        <span
                            className={`px-2 py-1 rounded ${
                                profileStatus === "Profile Complete"
                                    ? "bg-green-100 text-green-800"
                                    : profileStatus === "Onboarded"
                                    ? "bg-yellow-100 text-yellow-800"
                                    : "bg-red-100 text-red-800"
                            }`}
                        >
                            {profileStatus}
                        </span>
                    )}
                    <p>
                        Created:{" "}
                        {new Date(student.created_at).toLocaleDateString()}
                    </p>
                    {student.profile_updated_at && (
                        <p>
                            Last Updated:{" "}
                            {new Date(
                                student.profile_updated_at
                            ).toLocaleDateString()}
                        </p>
                    )}
                    <p>Source: {student.onboarding_source || "N/A"}</p>
                </div>
            );
        case "diversity":
            return (
                <div
                    className={classNames(
                        "flex flex-col text-xs",
                        column.isCentered && "items-center"
                    )}
                >
                    <p>
                        <span className="text-slate-400">Authorized:</span>{" "}
                        {student.diversity?.authorized || "N/A"}
                    </p>
                    <p>
                        <span className="text-slate-400">Disabled:</span>{" "}
                        {student.diversity?.disabled || "N/A"}
                    </p>
                    <p>
                        <span className="text-slate-400">First Gen:</span>{" "}
                        {student.diversity?.first_generation || "N/A"}
                    </p>
                    <p>
                        <span className="text-slate-400">Gender:</span>{" "}
                        {student.diversity?.gender || "N/A"}
                    </p>
                    <p>
                        <span className="text-slate-400">LGBTQ:</span>{" "}
                        {student.diversity?.lgbtq || "N/A"}
                    </p>
                    <p>
                        <span className="text-slate-400">Veteran:</span>{" "}
                        {student.diversity?.veteran || "N/A"}
                    </p>
                    {student.diversity?.race?.map((race, index) => (
                        <p key={index}>
                            <span className="text-slate-400">
                                Race {index + 1}:
                            </span>{" "}
                            {race}
                        </p>
                    ))}
                </div>
            );
        case "experience":
            const collapsedMaxExperiences = 2;
            return (
                <div className={classNames(
                    "flex flex-col text-xs gap-2",
                    column.isCentered && "items-center"
                )}>
                    {(student.experiences || []).length > 0 ? (
                        <Collapsible.Root>
                            <div className="flex flex-col gap-2">
                                {/* Always visible experiences */}
                                {student.experiences.slice(0, collapsedMaxExperiences).map((experience, index) => (
                                    <ExperienceItem key={index} experience={experience} />
                                ))}
                            </div>

                            {student.experiences.length > collapsedMaxExperiences && (
                                <>
                                    <Collapsible.Content className="flex flex-col gap-2">
                                        {student.experiences.slice(collapsedMaxExperiences).map((experience, index) => (
                                            <ExperienceItem key={index + collapsedMaxExperiences} experience={experience} />
                                        ))}
                                    </Collapsible.Content>

                                    <Collapsible.Trigger asChild>
                                        <button className="flex items-center justify-center gap-1 my-2 text-primary hover:text-primary-dark">
                                            <RowSpacingIcon className="w-3 h-3" />
                                            <span>Toggle {student.experiences.length - collapsedMaxExperiences} Experiences</span>
                                            
                                        </button>
                                    </Collapsible.Trigger>
                                </>
                            )}
                        </Collapsible.Root>
                    ) : (
                        <p className="text-slate-400">No experience listed</p>
                    )}
                </div>
            );
        default:
            return student[column.key] || "-";
    }
};

const ExperienceItem = ({ experience }) => {
    const startsAt = experience.starts_at || {};
    const endsAt = experience.ends_at || {};
    const ending = Object.keys(endsAt).length === 0 ? "Present" : `${numToMonth[endsAt.month]} ${endsAt.year}`;
    const logoUrl = experience.logo_url || "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fcover_image%2FdefaultCompanyLogo.jpg?alt=media";

    return (
        <div className="flex flex-row items-start gap-3 border-b border-slate-100 last:border-0 pb-2 last:pb-0">
            <img
                src={logoUrl}
                className="w-8 h-8 rounded-md border-neutral-200 border bg-white object-contain"
                onError={(e) => {
                    e.target.src = "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fcover_image%2FdefaultCompanyLogo.jpg?alt=media";
                }}
            />
            <div className="flex flex-col flex-1">
                <p className="font-semibold text-neutral-900">{experience.title}</p>
                <p className="text-neutral-500">{experience.company}</p>
                <p className="text-neutral-400">
                    {numToMonth[startsAt.month]} {startsAt.year} - {ending}
                </p>
                {experience.location && (
                    <p className="text-neutral-400 text-2xs">{experience.location}</p>
                )}
                {experience.description && (
                    <Tooltip
                        label={experience.description}
                        toggleComponent={
                            <p className="text-neutral-500 line-clamp-2 text-2xs mt-1">{experience.description}</p>
                        }
                    />
                )}
            </div>
        </div>
    );
};

const ClubItem = ({ clubId, studentClubs }) => {

    const club = studentClubs.find(c => c.id === clubId) || {};
    
    return (
        <div className="flex flex-row items-center gap-3 border-b border-slate-100 last:border-0 pb-2 last:pb-0">
            <ClubLogo
                clubId={clubId}
                className="border border-slate-200"
                size={30}
            />
            <div className="flex flex-col flex-1 ">
                <p className="text-xs line-clamp-1">{club.name || 'Unknown Club'}</p>
            </div>
        </div>
    );
};

export default connect()(AdminStudentManagementRow);
