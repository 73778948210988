import React from "react";
import ExperienceListItem from "../profilepage/ExperienceListItem";
import EditProfileWrapper from "./EditProfileWrapper";
import EditProfileCtaButton from "./EditProfileCtaButton";
import { useSelector } from "react-redux";
import SvgRestart from "../icons/SvgRestart";
import AlertModal from "../atoms/modals/AlertModal";

export default function EditProfileExperienceDetails({ loading, handleResync, goToNextSection, hasNextSection }) {
    const user = useSelector((state) => state.userReducer.user);

    return (
        <>
            <EditProfileWrapper>
                {user.experiences.map((experience, i) => (
                    <ExperienceListItem
                        key={experience.ends_at + i + experience.title}
                        experience={experience}
                        last={i === user.experiences.length - 1}
                        i={i}
                    />
                ))}
            </EditProfileWrapper>
            <div className="grid grid-cols-4 gap-4">
                {user.linkedin && (
                    <div className={hasNextSection ? "col-span-3" : "col-span-4"}>
                        <AlertModal
                            title="Are you sure you want to re-sync your LinkedIn profile? "
                            description="This will automatically update your Professional Experience from LinkedIn. Note: you can only re-sync your LinkedIn once every 12 hours"
                            onConfirm={handleResync}
                            disabled={loading}
                        >
                            <EditProfileCtaButton>
                                <div className='flex flex-row justify-center'>
                                    <SvgRestart className={'mr-2'}/>
                                    Resync your LinkedIn profile to update
                                </div>
                            </EditProfileCtaButton>
                        </AlertModal>
                    </div>
                )}
                {hasNextSection && (
                    <div className={user.linkedin ? "col-span-1" : "col-span-4"}>
                        <EditProfileCtaButton onClick={goToNextSection} disabled={loading}>
                            Next section
                        </EditProfileCtaButton>
                    </div>
                )}
            </div>
        </>
    );
}
