export const downloadCSV = (data, filename) => {
    // Create a Blob with UTF-8 BOM to ensure Excel recognizes the encoding
    const BOM = '\uFEFF';
    const blob = new Blob([BOM + data], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    
    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

export const formatStudentForCSV = (student, type = 'full') => {
    if (type === 'email') {
        return {
            Email: student.email
        };
    }

    return {
        first_name: student.first_name,
        last_name: student.last_name,
        email: student.email,
        secondary_email: student.secondary_email,
        username: student.username,
        college: student.college,
        degree_name: student.education?.[0]?.degree_name,
        field_of_study: student.education?.[0]?.field_of_study,
        grade: student.grade,
        gpa: student.gpa,
        sports: student.sports?.join(':'),
        linkedin: student.linkedin,
        resume: student.resume,
        clubs: (student.clubs || []).join(':'),
        bio: student.bio,
        city: student.city,
        state: student.state,
        have_job: student.have_job ? 'Yes' : 'No',
        job_space: student.job_space,
        job_type: student.job_type,
        seeking_role: student.seeking_role ? 'Yes' : 'No',
        viewed_suggested_jobs: student.viewed_suggested_jobs ? 'Yes' : 'No',
        profile_status: student.onboarded 
            ? (student.completed_personalization ? "Profile Complete" : "Onboarded")
            : "Account Created",
        created_at: new Date(student.created_at).toLocaleDateString(),
        profile_updated_at: student.profile_updated_at 
            ? new Date(student.profile_updated_at).toLocaleDateString()
            : '',
        onboarding_source: student.onboarding_source,
        authorized: student.diversity?.authorized,
        disabled: student.diversity?.disabled,
        first_generation: student.diversity?.first_generation,
        gender: student.diversity?.gender,
        lgbtq: student.diversity?.lgbtq,
        veteran: student.diversity?.veteran,
        race: student.diversity?.race?.join(':'),
    };
}; 