import React from "react";
import Checkbox from "../../components/atoms/Checkbox";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

function RecruiterDashboardTable({
    columns,
    TableBody,
    hasCheckbox,
    checked,
    toggleCheckbox,
    containerStyle,
    onSort,
    sortStates = {}
}) {
    const getSortIcon = (sortState) => {
        switch (sortState) {
            case 1:
                return <BsArrowDown className="text-blue-500" size={16}/>;
            case 2:
                return <BsArrowUp className="text-blue-500" size={16}/>;
            default:
                return <BsArrowDown className="text-slate-400" size={16}/>;
        }
    };

    return (
        <div className={`flex flex-col border rounded-xl bg-white overflow-hidden ${containerStyle}`}>
            <div className='flex flex-row items-center py-2 px-5 border-b bg-slate-50'>
                {hasCheckbox &&
                    <div className='flex-[0.25] flex items-center justify-center'>
                        <input
                            type="checkbox"
                            checked={checked}
                            onChange={toggleCheckbox}
                            className="checkbox mr-2"
                        />
                    </div>
                }
                {columns.map((column, index) => (
                    <div
                        key={index}
                        className={`flex items-center`}
                        style={{flex: column.flex}}
                    >
                        <span className='text-sm font-medium text-slate-600 whitespace-nowrap'>
                            {column.label}
                        </span>
                        {column.sortable && (
                            <div 
                                className="ml-2 cursor-pointer" 
                                onClick={() => onSort?.(column.key)}
                            >
                                {getSortIcon(sortStates[column.key] || 0)}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {TableBody}
        </div>
    );
}

export default RecruiterDashboardTable;
