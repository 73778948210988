import React, {useEffect, useState} from "react";
import Spinner from "../../components/Spinner";
import {connect} from "react-redux";
import MessageStudentFlow from "../MessageStudentFlow";
import {Button} from "../../components/atoms/Button";
import {deleteList, editList, getLists, getSingleList} from "../../api/recruiter/lists";
import EditListModal from "../modals/EditListModal";
import { DateTime } from 'luxon';
import ClubLogo from "../atoms/ClubLogo";
import SvgSchool from "../../components/icons/SvgSchool";
import SvgClose from "../../components/icons/SvgClose";
import RecruiterDashboardTable from "../dashboard/RecruiterDashboardTable";
import {SAVED_LISTS_TABLE_HEADERS} from "../../utils/dummy";
import RecruiterFavoritesTableItem from "../RecruiterFavoritesTableItem";
import SvgMoreVertical from "../../components/icons/SvgMoreVertical";
import GoBack from "../../components/atoms/GoBack";
import SmartListModal from "../modals/SmartListModal";
import SvgStars from "../../components/icons/SvgStars";
import { NUM_STUDENTS_SHOWN } from "../../utils/constants";
import {downloadAllResumes} from "../../api/student/clubs";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { TOAST_OPTIONS } from "../../utils/constants";
import SearchHeaderWrapper from "../atoms/SearchHeaderWrapper";
import { MAJOR_OPTIONS } from "../../utils/dummy";
import { filterUsers } from "../../utils/searchUtils";
import { useMemo } from "react";
import { useCallback } from "react";
import { searchClubs } from "../../utils/typesense";
import {searchUsers} from "../../api/recruiter/users";
import FiltersSidebar from "../atoms/FiltersSidebar";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        recruiterCompany: state.userReducer.recruiterCompany,
        tier: state.userReducer.tier,
        constants: state.userReducer.constants,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: (clubs) => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
                clubs
            })
        },
        toggleCreateEventModal: (clubs) => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
                clubs
            })
        },
        toggleClubPeek: () => {
            dispatch({
                type: "TOGGLE_CLUB_PEEK",
            })
        },
        setClubPeekClub: (club) => {
            dispatch({
                type: "SET_CLUB_PEEK_CLUB",
                user: club,
            })
        },
    }
}


function RecruiterSavedClubsPage({
                                     authUser,
                                     toggleCreateEventModal,
                                     toggleCreateJobModal,
                                     toggleClubPeek,
                                     setClubPeekClub,
                                     history,
                                     recruiterCompany,
                                     tier,
                                     constants
                                 }) {
    const [type, setType] = useState("user");
    const [list, setList] = useState({});
    const [error, setError] = useState(false);
    const [IsEditClubListModalOpen, setIsEditClubListModalOpen] = useState(false)
    const [activeTab, setActiveTab] = useState('Students');
    const [isEditing, setIsEditing] = useState(false);
    const [page, setPage] = useState(1);
    const [hideMoreStudents, setHideMoreStudents] = useState(false);
    const [moreStudentsLoading, setMoreStudentsLoading] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [removeStudentConfirmationModal, setRemoveStudentConfirmationModal] = useState(false);
    const [currentStudentId, setCurrentStudentId] = useState(null);
    const [search, setSearch] = useState("");
    const [majors, setMajors] = useState([]);
    const [classYears, setClassYears] = useState([]);
    const [gpa, setGpa] = useState("");
    const [gender, setGender] = useState("");
    const [race, setRace] = useState("");
    const [veteran, setVeteran] = useState(false);
    const [lgbtq, setLgbtq] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [college, setCollege] = useState("");
    const [clubs, setClubs] = useState([]);
    const [selectedClubs, setSelectedClubs] = useState([]);

    const MAJOR_CATEGORIES = constants.major_categories;

    const selectedClubsMappedToIds = useMemo(() => {
        return clubs.filter(club => selectedClubs.includes(club.name)).map(club => club.id);
    }, [selectedClubs, clubs]);

    const delList = async () => {
        await deleteList(authUser, list.id, type);
        history.push('/saved-lists')
    }

    const remove = async () => {
        await editList(authUser, list.id, type, {
            [`${type}_ids`]: [currentStudentId]
        })
        setRemoveStudentConfirmationModal(false);
        setCurrentStudentId(null);
        getList();
    }

    useEffect(() => {
        getList();
    }, [])

    const getList = async () => {
        setLoading(true);
        const parts = window.location.pathname.split("/");
        const lastArg = parts[3];
        const type = parts[2];
        const listId = lastArg.split("?")[0];

        const res = await getSingleList(authUser, listId, type)
        const list = res.list
        if (type === "smart") {
            await fetchUserSearch("", list);
        } else {
            setList(list);
        }
        setType(type);
        setLoading(false);
    }

    const fetchUserSearch = useCallback(async (query, currentList) => {
        const page = 1
        setPage(page);
        const clubId = tier === "blk" ? "baeZVpmndkm8fCNGFIuZ" : null;
        const result = await searchUsers(authUser, query, page, clubId, currentList.grades, currentList.majors, currentList.colleges, currentList.gpa, currentList.gender, currentList.race, currentList.veteran, currentList.lgbtq, currentList.disabled, currentList.authorized, currentList.industries || [], null, currentList.current_companies ?? [], currentList.previous_companies ?? [], currentList.is_ib ?? null);
        setList({
            ...currentList,
            users: result.students,
            total: result.total
        });
        setHideMoreStudents(result.students.length < NUM_STUDENTS_SHOWN);
    }, [list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries]);

    const filteredUsers = useMemo(() => {
        if (!list.users || type === 'smart') return [];
        return filterUsers(list.users, {
            search,
            majors,
            classYears,
            gpa,
            gender,
            race,
            veteran,
            lgbtq,
            disabled,
            authorized,
            college,
            selectedClubsMappedToIds
        });
    }, [list.users, search, majors, classYears, gpa, gender, race, veteran, lgbtq, disabled, authorized, college, type, selectedClubsMappedToIds]);

    const getNextPageInfo = useCallback(async () => {
        setMoreStudentsLoading(true);
        const clubId = tier === "blk" ? "baeZVpmndkm8fCNGFIuZ" : null;
        const newUsers = await searchUsers(authUser, search, page + 1, clubId, list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries || [], null, list.current_companies ?? [], list.previous_companies ?? [], list.is_ib ?? null);
        setList(prevList => ({
            ...prevList,
            users: [...prevList.users, ...newUsers.students]
        }));
        setPage(prevPage => prevPage + 1);
        setMoreStudentsLoading(false);
        setHideMoreStudents(newUsers.students.length < NUM_STUDENTS_SHOWN);
    }, [search, page, list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries]);

    const downloadCsv = async () => {
        let csv = '';
        if (type === 'club') {
            csv = `${list.name}\n`;
            csv += 'Club Name,School\n';
            const clubsToDownload = list.clubs || [];
            csv += clubsToDownload.map(club => `"${club.name.replace(/"/g, '""')}","${club.college.replace(/"/g, '""')}"`).join('\n');
        } else if (type === 'smart') {
            let usersToDownload = [...list.users];
            for (let page = 2; page < 100; page += 1) {
                const clubId = tier === "blk" ? "baeZVpmndkm8fCNGFIuZ" : null;
                const newUsers = await searchUsers(authUser, search, page, clubId, list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries || [], null, list.current_companies ?? [], list.previous_companies ?? [], list.is_ib ?? null);
                usersToDownload = [...usersToDownload, ...newUsers.students];
                if (newUsers.students.length < NUM_STUDENTS_SHOWN) {
                    break
                }
            }
            csv = `name,email,class year,college,${recruiterCompany.id === "recruitu" ? 'user id,' : ''}gpa,majors,minors,authorized to work,disability,first generation,gender,race,requires sponsorship,veteran\n`;
            csv += usersToDownload.map(row => `${row.first_name} ${row.last_name},${row.email},${row.grade},"${row.college.replace(/"/g, '""')}",${recruiterCompany.id === "recruitu" ? `${row.id},` : ''}${row.gpa},${row?.major.join("/")},${row?.minor?.join("|")},${row?.diversity?.authorized},${row.diversity?.disabled},${row.diversity?.first_generation},${row.diversity?.gender},${row.diversity?.race?.join("|")},${row.diversity?.require_sponsorship},${row.diversity?.veteran}`).join('\n');
        } else {
            const usersToDownload = filteredUsers;
            csv = `name,email,class year,college,${recruiterCompany.id === "recruitu" ? 'user id,' : ''}gpa,majors,minors,authorized to work,disability,first generation,gender,race,requires sponsorship,veteran\n`;
            csv += usersToDownload.map(row => `${row.first_name} ${row.last_name},${row.email},${row.grade},"${row.college.replace(/"/g, '""')}",${recruiterCompany.id === "recruitu" ? `${row.id},` : ''}${row.gpa},${row?.major.join("/")},${row?.minor?.join("|")},${row?.diversity?.authorized},${row.diversity?.disabled},${row.diversity?.first_generation},${row.diversity?.gender},${row.diversity?.race?.join("|")},${row.diversity?.require_sponsorship},${row.diversity?.veteran}`).join('\n');
        }

        // Create a Blob object from the CSV data
        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);

        // Create a link element and click it programmatically to trigger download
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${list.name}.csv`);
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const handleDownload = async (year = "*") => {
        try {
            let usersToDownload = [];
            if (type === 'smart') {
                usersToDownload = [...list.users];
                for (let page = 2; page < 100; page += 1) {
                    const clubId = tier === "blk" ? "baeZVpmndkm8fCNGFIuZ" : null;
                    const newUsers = await searchUsers(authUser, search, page, clubId, list.grades, list.majors, list.colleges, list.gpa, list.gender, list.race, list.veteran, list.lgbtq, list.disabled, list.authorized, list.industries || [], null, list.current_companies ?? [], list.previous_companies ?? [], list.is_ib ?? null);
                    usersToDownload = [...usersToDownload, ...newUsers.students];

                    if (newUsers.students.length < NUM_STUDENTS_SHOWN) {
                        break
                    }
                }
            } else {
                usersToDownload = filteredUsers;
            }
            const resumePromise = downloadAllResumes(authUser, usersToDownload);
            toast.promise(
                resumePromise,
                {
                    pending: "Downloading resumes...",
                    success: "Resumes downloaded successfully!",
                    error: "No resumes available for these filters",
                },
                TOAST_OPTIONS
            );
            const resumeResult = await resumePromise;
            if (!resumeResult) {
                setError(true);
            } else {
                setError(false);
            }
        } catch (error) {
            console.log("Error no resumes this year");
        }
    }

    const [loading, setLoading] = useState(false)
    if (loading) {
        return (
            <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                <Spinner size={40}/>
            </div>
        )
    }

    const users = list?.users || [];
    // const clubs  = list?.clubs || [];
    const listCreation = DateTime.fromISO(list.created_at);
    const colleges = list?.colleges || [];

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        if (type === 'smart') {
            fetchUserSearch(value, list);
        }
    };

    const updateMajor = (majors) => {
        if (majors === null) {
            setMajors([]);
            return;
        }

        setMajors(prevMajors =>
            prevMajors.includes(majors)
                ? prevMajors.filter(major => major !== majors)
                : [...prevMajors, majors]
        );
    };

    const updateClassYears = (value) => {
        if (value === null) {
            setClassYears([]);
            return;
        }

        setClassYears(prevClassYears =>
            prevClassYears.includes(value)
                ? prevClassYears.filter(year => year !== value)
                : [...prevClassYears, value]
        );
    };

    const updateGpa = (value) => {
        setGpa(value);
    };

    const removeStudents = (id) => {
        setIsEditing(true);
    }

    const clickRemoveStudent = (id) => {
        setCurrentStudentId(id);
        setRemoveStudentConfirmationModal(true)
    }

    const handleClubChange = (value) => {
        if (value === null) {
            setSelectedClubs([]);
            return;
        }
        setSelectedClubs(prevSelectedClubs =>
            prevSelectedClubs.includes(value)
                ? prevSelectedClubs.filter(club => club !== value)
                : [...prevSelectedClubs, value]
        );
    }

    const updateDiversity = (field, value) => {
        switch (field) {
            case 'gender':
                setGender(value);
                break;
            case 'race':
                setRace(value);
                break;
            case 'veteran':
                setVeteran(value);
                break;
            case 'lgbtq':
                setLgbtq(value);
                break;
            case 'disabled':
                setDisabled(value);
                break;
            case 'authorized':
                setAuthorized(value);
                break;
        }
    };

    const setSchool = async (value) => {
        setCollege(value);
        if (value) {
            try {
                const clubResults = await searchClubs("", 1, 100, value);
                setClubs(clubResults);
            } catch (error) {
                setClubs([]);
            }
        } else {
            setClubs([]);
        }
    };

    const openClubPeek = (club) => {
        toggleClubPeek();
        setClubPeekClub(club);
    }

    const getMajorCategories = () => {
        const categoryCounts = {};
        list.majors.forEach(major => {
            // Find which category this major belongs to
            for (const [category, majors] of Object.entries(MAJOR_CATEGORIES)) {
                if (majors.includes(major)) {
                    categoryCounts[category] = (categoryCounts[category] || 0) + 1;
                    break;
                }
            }
        });
        return categoryCounts;
    }

    return (
        <div className={`flex flex-col flex-1 overflow-hidden`}>
            <div className='flex flex-row items-end justify-between p-[12px] pb-0'>
                <div>
                    <GoBack/>
                    <div className='flex flex-row items-center mt-1 gap-2 mb-2'>
                        <p className='text-slate-800 text-2xl font-semibold'>
                            {list.name}
                            <span className='text-slate-500'>
                            {` (${
                                type === 'club'
                                    ? list.clubs?.length ?? 0
                                    : type === 'smart'
                                        ? list.total
                                        : filteredUsers.length
                            } ${type === 'club' ? 'clubs' : 'students'})`}
                        </span>
                        </p>
                        {type === 'smart' &&
                            <SvgStars className={'text-primary'}/>
                        }
                    </div>
                </div>
                <div className='flex flex-row gap-3'>
                    {type === "user" && <MessageStudentFlow users={list[`users`]} buttonVariant={'secondary'}/>}
                    {isEditing &&
                        <Button
                            variant={'green'}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(false);
                            }}>
                            Done Editing
                        </Button>
                    }
                    <div
                        className={`group z-50 relative py-3 ml-1 pointer px-1 flex flex-row items-center h-10 hover:bg-white/10 active:bg-white/20 rounded-lg cursor-pointer`}>
                        <Button
                            icon={SvgMoreVertical} size={'icon'}
                            variant={'secondary'}
                        />
                        <div
                            className='hidden w-[200px] group-hover:block absolute p-1 top-[40px] right-0 bg-white shadow-xl border border-slate-300 rounded-xl'>
                            <div className=' w-full hover:text-primary hover:bg-slate-100 rounded-lg'>
                                <div onClick={downloadCsv} className='px-3 py-2 hover:bg-slate-100 rounded-lg'>
                                    Download CSV
                                </div>
                            </div>
                            {type !== "club" &&
                                <div className='w-full hover:text-primary hover:bg-slate-100 rounded-lg'>
                                    <div className='block px-3 py-2 w-full' onClick={handleDownload}>
                                        Download Resumes
                                    </div>
                                </div>
                            }
                            {type === "user" &&
                                <div className='w-full hover:text-primary hover:bg-slate-100 rounded-lg'>
                                    <div className='block px-3 py-2 w-full' onClick={removeStudents}>
                                        Remove Candidates
                                    </div>
                                </div>
                            }
                            <div className='w-full hover:text-primary'>
                                <div onClick={(e) => {
                                    e.stopPropagation()
                                    setIsEditClubListModalOpen(true)
                                }} className='px-3 py-2 hover:bg-slate-100 rounded-lg'>
                                    Edit List
                                </div>
                            </div>
                            <div className='w-full hover:text-primary'>
                                <div onClick={(e) => {
                                    e.stopPropagation();
                                    setConfirmationModalOpen(true);
                                }} className='px-3 py-2 hover:bg-slate-100 rounded-lg'>
                                    Delete List
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={'flex flex-row flex-wrap px-[12px] gap-4 opacity-70'}>
                <div className='flex flex-row items-end '>
                    <p className='text-slate-800 text-sm'>
                        <strong>Created:</strong> {listCreation.toLocaleString(DateTime.DATETIME_MED)} {!!list.creator_name && ` by ${list.creator_name}`}
                    </p>
                </div>
                {colleges.length ?
                    <div className='flex flex-row items-end'>
                        <p className='text-slate-800 text-sm'>
                            <strong>Colleges:</strong> {list.colleges.join(", ")}
                        </p>
                    </div>
                    : null
                }
                {type === 'smart' && (
                    <>
                        {list.grades?.length > 0 && (
                            <div className='flex flex-row items-end'>
                                <p className='text-slate-800 text-sm'>
                                    <strong>Class Years:</strong> {list.grades.join(", ")}
                                </p>
                            </div>
                        )}
                        {list.majors?.length > 0 && (
                            <div className='flex flex-row items-end'>
                                <p className='text-slate-800 text-sm'>
                                    <strong>Majors:</strong> {
                                        Object.entries(getMajorCategories())
                                            .map(([category, count]) => `${category} (${count})`)
                                            .join(", ")
                                    }
                                </p>
                            </div>
                        )}
                        {list.current_companies?.length > 0 && (
                            <div className='flex flex-row items-end'>
                                <p className='text-slate-800 text-sm'>
                                    <strong>Current Companies:</strong> {list.current_companies.join(", ")}
                                </p>
                            </div>
                        )}
                        {list.previous_companies?.length > 0 && (
                            <div className='flex flex-row items-end'>
                                <p className='text-slate-800 text-sm'>
                                    <strong>Previous Companies:</strong> {list.previous_companies.join(", ")}
                                </p>
                            </div>
                        )}
                        {list.gpa && (
                            <div className='flex flex-row items-end'>
                                <p className='text-slate-800 text-sm'>
                                    <strong>Min GPA:</strong> {list.gpa}
                                </p>
                            </div>
                        )}
                        {list.industries?.length > 0 && (
                            <div className='flex flex-row items-end'>
                                <p className='text-slate-800 text-sm'>
                                    <strong>Industries:</strong> {list.industries.join(", ")}
                                </p>
                            </div>
                        )}
                        {list.race?.length > 0 && (
                            <div className='flex flex-row items-end'>
                                <p className='text-slate-800 text-sm'>
                                    <strong>Race:</strong> {list.race.join(", ")}
                                </p>
                            </div>
                        )}
                        {list.gender && (
                            <div className='flex flex-row items-end'>
                                <p className='text-slate-800 text-sm'>
                                    <strong>Gender:</strong> {list.gender}
                                </p>
                            </div>
                        )}
                        {(list.veteran || list.lgbtq || list.disabled || list.authorized || list.is_ib) && (
                            <div className='flex flex-row items-end'>
                                <p className='text-slate-800 text-sm'>
                                    <strong>Filters:</strong> {[
                                        list.veteran && "Veteran",
                                        list.lgbtq && "LGBTQ+",
                                        list.disabled && "Disability",
                                        list.authorized && "Work Authorized",
                                        list.is_ib && "Investment Banking"
                                    ].filter(Boolean).join(", ")}
                                </p>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className={'flex flex-1 flex-col overflow-hidden px-[12px] pb-[12px]'}>
                <div className='flex flex-col flex-1 overflow-y-scroll'>
                    {type === "club" ?
                        <div className='pt-3'>
                            {/* <div>
                                {type === "club" &&
                                    <div className='bg-primary/10 rounded-2xl p-3 flex flex-row items-center mb-2'>
                                        <div className='flex flex-row items-center gap-2 text-primary mr-a'>
                                            <SvgShare/>
                                            <p className='font-bold'>
                                                Post to this clubs list
                                                ({list[`clubs`].length} {'club'}{list[`clubs`].length !== 1 && "s"}):
                                            </p>
                                        </div>
                                        <div className='flex flex-row items-center gap-2'>
                                            <Button variant={'default'}
                                                    icon={SvgPlus}
                                                    onClick={() => {
                                                        window.analytics.track("Recruiter Create New Job Clicked");
                                                        toggleCreateJobModal(list.clubs);
                                                    }}>
                                                New Job
                                            </Button>
                                            <Button
                                                variant={'default'}
                                                icon={SvgPlus}
                                                onClick={() => {
                                                    window.analytics.track("Recruiter Create New Event Clicked");
                                                    toggleCreateEventModal(list.clubs);
                                                }}>
                                                New Event
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div> */}
                            {type === "club" && list.clubs?.length === 0 ?
                                <div className='flex flex-col items-center py-12 gap-3'>
                                    <p className='text-slate-500'>
                                        No clubs added yet
                                    </p>
                                    <Button onClick={() => setIsEditClubListModalOpen(true)}>
                                        Add clubs
                                    </Button>
                                </div>
                                : list.clubs?.map(item =>
                                    <div
                                        key={item.id}
                                        onClick={() => openClubPeek(item)}
                                        className={'p-2 shadow-lg shadow-slate-200 border border-slate-200 rounded-xl bg-white  mb-2 cursor-pointer flex flex-row hover:bg-slate-50 items-center gap-3'}>
                                        <div className='flex flex-row items-center gap-5'>
                                            <ClubLogo clubId={item.id} size={64} backupUrl={item.logo_url}
                                                      className={'border bg-white border-slate-200'}/>
                                            <div className='mr-2 flex flex-col gap-2'>
                                                <p className='body1-bold m-0 text-gray-800'>
                                                    {item.name}
                                                </p>
                                                <div className='flex flex-row items-center text-neutral-400 gap-1'>
                                                    <SvgSchool className={'w-4 h-4'}/>
                                                    <p className='body2 m-0'>
                                                        {item.college}&nbsp; {!!item.founded && `• Est. ${item.founded}`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {isEditing &&
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    remove(item.id)
                                                }}
                                                size={'icon'} variant={'destructive'} icon={SvgClose}
                                                className='ml-auto'/>
                                        }
                                    </div>
                                )}
                        </div>
                        :
                        <div className='flex-1 flex flex-col overflow-hidden'>
                            <div className='flex-1 flex flex-row gap-3 overflow-hidden'>
                                <div className={'w-[250px] flex flex-col'}>
                                    <FiltersSidebar
                                        search={{
                                            placeholder: 'Search by name...',
                                            value: search,
                                            onChange: handleSearchChange,
                                        }}
                                        school={{
                                            setSchool: setSchool,
                                            schoolSearch: type !== 'smart' ? college : undefined
                                        }}
                                        majorSelect={type !== 'smart' && {
                                            label: 'Major',
                                            value: majors,
                                            setValue: updateMajor,
                                            options: MAJOR_OPTIONS,
                                            multiSelect: true,
                                        }}
                                        classYearSelect={type !== 'smart' && {
                                            label: 'Class Year',
                                            value: classYears,
                                            setValue: updateClassYears,
                                            options: [{title: "2025",}, {title: "2026",}, {title: "2027",}, {title: "2028",}],
                                            multiSelect: true
                                        }}
                                        gpaSelect={type !== 'smart' && {
                                            label: 'Min GPA',
                                            value: gpa,
                                            setValue: updateGpa,
                                            options: [{title: "3.9",}, {title: "3.8",}, {title: "3.7",}, {title: "3.6",}, {title: "3.5",}, {title: "3.4",}, {title: "3.3",}, {title: "3.2",}, {title: "3.1",}, {title: "3.0",}],
                                        }}
                                        genderSelect={type !== 'smart' && {
                                            label: 'Gender',
                                            value: gender,
                                            setValue: (value) => updateDiversity('gender', value),
                                            options: [{title: "Male",}, {title: "Female",}, {title: "Prefer Not to Specify",}],
                                        }}
                                        raceSelect={type !== 'smart' && {
                                            label: 'Race',
                                            value: race,
                                            setValue: (value) => updateDiversity('race', value),
                                            options: [{title: "White",}, {title: "Hispanic or Latino",}, {title: "Black or African American",}, {title: "American Indian or Native American",}, {title: "Asian or Pacific Islander",}, {title: "Other",}, {title: "Prefer Not to Specify",}],
                                        }}
                                        checkboxes={type !== 'smart' ? [
                                            {
                                                label: 'Veteran',
                                                active: veteran,
                                                onClick: () => updateDiversity("veteran", !veteran)
                                            },
                                            {
                                                label: 'LGBTQ',
                                                active: lgbtq,
                                                onClick: () => updateDiversity("lgbtq", !lgbtq)
                                            },
                                            {
                                                label: 'Disability',
                                                active: disabled,
                                                onClick: () => updateDiversity("disabled", !disabled)
                                            },
                                            {
                                                label: 'Authorized to work',
                                                active: authorized,
                                                onClick: () => updateDiversity("authorized", !authorized)
                                            },
                                        ] : []}
                                    />
                                </div>
                                <div className='flex flex-col overflow-hidden flex-1'>
                                    <div className={' py-2 text-lg font-semibold'}>
                                        {type === 'smart' ? list.total : filteredUsers.length} students
                                    </div>
                                    <RecruiterDashboardTable
                                        columns={SAVED_LISTS_TABLE_HEADERS}
                                        TableBody={
                                            <div className='flex flex-col gap-4 p-5 flex-1 overflow-y-scroll'>
                                                {(type === 'smart' ? list.users?.length ?? 0 : filteredUsers.length) === 0 ?
                                                    <div className='flex flex-col items-center py-12 gap-3'>
                                                        <p className='text-slate-500'>
                                                            No students match your search criteria
                                                        </p>
                                                        <Button onClick={() => setIsEditClubListModalOpen(true)}>
                                                            Edit list filters
                                                        </Button>
                                                    </div>
                                                    :
                                                    (type === 'smart' ? list.users : filteredUsers).map(item =>
                                                        <div key={item.id}>
                                                            <RecruiterFavoritesTableItem
                                                                item={item}
                                                                columns={SAVED_LISTS_TABLE_HEADERS}
                                                                remove={clickRemoveStudent}
                                                                isEditing={isEditing}
                                                                list={list}
                                                                type={type}
                                                            />
                                                        </div>
                                                    )
                                                }
                                                {(!hideMoreStudents && type === "smart" && !loading && list.users?.length !== 0) &&
                                                    <div
                                                        className='flex flex-row items-center justify-center mr-3 py-8 mb-20 body2-bold'
                                                        onClick={getNextPageInfo}>
                                                        <Button loading={moreStudentsLoading} variant={'ghost'}>
                                                            Next page
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {type === 'smart' ? (
                <SmartListModal
                    isOpen={IsEditClubListModalOpen}
                    initialData={list}
                    constants={constants}
                    authUser={authUser}
                    closeModal={() => setIsEditClubListModalOpen(false)}
                    mode='edit'
                    onSave={ () => {
                        setIsEditClubListModalOpen(false);
                        setIsEditing(false);
                        getList();
                    }}
                />
            ) : (
                <EditListModal
                    isOpen={IsEditClubListModalOpen}
                    currentList={list}
                    type={type}
                    closeModal={() => setIsEditClubListModalOpen(false)}
                    getLists={getList}
                />
            )}
            <ConfirmationModal
                isOpen={confirmationModalOpen}
                closeModal={() => setConfirmationModalOpen(false)}
                confirmText={'Delete'}
                title={'Are you sure?'}
                onConfirm={delList}
                description={'Are you sure you want to delete this list? This action cannot be undone.'}
            />
            <ConfirmationModal
                isOpen={removeStudentConfirmationModal}
                closeModal={() => setRemoveStudentConfirmationModal(false)}
                confirmText={'Delete'}
                title={'Are you sure?'}
                onConfirm={remove}
                description={'Are you sure you want to delete this student? This action cannot be undone.'}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterSavedClubsPage);
