import axios from '../axios';

export const editUser = async (user, user_id, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = params;
        const res = await axios.put(`/admin/users/${user_id}`, body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deactivateUser = async (user, user_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post(`/admin/users/${user_id}/deactivate`, {}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const verifyUser = async (user, user_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = { manually_verified: true };
        const res = await axios.put(`/admin/users/${user_id}`, body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const unverifyUser = async (user, user_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = { manually_verified: false };
        const res = await axios.put(`/admin/users/${user_id}`, body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const reactivateUser = async (user, user_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post(`/admin/users/${user_id}/reactivate`, {}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}