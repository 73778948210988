import SchoolLogo from "../../components-recruiter/atoms/SchoolLogo";
import SvgClose from "../icons/SvgClose";
import CollapseComponent from "./CollapseComponent";
import FadeInOnScroll from "./FadeInOnScroll";
import classNames from "classnames";
import { useRef, useEffect, useState } from 'react';

export default function MultiSelectWithSearch({
    outerLabel,
    searchValue,
    selected,
    error,
    placeholder,
    results,
    openCollapse,
    maxToShow = 1,
    onSearchChange,
    onResultClick,
    onClearAll,
    resultMapper,
    leftIcon,
    rightIcon,
    
}) {
    const containerRef = useRef(null);
    const selectedItemsRef = useRef(null);
    const [inputWidth, setInputWidth] = useState('0px');

    useEffect(() => {
        if (containerRef.current && selectedItemsRef.current && selected.length > 0) {
            const containerWidth = containerRef.current.offsetWidth;
            const selectedItemsWidth = selectedItemsRef.current.offsetWidth;
            const newWidth = containerWidth - selectedItemsWidth - 1;
            setInputWidth(`${newWidth}px`);
        }
    }, [selected, containerRef.current, selectedItemsRef.current]);

    return (
        <div className="flex flex-col w-full">
            <p className="text-sm text-slate-500 mb-1">{outerLabel}</p>
            <div className="relative w-full">
                <div ref={containerRef} className={`bg-white rounded-lg border ${error ? 'border-red-400' : 'border-neutral-200'} w-full`}>
                    <div className="flex flex-row items-center w-full">
                        {(leftIcon && selected.length === 0) && (
                            <span className="absolute left-3 top-1/2 -translate-y-1/2">{leftIcon}</span>
                        )}
                        {selected.length === 0 ? (
                            <input
                                type="text"
                                placeholder={placeholder}
                                className={classNames("input-light !border-0 !outline-none py-2 px-3 text-sm w-full", {
                                    '!pl-10': !!leftIcon,
                                    '!pr-10': !!rightIcon
                                })}
                                value={searchValue}
                                onChange={onSearchChange}
                            />
                        ) : (
                            <>
                                <div ref={selectedItemsRef} className="flex flex-row items-center pl-3 flex-shrink-0 overflow-hidden">
                                    <div className="flex flex-row flex-wrap gap-2">
                                        {selected.length > maxToShow ? (
                                            <div className="flex flex-row items-center gap-1 bg-slate-100 rounded-full px-2 py-1">
                                                <span className="text-sm line-clamp-1 max-w-[70px]">
                                                    {selected.length} selected
                                                </span>

                                                <div
                                                    className="text-slate-500 hover:text-red-400 cursor-pointer"
                                                    onClick={onClearAll}
                                                >
                                                    <SvgClose className="h-3 w-3" />
                                                </div>

                                            </div>
                                        ) : (
                                    
                                                selected.map((value, index) => (
                                                    <div
                                                        key={index}
                                                        className="flex flex-row items-center gap-1 bg-slate-100 rounded-full px-2 py-1"
                                                >
                                                    <span className="text-sm line-clamp-1 max-w-[70px]">
                                                        {value}
                                                    </span>
                                                    <div
                                                        className="text-slate-500 hover:text-red-400 cursor-pointer"
                                                        onClick={() => onResultClick(value)}
                                                    >
                                                        <SvgClose className="h-3 w-3" />
                                                    </div>
                                                </div>
                                            ))

                                        )}
                                    </div>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search more..."
                                    className={classNames("input-light !border-0 !outline-none py-2 px-3 text-sm", {
                                        '!pr-10': !!rightIcon
                                    })}
                                    style={{ width: inputWidth }}
                                    value={searchValue}
                                    onChange={onSearchChange}
                                />
                            </>
                        )}
                        {rightIcon && (
                            <span className="absolute right-3 top-1/2 -translate-y-1/2">{rightIcon}</span>
                        )}
                    </div>
                </div>
                {error && <p className="text-xs text-red-400 mt-1">{error}</p>}
                <div className="absolute top-12 left-0 right-0 z-[99] w-full">
                    <CollapseComponent
                        isOpen={searchValue.length !== 0 && openCollapse}
                        className="bg-white shadow-lg border overflow-hidden border-neutral-200 rounded-lg w-full"
                    >
                        {results.map((result) => {
                            const mappedResult = resultMapper(result);
                            return (
                                <FadeInOnScroll
                                    triggerImmediately
                                    delay={10}
                                    key={mappedResult.id}
                                >
                                    <div
                                        onClick={() => onResultClick(mappedResult.value)}
                                        className="flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 px-2"
                                    >
                                        {/* <img
                                            className=""
                                            src={mappedResult.imageUrl}
                                            alt={mappedResult.name}
                                        /> */}
                                        <div className="h-10 aspect-square border border-slate-200 bg-white rounded-lg object-contain">
                                            <SchoolLogo image={mappedResult.imageUrl}/>
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-sm text-slate-800 line-clamp-2">
                                                {mappedResult.name}
                                            </p>
                                        </div>
                                    </div>
                                </FadeInOnScroll>
                            );
                        })}
                    </CollapseComponent>
                </div>
            </div>
        </div>
    );
}