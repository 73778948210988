import React, { useState, useEffect, useMemo } from "react";
import ModalComponent from "../components/atoms/modals/ModalComponent";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { ACTIVELY_LOOKING_OPTIONS } from "../components/onboarding/onboarding-constants";
import { getSchoolClubs } from "../api/student/clubs";
import { useSelector } from "react-redux";
import AdminEditStudentEducation from "./AdminEditStudentEducation";
import AdminEditStudentProfile from "./AdminEditStudentProfile";
import AdminEditStudentLocation from "./AdminEditStudentLocation";
import AdminEditStudentDiversity from "./AdminEditStudentDiversity";
import { editUser } from "../api/admin/users";
import { toast } from "react-toastify";
import {Button} from "../components/atoms/Button";

function AdminEditStudentModal({ student }) {
    const [isOpen, setIsOpen] = useState(false);
    const authUser = useSelector((state) => state.userReducer.authUser);
    const [formData, setFormData] = useState({
        first_name: student.first_name,
        last_name: student.last_name,
        email: student.email,
        headline: student.headline,
        city: student.city,
        state: student.state,
        bio: student.bio,
        job_status: student.job_status || ACTIVELY_LOOKING_OPTIONS[2].label,
        gpa: student.gpa ?? "",
        classYear: student.grade ?? "",
        clubs: student.clubs ?? [],
        graduationSeason: student.graduation_season ?? "",
        diversity: {
            gender: student.diversity?.gender ?? "",
            race: student.diversity?.race ?? [],
            veteran: student.diversity?.veteran ?? "",
            disabled: student.diversity?.disabled ?? "",
            firstGeneration: student.diversity?.first_generation ?? "",
            lgbtq: student.diversity?.lgbtq ?? "",
            authorized: student.diversity?.authorized ?? "",
            requireSponsorship: student.diversity?.require_sponsorship ?? "",
        },
        secondary_email: student.secondary_email ?? "",
    });

    // Education-related state
    const [selectedMajors, setSelectedMajors] = useState(
        () => student.major ?? []
    );
    const [selectedMinors, setSelectedMinors] = useState(
        () => student.minor ?? []
    );
    const [clubOptions, setClubOptions] = useState([]);
    const [sportsMap, setSportsMap] = useState(() => {
        const initialMap = {};
        student.sports?.forEach((sport) => {
            initialMap[sport.sport] = sport.type === "varsity";
        });
        return initialMap;
    });
    const [errors, setErrors] = useState({});

    // Club mapping helpers
    const clubsNamesMap = useMemo(() => {
        return Object.fromEntries(
            clubOptions.map((club) => [club.id, club.name])
        );
    }, [clubOptions]);

    const clubsIdsMap = useMemo(() => {
        return Object.fromEntries(
            clubOptions.map((club) => [club.name, club.id])
        );
    }, [clubOptions]);

    const selectedClubNames = useMemo(() => {
        return formData.clubs.map((clubId) => clubsNamesMap[clubId]);
    }, [formData.clubs, clubsNamesMap]);

    // Location-related state
    const [selectedLocations, setSelectedLocations] = useState(
        () => student.locations ?? []
    );
    const [locationSortOrder, setLocationSortOrder] = useState("popularity");

    // Education-related handlers
    const handleGPAChange = (e) => {
        setFormData((prev) => ({ ...prev, gpa: e.target.value }));
    };

    const handleGradYearChange = (e) => {
        const text = e.target.value;
        if (
            text === "" ||
            (text.length <= 4 && /^\d*$/.test(text) && Number(text) <= 2030)
        ) {
            setFormData((prev) => ({ ...prev, classYear: text }));
            setErrors((prev) => ({ ...prev, classYear: "" }));
        }
    };

    const handleGradSeasonChange = (selectedSeason) => {
        setFormData((prev) => ({ ...prev, graduationSeason: selectedSeason }));
    };

    const handleClubsChange = (selectedClubName) => {
        let updatedClubs;
        if (selectedClubNames.includes(selectedClubName)) {
            updatedClubs = formData.clubs.filter(
                (clubId) => clubsNamesMap[clubId] !== selectedClubName
            );
        } else if (selectedClubName === null) {
            updatedClubs = [];
        } else {
            updatedClubs = [...formData.clubs, clubsIdsMap[selectedClubName]];
        }
        setFormData((prev) => ({ ...prev, clubs: updatedClubs }));
    };

    const handleSportsChange = (selectedSport) => {
        if (selectedSport === null) {
            setSportsMap({});
        } else {
            if (selectedSport in sportsMap) {
                const { [selectedSport]: removed, ...rest } = sportsMap;
                setSportsMap(rest);
            } else {
                setSportsMap((prev) => ({
                    ...prev,
                    [selectedSport]: true,
                }));
            }
        }
    };

    const handleSportSwitchChange = (checked, sport) => {
        setSportsMap((prev) => ({
            ...prev,
            [sport]: checked,
        }));
    };

    // Fetch clubs on component mount
    useEffect(() => {
        const fetchClubs = async () => {
            const res = await getSchoolClubs(authUser, student.college);
            setClubOptions(
                res?.clubs?.filter((club) => club.demo !== true) ?? []
            );
        };

        if (isOpen) {
            fetchClubs();
        }
    }, [authUser, student.college, isOpen]);

    // Profile-related handlers
    const handleChange = (field) => (e) => {
        setFormData((prev) => ({
            ...prev,
            [field]: e.target.value,
        }));
    };

    const setJobStatus = (status) => {
        setFormData((prev) => ({
            ...prev,
            job_status: status,
        }));
    };

    // Handlers for diversity data
    const handleMultiSelectChange = (inputName, selectedValue) => {
        const array = [...formData.diversity[inputName]];
        let updatedArray;

        if (selectedValue === null) {
            updatedArray = [];
        } else if (array.includes(selectedValue)) {
            updatedArray = array.filter((item) => item !== selectedValue);
        } else {
            updatedArray = [...array, selectedValue];
        }

        setFormData((prev) => ({
            ...prev,
            diversity: {
                ...prev.diversity,
                [inputName]: updatedArray,
            },
        }));
    };

    const handleDiversitySelectChange = (inputName, value) => {
        setFormData((prev) => ({
            ...prev,
            diversity: {
                ...prev.diversity,
                [inputName]: value,
            },
        }));
    };

    const handleEmailChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            secondary_email: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const updatedData = {
            job_status: formData.job_status,
            seeking_role: formData.job_status !== "Not looking right now",
            gpa: formData.gpa ? parseFloat(formData.gpa) : 0,
            grade: formData.classYear,
            graduation_season: formData.graduationSeason,
            clubs: formData.clubs,
            major: selectedMajors,
            minor: selectedMinors,
            sports: Object.entries(sportsMap).map(([sport, isVarsity]) => ({
                sport,
                type: isVarsity ? "varsity" : "club",
            })),
            locations: selectedLocations,
            diversity: {
                gender: formData.diversity.gender,
                race: formData.diversity.race,
                veteran: formData.diversity.veteran,
                disabled: formData.diversity.disabled,
                first_generation: formData.diversity.firstGeneration,
                lgbtq: formData.diversity.lgbtq,
                authorized: formData.diversity.authorized,
                require_sponsorship: formData.diversity.requireSponsorship,
            },
            secondary_email: formData.secondary_email,
        };

        toast.promise(editUser(authUser, student.id, updatedData), {
            pending: "Updating student...",
            success: "Student updated successfully",
            error: "Failed to update student",
        });

        setIsOpen(false);
    };

    return (
        <>
            <PencilSquareIcon
                className="w-6 h-6 text-primary/80 hover:text-primary cursor-pointer"
                onClick={() => setIsOpen(true)}
            />

            <ModalComponent
                backgroundColor={"white"}
                isOpen={isOpen}
                toggle={() => setIsOpen(false)}
                header="Edit Student"
                size="xl"
                showScrollBar
                footerAlignment="right"
                FooterComponent={
                    <div className="flex gap-2">
                        <Button
                            variant="secondary"
                            onClick={() => setIsOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                        >
                            Save Changes
                        </Button>
                    </div>
                }
            >
                <form onSubmit={handleSubmit} className="space-y-4">
                    {/* Profile section */}
                    <AdminEditStudentProfile
                        formData={formData}
                        setJobStatus={setJobStatus}
                    />

                    {/* Education section */}
                    <AdminEditStudentEducation
                        formData={formData}
                        selectedMajors={selectedMajors}
                        setSelectedMajors={setSelectedMajors}
                        errors={errors}
                        handleGPAChange={handleGPAChange}
                        handleGradYearChange={handleGradYearChange}
                        handleGradSeasonChange={handleGradSeasonChange}
                        clubOptions={clubOptions}
                        selectedClubNames={selectedClubNames}
                        handleClubsChange={handleClubsChange}
                        sportsMap={sportsMap}
                        handleSportsChange={handleSportsChange}
                        handleSportSwitchChange={handleSportSwitchChange}
                    />
                    {/* Location section */}
                    <AdminEditStudentLocation
                        selectedLocations={selectedLocations}
                        setSelectedLocations={setSelectedLocations}
                        locationSortOrder={locationSortOrder}
                        setLocationSortOrder={setLocationSortOrder}
                    />

                    <AdminEditStudentDiversity
                        formData={formData}
                        handleMultiSelectChange={handleMultiSelectChange}
                        handleSelectChange={handleDiversitySelectChange}
                        handleEmailChange={handleEmailChange}
                    />
                </form>
            </ModalComponent>
        </>
    );
}

export default AdminEditStudentModal;
