import React, { useState } from "react";
import { searchCompanies } from "../../utils/typesense";
import MultiSelectWithSearch from "../atoms/MultiSelectWithSearch";
import SvgCompanyBuilding from "../icons/SvgCompanyBuilding";

export default function MultiSearchCompanies({ outerLabel, selectedCompanies, setSelectedCompanies, limit, error, placeholder }) {
    const [companySearch, setCompanySearch] = useState("");
    const [openCollapse, setOpenCollapse] = useState(false);
    const [companySearchResults, setCompanySearchResults] = useState([]);

    const filteredCompanyResults = companySearchResults.filter(
        (result) => !selectedCompanies.includes(result.name)
    );

    const handleCompanyClick = (company) => {
        if (selectedCompanies.includes(company)) {
            setSelectedCompanies([
                ...new Set(
                    [...selectedCompanies].filter((item) => item !== company)
                ),
            ]);
        } else {
            setSelectedCompanies([...new Set([...selectedCompanies, company])]);
            setCompanySearch("");
            setCompanySearchResults([]);
            setOpenCollapse(false);
        }
    };

    const handleSearchChange = (event) => {
        const text = event.target.value;
        setCompanySearch(text);

        if (text === "") {
            setOpenCollapse(false);
            setCompanySearchResults([]);
            return;
        }

        setOpenCollapse(true);
        companySearchChanged(text);
    };

    const companySearchChanged = async (text) => {
        const results = await searchCompanies(text);
        setCompanySearchResults(results);
    };



    return (
        <MultiSelectWithSearch
            outerLabel={outerLabel}
            searchValue={companySearch}
            selected={selectedCompanies}
            error={error}
            placeholder={placeholder}
            results={filteredCompanyResults}
            openCollapse={openCollapse}
            onSearchChange={handleSearchChange}
            onResultClick={handleCompanyClick}
            onClearAll={() => setSelectedCompanies([])}
            resultMapper={(result) => ({
                id: result.id,
                name: result.name,
                imageUrl: result.logo_url,
                value: result.name
            })}
            leftIcon={<SvgCompanyBuilding className="h-5 w-5 text-slate-500" />}
            maxToShow={limit}
        />
    );
}
