import axios from '../axios';

export const getRecruiter = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/recruiters", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getStudentApplicationsRecruiter = async (user, queried_user_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id}
        };
        const res = await axios.get("/recruiters/studentjobs", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getRecruiterStudent = async (user, student_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {student_id}
        };
        const res = await axios.get("/recruiters/student", config);
        return res.data.student;
    } catch (err) {
        console.log(err);
    }
}

export const editCompanyStudent = async (user, student_id, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit,
            student_id,
        }
        const res = await axios.put("/recruiters/student", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const addNote = async (user, student_id, text) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const params = {
            text,
            student_id
        }
        const res = await axios.put("/recruiters/student/notes", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteNote = async (user, student_id, index) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const params = {
            index,
            student_id
        }
        const res = await axios.post("/recruiters/student/notes", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}