import React, {PureComponent} from "react";
import SvgMoney from "../icons/SvgMoney";
import SvgDiversity from "../icons/SvgDiversity";
import SvgChevronRight from "../icons/SvgChevronRight";
import {Link} from "react-router-dom";
import {createApplication} from "../../api/student/applications";
import {connect} from "react-redux";
import SvgAddCircle from "../icons/SvgAddCircle";
import ModalComponent from "../atoms/modals/ModalComponent";
import Tooltip from "../atoms/modals/Tooltip";
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "../../utils/constants";
import CompanyLogo from "../atoms/CompanyLogo";
import {getCompanyPeople} from "../../api/student/people";
import SvgGroup from "../icons/SvgGroup";
import SvgClock from "../icons/SvgClock";
import ApplyNowButton from "../ApplyNowButton";
import {DateTime} from 'luxon';
import {getCompany} from '../../api/student/companies';
import SvgCheckCircle from "../icons/SvgCheckCircle";
import SidebarOverlayWrapper from "../atoms/SidebarOverlayWrapper";
import {Button} from "../atoms/Button";
import json from "../../img/lottie/check.json";
import LottieWrapper from "../atoms/LottieWrapper";
import SvgPin from "../icons/SvgPin";
import SvgCalendar from "../icons/SvgCalendar";
import { editApplication } from "../../api/student/applications";
import TagComponent from "../atoms/TagComponent";
import RuDirectLabel from "../atoms/RuDirectLabel";
import SvgSchool from "../icons/SvgSchool";
import DownloadPdfButton from "../atoms/DownloadPdfButton";
import RenderHtml from "../atoms/RenderHtml";


var parse = require('html-react-parser');


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        applications: state.userReducer.applications,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
    }
}

class JobPeek extends PureComponent {
    state = {
        location: "",
        status: 'To apply',
        people: [],
        networkingLoading: true,
        company: {},
        successModalOpen: false
    }

    componentDidMount = () => {
        this.updateInfo();
        this.getNetworks();
        this.setState({status: this.props.application?.personal_status});
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.listing !== prevProps.listing) {
            this.updateInfo();
            this.getNetworks();
            this.setState({status: this.props.application?.personal_status})
            this.getCompany();
        }
    }

    getNetworks = async () => {
        this.setState({networkingLoading: true})
        const listing = this.props.listing || {};
        const company = listing.company || {};
        const res = await getCompanyPeople(this.props.authUser, company.id);
        this.setState({people: res.people, networkingLoading: false});
    }

    updateInfo = () => {
        const listing = this.props.listing || {};
        if (listing.city !== undefined && !!listing.city.length) {
            if (listing.city.length === 1) {
                this.setState({location: listing.city[0] + ", " + listing.state[0]})
            } else {
                this.setState({location: "Multiple Locations"})
            }
        }
    }

    updateStatus = (status) => {
        const application = this.props.application;
        this.props.editApplication(application.id, application.job_id, {personal_status: status});
        this.setState({status});
    }

    startApplication = async (answers=null, personal_status="To apply") => {
        try {
            const listing = this.props.listing;

            const result = await createApplication(this.props.authUser, {
                company_id: listing.company_id,
                job_id: listing.id,
                answers,
                personal_status

            });
            this.props.updateApplication(result.application);
            if (personal_status === "To apply") { 
                toast.promise(
                    Promise.resolve('Application saved'),
                    {
                        pending: 'Adding application...',
                        success: "Application saved",
                        error: 'Failed to save application',
                    },
                    TOAST_OPTIONS
                );
            } else {
                this.setState({successModalOpen: true})
            }
            window.analytics.track("Saved Job", {
                "job_id": listing.id
            })
        } catch (error) {
            toast.error('Failed to save application', TOAST_OPTIONS);
        }
    };

    closeAllModals = () => {
        this.setState({successModalOpen: false})
    };

    getCompany = async () => {
        const res = await getCompany(this.props.listing.company_id);
        this.setState({company: res?.company});
    }

    editApplication = async (applicationId, jobId, params) => {
        const newApplication = {...this.props.applications[jobId]}
        for (const key of Object.keys(params)) {
            newApplication[key] = params[key];
        }
        this.props.updateApplication(newApplication)
        await editApplication(this.props.authUser, applicationId, params);
        this.setState({successModalOpen: params["personal_status"] === "Applied"})
    }

    render() {
        const listing = this.props.listing || {};
        const classYears = listing.class_years || [];

        const enabled = !listing.qualified_only || classYears.includes(this.props.user.grade)
        const company = this.state.company || {};
        const description = listing?.description || "";
        const createdAt = DateTime.fromISO(listing.created_at);
        classYears.sort();
        let yearText = '';
        const jobPdfPath = listing.job_pdf_path

        if (classYears.length === 1) {
            yearText = `Class of '${classYears[0].toString().slice(-2)}`;
        } else if (classYears.length > 1) {
            const minYear = classYears[0].toString().slice(-2);
            const maxYear = classYears[classYears.length - 1].toString().slice(-2);
            yearText = `Class of '${minYear} - '${maxYear}`;
        }
        const highlights = [
            {
                title: yearText,
                icon: SvgSchool,
                visible: !!classYears.length > 0
            },
            {
                title: createdAt.isValid ? `Posted ${createdAt.toRelative()}` : null,
                icon: SvgClock
            },
            {
                title: this.state.location,
                icon: SvgPin,
                visible: !!this.state.location
            },
            {
                title: listing.salary,
                icon: SvgMoney,
                visible: !!listing.salary
            },
            {
                title: listing.work_model,
                icon: SvgDiversity,
                visible: !!listing.work_model
            },
            {
                title: `${company.num_employees} employees`,
                icon: SvgGroup,
                visible: !!listing.num_employees
            },
            {
                title: `Due by ${DateTime.fromISO(listing.deadline).toFormat('MMM dd')}`,
                icon: SvgCalendar,
                visible: !!listing.deadline
            }

        ]

        return (
            <SidebarOverlayWrapper label={'Job Details'} onClose={this.props.close} open={this.props.open}
                                   width={'40vw'}>
                <div className='col-jb flex-1 overflow-hidden gap-5 mb-0' style={this.props.style}>

                    <div className='flex flex-row gap-3'>
                        <CompanyLogo id={listing.company_id} className={'w-20 h-20'}/>
                        <div className=''>
                            <p className='text-lg font-semibold blacks-1'>
                                {listing.name}
                            </p>
                            <div className='row-ac primary'>
                                {!!listing.company_id ?
                                    <Link to={{
                                        pathname: '/company/' + listing.company_id,
                                        state: {company_id: listing.company_id, previousPage: {location: "jobboard", data: {}}}
                                    }}
                                        className='row-ac primary'>
                                        <p className='text-md font-semibold'>
                                            {listing.company_name}
                                        </p>
                                        <SvgChevronRight className={'w-5 h-5'}/>
                                    </Link>
                                    :
                                    <p className='text-md font-semibold'>
                                        {listing.company_name}
                                    </p>
                                }
                            </div>
                            <div className='flex flex-row items-center gap-2 wrap mt-2'>
                                <TagComponent type={'random'} label={listing.type}/>
                                {!!listing.secondary_specialties && listing.secondary_specialties.length > 0 ? 
                                    <div className='flex flex-row gap-1 whitespace-nowrap flex-wrap'>
                                        {listing?.secondary_specialties.map(specialty =>
                                            <TagComponent
                                                label={specialty}
                                                type={'industry'}
                                            />
                                        )}
                                    </div>
                                    :
                                    <TagComponent
                                        label={listing.industry}
                                        type={'industry'}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row items-center gap-3'>
                        {(this.props.application && !listing.link && this.props.application?.personal_status !== "To apply") ? null :
                            <div className='flex-1'>
                                <ApplyNowButton startApplication={this.startApplication} job={listing}
                                                applications={this.props.applications} editApplication={this.editApplication}/>
                            </div>
                        }
                        {!!this.props.application ?
                            <div className="flex flex-1">
                                <Tooltip
                                    toggleComponent={
                                        <Button
                                            variant={'secondary'}
                                            icon={SvgCheckCircle}
                                            className={' w-full cursor-not-allowed'}
                                        >
                                            {listing.apply_direct && this.props.application?.personal_status !== "To apply" ? "Application Submitted!" : "Saved"}
                                        </Button>
                                    }
                                    label={listing.apply_direct && this.props.application?.personal_status !== "To apply" ? 'You have already applied to this job on RecruitU' : 'You already have this job saved'}
                                />
                            </div>
                            :
                            <>
                                <div className="flex flex-1">
                                    <Tooltip
                                        toggleComponent={
                                            <Button
                                                onClick={(e) => enabled && this.startApplication()}
                                                variant={'secondary'}
                                                className={`flex-1 flex w-full ${!enabled && 'opacity-50'}`}
                                                icon={SvgAddCircle}
                                            >
                                                Save
                                            </Button>
                                        }
                                        label={enabled ? 'Save this to your saved jobs' : 'Your class year is not eligible to apply to this job'}
                                    />
                                </div>
                            </>
                        }
                        {jobPdfPath &&
                            <DownloadPdfButton pdfPath={jobPdfPath} />
                        }
                    </div>
                    <div className='col-plain overflow-scroll flex-1 gap-5'>
                        <div className='flex flex-row items-center gap-5 flex-wrap text-sm font-semibold'>
                            {highlights.map(item => {
                                    if (item.visible) {
                                        return (
                                            <div className='row-ac'>
                                                <item.icon className={'w-4 h-4'}/>
                                                <p className='m-0 ml-8'>
                                                    {item.title}
                                                </p>
                                            </div>
                                        )
                                    }
                                }
                            )}
                            {listing.exclusive &&
                                <RuDirectLabel className={'mt-1'}/>
                            }
                        </div>
                        {listing.exclusive ?
                            <div className='flex flex-col flex-1 gap-2'>
                                <p className='text-lg text-slate-900 font-bold'>
                                    Disclaimer
                                </p>
                                <div className='flex flex-col flex-1'>
                                    <p className='text-sm mt-0 text-slate-500'>
                                        Please make sure your profile is up to date to ensure recruiters are seeing your correct information.
                                    </p>
                                </div>
                            </div>
                            : null}

                        {!!description ?
                            <div className='flex flex-col flex-1 gap-2'>
                                <p className='text-lg text-slate-900 font-bold'>
                                    About this job
                                </p>
                                <div className='flex flex-col flex-1'>
                                    <p className='text-sm mt-0 text-slate-500'>
                                        <RenderHtml htmlString={description} />
                                    </p>
                                </div>
                            </div>
                            : null}
                    </div>
                    <div className='flex-col mt-2'>
                        <Link to={{
                            pathname: '/job/' + listing.id,
                            state: {previousPage: {location: "jobboard", data: {}}}
                        }} className={'flex-1'}>
                            <Button className='w-full'>
                                View Full Job Page
                            </Button>
                        </Link>
                    </div>
                    <ModalComponent
                        showScrollBar
                        isOpen={this.state.successModalOpen}
                        backgroundColor={"white"}
                        header={"Application Submitted!"}
                        headerToggle
                        size={"sm"}
                        toggle={this.closeAllModals}
                        FooterComponent={
                            <div className="flex flex-row w-full justify-center items-center gap-3">
                                <Button variant={"secondary"} onClick={this.closeAllModals}>
                                    Close
                                </Button>
                            </div>
                        }
                    >
                        <div className="hue-rotate-[120deg]">
                            <LottieWrapper
                                loop={false}
                                json={json}
                                width={100}
                                height={100}
                            />
                        </div>
                    </ModalComponent>
                </div>
            </SidebarOverlayWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobPeek);
