import React, {PureComponent} from "react";
import SvgClock from "../icons/SvgClock";
import CompanyLogo from "../atoms/CompanyLogo";
import Tooltip from "../atoms/modals/Tooltip";
import SvgDocument from "../icons/SvgDocument";
import {DateTime} from 'luxon';
import TagComponent from "../atoms/TagComponent";
import Checkbox from "../atoms/Checkbox";


class TrackedJobTableItem extends PureComponent {
    state = {
        status: 'To apply',
        deadline: DateTime.now(),
        dueSoon: false,
        pastDue: false,
        checked: false
    }
    updateStatus = () => {
        const application = this.props.application;
        const job = application.job || {};
        if (job.exclusive) {
            return;
        }
        const newStatus = application.personal_status === "To apply" ? "Applied" : "To apply";
        this.props.editApplication(application.id, application.job_id, {personal_status: newStatus});
        this.setState({status: newStatus});
    }

    componentDidMount = () => {
        const application = this.props.application || {};
        const job = application.job || {};
        const deadline = DateTime.fromISO(job.deadline);
        const currentDate = DateTime.now(); // Current date and time

        // if (deadline.isValid()) {
        const diff = deadline.diff(currentDate, 'days').days;
        if (diff < -1) {
            // Past due only if more than one day has passed since the deadline
            this.setState({dueSoon: false, pastDue: true});
        } else if (diff <= 5) {
            // Due soon: Deadline is less than five days from now but not past due
            this.setState({dueSoon: true, pastDue: false});
        } else {
            // Not due soon or past due
            this.setState({dueSoon: false, pastDue: false});
        }
        this.setState({status: this.props.application.personal_status, deadline});
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.application?.personal_status !== prevProps.application?.personal_status) {
            this.setState({status: this.props.application.personal_status})
        }
    }

    updateApplicationStatus = (status) => {
        const application = this.props.application;
        this.props.editApplication(application.id, application.job_id, {personal_status: status});
        this.setState({status});
    }

    render() {
        const application = this.props.application || {};
        const job = application.job || {};
        const dueSoon = this.state.dueSoon;
        const pastDue = this.state.pastDue;

        let location = null;
        if (!!job.city) {
            if (job.city.length === 1) {
                location = job.city[0] + ", " + job.state[0]
            } else {
                location = "Multiple Locations"
            }
        }
        return (
            <>
                <div onClick={() => this.props.previewApplication(this.props.application)}
                     className={`${this.props.className} 
                     row-ac-jb py-3 rounded-md hover:bg-neutral-50 cursor-pointer border-b border-b-neutral-100 last:border-none`}>
                    <div className='row-ac' style={{flex: 2}}>
                        <CompanyLogo id={job.company_id} className={'w-16 h-16'}/>
                        <div className='ml-8 mr-8 flex flex-col gap-0'>
                            <div className='flex flex-col gap-0.5'>
                                {!!job.deprecated &&
                                    <Tooltip
                                        toggleComponent={
                                            <span className='bg-slate-200 px-2 rounded-md inline mr-a my-auto inline'>
                                                <p className='text-xs text-slate-500'>
                                                    Expired
                                                </p>
                                            </span>
                                        }
                                        label={'This job has been removed from RecruitU. You can no longer navigate to the full job page.'}
                                    />
                                }
                                <p className='base-black-70 text-md font-semibold m-0'>
                                    {job.name}
                                </p>
                            </div>
                            <p className='text-sm text-slate-500 m-0 capitalize'>
                                {job.company_name}{job.type && ' • '}{job.type} {!!location ? "•" : ""} {location}
                            </p>
                            <div className='flex flex-row items-center gap-2 wrap mt-1'>
                                {!!job.type ? <TagComponent sm type={'random'} label={job.type}/> : null}
                                {!!job.secondary_specialties && job.secondary_specialties.length > 0 ? 
                                    <div className='flex flex-row gap-1 whitespace-nowrap flex-wrap'>
                                        {job?.secondary_specialties.map(specialty =>
                                            <TagComponent
                                                sm
                                                label={specialty}
                                                type={'industry'}
                                            />
                                        )}
                                    </div>
                                    :
                                    <TagComponent
                                        sm
                                        label={job.industry}
                                        type={'industry'}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{flex: .3}}>
                        {pastDue ?
                            <div className='text-neutral-400 flex flex-row items-center'>
                                <Tooltip
                                    toggleComponent={
                                        <div className='text-red-500 flex flex-row items-center'>
                                            <p className='body3-bold'
                                               style={{textDecoration: "line-through"}}>
                                                {!!job.deadline ? this.state.deadline.toFormat('MMM d') : '--'}
                                            </p>
                                            {/*<SvgClose className={'w-4 h-4 ml-0.5'}/>*/}
                                        </div>
                                    }
                                    label={`This application deadline has passed, it was due on ${!!job.deadline ? this.state.deadline.toFormat('MMM d') : '--'}.`}
                                />
                            </div>
                            :
                            dueSoon ?
                                <div className='text-orange-400 flex flex-row items-center'>
                                    <p className='body3-bold'>
                                        {!!job.deadline ? this.state.deadline.toFormat('MMM d') : '--'}
                                    </p>
                                    <SvgClock className={'w-4 h-4 ml-0.5'}/>
                                </div>
                                :
                                <p className='base-black-50 body3'>
                                    {!!job.deadline ? this.state.deadline.toFormat('MMM d') : '--'}
                                </p>
                        }
                    </div>
                    {/* <div style={{flex: .3}} className='flex flex-row items-center gap-1 body3 base-black-50'>
                        <p>
                            {job.exclusive ? job.company_name : !!job.creator ? "Personal" : "RecruitU"}
                        </p>
                    </div> */}
                    <div style={{flex: .3}} className='flex flex-row items-center gap-1 body3 base-black-50'>
                        <SvgDocument className={'w-4 h-4'}/>
                        {application.notes.length}
                    </div>
                    <div style={{flex: .25}} className='flex flex-row items-center justify-start'>
                        <Checkbox
                            checked={this.props.application?.personal_status !== "To apply"}
                            onClick={this.updateStatus}
                            label={'Applied'}
                        />
                        {/* <HorizontalStepperList
                            updateStatus={this.updateApplicationStatus}
                            steps={APPLICATION_STEPS}
                            status={this.props.application?.personal_status || 'To apply'}
                            link={this.props.application?.job.link}
                        /> */}
                    </div>
                </div>
            </>
        )
    }
}

export default TrackedJobTableItem
