import axios from '../axios';

export const getUserApplications = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {}
        };
        const res = await axios.get("/applications/user", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getJobApplication = async (user, job_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {job_id}
        };
        const res = await axios.get("/applications/job", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getApplicationCount = async (user, job_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {job_id}
        };
        const res = await axios.get("/applications/count", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createApplication = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/applications", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteApplication = async (user, application_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {application_id}
        };
        const res = await axios.delete("/applications", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const addNote = async (user, application_id, text) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const params = {
            text,
            application_id
        }
        const res = await axios.put("/applications/notes", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteNote = async (user, application_id, index) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const params = {
            index,
            application_id
        }
        const res = await axios.post("/applications/notes", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const editApplication = async (user, application_id, updated_params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {updated_params, application_id};
        const res = await axios.put("/applications", body, config);

        return res.data;
    } catch (err) {
        console.log(err);
    }
}
