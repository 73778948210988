import React, {PureComponent} from "react";
import {connect} from "react-redux";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import PageLTRWrapper from "../wrappers/PageLTRWrapper";
import PageLeftWrapper from "../wrappers/PageLeftWrapper";
import TrackedNetworkingWidget from "../networkingpage/TrackedNetworkingWidget";
import PageHeaderWrapper from "../wrappers/PageHeaderWrapper";
import {getUserNetworks} from "../../api/student/networks";
import SvgClose from "../icons/SvgClose";
import ScrapedNetworkingPeek from "../networkingpage/ScrapedNetworkingPeek";
import { SEGMENT_QUERY_KEYSTROKE_TIMEOUT_LENGTH } from '../../utils/typesense';

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        networks: state.userReducer.networks,
        companyNetworks: state.userReducer.companyNetworks,
        user: state.userReducer.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        deleteNetwork: (personId) => {
            dispatch({
                type: "DELETE_NETWORK",
                personId,
            })
        },
        setCompanyNetworks: (companyNetworks) => {
            dispatch({
                type: "SET_COMPANY_NETWORKS",
                companyNetworks,
            })
        },
        setNetworks: (networks) => {
            dispatch({
                type: "SET_NETWORKS",
                networks,
            })
        },
    }
}

const ZERO_STATE = {
    networkingPeekOpen: false,
    selectedPersonId: null,
    networks: {},
    quickAddOpen: true,
    selectedPerson:{},
    search: "",
    position: [],
    companyId: null,
    companyName: null,
    school: "",
}

let segmentInterval = null;

class NetworkingPage extends PureComponent {
    state = ZERO_STATE

    openPeek = (personId, person) => {
        this.setState({selectedPersonId: personId, selectedPerson:person, networkingPeekOpen: true})
    }

    closePeek = () => {
        this.setState({networkingPeekOpen: false});
    }



    refreshNetworks = async () => {
        const res = await getUserNetworks(this.props.authUser);
        this.props.setNetworks(res.networks);
        this.props.setCompanyNetworks(res);
    }

    componentDidUpdate = (prevProps) => {
        if (Object.values(this.props.networks).length !== Object.values(prevProps.networks).length) {
            this.setState({loading: true});
            this.refreshNetworks();
            this.setState({loading: false});

        }
    }

    componentDidMount = () => {
        this.setState({loading: true});
        this.refreshNetworks();
        this.setState({loading: false});
    }

    toggleQuickAdd = () => {
        this.setState({quickAddOpen: !this.state.quickAddOpen})
    }

    updatePosition = (selectedPosition) => {
        const industryArray = [...this.state.position];

        let updatedPosition;
        if (industryArray.includes(selectedPosition)) {
            updatedPosition = industryArray.filter((item) => item !== selectedPosition);
        } else if (selectedPosition === null) {
            updatedPosition = [];
        } else {
            updatedPosition = [...industryArray, selectedPosition]
        }

        this.setState({position: updatedPosition});
    };

    render() {
        const clearFiltersVisible = this.state.school || this.state.position?.length >= 1 || this.state.companyId;

        const allNetworks = Object.values(this.props.networks) || [];
        const networks = allNetworks.filter(network => {
            const person = network.person;
            let nameMatch = true;
            const {companyId, school, search, position} = this.state;
            if (!!search) {
                const fullName = person.first_name + " " + person.last_name;
                const lowerCaseName = fullName.toLowerCase();
                nameMatch = lowerCaseName.startsWith(search.toLowerCase());
            }

            let companyMatch = true;
            if (!!companyId) {
                companyMatch = person.company_id === companyId;
            }
            let schoolMatch = true;
            if (!!school) {
                schoolMatch = person.school === school
            }
            let positionMatch = true;
            if (position.length) {
                positionMatch = position.includes(person.title);
            }
            return nameMatch && companyMatch && schoolMatch && positionMatch
        });

        // segment tracking
        const {companyId, school, search, position} = this.state;
        const jsonFilters = {
          ...search && {search},
          ...companyId && {companyId},
          ...school && {school},
          ...position.length && {position}
        };
      clearTimeout(segmentInterval);
      if (Object.keys(jsonFilters).length > 0) {
        if (search !== "") {
          segmentInterval = setTimeout(() => {
            window.analytics.track("Contacts Searched", {
              filters: {...jsonFilters}
            })
          }, SEGMENT_QUERY_KEYSTROKE_TIMEOUT_LENGTH);
        } else {
          window.analytics.track("Contacts Searched", {
            filters: {...jsonFilters}
          })
        }
      }



        return (
            <PageContentWrapper>
                <PageLTRWrapper>
                    <PageLeftWrapper>
                        <PageHeaderWrapper
                            className={'py-0 md:py-0 lg:py-0 sm:px-0 md:px-0 lg:px-0 -mt-1.5 mb-2'}
                            search={{
                                placeholder: 'Search people...',
                                value: this.state.search,
                                onChange: (e) => this.setState({search: e.target.value}),
                            }}
                            selects={[
                                {
                                    label: 'Position',
                                    value: this.state.position,
                                    setValue: this.updatePosition,
                                    options: [{title: "Analyst"}, {title: "Associate",}, {title: "Vice President",}, {title: "Managing Director",},],
                                    multiSelect: true,
                                },
                            ]}
                            setCompany={(result) => this.setState({
                                companyId: result.id,
                                companyName: result.name,
                            })}
                            setSchool={(school) => this.setState({school})}
                            company={{id: this.state.companyId, name: this.state.companyName}}
                            clearFilters={clearFiltersVisible &&
                                <div
                                    onClick={() => this.setState(ZERO_STATE)}
                                    className='body2 base-white-30 hover:text-neutral-50 flex flex-row items-center ml-3 cursor-pointer'>
                                    <SvgClose className={'w-5 h-5 mr-1'}/> Clear
                                </div>
                            }
                            manualContact
                            updateNetwork={this.props.updateNetwork}
                        />
                        <div className='flex-col flex-1 flex overflow-y-scroll hide-scrollbar mb-0 -mr-2 -mt-4'>
                            <TrackedNetworkingWidget openPeek={this.openPeek}
                                                     networks={networks}
                                                     companyNetworks={this.props.companyNetworks}
                                                     history={this.props.history}
                                                     loading={this.state.loading}
                                                     updateNetwork={this.props.updateNetwork}
                                                    changeTab={this.props.changeTab}
                            />
                        </div>
                    </PageLeftWrapper>
                </PageLTRWrapper>
                <ScrapedNetworkingPeek
                    open={this.state.networkingPeekOpen}
                    network={this.props.networks[this.state.selectedPersonId]}
                    isSaved={(this.state.selectedPersonId in this.props.networks)}
                    person={this.state.selectedPerson}
                    close={this.closePeek}
                    deleteNetwork={this.props.deleteNetwork}
                    updateNetwork={this.props.updateNetwork}
                />
            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkingPage);
