import React, {Component} from "react";
import {connect} from "react-redux";
import RecruiterCompanyPageHeader from "../companypage/RecruiterCompanyPageHeader";
import RecruiterCompanyPageAbout from "../companypage/RecruiterCompanyPageAbout";
import RecruiterCompanyPageLifeAtSection from "../companypage/RecruiterCompanyPageLifeAtSection";
import RecruiterCompanyPagePerks from "../companypage/RecruiterCompanyPagePerks";
import RecruiterCompanyPageTestimonialsSection from "../companypage/RecruiterCompanyPageTestimonialsSection";
import RecruiterCompanyPageUpdatesSection from "../companypage/RecruiterCompanyPageUpdatesSection";
import RecruiterCompanyPageOverview from "../companypage/RecruiterCompanyPageOverview";
import RecruiterCompanyPageDeals from "../companypage/RecruiterCompanyPageDeals";
import {getCompany, editCompany} from "../../api/recruiter/companies";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}


class RecruiterCompanyPage extends Component {
    state = {
        company: {},
        hidden: []
    }

    componentDidMount = () => {
        this.getInfo();
        window.analytics.page("Recruiter Company Page");
    }

    getInfo = async () => {
        const res = await getCompany(this.props.authUser);
        this.setState({company: res.company, hidden: res.company.hidden || []});
    }

    removeItem = async (field, index) => {
        const newField = [...this.state.company[field]];
        newField.splice(index, 1);
        await editCompany(this.props.authUser, {[field]: newField})
        this.getInfo();
    }

    hide = async (field) => {
        const hidden = [...this.state.hidden];
        if (hidden.includes(field)) {
            const index = hidden.indexOf(field);
            hidden.splice(index, 1);
        } else {
            hidden.push(field);
        }
        this.setState({hidden})
        await editCompany(this.props.authUser, {hidden});
    }

    render() {
        const company = this.state.company || {};
        return (
            <div className={`flex flex-col flex-1 relative p-5 gap-8`}>
                <img
                    className='absolute left-0 right-0 top-0 h-60 w-full object-cover z-[0]'
                    src={company.cover_photo || 'https://www.morganstanley.com/content/dam/msdotcom/articles/finance-technology-at-morgan-stanley/tw-finance-technology-at-morgan-stanley.jpg'}/>
                <div className='z-[2] mt-100 flex flex-col gap-5'>
                    <RecruiterCompanyPageHeader company={company}/>
                    <div className='flex flex-row gap-5'>
                        <div className='flex-1 flex flex-col gap-5'>
                            <RecruiterCompanyPageAbout
                                editable
                                hide={this.hide} hidden={this.state.hidden}
                                company={company}/>
                            <RecruiterCompanyPageLifeAtSection removeItem={this.removeItem} hide={this.hide} hidden={this.state.hidden}
                                company={company}/>
                            <RecruiterCompanyPageTestimonialsSection company={company} removeItem={this.removeItem} hide={this.hide} hidden={this.state.hidden}/>
                            <RecruiterCompanyPageUpdatesSection company={company} removeItem={this.removeItem} hide={this.hide} hidden={this.state.hidden}/>
                        </div>
                        <div className={'w-[400px] flex flex-col gap-5'}>
                            <RecruiterCompanyPageOverview company={company} hide={this.hide} hidden={this.state.hidden}/>
                            <RecruiterCompanyPageDeals company={company} removeItem={this.removeItem} hide={this.hide} hidden={this.state.hidden}/>
                            <RecruiterCompanyPagePerks company={company} hide={this.hide} hidden={this.state.hidden}/>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterCompanyPage);
