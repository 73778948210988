import React from "react";
import WrappedTextInput from "../components/atoms/WrappedTextInput";
import { ACTIVELY_LOOKING_OPTIONS } from "../components/onboarding/onboarding-constants";

function AdminEditStudentProfile({
    formData,
    setJobStatus,
}) {
    return (
        <div className="space-y-4">
            <div className="col-plain w-full pb-3">
                <WrappedTextInput
                    outerLabel="Name"
                    value={`${formData.first_name} ${formData.last_name}`}
                    className="input-light px-3 body2 base-black-50 w-full"
                    disabled={true}
                    placeholder="Add your name"
                />
            </div>
            <div className="col-plain w-full pb-3">
                <WrappedTextInput
                    outerLabel="Email"
                    value={formData.email}
                    className="input-light px-3 body2 base-black-50 w-full"
                    disabled={true}
                    placeholder="Add your email"
                />
            </div>
            <div className="col-plain w-full pb-3">
                <WrappedTextInput
                    outerLabel="Tagline"
                    value={formData.headline}
                    className="input-light px-3 body2 base-black-50 w-full"
                    disabled={true}
                    placeholder="Add your profile tagline"
                />
            </div>
            <div className="flex flex-row items-center gap-4">
                <div className="col-plain w-full pb-3">
                    <WrappedTextInput
                        outerLabel="City"
                        value={formData.city}
                        className="input-light px-3 body2 base-black-50 w-full"
                        disabled={true}
                        placeholder="City"
                    />
                </div>
                <div className="col-plain w-full pb-3">
                    <WrappedTextInput
                        outerLabel="State"
                        value={formData.state}
                        className="input-light px-3 body2 base-black-50 w-full"
                        disabled={true}
                        placeholder="State"
                    />
                </div>
            </div>
            <div>
                <p className="body3-bold text-neutral-400 mb-1">Job Status</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {ACTIVELY_LOOKING_OPTIONS.map((item) => (
                        <div
                            key={item.label}
                            onClick={() => setJobStatus(item.label)}
                            className={`flex flex-row items-center gap-3 p-4 rounded-xl cursor-pointer transition-colors ${
                                formData.job_status === item.label
                                    ? "bg-primary text-white"
                                    : "bg-slate-100 hover:bg-slate-200"
                            }`}
                        >
                            <item.Icon className="w-6 h-6" />
                            <p className="text-sm">{item.label}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-plain w-full pb-5">
                <WrappedTextInput
                    outerLabel="About"
                    value={formData.bio}
                    className="input-light px-3 body2 base-black-50 w-full pt-2 bg-neutral-200 min-h-[82px]"
                    disabled={true}
                    placeholder="A little more about you..."
                    multiline={true}
                />
            </div>
        </div>
    );
}

export default AdminEditStudentProfile; 