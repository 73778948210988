import React, {PureComponent} from "react";
import ModalComponent from "../atoms/modals/ModalComponent";
import { sendResetPasswordEmail } from "../../api/authentication";
import Spinner from "../Spinner";

class ForgotPassword extends PureComponent {
    state = {
        email: '',
        modalOpen: false,
        processing: false,
        emailSent: false,
        error: ''
    }

    emailChanged = e => this.setState({email: e.target.value})

    sendPasswordReset = async () => {
        this.setState({processing: true, error: ''})
        try {            
            await sendResetPasswordEmail(this.state.email, this.props.recruiter);
            this.setState({
                processing: false,
                emailSent: true
            });
        } catch (error) {
            this.setState({
                processing: false,
                error: error.message
            });
        }
    };

    openModal = () => {
        this.setState({modalOpen: true})
    }

    closeModal = () => {
        this.setState({
            email: '',
            modalOpen: false,
            processing: false,
            emailSent: false,
            error: ''
        })
    }

    render() {
        return (
            <>
                <div className='text-right' onClick={this.openModal}>
                    <p className="body3-bold primary pointer">
                        Forgot password?
                    </p>
                </div>
                <ModalComponent
                    isOpen={this.state.modalOpen}
                    backgroundColor={'white'}
                    header={this.state.emailSent ? 'All set' : 'Forgot your password?'}
                    headerToggle
                    size={'sm'}
                    toggle={this.closeModal}
                    FooterComponent={
                        !this.state.emailSent ?
                            <div className='flex flex-row flex-1 gap-3'>
                                {this.state.processing ?
                                    <button className="primary-button body1-bold flex flex-1 w-full">
                                        <Spinner style={{borderColor:"white", borderLeftColor:'#FFFFFF50'}} size={24}/>
                                    </button>
                                    :
                                    <button className="primary-button body1-bold flex flex-1 w-full"
                                            onClick={() => this.sendPasswordReset()}>
                                        Send Reset Email
                                    </button>
                                }
                                <button className="secondary-button body1-bold flex flex-1" onClick={this.closeModal}>
                                    Cancel
                                </button>
                            </div>
                            :
                            this.state.emailSent ?
                                <button className="secondary-button body1-bold flex flex-1" onClick={this.closeModal}>
                                    Close
                                </button>
                                :
                                null
                    }
                >
                    {!this.state.emailSent ?
                        <div className='mb-3'>
                            <p className='text-sm text-slate-500 mb-5'>
                                Enter the email address associated with your account to receive a reset email.
                            </p>
                            <p className="body3-bold text-slate-500 mb-1">
                                Email
                            </p>
                            <input
                                value={this.state.email}
                                onChange={this.emailChanged}
                                placeholder={'Enter email'}
                                className='input-light px-3 body2 base-black-50 w-full'
                            />
                            {this.state.error && (
                                <p className="text-xs text-red-400 mt-1">
                                    {this.state.error}
                                </p>
                            )}
                        </div>
                        :
                        <>
                            <p className='text-sm text-slate-500 mb-5'>
                                You will receive an email shortly if you have an account with RecruitU.
                            </p>
                        </>
                    }
                </ModalComponent>
            </>
        )
    }
}

export default ForgotPassword;
