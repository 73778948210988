import React, {PureComponent} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import CompanyStatusTrackerComponent from "../atoms/CompanyStatusTrackerComponent";
import CompanyLogo from "../atoms/CompanyLogo";
import Checkbox from "../atoms/Checkbox";
import TagComponent from '../atoms/TagComponent';
import SidebarOverlayWrapper from "../atoms/SidebarOverlayWrapper";
import {Button} from "../atoms/Button";
import {addNote, deleteNote, editTracker} from "../../api/student/trackers";
import SvgLink from "../icons/SvgLink";
import {TOAST_OPTIONS} from "../../utils/constants";
import {toast} from "react-toastify";
import NotetakingWidget from "../../components-recruiter/atoms/NotetakingWidget";
import ShowMoreText from "react-show-more-text";
import CompanyInfoBlock from "../companypage/CompanyInfoBlock";
import CompanyAboutBlock from "../companypage/CompanyAboutBlock";
import {SIDEBAR_CARD} from "../../utils/dummy";
import { sanitizeHtml } from "../../utils/strings";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        trackers: state.userReducer.trackers
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateTracker: (tracker) => {
            dispatch({
                type: "UPDATE_TRACKER",
                tracker,
            })
        }
    }
}

class CompanyPeek extends PureComponent {
    state = {
        note: ""
    }

    toggleCheckbox = async () => {
        const newTracker = {...this.props.trackers[this.props.company.id]};
        const newStatus = !newTracker.status
        newTracker.status = newStatus
        this.props.updateTracker(newTracker);
        await editTracker(this.props.authUser, newTracker.id, {status: newStatus});

        if (newStatus) {
          window.analytics.track("Company Status Updated", {
            company_id: this.props.company.id
          })
        }
    }

    updateNote = (e) => {
        this.setState({note: e.target.value})
    }

    saveNote = async (note) => {
        try {
            const tracker = this.props.trackers[this.props.company.id] || {};
            const savePromise = addNote(this.props.authUser, tracker.id, note);
            toast.promise(
                savePromise,
                {
                    pending: 'Adding note...',
                    success: 'Note added!',
                    error: 'Failed to add note!',
                },
                TOAST_OPTIONS
            );
            const saveResult = await savePromise;
            if (saveResult) {
                const newTracker = {...tracker};
                newTracker.notes.push({text: note, created_at: new Date()});
                this.props.updateTracker(newTracker);
            }
        } catch (error) {
            toast.error('Failed to add note!', TOAST_OPTIONS);
        }
    };

    deleteNote = async (index) => {
        try {
            const tracker = this.props.trackers[this.props.company.id] || {};
            const deletePromise = deleteNote(this.props.authUser, tracker.id, index);
            toast.promise(
                deletePromise,
                {
                    pending: 'Deleting note...',
                    success: 'Note deleted!',
                    error: 'Failed to delete note!',
                },
                TOAST_OPTIONS
            );

            const deleteResult = await deletePromise;
            if (deleteResult) {
                const newTracker = {...tracker};
                newTracker.notes.splice(index, 1);
                this.props.updateTracker(newTracker);
            }
        } catch (error) {
            console.error(error);
        }
    };

    render() {
        const company = this.props.company;
        const tracker = this.props.trackers[company.id] || {};
        const notes = tracker.notes || [];
        return (
            <SidebarOverlayWrapper label={'Company Details'} onClose={this.props.close} open={this.props.open}
                                   width={'40vw'}>
                <div className='flex flex-col flex-1 overflow-y-scroll gap-1'>
                    <div className="flex flex-row justify-between items-center">
                        <div className='flex flex-row gap-3'>
                            <CompanyLogo id={company.id} className={'w-20 h-20'}/>
                            <div className='flex flex-col items-start gap-2'>
                                <p className='text-2xl font-bold'>
                                    {company.name}
                                </p>
                                <TagComponent
                                    label={company.sector}
                                />
                            </div>
                        </div>
                        {!this.props.inTracker &&
                            <div>
                                <CompanyStatusTrackerComponent companyId={company.id}/>
                            </div>
                        }
                    </div>
                    <ShowMoreText lines={3} className={'text-slate-500 mb-2 mt-2 text-sm flex-col'}
                                  anchorClass={'text-primary'}>
                        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(company.description) }} />
                    </ShowMoreText>
                    {company.domain &&
                        <a href={'https://' + company.domain} target={'_blank'} rel={'noreferrer'}
                           className='row-ac text-sm mt-0 text-primary font-semibold'>
                            <SvgLink width={16} height={16}/>
                            <p className='m-0 ml-8'>
                                {company.domain}
                            </p>
                        </a>
                    }
                    {this.props.inTracker ?
                        <div className={`${SIDEBAR_CARD} mt-5`}>
                            <div className='flex flex-row items-center justify-between'>
                                <p className='text-lg font-bold'>
                                    Status
                                </p>
                                <Checkbox
                                    checked={tracker?.status}
                                    onClick={this.toggleCheckbox}
                                    label={'Offered'}
                                />
                            </div>
                        </div>
                        :
                        <></>
                    }
                    {this.props.inTracker &&
                        <div className='mt-3 mb-3'>
                            <NotetakingWidget
                                notes={notes}
                                onSaveNote={this.saveNote}
                                onDeleteNote={this.deleteNote}
                            />
                        </div>
                    }
                    <CompanyAboutBlock company={company} inPeek/>
                    <div className='my-5'>
                        <CompanyInfoBlock company={company} inPeek/>
                    </div>
                </div>
                <div className='flex-col mt-2'>
                    <Link to={{
                        pathname: '/company/' + company.id,
                        state: {company_id: company.id, origin: "discover"}
                    }} className={'flex-1'}>
                        <Button className='w-full'>
                            View Full Company Page
                        </Button>
                    </Link>
                </div>
            </SidebarOverlayWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPeek);
