import React, {Component} from "react";
import {truncateString} from "../../utils/strings";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import SelectClubListModal from "../../components-recruiter/modals/SelectClubListModal";
import ProfilePicture from "../../components-recruiter/atoms/ProfilePicture";
import {Button} from "./Button";
import MessageStudentFlow from "../../components-recruiter/MessageStudentFlow";
import SvgCelebration from "../icons/SvgCelebration";
import SvgZap from "../icons/SvgZap";


class StudentPeekHeader extends Component {
    state = {
        rating: 0,
        isSelectListModalOpen: false,
    }

    appendHttps(url) {
        // Check if the URL starts with "www." or "linkedin."
        if (url.startsWith("www.") || url.startsWith("linkedin.")) {
          // If the URL doesn't already start with "http://" or "https://"
          if (!url.startsWith("http://") && !url.startsWith("https://")) {
            return `https://${url}`;
          }
        }
        return url;
      }

    render() {
        const user = this.props.student || {};
        const major = user.major || [];
        const grade = user.grade;
        const gpa = user.gpa;
        const college = this.props.college;
        const stats = this.props.stats || {};
        return (
            <div>
                <div className={`flex flex-row flex-wrap gap-y-4 items-center justify-between ${this.props.className}`}>
                    <div className='flex flex-row flex-1 gap-4 items-center text-neutral-400 body2'>
                        <ProfilePicture
                            image={user.profile_pic_url}
                            userID={user.id}
                            size={92}
                            className={'border border-neutral-200'}
                        />
                        <div className='flex flex-col gap-1 flex-1'>
                            <div className='flex flex-row items-center gap-2'>
                                <p className='text-2xl font-semibold m-0 blacks-1'>
                                    {`${user.first_name} ${user.last_name}`}
                                </p>
                                <div className='flex flex-row items-center gap-1'>
                                    {user.linkedin &&
                                        <a href={this.appendHttps(user.linkedin)}
                                           target={"_blank"} rel="noreferrer">

                                            <SvgLinkedIn className={'base-black-30 h-base-black-70 pointer w-4 h-4'}/>
                                        </a>
                                    }
                                </div>
                            </div>
                            <div className='flex flex-col text-sm font-semibold'>
                                {!!user.college &&
                                    <p className=''>
                                        {truncateString(user.college, 60)}
                                    </p>
                                }
                                {major.length > 0 ?
                                    <div className='line-clamp-2'>
                                        Majoring in {user.major.join(', ')}
                                    </div>
                                    : null
                                }
                                {(grade || gpa) ?
                                    <div className='flex flex-row items-center gap-2'>
                                        {grade &&
                                            <div className='flex flex-row items-center'>
                                                <SvgCelebration width={16} height={16} />
                                                <span className='ml-1'>{grade}</span>
                                            </div>
                                        }
                                        {grade && gpa && !this.props.hideStats ? ' • ' : null}
                                        {gpa && !this.props.hideStats ?
                                            <div className='flex flex-row items-center'>
                                                <SvgZap width={16} height={16} />
                                                <span className='ml-1'>{gpa} GPA</span>
                                            </div>
                                        : null}
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    {!this.props.clubAdmin &&
                        <div className='flex flex-col items-end gap-3'>
                            <MessageStudentFlow user={user}/>
                            <Button onClick={(e) => {
                                e.stopPropagation();
                                this.setState({isSelectListModalOpen: true})
                            }}>
                                Add to list
                            </Button>
                        </div>
                    }
                    {!this.props.hideStats && !this.props.loading &&
                        <div className='flex flex-row items-center gap-5'>
                            <div>
                                <p className='text-lg font-semibold text-slate-700'>
                                    {stats.jobs_applied}
                                </p>
                                <p className='text-xs xl:text-sm text-slate-500'>
                                    Jobs applied
                                </p>
                            </div>
                            <div>
                                <p className='text-lg font-semibold text-slate-700'>
                                    {stats.events_attended}
                                </p>
                                <p className='text-xs xl:text-sm text-slate-500'>
                                    Events attended
                                </p>
                            </div>
                            <div>
                                <p className='text-lg font-semibold text-slate-700'>
                                    {stats.people_networked}
                                </p>
                                <p className='text-xs xl:text-sm text-slate-500'>
                                    People networked
                                </p>
                            </div>
                            <div>
                                <p className='text-lg font-semibold text-slate-700'>
                                    {stats.questions_practiced}
                                </p>
                                <p className='text-xs xl:text-sm text-slate-500'>
                                    Interview questions practiced
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <SelectClubListModal
                    items={[user]}
                    type={"user"}
                    isOpen={this.state.isSelectListModalOpen}
                    authUser={this.props.authUser}
                    closeModal={() => this.setState({isSelectListModalOpen: false})}
                />
            </div>
        )
    }
}



export default StudentPeekHeader
