import React from "react";
import DiversityQuestions from "../components/personalizationflow/DiversityQuestions";
import AuthorizationSponsorshipQuestions from "../components/editprofile/AuthorizationSponsorshipQuestions";

function AdminEditStudentDiversity({
    formData,
    handleMultiSelectChange,
    handleSelectChange,
    handleEmailChange,
}) {
    return (
        <>
            <DiversityQuestions
                gender={formData.diversity?.gender}
                race={formData.diversity?.race}
                veteran={formData.diversity?.veteran}
                disabled={formData.diversity?.disabled}
                firstGeneration={formData.diversity?.firstGeneration}
                lgbtq={formData.diversity?.lgbtq}
                handleMultiSelectChange={handleMultiSelectChange}
                handleSelectChange={handleSelectChange}
                handleEmailChange={handleEmailChange}
                secondaryEmail={formData.secondary_email}
                errors={{}}
                hasCollapse={true}
            />

            <AuthorizationSponsorshipQuestions
                authorized={formData.diversity?.authorized}
                requireSponsorship={formData.diversity?.requireSponsorship}
                handleSelectChange={handleSelectChange}
                hasCollapse={true}
            />
        </>
    );
}

export default AdminEditStudentDiversity;
