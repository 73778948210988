import React, {Component} from "react";
import {rankColor, truncateString} from "../utils/strings";
import Tooltip from "../components/atoms/modals/Tooltip";
import {connect} from "react-redux";
import SvgClose from "../components/icons/SvgClose";
import ClubLogo from "./atoms/ClubLogo";
import SchoolLogo from "./atoms/SchoolLogo";
import {withRouter} from "react-router-dom";
import ProfilePicture from "./atoms/ProfilePicture";
import { Button } from "../components/atoms/Button";
import { DateTime } from 'luxon';
import { COLLEGE_LOGOS_MAP } from "../utils/constants";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleStudentPeek: () => {
            dispatch({
                type: "TOGGLE_STUDENT_PEEK",
            })
        },
        setStudentPeekStudent: (user) => {
            dispatch({
                type: "SET_STUDENT_PEEK_STUDENT",
                user,
            })
        },
    }
}

class RecruiterFavoritesTableItem extends Component {
    state = {
        profileError: false,
        schoolError: false,
    }

    openStudentPeek = () => {
        this.props.toggleStudentPeek()
        this.props.setStudentPeekStudent(this.props.item)
    }

    openStudentPage = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
    }

    handleRemove = (e) => {
        e.stopPropagation();
        this.props.remove(this.props.item.id)
    }

    render() {
        const columns = this.props.columns
        const user = this.props.item || {};
        const clubs = user.clubs || [];
        const education = user.education || [];
        const list = this.props.list || {}
        let formattedDate = ""
        if (!!list && this.props.type === "smart") {
            const parsedDate1 = DateTime.fromISO(user.created_at);
            const parsedDate2 = DateTime.fromISO(this.props.list.created_at);
            const moreRecentDate = parsedDate1 > parsedDate2 ? parsedDate1 : parsedDate2;
            formattedDate = moreRecentDate.toLocaleString(DateTime.DATE_MED);
        }
        let collegeLogo = "";
        if (user.college in COLLEGE_LOGOS_MAP) {
            collegeLogo = COLLEGE_LOGOS_MAP[user.college]
        } else {
            collegeLogo = education[0]?.logo_url
        }
        return (
            <div
                onClick={() => this.openStudentPeek()}
                className='flex flex-row items-center text-slate-500 pointer'>
                <div
                    className={'flex flex-row items-center gap-2'}
                    style={{flex: columns[0].flex}}>
                    <ProfilePicture
                        image={user.profile_pic_url}
                        userID={user.id}
                        size={40}
                        className={'border border-slate-200'}
                    />
                    <div className={'flex flex-col gap-0.5'}>
                        <p className=' text-slate-800 font-semibold text-sm'>
                            {user.first_name} {user.last_name}
                        </p>
                        {!!formattedDate &&
                            <p className='text-slate-500 text-xs mt-1 ml-2'>
                                Added to list: {formattedDate}
                            </p>
                        }
                        
                    </div>
                </div>
                <div style={{flex: columns[1].flex}}>
                    <Tooltip
                        toggleComponent={
                            // <img src={!this.state.schoolError ? education[0].logo_url : "https://handshake-production-cdn.joinhandshake.com/static_assets/default_institution_1.png"}
                            //      onError={() => this.setState({schoolError: true})}
                            //      className='w-10 w-10 rounded-full border border-slate-200 object-contain'/>
                            <SchoolLogo image={collegeLogo} college={user.college}/>

                        }
                        label={!!education[0] ? truncateString(education[0].school, 30) : "College"}
                    />
                </div>
                <div style={{flex: columns[2].flex}} className='flex flex-row items-center'>
                    <div className='flex flex-row items-center relative'>
                        {clubs.slice(0, 2).map((club, index) =>
                            <Tooltip
                                toggleComponent={
                                    <ClubLogo clubId={club} className={`${index !== 0 && '-ml-5'} border border-slate-200`}/>
                                }
                                label={club?.name}
                            />

                        )}
                        {!!clubs && clubs?.length > 2 &&
                            <div
                                className='absolute bg-primary bottom-[-4px] right-[-4px] rounded-full px-1 cursor-pointer'>
                                <p className='text-xs font-bold text-white'>
                                    +{clubs?.length - 2}
                                </p>
                            </div>
                        }
                    </div>
                </div>
                <div style={{flex: columns[3].flex}}>
                    <p className='text-xs'>
                        {user.major.join(', ')}
                    </p>
                </div>
                <div style={{flex: columns[4].flex}}>
                    <p className='text-xs'>
                        {user.gpa}
                    </p>
                </div>
                <div style={{flex: columns[5].flex}}>
                    <p className='text-xs'>
                        {user.grade}
                    </p>
                </div>
                {!!this.props.remove && this.props.isEditing && this.props.type !== "smart" &&
                    <div style={{flex: columns[6].flex}}>
                        <Button
                            onClick={this.handleRemove}
                            size={'icon'} variant={'destructive'} icon={SvgClose}
                            className='-ml-10'/>
                    </div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecruiterFavoritesTableItem))
