import React, {PureComponent} from "react";
import RecruiterStudentPeekApplicationItem from "../RecruiterStudentPeekApplicationItem";
import RecruiterStudentPeekEventItem from "../RecruiterStudentPeekEventItem";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {SIDEBAR_CARD} from "../../../utils/dummy";
import RecruiterStudentPeekEmailItem from "../RecruiterStudentPeekEmailItem";
import CollapseComponent from "../../../components/atoms/CollapseComponent";
import {Button} from "../../../components/atoms/Button";
import { withRouter } from 'react-router-dom';

class RecruiterStudentActivity extends PureComponent {
    state = {
        showMoreEvents: false,
        showMoreEmails: false,
        showMoreApplications:false,
        showMoreLists: false
    }

    toggleShowMoreEvents = () => {
        this.setState({showMoreEvents: !this.state.showMoreEvents})
    }

    toggleShowMoreEmails = () => {
        this.setState({showMoreEmails: !this.state.showMoreEmails})
    }

    toggleShowMoreApplications = () => {
        this.setState({showMoreApplications: !this.state.showMoreApplications})
    }

    toggleShowMoreLists = () => {
        this.setState({showMoreLists: !this.state.showMoreLists})
    }

    handleListClick = (type, listId) => {
        if (!listId) return;
        this.props.history.push(`/list/${type}/${listId}`);
    }

    getStudentLists = () => {
        const { lists = {}, student = {} } = this.props;
        const belongsToLists = [];

        
        lists.student_lists?.forEach(list => {
            if (list?.user_ids?.includes(student?.id)) {
                belongsToLists.push({ 
                    name: list.name ?? 'Unnamed List', 
                    type: 'user', 
                    creator: list.creator_name ?? 'Unknown',
                    id: list.id
                });
            }
        });

        lists.club_lists?.forEach(list => {
            if (student?.clubs?.some(club => list?.club_ids?.includes(club))) {
                belongsToLists.push({ 
                    name: list.name ?? 'Unnamed List', 
                    type: 'club', 
                    creator: list.creator_name ?? 'Unknown',
                    id: list.id
                });
            }
        });

       
        lists.smart_lists?.forEach(list => {
            let matches = true;
            
            if (list?.colleges?.length && !list.colleges?.includes(student?.college)) matches = false;
            if (list?.majors?.length && !student?.major?.some(m => list.majors?.includes(m))) matches = false;
            if (list?.industries?.length && !student?.industries?.some(i => list.industries?.includes(i))) matches = false;
            if (list?.grades?.length && !list.grades?.includes(student?.grade)) matches = false;
            if (list?.race?.length && !student?.diversity?.race?.some(r => list.race?.includes(r))) matches = false;
            if (list?.gender && list?.gender !== student?.diversity?.gender) matches = false;
            if (list?.gpa && (student?.gpa == null || student.gpa < parseFloat(list.gpa))) matches = false;
            
            // TODO: this might not work as the student ids are not the same as the filter ids
            if (list?.current_companies?.length && !student?.current_companies?.some(c => list.current_companies?.includes(c))) matches = false;
            if (list?.previous_companies?.length && !student?.previous_companies?.some(c => list.previous_companies?.includes(c))) matches = false;
            
            if (list?.is_ib && !student?.is_ib) matches = false;
            if (list?.authorized && !student?.authorized) matches = false;
            if (list?.disabled && !student?.diversity?.disabled) matches = false;
            if (list?.veteran && !student?.diversity?.veteran) matches = false;
            if (list?.lgbtq && !student?.diversity?.lgbtq) matches = false;
            
            if (matches) {
                belongsToLists.push({ 
                    name: list.name ?? 'Unnamed List', 
                    type: 'smart', 
                    creator: list.creator_name ?? 'Unknown',
                    id: list.id
                });
            }
        });

        return belongsToLists;
    }

    render() {
        const student = this.props.student
        const studentLists = this.getStudentLists();

        return (
            <div className='flex-1 overflow-y-scroll gap-5 pt-3'>
                 <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-lg font-semibold'>
                        Lists
                    </p>
                    {studentLists.length > 0 ? (
                        <div className='flex flex-col gap-3'>
                            {studentLists.slice(0, 3).map((list, index) => (
                                <div 
                                    key={index} 
                                    className='flex flex-col gap-1 cursor-pointer hover:bg-slate-50 p-2 rounded-md transition-colors'
                                    onClick={() => this.handleListClick(list.type, list.id)}
                                >
                                    <p className='text-sm text-slate-800 font-medium'>{list.name}</p>
                                    <p className='text-xs text-slate-500'>
                                        {list.type?.charAt(0).toUpperCase() + list.type?.slice(1)} List • Created by {list.creator}
                                    </p>
                                </div>
                            ))}
                            {studentLists.length > 3 && (
                                <>
                                    <CollapseComponent isOpen={this.state.showMoreLists}>
                                        <div className='flex flex-col gap-3'>
                                            {studentLists.slice(3).map((list, index) => (
                                                <div 
                                                    key={index + 3} 
                                                    className='flex flex-col gap-1 cursor-pointer hover:bg-slate-50 p-2 rounded-md transition-colors'
                                                    onClick={() => this.handleListClick(list.type, list.id)}
                                                >
                                                    <p className='text-sm text-slate-800 font-medium'>{list.name}</p>
                                                    <p className='text-xs text-slate-500'>
                                                        {list.type?.charAt(0).toUpperCase() + list.type?.slice(1)} List • Created by {list.creator}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </CollapseComponent>
                                    <Button variant={'secondary'} onClick={this.toggleShowMoreLists}>
                                        {this.state.showMoreLists ? 'Hide' : `Show ${studentLists.length - 3} more`}
                                    </Button>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className='flex flex-col items-center pt-5 pb-7'>
                            <p className='text-xs text-slate-400'>
                                {student.first_name} isn't part of any lists
                            </p>
                        </div>
                    )}
                </div>
                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-lg font-semibold'>
                        Event History
                    </p>
                    {this.props.studentEvents.length > 0 ?
                        <div className='flex flex-col gap-3'>
                            {this.props.studentEvents.slice(0, 3).map(item =>
                                <RecruiterStudentPeekEventItem event={item}/>
                            )}
                            {this.props.studentEvents.length > 3 &&
                                <>
                                    <CollapseComponent isOpen={this.state.showMoreEvents}>
                                        <div className='flex flex-col gap-3'>
                                            {this.props.studentEvents.slice(3).map(item =>
                                                <RecruiterStudentPeekEventItem event={item}/>
                                            )}
                                        </div>
                                    </CollapseComponent>
                                    <Button variant={'secondary'} onClick={this.toggleShowMoreEvents}>
                                        {this.state.showMoreEvents ? 'Hide' : `Show ${this.props.studentEvents.length - 3} more`}
                                    </Button>
                                </>
                            }
                        </div>
                        :
                        <div className='flex flex-col items-center pt-5 pb-7'>
                            <p className='text-xs text-slate-400'>
                                {student.first_name} hasn't interacted with any events
                            </p>
                        </div>
                    }
                </div>

                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-lg font-semibold'>
                        Email History
                    </p>
                    {this.props.companyUserEmails?.length > 0 ?
                        <div className='flex flex-col gap-3'>
                            {this.props.companyUserEmails.slice(0, 3).map(email =>
                                <RecruiterStudentPeekEmailItem email={email} key={email.id}/>
                            )}
                            {this.props.companyUserEmails.length > 3 &&
                                <>
                                    <CollapseComponent isOpen={this.state.showMoreEmails}>
                                        <div className='flex flex-col gap-3'>
                                            {this.props.companyUserEmails.slice(3).map(email =>
                                                <RecruiterStudentPeekEmailItem email={email} key={email.id}/>
                                            )}
                                        </div>
                                    </CollapseComponent>
                                    <Button variant={'secondary'} onClick={this.toggleShowMoreEmails}>
                                        {this.state.showMoreEmails ? 'Hide' : `Show ${this.props.companyUserEmails.length - 3} more`}
                                    </Button>
                                </>
                            }
                        </div>
                        :
                        <div className='flex flex-col items-center pt-5 pb-7'>
                            <p className='text-xs text-slate-400'>
                                No emails have been sent to {student.first_name}
                            </p>
                        </div>
                    }
                </div>

                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                    <p className='text-slate-800 text-lg font-semibold'>
                        Application History
                    </p>
                    {this.props.studentApplications.length > 0 ?
                        <div className='flex flex-col gap-3'>
                            {this.props.studentApplications.slice(0,3).map(item =>
                                <RecruiterStudentPeekApplicationItem application={item} student={student} />
                            )}
                            {this.props.studentApplications.length > 3 &&
                                <>
                                    <CollapseComponent isOpen={this.state.showMoreApplications}>
                                        <div className='flex flex-col gap-3'>
                                            {this.props.studentApplications.slice(3).map(item =>
                                                <RecruiterStudentPeekApplicationItem application={item} student={student} />
                                            )}
                                        </div>
                                    </CollapseComponent>
                                    <Button variant={'secondary'} onClick={this.toggleShowMoreApplications}>
                                        {this.state.showMoreApplications ? 'Hide' : `Show ${this.props.studentApplications.length - 3} more`}
                                    </Button>
                                </>
                            }
                        </div>
                        :
                        <div className='flex flex-col items-center pt-5 pb-7'>
                            <p className='text-xs text-slate-400'>
                                {student.first_name} hasn't interacted with any applications
                            </p>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(RecruiterStudentActivity);
