import React, {PureComponent} from "react";
import HorizontalDivider from '../atoms/HorizontalDivider'
import TooltipComponent from "./TooltipComponent";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import SvgChevronRight from "../icons/SvgChevronRight";
import SvgCheck from "../icons/SvgCheck";

class DropdownComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    handleClick = (e, title) => {
        e.stopPropagation()
        this.toggle(false, true);
        if (this.props.stateName) {
            this.props.setValue(this.props.stateName, title)
        } else {
            this.props.setValue(title)
        }
    }

    optionRenderer = (option, index) => {
        if (option.visible === false) {
            return null
        }
        if (option.divider) {
            return <HorizontalDivider className="w-100p" color={'#FFFFFF'} key={`divider-${index}`}/>
        }
        if (option.header) {
            return (
                <div className='body2-subheader server-channel-title uppercase ph-12 pt-12 pb-6'
                     key={option.header}
                >
                    {option.header}
                </div>
            )
        }
        if (option.component) {
            return (
                <React.Fragment key={index}>
                    {option.component}
                </React.Fragment>
            )
        }
        return (
            <div key={option.title}
                 className={`${this.props.light ? 'dropdown-menu-option-light' : 'dropdown-menu-option'} body2-emphasized ${option.active ? 'active' : ''} py-2`}
                 onClick={(e) => {
                     this.props.setValue && this.handleClick(e, option.title);
                     (!option.linkToClub && option.onClick) && option.onClick()
                 }}>
                {option.icon &&
                    <div className="w-8 h-7 flex flex-col items-center justify-center mr-2">
                        <option.icon className="w-6 h-6"
                                     fill={option.color || '#FFFFFF'}/>
                    </div>
                }
                {
                    this.props.selectDropdown &&
                    <div
                        className={`${this.props.value === option.title ? 'checkbox-active' : 'checkbox'} mr-12 ${(this.props.light && this.props.value !== option.title) && 'border-neutral-200'}`}>
                        {this.props.value === option.title &&
                            <SvgCheck className={'base-white-100'}/>
                        }
                    </div>
                }
                {option.img &&
                    <img src={option.img} alt={'club-icon'}
                         className='w-8 h-8 rounded-sm border object-contain bg-white border-neutral-200 mr-2'/>
                }
                <div className="col-plain" style={{color: option.color}}>
                    {option.linkToClub ?
                        <Link to={{pathname: '/club/' + option.id, state: {club_id: option.id}}}>
                            <p className={` ${!!option.subtitle ? 'body3 mb-4' : 'body2'}`}
                               style={{color: option.color}}
                            >
                                {option.title}
                            </p>
                        </Link>
                        :
                        <p className={` ${!!option.subtitle ? 'body3 mb-4' : 'body3'}`}
                           style={{color: option.color}}
                        >
                            {option.title}
                        </p>
                    }
                    {/*<p className="body3 base-white-50">{option.subtitle}</p>*/}
                </div>
            </div>
        )
    }

    toggle = (isOpen, fromClick) => {
        if (this.props.isControlled) {
            this.props.toggle(isOpen)
            this.props.toggleCallback && this.props.toggleCallback(isOpen);
            !!fromClick && this.props.optionClickedCallback && this.props.optionClickedCallback()
        } else {
            this.props.toggleCallback && this.props.toggleCallback(isOpen);
            this.setState({isOpen})
            !!fromClick && this.props.optionClickedCallback && this.props.optionClickedCallback()
        }
    }

    render() {
        return (
            <TooltipComponent
                ToggleComponent={this.props.ToggleComponent}
                toggleClassName={this.props.toggleClassName}
                ContentComponent={
                    <div
                        style={{zIndex: 1000}}
                        className={`dropdown-menu pr-0 overflow-hidden ${this.props.hasCollapse ? '!w-full' : ''} ${this.props.light ? 'dropdown-menu-light' : 'dropdown-menu'} ${(this.props.scrollable && this.props.options.length > 10) && 'max-h-[30vh] overflow-y-scroll'}`}>
                        {this.props.options.map((option, index) =>
                            this.optionRenderer(option, index)
                        )}
                    </div>
                }
                disabled={this.props.disabled}
                visible={this.props.isControlled ? this.props.isOpen : this.state.isOpen}
                toggle={this.toggle}
                offsetX={this.props.offsetX}
                offsetY={this.props.offsetY}
                placement={this.props.direction || "bottom-start"}
                node={this.props.node}
                hasCollapse={this.props.hasCollapse}
                collapseClassName={this.props.collapseClassName}
            />
        )
    }
}

DropdownComponent.propTypes = {
    isControlled: PropTypes.bool,
    toggle: PropTypes.func,
    toggleCallback: PropTypes.func,
    optionClickedCallback: PropTypes.func,
    ToggleComponent: PropTypes.object,
    toggleClassName: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    isOpen: PropTypes.bool,
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
    direction: PropTypes.string,
    node: PropTypes.node
}

export default DropdownComponent
