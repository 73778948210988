import { useEffect } from "react";
import { downloadCSV, formatStudentForCSV } from "../utils/csv";
import { toast } from "react-toastify";

const MAX_EXPORT_PAGES = 40;
const MAX_EXPORT_RESULTS = 250;
const MAX_EXPORT_RECORDS = MAX_EXPORT_PAGES * MAX_EXPORT_RESULTS;

export const useStudentExport = ({
    fetchStudents,
    filters,
    setExportEmailCsvLoading,
    setExportFullCsvLoading,
}) => {
    const setLoadingState = (type, isLoading) => {
        if (type === "email") {
            setExportEmailCsvLoading(isLoading);
        } else {
            setExportFullCsvLoading(isLoading);
        }
    };

    const fetchAllStudents = async (filters) => {
        const allStudents = [];
        let page = 1;
        let hasMore = true;

        while (hasMore && page <= MAX_EXPORT_PAGES) {
            try {
                const { students, total } = await fetchStudents(
                    filters,
                    page,
                    MAX_EXPORT_RESULTS,
                    false
                );

                if (page === 1 && total > MAX_EXPORT_RECORDS) {
                    toast.warn(
                        `Export will be limited to ${MAX_EXPORT_RECORDS} records out of ${total} total records`
                    );
                }

                if (!students?.length) {
                    hasMore = false;
                } else {
                    allStudents.push(...students);
                    page++;
                }
            } catch (error) {
                throw new Error('Failed to fetch students');
            }
        }

        if (hasMore) {
            console.warn(
                `CSV export truncated at ${MAX_EXPORT_PAGES * MAX_EXPORT_RESULTS} records`
            );
        }

        return allStudents;
    };

    const escapeCSVValue = (value) => {
        if (value === null || value === undefined) return '';
        
        // Convert to string and handle special cases
        const stringValue = String(value);
        
        // If the value contains commas, quotes, or newlines, wrap it in quotes
        if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
            // Double up any quotes within the value
            return `"${stringValue.replace(/"/g, '""')}"`;
        }
        
        return stringValue;
    };

    const generateCSV = (students, type) => {
        console.log('Starting CSV generation for', students.length, 'students');
        
        if (students.length < MAX_EXPORT_RESULTS) {
            console.warn(`Unexpected student count: ${students.length}. Expected: ${MAX_EXPORT_RESULTS}`);
        }

    
        const formattedData = students.map(student => 
            formatStudentForCSV(student, type)
        );
        
    
        const headers = Object.keys(formattedData[0]);
        
     
        const csv = [
     
            headers.map(header => escapeCSVValue(header)).join(","),

            ...formattedData.map(row =>
                headers
                    .map(header => escapeCSVValue(row[header]))
                    .join(",")
            )
        ].join("\n");

        console.log('CSV generation complete. Size:', csv.length, 'bytes');
        return csv;
    };

    const handleExportCSV = async (e) => {
        const { type } = e.detail;
        setLoadingState(type, true);

        try {
            const allStudents = await fetchAllStudents(filters);
            const csv = generateCSV(allStudents, type);
            
            downloadCSV(
                csv,
                `students-${type}-${new Date().toISOString().split("T")[0]}.csv`
            );
        } catch (error) {
            console.error(error);
            toast.error('Failed to export students');
        } finally {
            setLoadingState(type, false);
        }
    };

    useEffect(() => {
        window.addEventListener("exportStudentCSV", handleExportCSV);
        return () => window.removeEventListener("exportStudentCSV", handleExportCSV);
    }, [filters, fetchStudents, setExportEmailCsvLoading, setExportFullCsvLoading]);
};
