import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ProfilePicture from "./atoms/ProfilePicture";
import Tooltip from "../components/atoms/modals/Tooltip";
import SchoolLogo from "./atoms/SchoolLogo";
import { truncateString } from "../utils/strings";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        openPersonPeek: () => {
            dispatch({
                type: "SET_PERSON_PEEK_OPEN",
            })
        },
        setPersonPeekPerson: (person) => {
            dispatch({
                type: "SET_PERSON_PEEK_PERSON",
                person,
            })
        },
    }
}

const StudentSearchListItem = (props) => {
    const [profileError, setProfileError] = React.useState(false);

    const openPersonPeek = () => {
        props.openPersonPeek();
        props.setPersonPeekPerson(props.item);
    }

    const person = props.item || {};
    const profilePic = !!person.profile_pic_url && !profileError ? person.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media&token=ac3b0019-71c8-4830-932e-de437faf5fc8";
    const education = person.undergrad ?? {};
    let college = {};
    if (education.length > 0) {
        college = education[0]
    }
    const columns = props.columns;

    return (
        <li className='flex bg-white pointer hover:bg-slate-200/50 rounded-lg px-3 mx-[-12px] py-2 flex-row items-center text-slate-500'
            onClick={() => openPersonPeek()}
            key={person.id}>

            {/* <div style={{flex: 0.25}} className='flex flex-row items-center justify-center'>
                <Checkbox
                    checked={props.isSelected}
                    onClick={() => props.onSelectStudentChange(person)}
                />
            </div> */}
            <div
                className={'flex flex-row items-center gap-2'}
                style={{flex: columns[0].flex}}>
                <ProfilePicture
                // TODO: use the proper path to their photo url
                    image={person.profile_pic_url}
                    userID={person.id}
                    size={props.grid ? 52 : 40}
                    className={'border border-slate-200'}
                />
                <div className={'flex flex-col gap-0.5'}>
                    <p className='text-slate-800 font-semibold text-sm'>
                        {person.first_name} {person.last_name}
                    </p>
                </div>
            </div>
            <div style={{flex: columns[1].flex}} className='flex flex-row'>
                <p className='text-xs'>
                    {person.company_name || 'N/A'}
                </p>
            </div>
            <div style={{flex: columns[2].flex}} className='flex flex-row'>
                <p className='text-xs'>
                    {person.title || 'N/A'}
                </p>
            </div>
            <div style={{flex: columns[3].flex}} className='flex flex-row'>
                <p className='text-xs'>
                    {person.city || 'N/A'}
                </p>
            </div>
            <div style={{flex: columns[4].flex}} className='flex flex-row'>
                {/* <Tooltip
                    toggleComponent={
                        <SchoolLogo image={education?.logo_url} college={person.school}/>
                    }
                    label={!!education && !!education.school ? truncateString(education.school, 30) : "College"}
                /> */}
                <p className='text-xs line-clamp-1' title={person.school}>
                    {person.school || 'N/A'}
                </p>
            </div>
        </li>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StudentSearchListItem));
