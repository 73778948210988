import {convertFromHTML, ContentState, CompositeDecorator, EditorState} from "draft-js";
import { stateToHTML } from 'draft-js-export-html';
import React from "react";
import { sanitizeHtml } from "./strings";
/**
 * Converts HTML string to ContentState
 * @param {string} html - The HTML string to be converted
 * @returns {ContentState} The resulting ContentState object
 */
export const convertFromHtml = (html) => {
    const blocksFromHTML = convertFromHTML(html ?? '');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    return state;
}
/**
 * @param {ContentState} state - The ContentState object to be converted
 * @returns {string} The resulting HTML string
 */
export const convertToHtml = (state) => {
    const html = sanitizeHtml(stateToHTML(state));
    return html;
}

export const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
};


const Link = (props) => {
    const {url} = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} style={{color: 'blue', textDecoration: 'underline'}} target="_blank"
           rel="noopener noreferrer">
            {props.children}
        </a>
    );
};

export const RichTextDecorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: Link, // Renders the link with specific styles
    },
]);

export const DefaultEditorState = EditorState.createEmpty(RichTextDecorator)
