import React from "react";
import { truncateString } from "../../utils/strings";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import ProfilePicture from "../../components-recruiter/atoms/ProfilePicture";

const StudentPeekHeader = ({
    className,
    profilePicUrl,
    userId,
    firstName,
    lastName,
    linkedinUrl,
    school,
    majors = [],
}) => {
    return (
        <div>
            <div
                className={`flex flex-row flex-wrap gap-y-4 items-center justify-between ${className}`}
            >
                <div className="flex flex-row gap-4 items-center text-neutral-400 body2">
                    <ProfilePicture
                        image={profilePicUrl}
                        userID={userId}
                        size={92}
                        className={"border border-neutral-200"}
                    />
                    <div className="flex flex-col gap-1 flex-1">
                        <div className="flex flex-row items-center gap-2">
                            <p className="text-2xl font-semibold m-0 blacks-1">
                                {`${firstName} ${lastName}`}
                            </p>
                            <div className="flex flex-row items-center gap-1">
                                {linkedinUrl && (
                                    <a
                                        href={linkedinUrl}
                                        target={"_blank"}
                                        rel="noreferrer"
                                    >
                                        <SvgLinkedIn
                                            className={
                                                "base-black-30 h-base-black-70 pointer w-4 h-4"
                                            }
                                        />
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col text-sm font-semibold">
                            {!!school && (
                                <p className="">{truncateString(school, 60)}</p>
                            )}
                            {majors.length > 0 && (
                                <div className="">
                                    Major in {majors.join(", ")}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentPeekHeader;
