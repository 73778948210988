import axios from "../axios";
import qs from "qs";

export const getUser = async (user, queried_user_id) => {
    if (!user) {
        return;
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                mode: "no-cors",
                "Access-Control-Allow-Origin": "*",
            },
            params: { queried_user_id },
        };
        const res = await axios.get("/recruiter/users", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
};

export const searchUsers = async (
    user,
    name,
    page = 1,
    clubIds = [],
    classYears = [],
    majors = [],
    colleges = [],
    gpa = null,
    gender = null,
    races = [],
    veteran = null,
    lgbtq = null,
    disabled = null,
    authorized = null,
    industries = [],
    sortBy = null,
    currentCompanies = [],
    previousCompanies = [],
    isIb = null,
    searchSortState = null,
    schoolSortState = null,
    verticals = []
) => {
    if (!user) {
        return;
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                mode: "no-cors",
                "Access-Control-Allow-Origin": "*",
            },
            params: {
                name,
                page,
                club_ids: clubIds,
                class_years: classYears,
                majors,
                colleges: colleges,
                gpa,
                gender,
                races: races,
                veteran,
                lgbtq,
                disabled,
                authorized,
                industries,
                current_companies: currentCompanies,
                previous_companies: previousCompanies,
                is_ib: isIb,
                search_sort_state: searchSortState,
                school_sort_state: schoolSortState,
                roles: verticals,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        };
        const res = await axios.get("/recruiter/users/search", config);
   
        const users = res.data?.results?.map((user) => user.document) ?? [];
        return {
            students: users,
            total: res.data?.num_items ?? 0,
        };
    } catch (err) {
        console.log(err);
    }
};
