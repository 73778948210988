import React, {PureComponent} from "react";
import SelectComponent from "../atoms/SelectComponent";
import MultiSelectComponent from "../atoms/MultiSelectComponent";
import WrappedTextInput from "../atoms/WrappedTextInput";
import InputWrapper from "../atoms/inputs/InputWrapper";

class DiversityQuestions extends PureComponent {

    render() {
        const errors = this.props.errors || {};
        console.log(errors);
        return (
            <>
                <div className="col-plain w-full">
                    <div className='flex flex-col gap-3'>
                        <div className='flex-1 mb-2'>
                            <InputWrapper
                                label="Secondary email"
                                type="text"
                                name="secondaryEmail"
                                placeholder={'ie. john.smith@gmail.com'}
                                value={this.props.secondaryEmail}
                                onChange={this.props.handleEmailChange}
                                error={errors.secondary_email}
                            />
                        </div>
                        <div className='flex flex-row gap-3'>
                            <div className="flex flex-1">
                                <SelectComponent
                                    hasCollapse={this.props.hasCollapse}
                                    className={errors["diversity.gender"] ? 'border-red-400' : ''}
                                    outerLabel={'Gender'}
                                    label={'...'}
                                    value={this.props.gender}
                                    light
                                    setValue={this.props.handleSelectChange}
                                    clearFilter={() => this.props.handleSelectChange('gender', null)}
                                    stateName={'gender'}
                                    options={[{title: "Male",}, {title: "Female",}, {title: "Other",}, {title: "Prefer Not to Specify",}]}
                                    scrollable
                                    node={document.getElementById('personalization-modal')}
                                />
                            </div>
                            <div className='flex flex-1'>
                                <MultiSelectComponent
                                    className={errors["diversity.race"] ? 'border-red-400' : ''}
                                    outerLabel={'Race'}
                                    label={'...'}
                                    value={this.props.race}
                                    light
                                    scrollable
                                    setValue={(e) => this.props.handleMultiSelectChange('race', e)}
                                    clearFilter={() => this.props.handleMultiSelectChange('race', null)}
                                    node={document.getElementById('personalization-modal')}
                                    options={[{title: "White",}, {title: "Hispanic or Latino",}, {title: "Black or African American",}, {title: "American Indian or Native American",},  {title: "Asian or Pacific Islander",}, {title: "Other",}, {title: "Prefer Not to Specify",}]}
                                    hasCollapse={this.props.hasCollapse}
                                />
                            </div>
                        </div>

                        <div className='flex flex-row items-center gap-3 mt-16'>
                            <div className="col-plain flex-1">
                                <SelectComponent
                                    outerLabel={'Do you have a disability as defined under the Disability Discrimination Act?'}
                                    className={`w-full ${errors["diversity.disabled"] ? 'border-red-400' : ''}`}
                                    label={'...'}
                                    value={this.props.disabled}
                                    light
                                    setValue={this.props.handleSelectChange}
                                    clearFilter={() => this.props.handleSelectChange('disabled', null)}
                                    stateName={'disabled'}
                                    options={[{title: "Yes",}, {title: "No"}, {title: "Prefer Not to Specify"}]}
                                    scrollable
                                    node={document.getElementById('personalization-modal')}
                                    hasCollapse={this.props.hasCollapse}
                                />
                            </div>
                            <div className="col-plain flex-1">
                                <SelectComponent
                                    outerLabel={'Are you a member of the U.S. military or are you a veteran?'}
                                    className={`w-full ${errors["diversity.veteran"] ? 'border-red-400' : ''}`}
                                    label={'...'}
                                    value={this.props.veteran}
                                    light
                                    setValue={this.props.handleSelectChange}
                                    clearFilter={() => this.props.handleSelectChange('veteran', null)}
                                    stateName={'veteran'}
                                    options={[{title: "Yes",}, {title: "No"}, {title: "Prefer Not to Specify"}]}
                                    scrollable
                                    node={document.getElementById('personalization-modal')}
                                    hasCollapse={this.props.hasCollapse}
                                />
                            </div>
                        </div>

                        <div className='flex flex-row items-center gap-3 mt-16'>
                            <div className="col-plain flex-1">
                                <SelectComponent
                                    outerLabel={'Are you a first generation college student?'}
                                    className={`w-full ${errors["diversity.first_generation"] ? 'border-red-400' : ''}`}
                                    label={'...'}
                                    value={this.props.firstGeneration}
                                    light
                                    setValue={this.props.handleSelectChange}
                                    clearFilter={() => this.props.handleSelectChange('firstGeneration', null)}
                                    stateName={'firstGeneration'}
                                    options={[{title: "Yes",}, {title: "No"}, {title: "Prefer Not to Specify"}]}
                                    scrollable
                                    node={document.getElementById('personalization-modal')}
                                    hasCollapse={this.props.hasCollapse}
                                />
                            </div>
                            <div className="col-plain flex-1">
                                <SelectComponent
                                    outerLabel={'Do you identify yourself as a member of the LGBTQIA+ community?'}
                                    className={`w-full ${errors["diversity.lgbtq"] ? 'border-red-400' : ''}`}
                                    label={'...'}
                                    value={this.props.lgbtq}
                                    light
                                    setValue={this.props.handleSelectChange}
                                    clearFilter={() => this.props.handleSelectChange('lgbtq', null)}
                                    stateName={'lgbtq'}
                                    options={[{title: "Yes",}, {title: "No"}, {title: "Prefer Not to Specify"}]}
                                    scrollable
                                    node={document.getElementById('personalization-modal')}
                                    hasCollapse={this.props.hasCollapse}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default DiversityQuestions;
