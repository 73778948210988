import { APP_ADMINS, RECRUIT_USERS } from "./constants";

export const NAV_ITEMS = [
    {
        // icon: SvgHome,
        label: 'Home',
        route: '/'
    },
    // {
    //     // icon: SvgBriefcase,
    //     label: 'Saved',
    //     route: '/saved'
    // },
    {
        // icon: SvgPeopleFeather,
        label: 'Networks',
        route: '/network'
    },
    {
        // icon: SvgBriefcase,
        label: 'Jobs',
        route: '/jobs'
    },
    {
        // icon: SvgCalendar,
        label: 'Events',
        route: '/events'
    },
    {
        // icon: SvgDomain,
        label: 'Companies',
        route: '/companies'
    },
    {
        // icon: SvgZap,
        label: 'Learn',
        route: '/learn'
    },
    {
        // icon: SvgZap,
        label: 'Profile',
        route: '/profile'
    },
]

export const RECRUITER_NAV_ITEMS = [
    {
        // icon: SvgLayout,
        label: 'Home',
        route: '/'
    },
    {
        // icon: SvgSearchFeather,
        label: 'Search',
        route: '/search'
    },
    {
        // icon: SvgSavedClubs,
        label: 'Lists',
        route: '/saved-lists'
    },
    {
        // icon: SvgBriefcase,
        label: 'Engage',
        route: '/engage'
    },
    // {
    //     icon: SvgCalendar,
    //     label: 'Events',
    //     route: '/events'
    // },
    // {
    //     icon: SvgUserCheck,
    //     label: 'Enrich',
    //     route: '/enrich'
    // },

    // {
    //     icon: SvgMessage,
    //     label: 'Messages',
    //     route: '/messages'
    // },
    // {
    //     icon: SvgUsers,
    //     label: 'Saved Candidates',
    //     route: '/saved-candidates'
    // },
    // {
    //     label: 'divider'
    // },
    {
        // icon: SvgAt,
        label: 'Company Page',
        route: '/company-page'
    },
    {
        // icon: SvgSettings,
        label: 'Settings',
        route: '/settings'
    },
    {
        label: 'Admin',
        route: '/admin',
        canAccess: (userId) => APP_ADMINS.includes(userId) || RECRUIT_USERS.includes(userId)
    },
    {
        // icon: SvgLogout,
        label: 'Sign Out',
        logout: true
    }
    // {
    //     label: 'Tracker',
    //     route: '/'
    // },
    // {
    //     label: 'Club Search',
    //     route: '/clubsearch'
    // },
    // {
    //     label: 'Student Search',
    //     route: '/studentsearch'
    // },
]


export const CLUB_ADMIN_NAV_ITEMS = [
    {
        label: 'Members',
        route: '/clubadmin'
    },
    {
        label: 'Alumni',
        route: '/clubadmin/alumni'
    },
    {
        label: 'Engage',
        route: '/clubadmin/engage'
    },
    {
        label: 'Club Page',
        route: '/clubadmin/club-page'
    },
    {
        label: 'Settings',
        route: '/clubadmin/settings'
    },
    {
        label: 'Sign Out',
        logout: true
    }
]



export const CLUB_MEMBERS = [
    {
        name: 'Tom Allen',
        title: 'President',
        class: '24',
        seekingOpportunity: true,
        resume: true,
        linkedin: 'example linkedin link',
        major: ['Finance', 'Economics'],
        minor: ['Strategy'],
    },
    {
        name: 'Sarah Johnson',
        title: 'Vice President',
        class: '23',
        seekingOpportunity: false,
        resume: false,
        linkedin: 'example linkedin link',
        major: ['Accounting'],
        minor: [],
    },
    {
        name: 'John Smith',
        title: 'Treasurer',
        class: '23',
        seekingOpportunity: true,
        resume: true,
        linkedin: 'example linkedin link',
        major: ['Finance'],
        minor: ['Mathematics'],
    },
    {
        name: 'Emily Davis',
        title: 'Secretary',
        class: '22',
        seekingOpportunity: false,
        resume: true,
        linkedin: 'example linkedin link',
        major: ['Economics'],
        minor: [],
    },
    {
        name: 'Michael Anderson',
        class: '23',
        seekingOpportunity: true,
        resume: false,
        linkedin: 'example linkedin link',
        major: ['Finance'],
        minor: [],
    },
    {
        name: 'Jessica Lee',
        class: '23',
        seekingOpportunity: true,
        resume: true,
        linkedin: 'example linkedin link',
        major: ['Marketing'],
        minor: ['Finance'],
    },
    {
        name: 'Alex Brown',
        class: '24',
        seekingOpportunity: false,
        resume: true,
        linkedin: 'example linkedin link',
        major: ['Communication'],
        minor: ['Business'],
    },
    {
        name: 'Ryan Chen',
        class: '24',
        seekingOpportunity: true,
        resume: true,
        linkedin: 'example linkedin link',
        major: ['Finance'],
        minor: ['Economics'],
    },
    {
        name: 'Sophia Rodriguez',
        class: '22',
        seekingOpportunity: true,
        resume: false,
        linkedin: 'example linkedin link',
        major: ['Economics'],
        minor: [],
    },
];

export const CLUB_ALUMNI = [
    {
        name: 'Sophia Rodriguez',
        class: '22',
        linkedin: true,
        company: 'Morgan Stanley',
        title: 'Healthcare Analyst',
        tracking: true,
        notes: [
            {
                id: 1,
                content: 'Met with her Tuesday, had a great talk.',
            },
            {
                id: 2,
                content: "She's a big fan of the Falcons!",
            },
        ],
    },
    {
        name: 'Michael Thompson',
        class: '20',
        linkedin: true,
        company: 'Goldman Sachs',
        title: 'Investment Banker',
        notes: [
            {
                id: 1,
                content: 'Attended a conference with him last month.',
            },
            {
                id: 2,
                content: 'Has a keen interest in emerging markets.',
            },
        ],
    },
    {
        name: 'Emily Johnson',
        class: '23',
        linkedin: true,
        company: 'J.P. Morgan',
        title: 'Financial Analyst',
        tracking: true,
        notes: [
            {
                id: 1,
                content: 'Collaborated on a project together.',
            },
            {
                id: 2,
                content: 'Passionate about sustainable investing.',
            },
        ],
    },
    {
        name: 'Daniel Lee',
        class: '21',
        linkedin: true,
        company: 'Bank of America',
        title: 'Risk Management Associate',
        notes: [
            {
                id: 1,
                content: 'Worked with him on a client pitch.',
            },
            {
                id: 2,
                content: 'Incredibly detail-oriented and analytical.',
            },
        ],
    },
    {
        name: 'Olivia Smith',
        class: '24',
        linkedin: true,
        company: 'Citigroup',
        title: 'Investment Analyst',
        notes: [
            {
                id: 1,
                content: 'Attended a networking event where we connected.',
            },
            {
                id: 2,
                content: 'Passionate about impact investing.',
            },
        ],
    },
    {
        name: 'Andrew Wilson',
        class: '19',
        linkedin: true,
        company: 'Wells Fargo',
        title: 'Private Banker',
        notes: [
            {
                id: 1,
                content: 'Collaborated on a clients wealth management strategy.',
            },
            {
                id: 2,
                content: 'Has extensive knowledge of estate planning.',
            },
        ],
    },
    {
        name: 'Sophie Martin',
        class: '22',
        linkedin: true,
        company: 'HSBC',
        title: 'Investment Banking Analyst',
        notes: [
            {
                id: 1,
                content: 'Met at a finance conference, great conversation.',
            },
            {
                id: 2,
                content: 'Has experience in cross-border M&A deals.',
            },
        ],
    },
    {
        name: 'Benjamin Brown',
        class: '23',
        linkedin: true,
        company: 'UBS',
        title: 'Equity Research Associate',
        notes: [
            {
                id: 1,
                content: 'Worked on a research project with him.',
            },
            {
                id: 2,
                content: 'Specializes in the technology sector.',
            },
        ],
    },
    {
        name: 'Ava Davis',
        class: '21',
        linkedin: true,
        company: 'Barclays',
        title: 'Portfolio Manager',
        notes: [
            {
                id: 1,
                content: 'Met during a client meeting, impressed by her insights.',
            },
            {
                id: 2,
                content: 'Focuses on alternative investments.',
            },
        ],
    },
    {
        name: 'William Taylor',
        class: '24',
        linkedin: true,
        company: 'Credit Suisse',
        title: 'Mergers and Acquisitions Analyst',
        notes: [
            {
                id: 1,
                content: 'Connected through a mutual contact, shared industry updates.',
            },
            {
                id: 2,
                content: 'Interested in cross-border dealmaking.',
            },
        ],
    },
    {
        name: 'Grace Anderson',
        class: '20',
        linkedin: true,
        company: 'Deutsche Bank',
        title: 'Financial Planner',
        notes: [
            {
                id: 1,
                content: 'Collaborated on a retirement planning project.',
            },
            {
                id: 2,
                content: 'Helps clients with comprehensive financial strategies.',
            },
        ],
    },
    {
        name: 'Jacob Martinez',
        class: '23',
        linkedin: true,
        company: 'Standard Chartered',
        title: 'Investment Banking Associate',
        notes: [
            {
                id: 1,
                content: 'Met at a corporate finance workshop, shared industry insights.',
            },
            {
                id: 2,
                content: 'Has experience in emerging markets.',
            },
        ],
    },
    {
        name: 'Victoria Turner',
        class: '22',
        linkedin: true,
        company: 'Nomura',
        title: 'Equity Trader',
        notes: [
            {
                id: 1,
                content: 'Connected through a professional networking event.',
            },
            {
                id: 2,
                content: 'Skilled in executing trades in fast-paced environments.',
            },
        ],
    },
    {
        name: 'Christopher Clark',
        class: '19',
        linkedin: true,
        company: 'RBC Capital Markets',
        title: 'Investment Analyst',
        notes: [
            {
                id: 1,
                content: 'Worked together on an investment research project.',
            },
            {
                id: 2,
                content: 'Focused on the energy sector.',
            },
        ],
    },
    {
        name: 'Emma Walker',
        class: '24',
        linkedin: true,
        company: 'ANZ',
        title: 'Financial Advisor',
        notes: [
            {
                id: 1,
                content: 'Met at a wealth management conference.',
            },
            {
                id: 2,
                content: 'Provides personalized financial guidance.',
            },
        ],
    },
    {
        name: 'Ryan Hill',
        class: '21',
        linkedin: true,
        company: 'TD Bank',
        title: 'Commercial Lending Officer',
        notes: [
            {
                id: 1,
                content: 'Collaborated on a business loan application.',
            },
            {
                id: 2,
                content: 'Knowledgeable about small business financing.',
            },
        ],
    },
]

export const NOTES = [
    {
        note: "Testing a message about this job if i want to jot down a quick note",
        timestamp: '5d ago'
    },
    {
        note: "Testing a message",
        timestamp: '5d ago'
    },
]

export const APPLICATION_STEPS = [
    {
        title: 'To apply',
        date: null,
        desc: '',
        complete: false
    },
    {
        title: 'Applied',
        date: null,
        desc: '',
        complete: false
    },
    {
        title: 'Interviewing',
        date: null,
        desc: '',
        complete: false
    },
    {
        title: 'Offered',
        date: '',
        desc: '',
        complete: false
    },
    // {
    //     title: 'Decided',
    //     date: '',
    //     desc: '',
    //     complete: false
    // },
]



export const NETWORK_TABLE_HEADERS = [
    {
        title: "Name",
        flex: .70
    },
    {
        title: "Company",
        flex: .75
    },
    {
        title: "Title",
        flex: .75
    },
    // {
    //     title: "Source",
    //     flex: .5
    // },
    {
        title: "Notes",
        flex: .5
    },
    {
        title: "Status",
        flex: .5
    },
]

export const NETWORK_TABLE_HEADERS_NO_COMPANY = [
    {
        title: "Name",
        flex: 0.7
    },
    {
        title: "Title",
        flex: .75
    },
    // {
    //     title: "Favorited",
    //     flex: .2
    // },
    // {
    //     title: "Action Needed",
    //     flex: .2
    // },
    {
        title: "Status",
        flex: .5
    },
]

export const CRM_TABLE_HEADERS = [
    {
        title: "Job",
        flex: 2
    },
    {
        title: "Deadline",
        flex: .3
    },
    // {
    //     title: "Source",
    //     flex: .3
    // },
    {
        title: "Notes",
        flex: .3
    },
    {
        title: "Status",
        flex: .25
    },
]

export const EVENTS_TRACKER_TABLE_HEADERS = [
    {
        title: "Event",
        flex: 2
    },
    {
        title: "Class",
        flex: .5
    },
    {
        title: "Date",
        flex: .5
    },
    {
        title: "Company",
        flex: .75
    },
    // {
    //     title: "Source",
    //     flex: .5
    // },
    {
        title: "Notes",
        flex: .5
    },
    {
        title: "Status",
        flex: .75
    },
]

export const CLUB_ADMIN_MEMBERS_TABLE_HEADERS = [
    {
        title: "Name",
        flex: .3,
        sm:true
    },
    {
        title: "Email",
        flex: .25,
        sm:true
    },
    {
        title: "Role",
        flex: .15,
        sm:true
    },
    {
        title: "Year",
        flex: .15,
        sm:true
    },
    {
        title: "Major",
        flex: .3,
        sm:false,
    },
    {
        title: "GPA",
        flex: .1,
        sm:false
    },
    {
        title: "Status",
        flex: .15,
        sm:false
    },
    {
        title: "",
        flex: .15,
        sm:true
    },
]

export const COMPANY_TRACKER_TABLE_HEADERS = [
    {
        title: "Company",
        flex: 1.25
    },
    {
        title: "Industry",
        flex: 1
    },
    {
        title: "Employees",
        flex: 1
    },
    {
        title: "Notes",
        flex: .5
    },
    {
        title: "Status",
        flex: .5
    },
]

export const SIDEBAR_CARD = 'border border-neutral-200 rounded-xl p-3'

export const APPLICANTS_TABLE_HEADERS = [
    {
        label: 'Name',
        flex: 1,
    },
    {
        label: 'Position',
        flex: 1,
    },
    {
        label: 'School',
        flex: .4,
    },
    {
        label: 'Club',
        flex: .4,
    },
    {
        label: 'Major',
        flex: .75,
    },
    {
        label: 'GPA',
        flex: .5,
    },
    {
        label: 'Grad',
        flex: .5,
    },
]

export const APPLICANTS_TABLE_HEADERS_FULLPAGE = [
    {
        label: 'Name',
        flex: 1,
        key: 'name',
        sortable: true
    },
    {
        label: 'Applied On',
        flex: .5,
        key: 'applied_at',
        sortable: true
    },
    {
        label: 'School',
        flex: .5,
    },
    {
        label: 'Club',
        flex: .5,
    },
    {
        label: 'Major',
        flex: 1,
    },
    {
        label: 'GPA',
        flex: .5,
    },
    {
        label: 'Grad',
        flex: .5,
    },
]

export const ATTENDEES_TABLE_HEADERS_FULLPAGE = [
    {
        label: 'Name',
        flex: 1,
        key: 'name',
        sortable: true
    },
    {
        label: 'RSVP',
        flex: .5,
        key: 'rsvp_at',
        sortable: true
    },
    {
        label: 'School',
        flex: .5,
    },
    {
        label: 'Club',
        flex: .5,
    },
    {
        label: 'Major',
        flex: 1,
    },
    {
        label: 'GPA',
        flex: .5,
    },
    {
        label: 'Grad',
        flex: .5,
    },
]

export const CLUB_ADMIN_APPLICANTS_TABLE_HEADERS = [
    {
        label: 'Name',
        flex: 1.2,
    },
    {
        label: 'Major',
        flex: 1,
    },
    {
        label: 'GPA',
        flex: 1,
    },
    {
        label: 'Grad',
        flex: 1,
    },
    {
        label: '',
        flex: 1,
    },
]

export const FAVORITES_TABLE_HEADERS = [
    {
        label: 'Name',
        flex: 1,
    },
    {
        label: 'School',
        flex: .25,
    },
    {
        label: 'Club',
        flex: .25,
    },
    {
        label: 'Major',
        flex: .5,
    },
    {
        label: 'GPA',
        flex: .3,
    },
    {
        label: 'Grad',
        flex: .25,
    },
]

export const SAVED_LISTS_TABLE_HEADERS = [
    {
        label: 'Name',
        flex: 1,
    },
    {
        label: 'School',
        flex: .25,
    },
    {
        label: 'Club',
        flex: .25,
    },
    {
        label: 'Major',
        flex: .5,
    },
    {
        label: 'GPA',
        flex: .3,
    },
    {
        label: 'Grad',
        flex: .25,
    },
    {
        label: '',
        flex: 0,
    },
]

export const EVENTS_TABLE_HEADERS = [
    {
        label: 'Name',
        flex: 1,
    },
    {
        label: 'Event',
        flex: 1,
    },
    {
        label: 'School',
        flex: .4,
    },
    {
        label: 'Club',
        flex: .4,
    },
    {
        label: 'Major',
        flex: .75,
    },
    {
        label: 'GPA',
        flex: .5,
    },
    {
        label: 'Grad',
        flex: .5,
    },
]

export const SEARCH_STUDENT_TABLE_HEADERS = [
    {
        label: 'Name',
        flex: 1,
    },
    {
        label: 'School',
        flex: .5,
    },
    {
        label: 'Club',
        flex: .5,
    },
    {
        label: 'Major',
        flex: 1,
    },
    {
        label: 'GPA',
        flex: .5,
    },
    {
        label: 'Grad',
        flex: .25,
    },
]

export const SEARCH_CLUBS_TABLE_HEADERS = [
    {
        label: '',
        flex: 1,
    },
    {
        label: '',
        flex: 1,
    },
    {
        label: '',
        flex: 1,
    },
    {
        label: '',
        flex: 1,
    },
    {
        label: '',
        flex: 1,
    },
    {
        label: '',
        flex: 1,
    },
]

export const SEARCH_INDUSTRY_PROFESSIONALS_TABLE_HEADERS = [
    {
        label: 'Name',
        flex: .7,
    },
    {
        label: 'Current Company',
        flex: .5,
    },
    {
        label: 'Title',
        flex: .5,
    },
    {
        label: 'City',
        flex: .5,
    },
    {
        label: 'School',
        flex: .5,
    }
]


export const MAJOR_OPTIONS = [
    {title: "Finance",}, {title: "Economics",}, {title: "Math",}, {title: "Engineering",}, {title: "Business",}, {title: "Computer Science",}, {title: "History",}, {title: "English",}, {title: "Other",}
]

export const INDUSTRY_OPTIONS = [
    {title: "Investment Banking"},
    {title: "Sales & Trading"},
    {title: "Equity Research"},
    {title: "Private Equity / Venture Capital"},
    {title: "Asset Management"},
    {title: "Management Consulting"},
    {title: "Wealth Management"},
    {title: "Quant"},
    {title: "Other"}
]

export const APPLICATION_STATUS_OPTIONS = [
    {title: "Applied"},
    {title: "Interviewing"},
]

export const EVENT_STATUS_OPTIONS = [
    {title: "RSVPd"},
    {title: "ATTENDED"},
]

export const workEnvironmentOptions = [
    {value: "On-site", label: "On-site"},
    // {value: "Hybrid", label: "Hybrid"},
    {value: "Remote", label: "Remote"},
];

export const qualifiedOptions = [
    {value: "qualified", label: "Share only with qualified matches"},
    {value: "all", label: "Share with everyone on RecruitU"},
];


export const JOB_TYPE_OPTIONS = [
    {title: "Internship", value: "Internship"},
    {title: "Full Time", value: "Full Time"},
    {title: "Early Insight Program", value: "Early Insight Program"}
]

export const CLASS_YEARS = [
    {title: "2025", value: '2025'},
    {title: "2026", value: '2026'},
    {title: "2027", value: '2027'},
    {title: "2028", value: '2028'}];

export const PRIMARY_TAGS = [
        {title: "Investment Banking", value: "Investment Banking"},
        {title: "Buyside", value: "Buyside"},
        {title: "Asset & Wealth Management", value: "Asset & Wealth Management"},
        {title: "Consulting", value: "Consulting"},
        {title: "Research", value: "Research"},
        {title: "Risk Management", value: "Risk Management"},
        {title: "Commercial Banking", value: "Commercial Banking"}
    ]

export const SECONDARY_TAGS = [
        {title: "M&A", value: "M&A"},
        {title: "Restructuring", value: "Restructuring"},
        {title: "Capital Markets", value: "Capital Markets"},
        {title: "Sales & Trading", value: "Sales & Trading"},
        {title: "Private Equity", value: "Private Equity"},
        {title: "Hedge Fund", value: "Hedge Fund"},
        {title: "Venture Capital", value: "Venture Capital"},
    ]


export const question_categories = [
    {title: "Private Equity / Buyside Recruiting",},
    {title: "Leveraged Buyouts (LBOs)"},
    {title: "Merger Model (M&A)"},
    {title: "DCF"},
    {title: "Accounting"},
    {title: 'Restructuring (Rx) / Distressed M&A'},
    {title: 'Valuation'},
    {title: 'Enterprise & Equity Value'},
    {title: 'Brain Teasers / Misc.'}
]
