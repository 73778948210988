import axios from '../axios';

export const getUser = async (user, queried_user_id = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id}
        };
        const res = await axios.get("/users", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const editUser = async (user, params_to_edit) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            updated_params: params_to_edit
        }
        const res = await axios.put("/users", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const sendBumpEmail = async (user, club_id, user_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            club_id, user_id
        }
        const res = await axios.put("/users/club", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createNewClubMember = async (user, club_id, email, first_name, last_name) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/users/club", {club_id, email, first_name, last_name}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const addCompany = async (user, company_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/users/company", {company_id}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const uploadRoster = async (user, club_id, roster_data, email_users) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/users/roster", {club_id, roster_data, email_users}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const addLinkedinUrl = async (user, linkedin_url) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    const config = {
        headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        params: {linkedin_url}
    };
    const res = await axios.get("/linkedin/add", config);
    return res.data;
}



export const getUserEvents = async (user, queried_user_id = null) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {queried_user_id}
        };
        const res = await axios.get("/users/events", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}


export const requestClubs = async (user, clubs) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/users/request-clubs", {clubs}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
