import React, {Component} from "react";


class RecruiterPaywall extends Component {
    render() {
        return (
            <div
                className='absolute h-screen top-0 left-0 right-0 bottom-0 bg-slate-400/20 backdrop-blur-sm flex flex-col items-center justify-center'>
                <div className='card w-[360px]'>
                    <img alt={'invite'}
                         src={require('../../img/illustrations/techny-secure-lock-and-key.gif')}
                         className='w-1/2 mx-auto scale-125'/>
                    <p className='text-xl text-slate-800 font-semibold mb-2'>
                        {this.props.title || ' Student and Club Search'}
                    </p>
                    <p className='text-md text-slate-500'>
                        Contact us at <a className='text-primary font-semibold'
                                         href={'mailto:hsupport@recruitu.com'} target={'_blank'}
                                         rel={'noreferrer'}>support@recruitu.com</a> to learn how to get access to
                        our entire database of
                        exclusive finance talent.
                    </p>

                    <a
                        href={'mailto:support@recruitu.com'} target={'_blank'}
                        rel={'noreferrer'} className='primary-button body1-bold mx-auto mt-5'>
                        Get in touch
                    </a>
                </div>
            </div>
        )
    }
}

export default RecruiterPaywall
