import React from "react";
import Checkbox from "../components/atoms/Checkbox";
import classNames from "classnames";


const AdminDashboardTable = ({ columns, TableBody, hasCheckbox, toggleCheckbox, checked }) => {
    return (
        <div className="bg-white shadow-lg shadow-slate-200 flex flex-col" 
             style={{ height: 'calc(100vh - 223px)' }}>
            <div className="overflow-auto flex-1">
                <div className="min-w-max">
                    <div className="sticky top-0 z-50 bg-slate-50">
                        <div className="p-5 py-3 grid gap-4"
                             style={{ 
                                 gridTemplateColumns: hasCheckbox 
                                     ? `minmax(40px, 60px) ${columns.map(col => col.width).join(' ')}` 
                                     : columns.map(col => col.width).join(' '),
                                 minWidth: "fit-content"
                             }}>
                            {hasCheckbox && (
                                <div className="flex items-center">
                                    <Checkbox
                                        checked={checked}
                                        onClick={toggleCheckbox}
                                        className={"flex flex-1"}
                                    />
                                </div>
                            )}
                            {columns.map((item) => (
                                <div
                                    key={item.label}
                                    className={classNames(
                                        "overflow-hidden text-ellipsis",
                                        item.isCentered && "text-center"
                                    )}
                                >
                                    <p className="text-sm text-slate-500 font-semibold" title={item.label}>
                                        {item.label}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    {TableBody}
                </div>
            </div>
        </div>
    );
};

export default AdminDashboardTable;
