import React, { useState } from "react";
import EditProfileCtaButton from "./EditProfileCtaButton";
import EditProfileWrapper from "./EditProfileWrapper";
import useUser from "../../hooks/useUser";
import Checkbox from "../atoms/Checkbox";
import { editUser, getUser } from "../../api/student/users";
import { toast } from "react-toastify";
import SvgRestart from "../icons/SvgRestart";
import AlertModal from "../atoms/modals/AlertModal";
import { ACTIVELY_LOOKING_OPTIONS } from "../onboarding/onboarding-constants";

export default function EditProfileDetails({
    loading,
    handleResync,
    goToNextSection,
    hasNextSection,
    setLoading,
}) {
    const { user, setUser, authUser } = useUser();
    const [jobStatus, setJobStatus] = useState(
        () => user.job_status || ACTIVELY_LOOKING_OPTIONS[2].label
    );

    const updateProfile = async () => {
        try {
            setLoading(true);
            await editUser(authUser, {
                job_status: jobStatus,
                seeking_role: jobStatus !== "Not looking right now",
            });
            const { user: updatedUser } = await getUser(authUser);
            setUser(updatedUser);
            if (hasNextSection) {
                goToNextSection();
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const handleSave = () => {
        toast.promise(updateProfile, {
            pending: "Saving...",
            success: "Saved!",
            error: "Error saving",
        });
    };

    return (
        <>
            <EditProfileWrapper>
                <div className="space-y-4">
                    <div className="col-plain w-full pb-3">
                        <p className="body3-bold text-neutral-400 mb-1">Name</p>
                        <input
                            value={user.first_name + " " + user.last_name}
                            disabled={true}
                            placeholder={"Add your name"}
                            className="input-light px-3 body2 base-black-50 w-full"
                        />
                    </div>
                    <div className="col-plain w-full pb-3">
                        <p className="body3-bold text-neutral-400 mb-1">Email</p>
                        <input
                            value={user.email}
                            disabled={true}
                            placeholder={"Add your email"}
                            className="input-light px-3 body2 base-black-50 w-full"
                        />
                    </div>
                    <div className="col-plain w-full pb-3">
                        <p className="body3-bold text-neutral-400 mb-1">
                            Tagline
                        </p>
                        <input
                            value={user.headline}
                            disabled={true}
                            placeholder={"Add your profile tagline"}
                            className="input-light px-3 body2 base-black-50 w-full"
                        />
                    </div>
                    <div className="flex flex-row items-center gap-4">
                        <div className="col-plain w-full pb-3">
                            <p className="body3-bold text-neutral-400 mb-1">
                                City
                            </p>
                            <input
                                value={user.city}
                                disabled={true}
                                placeholder={"City"}
                                className="input-light px-3 body2 base-black-50 w-full"
                            />
                        </div>
                        <div className="col-plain w-full pb-3">
                            <p className="body3-bold text-neutral-400 mb-1">
                                State
                            </p>
                            <input
                                value={user.state}
                                disabled={true}
                                placeholder={"State"}
                                className="input-light px-3 body2 base-black-50 w-full"
                            />
                        </div>
                    </div>
                    <div>
                        <p className="body3-bold text-neutral-400 mb-1">
                            Job Status
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {ACTIVELY_LOOKING_OPTIONS.map((item) => (
                                <div
                                    key={item.label}
                                    onClick={() => setJobStatus(item.label)}
                                    className={`flex flex-row items-center gap-3 p-4 rounded-xl cursor-pointer transition-colors ${
                                        jobStatus === item.label
                                            ? "bg-primary text-white"
                                            : "bg-slate-100 hover:bg-slate-200"
                                    }`}
                                >
                                    <item.Icon className="w-6 h-6" />
                                    <p className="text-sm">{item.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-plain w-full pb-5">
                        <p className="body3-bold text-neutral-400 mb-1">
                            About
                        </p>
                        <textarea
                            value={user.bio}
                            disabled={true}
                            placeholder={"A little more about you..."}
                            className="input-light text-sm px-3 body2 base-black-50 w-full pt-2 bg-neutral-200 min-h-[82px]"
                        />
                    </div>
                    {/*<div className="flex items-center">*/}
                    {/*    <button*/}
                    {/*        className={`px-4 py-1 rounded-lg ${*/}
                    {/*            user.isEmailVerified*/}
                    {/*                ? "bg-green-500 text-white hover:bg-green-600"*/}
                    {/*                : "bg-yellow-500 text-white hover:bg-yellow-600"*/}
                    {/*        } transition`}*/}
                    {/*    >*/}
                    {/*        {user.isEmailVerified ? "Email Verified" : "Verify Email"}*/}
                    {/*    </button>*/}
                    {/*    <span className="ml-4">*/}
                    {/*        {user.isEmailVerified ? (*/}
                    {/*            <CompleteIcon />*/}
                    {/*        ) : (*/}
                    {/*            <IncompleteIcon />*/}
                    {/*        )}*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                </div>
            </EditProfileWrapper>
            <div className="grid grid-cols-4 gap-4">
                {user.linkedin && (
                    <div className="col-span-3">
                        <AlertModal
                            title="Are you sure you want to re-sync your LinkedIn profile? "
                            description={`This will automatically update your Profile Details and Professional Experience from LinkedIn. \nNote: You can only re-sync your LinkedIn once every 12 hours`}
                            onConfirm={handleResync}
                            disabled={loading}
                        >
                            <EditProfileCtaButton>
                                <div className="flex flex-row justify-center">
                                    <SvgRestart className={"mr-2"} />
                                    Resync your LinkedIn profile to update
                                </div>
                            </EditProfileCtaButton>
                        </AlertModal>
                    </div>
                )}
                <div className={user.linkedin ? "col-span-1" : "col-span-4"}>
                    <EditProfileCtaButton
                        onClick={handleSave}
                        disabled={loading}
                    >
                        {hasNextSection ? "Save & Next" : "Save"}
                    </EditProfileCtaButton>
                </div>
            </div>
        </>
    );
}
