import React, { useState, useEffect, useCallback, useMemo } from "react";
import SvgSearch from "../components/icons/SvgSearch";
import { connect } from "react-redux";
import JobBoardLoading from "../components/loading/JobBoardLoading";
import { Button } from "../components/atoms/Button";
import { adminUserSearch } from "../utils/typesense";
import { ADMIN_STUDENT_MANAGEMENT_TABLE_HEADERS } from "../utils/admin";
import AdminStudentManagementRow from "../components-admin/AdminStudentManagementRow";
import AdminDashboardTable from "../components-admin/AdminDashboardTable";
import { useInView } from "react-intersection-observer";
import { useStudentExport } from "../hooks/useStudentExport";
import AdminFiltersSidebar from "./AdminFiltersSidebar";
import { searchClubsById } from "../utils/typesense";

const MAX_PAGE_RESULTS = 20;

const PROFILE_STATUS_OPTIONS = [
    { title: "Account Created" },
    { title: "Onboarded" },
    { title: "Profile Complete" },
    { title: "Archived" }
];

const PROFILE_DETAILS_OPTIONS = [
    { title: "Profile Details" },
    { title: "Professional Experience" },
    { title: "Education Details" },
    { title: "Resume" },
    { title: "LinkedIn" },
    { title: "Location Preferences" },
    { title: "Role Preferences" },
    { title: "Company Preferences" },
    { title: "Equal Employment" },
    { title: "Authorization / Sponsorship" }
];

const mapStateToProps = (state) => ({
    authUser: state.userReducer.authUser,
    tier: state.userReducer.tier,
    constants: state.userReducer.constants,
});

const initialFilters = {
    search: "",
    page: 1,
    college: "",
    classYears: [],
    profileStatus: "",
    profileDetails: [],
    manuallyVerified: false,
    hasJob: false,
    seekingRole: false,
    viewedSuggestedJobs: false,
};

const AdminStudentManagement = ({
    setExportEmailCsvLoading,
    setExportFullCsvLoading,
    authUser,
}) => {
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [moreStudentsLoading, setMoreStudentsLoading] = useState(false);
    const [filters, setFilters] = useState(initialFilters);
    const [numOfPages, setNumOfPages] = useState(1);
    const [numItems, setNumItems] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const { ref: bottomRef, inView } = useInView({
        threshold: 0.1,
    });
    const [clubsById, setClubsById] = useState({});
    

    const studentClubs = useMemo(() => {
        return students.reduce((acc, student) => {
            acc[student.id] = (student.clubs || []).map(id => clubsById[id]).filter(Boolean);
            return acc;
        }, {});
    }, [students, clubsById]);

    const hideMoreStudents = filters.page >= numOfPages;

    const fetchStudents = useCallback(
        async (
            filters,
            page = null,
            count = MAX_PAGE_RESULTS,
            setState = true
        ) => {
            const { search } = filters;

            const pageToFetch = page ?? filters.page;
            const isFirstPage = pageToFetch === 1;

            if (setState) {
                if (isFirstPage) {
                    setLoading(true);
                } else {
                    setMoreStudentsLoading(true);
                }
            }

            const { students, total } =
                (await adminUserSearch(search, pageToFetch, count, {
                    college: filters.college,
                    classYears: filters.classYears,
                    profileStatus: filters.profileStatus,
                    profileDetails: filters.profileDetails,
                    manuallyVerified: filters.manuallyVerified,
                    hasJob: filters.hasJob,
                    seekingRole: filters.seekingRole,
                    viewedSuggestedJobs: filters.viewedSuggestedJobs,
                })) ?? {};

            if (setState) {
                setNumOfPages(total ?? 1);
                setNumItems(total ?? 0);
            }

            if (setState) {
                if (isFirstPage) {
                    setLoading(false);
                    setStudents(students);
                } else {
                    setStudents((prevStudents) => [
                        ...prevStudents,
                        ...students,
                    ]);
                    setMoreStudentsLoading(false);
                }
            }
            return { students, total };
        },
        [authUser]
    );

    const fetchStudentClubs = useCallback(async (students) => {
        
        const clubIdsToFetch = [...new Set(
            students.flatMap(student => 
                (student.clubs || []).filter(id => !clubsById[id])
            )
        )];

        
        
        if (clubIdsToFetch.length > 0) {
            const newClubs = await searchClubsById(clubIdsToFetch);
            const newClubsMap = newClubs.reduce((acc, club) => ({
                ...acc,
                [club.id]: club
            }), {});
            
            setClubsById(() => ({...clubsById, ...newClubsMap}));
        }

    }, [clubsById]);

    useEffect(() => {
        if (filters) {
            fetchStudents(filters).then(({ students }) => {
                fetchStudentClubs(students);
            });
        }
    }, [filters]);

    const getNextStudentInfo = () => {
        setFilters((prev) => ({
            ...prev,
            page: prev.page + 1,
        }));
    };

    useEffect(() => {
        if (inView && !loading && !hideMoreStudents) {
            setShowLoadMore(true);
        } else {
            setShowLoadMore(false);
        }
    }, [inView, loading, hideMoreStudents]);

    useStudentExport({
        fetchStudents,
        filters,
        setExportEmailCsvLoading,
        setExportFullCsvLoading,
    });

    const handleStatusChange = (studentId, deactivated) => {
        setStudents((prevStudents) =>
            prevStudents.map((student) => 
                student.id === studentId 
                    ? { ...student, deactivated }
                    : student
            )
        );
    };

    const handleSchoolChange = (school) => {
        setFilters((prev) => ({ ...prev, college: school }));
    };

    const handleClassYearsChange = (year) => {
        setFilters((prev) => {
            if (!year) {
                return { ...prev, classYears: [] };
            }

            const currentYears = prev.classYears || [];
            const yearExists = currentYears.includes(year);

            if (yearExists) {
                return {
                    ...prev,
                    classYears: currentYears.filter((y) => y !== year),
                };
            } else {
                return { ...prev, classYears: [...currentYears, year] };
            }
        });
    };

    const handleProfileStatusChange = (status) => {
        setFilters(prev => ({ ...prev, profileStatus: status }));
    };

    const handleProfileDetailsChange = (detail) => {
        setFilters((prev) => {
            if (!detail) {
                return { ...prev, profileDetails: [] };
            }

            const currentDetails = prev.profileDetails || [];
            const detailExists = currentDetails.includes(detail);

            if (detailExists) {
                return {
                    ...prev,
                    profileDetails: currentDetails.filter((d) => d !== detail),
                };
            } else {
                return { ...prev, profileDetails: [...currentDetails, detail] };
            }
        });
    };

    const handleCheckboxToggle = (field) => {
        setFilters(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <div className="flex flex-row flex-1 overflow-hidden">
                <div className="w-[250px] px-[12px] flex flex-col border-r border-slate-200">
                    <AdminFiltersSidebar
                        search={{
                            placeholder: "Search by name...",
                            value: filters.search,
                            onChange: (e) =>
                                setFilters((prev) => ({
                                    ...prev,
                                    search: e.target.value,
                                })),
                        }}
                        school={{
                            schoolSearch: filters.college,
                            setSchool: handleSchoolChange,
                        }}
                        classYearSelect={{
                            label: "Class Year",
                            value: filters.classYears,
                            setValue: handleClassYearsChange,
                            options: [
                                { title: "2024" },
                                { title: "2025" },
                                { title: "2026" },
                                { title: "2027" },
                                { title: "2028" },
                                { title: "2029" },
                                { title: "2030" },
                                { title: "2031" },
                                { title: "2032" },
                                { title: "2033" },
                                { title: "2034" },
                            ],
                            multiSelect: true,
                        }}
                        selects={[
                            {
                                label: "Profile Status",
                                value: filters.profileStatus,
                                setValue: handleProfileStatusChange,
                                options: PROFILE_STATUS_OPTIONS,
                                multiSelect: false
                            },
                            {
                                label: "Profile Steps",
                                value: filters.profileDetails,
                                setValue: handleProfileDetailsChange,
                                options: PROFILE_DETAILS_OPTIONS,
                                multiSelect: true
                            }
                        ]}
                        checkboxes={[
                            {
                                label: "Manually Verified",
                                active: filters.manuallyVerified,
                                onClick: () => handleCheckboxToggle('manuallyVerified')
                            },
                            {
                                label: "Has Job",
                                active: filters.hasJob,
                                onClick: () => handleCheckboxToggle('hasJob')
                            },
                            {
                                label: "Seeking Role",
                                active: filters.seekingRole,
                                onClick: () => handleCheckboxToggle('seekingRole')
                            },
                            {
                                label: "Viewed Suggested Jobs",
                                active: filters.viewedSuggestedJobs,
                                onClick: () => handleCheckboxToggle('viewedSuggestedJobs')
                            }
                        ]}
                        clearFilters={() => setFilters(initialFilters)}
                        overrideDemographicsLabel="Profile Steps"
                        checkboxesLabel="Filters"
                    />
                </div>

                <div className="flex flex-col flex-1 overflow-hidden">
                    <AdminDashboardTable
                        columns={ADMIN_STUDENT_MANAGEMENT_TABLE_HEADERS}
                        className="mt-2"
                        TableBody={
                            <div className="flex-1 flex flex-col gap-1 p-5 overflow-y-auto">
                                {loading ? (
                                    <JobBoardLoading page="admin-student-management" />
                                ) : students.length === 0 ? (
                                    <div className="my-8 flex text-neutral-400 flex-col items-center justify-center flex-1">
                                        <SvgSearch />
                                        <p className="body2 mt-2 mb-6">
                                            No professionals found...
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            {students.map((student) => (
                                                <AdminStudentManagementRow
                                                    key={student.id}
                                                    student={student}
                                                    studentClubs={studentClubs[student.id] ?? []}
                                                    columns={
                                                        ADMIN_STUDENT_MANAGEMENT_TABLE_HEADERS
                                                    }
                                                    onStatusChange={handleStatusChange}
                                                />
                                            ))}
                                        </div>
                                        {!hideMoreStudents &&
                                            !loading &&
                                            showLoadMore && (
                                                <div className="fixed bottom-8 left-[calc(50vw+125px)] -translate-x-1/2 z-10">
                                                    <Button
                                                        loading={
                                                            moreStudentsLoading
                                                        }
                                                        variant={"ghost"}
                                                        onClick={
                                                            getNextStudentInfo
                                                        }
                                                        className="bg-white shadow-lg"
                                                    >
                                                        Load more students
                                                    </Button>
                                                </div>
                                            )}
                                        <div ref={bottomRef} className="h-4" />
                                    </>
                                )}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(AdminStudentManagement);
