import React, {Component} from "react";
import {connect} from "react-redux";
import {page_h_padding_class} from "../../utils/constants";
import HorizontalDivider from "../atoms/HorizontalDivider";
import ConfirmationModal from "../modals/ConfirmationModal";
import SvgChevronLeft from "../icons/SvgChevronLeft";
import QuestionPageInfoSection from "../resources/QuestionPageInfoSection";
import QuestionPageResponsesSection from "../resources/QuestionPageResponsesSection";
import RecordingViewModal from "../modals/RecordingViewModal";
import {wait} from "@testing-library/user-event/dist/utils";
import {getQuestion} from "../../api/student/questions";
import VideoPreview from "../questionpage/VideoPreview";
import { ReactMediaRecorder } from "react-media-recorder";
import {editUser} from "../../api/student/users";
import SvgCheck from "../icons/SvgCheck";
import SvgClose from "../icons/SvgClose";
import {Button} from "../atoms/Button";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        answerIds: state.userReducer.answerIds
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}


class QuestionPage extends Component {
    state = {
        question: {},
        answers: [],
        isCameraConfirmationModalOpen: false,
        isRecordingViewModalOpen: false,
        isRecordingSubmittedConfirmationModalVisible: false,
        answerIds: [],
    }

    initiateResponse = () => {
        const user = this.props.user;
        if (!user.accepted_video_privacy) {
            this.openCameraConfirmationModal()
        } else {
            this.openRecordingViewModal();
        }
    }


    openCameraConfirmationModal = () => {
        this.setState({isCameraConfirmationModalOpen: true})
    }

    closeCameraConfirmationModal = () => {
        this.setState({isCameraConfirmationModalOpen: false})
    }

    acceptCamera = () => {
        editUser(this.props.authUser, {accepted_video_privacy: true});

        const newUser = {...this.props.user};
        newUser.accepted_video_privacy = true;
        this.props.setUser(newUser);

        this.setState({isCameraConfirmationModalOpen: false});
        this.openRecordingViewModal()
    }

    openRecordingViewModal = () => {
        this.setState({isRecordingViewModalOpen: true})
    }

    closeRecordingViewModal = () => {
        this.setState({isRecordingViewModalOpen: false})
    }

    submitRecording = () => {
        this.closeRecordingViewModal()
        wait(600).then(() => {
            this.setState({isRecordingSubmittedConfirmationModalVisible: true})
        })
    }

    closeRecordingSubmittedConfirmationModal = () => {
        this.setState({isRecordingSubmittedConfirmationModalVisible: false})
    }

    componentDidMount = () => {
        this.getInfo();
    }

    getInfo = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const questionId = lastArg.split("?")[0];

        const res = await getQuestion(this.props.authUser, questionId);
        this.setState({question: res.question, answers: res.answers})
    }

    navigateToQuestion = (item) => {
        this.props.history.push({
            pathname: `/question/${item.id}`,
            params: {
                item: item,
                interviewSet: this.props.location?.params?.interviewSet,
            }
        })
        this.getInfo()
    }

    nextQuestion = () => {
        const interviewSet = this.props.location?.params?.interviewSet;
        const allQuestionIds = interviewSet.questions.map(question => question.id);
        const currentIndex = allQuestionIds.indexOf(this.state.question.id);
        if (currentIndex < allQuestionIds.length - 1) {
            this.navigateToQuestion(interviewSet.questions[currentIndex + 1])
        }
    }

    previousQuestion = () => {
        const interviewSet = this.props.location?.params?.interviewSet;
        const allQuestionIds = interviewSet.questions.map(question => question.id);
        const currentIndex = allQuestionIds.indexOf(this.state.question.id);
        if (currentIndex > 0) {
            this.navigateToQuestion(interviewSet.questions[currentIndex - 1])
        }
    }

    render() {
        const question = this.state.question || {};
        const interviewSet = this.props.location?.params?.interviewSet;
        return (
            <div className={`flex flex-col pt-5 flex-1 overflow-hidden ${page_h_padding_class}`}>
                <div
                    onClick={() => this.props.history.goBack()}
                    className='ml-[-4px] flex flex-row items-center gap-1 text-slate-500 hover:text-primary cursor-pointer'>
                    <SvgChevronLeft/>
                    <p>
                        Back
                    </p>
                </div>
                {!!interviewSet &&
                    <div
                        className='flex flex-row items-center justify-between border border-slate-200 rounded-xl p-5 mt-5'>
                        <div>
                            <p className='text-sm font-semibold text-slate-500 -mb-1'>
                                Interview Set
                            </p>
                            <p className={'text-xl font-semibold'}>
                                {interviewSet.name}
                            </p>
                        </div>
                        <div className='flex flex-row gap-3 items-center'>
                            <Button size={'sm'} variant={'ghost'} onClick={this.previousQuestion}>
                                Previous
                            </Button>
                            {interviewSet.questions.map(item =>
                                <div
                                    onClick={() => this.navigateToQuestion(item)}
                                    className={`
                                    
                                    w-6 h-6 flex flex-col items-center justify-center rounded-full
                                    ${item.id === question.id ? 'outline outline-offset-2 outline-primary/50 bg-primary' : (item.score ? 'bg-primary' : 'bg-slate-200 hover:bg-slate-300 cursor-pointer')}
                                    `}>
                                    {this.props.answerIds.includes(item.id) ?
                                        <SvgCheck className={'w-5 h-5 text-white'}/>
                                        :
                                        item.name !== question.name ?
                                            <SvgClose className={'w-5 h-5 text-white'}/>
                                            :
                                            <></>
                                    }
                                </div>
                            )}
                            <Button size={'sm'} variant={'ghost'} onClick={this.nextQuestion}>
                                Next
                            </Button>
                        </div>
                    </div>
                }
                <div className='flex flex-row justify-between mt-7 gap-8 flex-1 overflow-hidden'>
                    <div className={'flex flex-col w-full gap-3 pb-5 overflow-y-scroll'}>
                        <QuestionPageInfoSection
                            question={question}
                            initiateResponse={this.initiateResponse}
                            hasResponses={this.state.answers.length > 0}
                            answers={this.state.answers}
                        />
                        <div className='flex flex-col gap-3 pb-5'>
                            <p className='text-xl font-bold text-slate-900'>
                                Prompt:
                            </p>

                            <p className='text-lg text-slate-600'>
                                {this.state.question?.prompt}
                            </p>
                            <ReactMediaRecorder
                                video
                                askPermissionOnMount
                                render={({previewStream}) => {
                                    return (
                                        <>
                                            <div
                                                className={`flex flex-col gap-1.5 flex-1 mx-auto overflow-hidden rounded-3xl items-center justify-center relative`}
                                                style={{ maxHeight: "50vh", width:640}}
                                            >
                                                <VideoPreview stream={previewStream}/>
                                            </div>
                                            <div className="items-center justify-center flex flex-col">
                                                <p className='text-xs text-slate-600 mt-8'>
                                                    You are not currently being recorded. Please click 'Record a Response' to get started.
                                                </p>
                                            </div>
                                            <RecordingViewModal
                                                isOpen={this.state.isRecordingViewModalOpen}
                                                question={this.state.question}
                                                getInfo={this.getInfo}
                                                submitRecording={this.submitRecording}
                                                closeModal={this.closeRecordingViewModal}
                                                previewStream={previewStream}
                                                interviewSet={interviewSet}
                                                answerIds={this.props.answerIds}
                                            />
                                        </>
                                    )
                                }}
                            />
                            <div className='w-full mt-5'>
                                <HorizontalDivider className={'w-full bg-slate-200'}/>
                            </div>
                        </div>
                        <QuestionPageResponsesSection
                            responses={this.state.answers}
                            initiateResponse={this.initiateResponse}
                        />
                    </div>
                </div>
                <ConfirmationModal
                    isOpen={this.state.isCameraConfirmationModalOpen}
                    closeModal={this.closeCameraConfirmationModal}
                    confirmText={'Accept'}
                    title={'Privacy Statement'}
                    onConfirm={this.acceptCamera}
                    description={'RecruitU needs access to your audio and video to record submissions. Your camera will only ever be active during recordings.'}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);
