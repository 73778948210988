import axios from '../axios';
import qs from 'qs';

export const getLateralRecruitingData = async (user, searchParams = {}) => {
    if (!user) {
        return
    }

    let cleanSearchParams = Object.fromEntries(
        Object.entries(searchParams)
            .filter(([_, value]) => {
                if (Array.isArray(value) && value.length === 0) {
                    return false;
                } else if (!value) {
                    return false;
                }
                return true;
            })
            .map(([key, value]) => [key, value])
    );

    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: cleanSearchParams,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            }
        };
        const res = await axios.get("/lateral-recruiting", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const searchLateralRecruitingPeople = async (user, ids = []) => {
    if (!user) {
        return
    }

    try {
        const token = await user.getIdToken();
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: { ids },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'comma', encode: false });
            }
        };
        const res = await axios.get("/lateral-recruiting/people/search", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

