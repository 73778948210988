import React, { useRef, useState } from "react";
import { Button } from "../../components/atoms/Button";
import { uploadDoc } from "../../api/firebase";
import { toast } from "react-toastify";
import DownloadPdfButton from "../../components/atoms/DownloadPdfButton";
import SvgDocument from "../../components/icons/SvgDocument";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

const RecruiterUploadPdf = ({ type, onUpload, jobPdfPath, id, saveUrl }) => {
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
        
        if (file.type !== "application/pdf") {
            toast.error("Please upload a PDF file");
            return;
        }

        if (file.size > MAX_FILE_SIZE) {
            toast.error("File size must be less than 5MB");
            return;
        }
        
        setLoading(true);
        
        try {
            const url = await uploadDoc(type, id, file);
            const path = `${type}/${id}`;
            onUpload(path);
            if (!!saveUrl) saveUrl(url);
            toast.success("PDF uploaded successfully");
        } catch (error) {
            toast.error("Failed to upload PDF");
            console.error("Upload error:", error);
        } finally {
            setLoading(false);
            event.target.value = "";
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <div className="flex flex-row gap-3 w-full">
            <div>
                <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                />

                <Button
                    loading={loading}
                    onClick={handleButtonClick}
                    className="w-full"
                    variant={'secondary'}
                >
                    <div className="flex items-center justify-center gap-1">
                        {jobPdfPath && (
                            <SvgDocument className="w-4 h-4 text-primary-400" />
                        )}
                        {jobPdfPath ? "Replace PDF" : "Upload PDF"}
                    </div>
                </Button>
                
            </div>
            {jobPdfPath &&
                <DownloadPdfButton pdfPath={jobPdfPath} />
            }
        </div>
    );
};

export default RecruiterUploadPdf;
