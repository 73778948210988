import React from "react";
import Spinner from "../Spinner";
import ForgotPassword from "../modals/ForgotPassword";
import AuthFormFooter from "./AuthFormFooter";

export default function AuthForm({
    currentTab,
    recruiter,
    email,
    emailChanged,
    firstName,
    firstNameChanged,
    lastName,
    lastNameChanged,
    password,
    passwordChanged,
    passwordConfirmation,
    passwordConfirmationChanged,
    error,
    buttonPressed,
    loading,
    mobile,
    history,
    setTab,
    path,
}) {
    return (
        <>
            <div className="col-plain mb-16">
                <p className="body3-bold text-slate-500 mb-1">
                    {recruiter ? "Company" : "Student"} Email
                </p>
                <input
                    value={email}
                    style={{ width: 300 }}
                    onChange={emailChanged}
                    placeholder={"Enter email"}
                    className="input-light px-3 body2 base-black-50"
                />
            </div>
            {currentTab === "signup" && (
                <>
                    <div className="row-ac-jb mb-3">
                        <div className="8">
                            <p className="body3-bold text-slate-500 mb-1">
                                First name
                            </p>
                            <input
                                value={firstName}
                                onChange={firstNameChanged}
                                placeholder={"John"}
                                className="input-light px-3 body2 base-black-50"
                                style={{ width: 140 }}
                            />
                        </div>
                        <div className="">
                            <p className="body3-bold text-slate-500 mb-1">
                                Last Name
                            </p>
                            <input
                                value={lastName}
                                onChange={lastNameChanged}
                                placeholder={"Doe"}
                                className="input-light px-3 body2 base-black-50"
                                style={{ width: 140 }}
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="col-plain mb-16 mt-8">
                <p className="body3-bold text-slate-500 mb-1">Password</p>
                <input
                    value={password}
                    style={{ width: 300 }}
                    onChange={passwordChanged}
                    placeholder={"•••••••••••••"}
                    className="input-light body2 px-3 base-black-50"
                    type="password"
                />
            </div>
            {currentTab === "signup" && (
                <>
                    <div className="col-plain mb-3">
                        <p className="body3-bold text-slate-500 mb-1">
                            Confirm Password
                        </p>
                        <input
                            value={passwordConfirmation}
                            style={{ width: 300 }}
                            onChange={passwordConfirmationChanged}
                            placeholder={"•••••••••••••"}
                            className="auth-input input-light body2 px-3 base-black-50"
                            type="password"
                        />
                    </div>
                </>
            )}
            <div className="row-ac-jb mt-[-12px]">
                <div className="">
                    <p
                        className="text-red-400 body4"
                        style={{ minHeight: 17, maxWidth: 300 }}
                    >
                        {error}
                    </p>
                </div>
            </div>
            {currentTab === "login" && <ForgotPassword recruiter={recruiter}/>}
            <div className="row-ac-jc mt-32 gap-2">
                <button
                    className="primary-button body1-bold w-100p"
                    type="submit"
                    style={{ height: 52 }}
                    onClick={buttonPressed}
                >
                    {loading ? (
                        <Spinner light />
                    ) : currentTab === "login" ? (
                        "Log in"
                    ) : (
                        "Next"
                    )}
                </button>
            </div>
            <AuthFormFooter
                currentTab={currentTab}
                recruiter={recruiter}
                mobile={mobile}
                setTab={setTab}
                history={history}
                path={path}
            />
            
        </>
    );
}
