import React from "react";
import * as Portal from "@radix-ui/react-portal";
import SearchMajors from "../components/profile/SearchMajors";
import MultiSelectComponent from "../components/atoms/MultiSelectComponent";
import SelectComponent from "../components/atoms/SelectComponent";
import WrappedTextInput from "../components/atoms/WrappedTextInput";
import Switch from "react-switch";
import { SPORT_OPTIONS } from "../utils/constants";

function AdminEditStudentEducation({
    formData,
    selectedMajors,
    setSelectedMajors,
    errors,
    handleGPAChange,
    handleGradYearChange,
    handleGradSeasonChange,
    clubOptions,
    selectedClubNames,
    handleClubsChange,
    sportsMap,
    handleSportsChange,
    handleSportSwitchChange,
}) {
    return (
        <div className="flex flex-col gap-3 md:gap-5">
            <SearchMajors
                selectedMajors={selectedMajors}
                setSelectedMajors={setSelectedMajors}
                outerLabel="Major(s)"
                limit={5}
                error={errors.major}
                placeholder={"Search any major..."}
            />

            <WrappedTextInput
                outerLabel="GPA*"
                type="number"
                value={formData.gpa}
                onChange={handleGPAChange}
                error={errors.gpa}
                placeholder="ie. 3.6"
                className="input-light px-3 body2 base-black-50 w-full"
            />

            <WrappedTextInput
                outerLabel="Class Year*"
                type="text"
                value={formData.classYear}
                onChange={handleGradYearChange}
                error={errors.classYear}
                placeholder="ie. 2024"
                className="input-light px-3 body2 base-black-50 w-full"
            />

            <div className="flex-1">
                <p className="text-sm text-slate-500 font-semibold mb-1">
                    Graduation Season
                </p>
                <SelectComponent
                    hasCollapse={true}
                    label={"Select graduation season"}
                    value={formData.graduationSeason}
                    light
                    setValue={handleGradSeasonChange}
                    options={[
                        { title: "Fall", value: "Fall" },
                        { title: "Spring", value: "Spring" },
                    ]}
                />
            </div>

            {clubOptions.length > 0 && (
                <div className="flex-1">
                    <p className="text-sm text-slate-500 font-semibold mb-1">
                        Club(s)
                    </p>

                    <MultiSelectComponent
                        label={"Select your clubs"}
                        value={selectedClubNames}
                        light
                        setValue={handleClubsChange}
                        clearFilter={() => handleClubsChange(null)}
                        options={clubOptions.map((club) => ({
                            title: club.name,
                            club: club,
                        }))}
                        scrollable={true}
                        hasCollapse={true}
                        collapseTop={"100%"}
                    />
                </div>
            )}

            <div className="flex-1">
                <p className="text-sm text-slate-500 font-semibold mb-1">
                    Sport(s)
                </p>

                <MultiSelectComponent
                    label={"Select your sports"}
                    value={Object.keys(sportsMap)}
                    light
                    setValue={handleSportsChange}
                    clearFilter={() => handleSportsChange(null)}
                    options={SPORT_OPTIONS.map((sport) => ({ title: sport }))}
                    scrollable={true}
                    hasCollapse={true}
                />

                <div className="flex flex-row gap-3 pt-3 items-center overflow-x-auto">
                    {Object.entries(sportsMap).map(
                        ([sport, isVarsity], index) => (
                            <React.Fragment key={sport}>
                                <div className="flex flex-row items-center gap-2 flex-nowrap bg-white p-2 rounded-lg border border-slate-200">
                                    <label className="text-sm text-slate-800 whitespace-nowrap">{`${sport} :`}</label>
                                    <span
                                        className={`text-xs ${
                                            !isVarsity
                                                ? "text-primary font-bold"
                                                : "text-slate-800"
                                        }`}
                                    >
                                        Club
                                    </span>
                                    <Switch
                                        size={15}
                                        onColor={"#4848F2"}
                                        offColor={"#4848F2"}
                                        checkedIcon={null}
                                        uncheckedIcon={null}
                                        onChange={(checked) =>
                                            handleSportSwitchChange(
                                                checked,
                                                sport
                                            )
                                        }
                                        checked={isVarsity}
                                    />
                                    <span
                                        className={`text-xs ${
                                            isVarsity
                                                ? "text-primary font-bold"
                                                : "text-slate-800"
                                        }`}
                                    >
                                        Varsity
                                    </span>
                                </div>
                                {index !==
                                    Object.entries(sportsMap).length - 1 && (
                                    <div className="w-px h-6 bg-slate-200" />
                                )}
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

export default AdminEditStudentEducation;
