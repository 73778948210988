import React, { useMemo } from 'react';
import SelectableItem from "../components/onboarding/SelectableItem";
import { ONBOARDING_CITIES } from "../components/onboarding/onboarding-constants";
import SelectedTag from "../components/onboarding/SelectedTag";

function AdminEditStudentLocation({ 
    selectedLocations, 
    setSelectedLocations, 
    locationSortOrder, 
    setLocationSortOrder 
}) {
    const handleLocationClick = (label) => {
        if (selectedLocations.includes(label)) {
            setSelectedLocations(selectedLocations.filter(item => item !== label));
        } else {
            setSelectedLocations([...selectedLocations, label]);
        }
    };

    const handleLocationSortChange = (e) => {
        setLocationSortOrder(e.target.value);
    };

    const selectAllLocations = () => {
        const allCities = ONBOARDING_CITIES.map(({city, state}) => `${city}, ${state}`);
        setSelectedLocations(allCities);
    };

    const deselectAllLocations = () => {
        setSelectedLocations([]);
    };

    const sortedCities = useMemo(() => {
        return [...ONBOARDING_CITIES].sort((a, b) => {
            if (locationSortOrder === 'alphabetical') {
                return a.city.localeCompare(b.city);
            }
            return 0;
        });
    }, [locationSortOrder]);

    return (
        <div className="space-y-4">
            <h3 className="text-lg font-medium">Locations</h3>
            <div className="flex flex-wrap gap-2 min-h-[40px]">
                {selectedLocations.map(location => (
                    <SelectedTag 
                        key={location} 
                        label={location} 
                        onClose={handleLocationClick}
                    />
                ))}
            </div>
            
            <div className="flex items-center justify-between">
                <select 
                    onChange={handleLocationSortChange} 
                    value={locationSortOrder}
                    className="text-sm cursor-pointer font-medium border border-slate-300 rounded-lg p-2"
                >
                    <option value="popularity">Sort by popularity</option>
                    <option value="alphabetical">Sort alphabetically</option>
                </select>
                <div className="flex gap-2">
                    <button
                        type="button"
                        onClick={selectAllLocations}
                        className="px-3 py-1 text-sm text-primary border border-primary rounded-md"
                    >
                        Select All
                    </button>
                    <button
                        type="button"
                        onClick={deselectAllLocations}
                        className="px-3 py-1 text-sm text-gray-600 border border-gray-300 rounded-md"
                    >
                        Deselect All
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 gap-2 max-h-[200px] overflow-y-auto">
                {sortedCities.map(({city, state}) => (
                    <SelectableItem
                        key={`${city}-${state}`}
                        label={`${city}, ${state}`}
                        onClick={handleLocationClick}
                        isSelected={selectedLocations.includes(`${city}, ${state}`)}
                    />
                ))}
            </div>
        </div>
    );
}

export default AdminEditStudentLocation; 