import React, {useState, useEffect, useCallback} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import JobBoardLoading from "../loading/JobBoardLoading";
import { SEARCH_INDUSTRY_PROFESSIONALS_TABLE_HEADERS } from "../../utils/dummy";
import SearchHeaderWrapper from "../../components-recruiter/atoms/SearchHeaderWrapper";
import RecruiterDashboardTable from "../../components-recruiter/dashboard/RecruiterDashboardTable";
import { Button } from "../atoms/Button";
import SelectClubListModal from "../../components-recruiter/modals/SelectClubListModal";
import IndustryProfessionalItem from "../../components-recruiter/IndustryProfessionalItem";
import {getLateralRecruitingData} from "../../api/recruiter/lateral-recruiting";
import {useDebouncedCallback} from "use-debounce";
import AlertModal from "../atoms/modals/AlertModal";
import Spinner from "../Spinner";
import FiltersSidebar from "../../components-recruiter/atoms/FiltersSidebar";
import { ONBOARDING_CITIES } from "../onboarding/onboarding-constants";
import MultiSearchCompanies from "../search/MultiSearchCompanies";
import MultiSearchSchools from "../search/MultiSearchSchools";

const MAX_CSV_RESULTS = 1000;
const MAX_PAGE_RESULTS = 20;

const mapStateToProps = (state) => ({
    authUser: state.userReducer.authUser,
    tier: state.userReducer.tier,
    constants: state.userReducer.constants,
});

const initialFilters = {
    classYears: [],
    college: [],
    search: "",
    currentCompany: [],
    previousCompany: [],
    page: 1,
    industry: [],
    roles: [],
    locations: [],
    title: [],
};

const RecruiterIndustryProfessionalsSearch = (props) => {
    const [persons, setPersons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [moreStudentsLoading, setMoreStudentsLoading] = useState(false);
    const [csvLoading, setCsvLoading] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState({});
    const [isSelectListModalOpen, setIsSelectListModalOpen] = useState(false);
    const [filters, setFilters] = useState(initialFilters);
    const [numOfPages, setNumOfPages] = useState(1);
    const [numItems, setNumItems] = useState(0);

    const INDUSTRY_TAGS = props.constants.industry_tags.map((tag) => ({
        title: tag,
        value: tag,
    }));
    const SECONDARY_TAGS = props.constants.secondary_tags.map((tag) => ({
        title: tag,
        value: tag,
    }));
    const LOCATION_TAGS = ONBOARDING_CITIES.map((loc) => ({
        title: loc.city,
        value: loc.city,
    }));

    const TITLE_TAGS = [
        {title: "Analyst"},
        {title: "Associate"},
        {title: "Vice President"},
        {title: "Managing Director"},
    ];

    const hideMoreStudents = filters.page >= numOfPages;

    const onSelectPersonChange = (person) => {
        setSelectedStudents((prevSelected) => {
            const newSelected = {...prevSelected};
            if (newSelected[person.id]) {
                delete newSelected[person.id];
            } else {
                newSelected[person.id] = person;
            }
            return newSelected;
        });
    };

    const isPersonSelected = (studentId) => {
        return !!selectedStudents[studentId];
    };

    const fetchStudents = useCallback(
        async (
            filters,
            page = null,
            count = MAX_PAGE_RESULTS,
            setState = true
        ) => {
            const {
                classYears,
                college,
                currentCompany,
                previousCompany,
                search,
                industry,
                roles,
                locations,
                title,
            } = filters;
            const pageToFetch = page ?? filters.page;
            const isFirstPage = pageToFetch === 1;

            if (setState) {
                if (isFirstPage) {
                    setLoading(true);
                } else {
                    setMoreStudentsLoading(true);
                }
            }

            const results = await getLateralRecruitingData(props.authUser, {
                undergraduate_year: classYears,
                school: college,
                current_company: currentCompany,
                previous_company: previousCompany,
                page: pageToFetch,
                name: search,
                sector: industry,
                role: roles,
                city: locations,
                title: title,
                count,
            });

            if (setState) {
                setNumOfPages(results?.num_pages ?? 1);
                setNumItems(results?.num_items ?? 0);
            }

            const newResults =
                results?.results?.map((result) => result.document) ?? [];

            if (setState) {
                if (isFirstPage) {
                    setLoading(false);
                    setPersons(newResults);
                } else {
                    setPersons((prevStudents) => [
                        ...prevStudents,
                        ...newResults,
                    ]);
                    setMoreStudentsLoading(false);
                }
            }
            return newResults;
        },
        [props.authUser]
    );

    const debouncedFetchStudents = useDebouncedCallback(fetchStudents, 500);

    useEffect(() => {
        if (filters.search) {
            debouncedFetchStudents(filters);
        } else {
            fetchStudents(filters);
        }
    }, [filters, debouncedFetchStudents, fetchStudents]);

    const updateFilters = (updates) => {
        setFilters((prev) => ({
            ...prev,
            ...updates,
            page: 1,
        }));
    };

    const handleSearchChange = (event) => {
        if (!props.showPaywall) {
            updateFilters({search: event.target.value});
        }
    };

    const getNextStudentInfo = () => {
        if (!props.showPaywall) {
            setFilters((prev) => ({
                ...prev,
                page: prev.page + 1,
            }));
        }
    };

    const clearFilters = () => {
        setFilters(initialFilters);
    };

    const canClearFilters =
        filters.classYears.length > 0 ||
        filters.college.length > 0 ||
        filters.search !== "" ||
        filters.industry.length > 0 ||
        filters.roles.length > 0 ||
        filters.locations.length > 0 ||
        filters.currentCompany.length > 0 ||
        filters.previousCompany.length > 0 ||
        filters.title.length > 0;

    const setCurrentCompany = (companies) => {
        updateFilters({currentCompany: companies});
    };

    const setPreviousCompany = (companies) => {
        updateFilters({previousCompany: companies});
    };

    const setClassYears = (year) => {
        if (!year) {
            updateFilters({classYears: []});
            return;
        }
        updateFilters({
            classYears: filters.classYears.includes(year)
                ? filters.classYears.filter(y => y !== year)
                : [...filters.classYears, year]
        });
    };

    const updateIndustry = (selectedIndustry) => {
        if (!selectedIndustry) {
            updateFilters({industry: []});
            return;
        }
        updateFilters({
            industry: filters.industry.includes(selectedIndustry)
                ? filters.industry.filter(i => i !== selectedIndustry)
                : [...filters.industry, selectedIndustry]
        });
    };

    const updateTitle = (selectedTitle) => {
        if (!selectedTitle) {
            updateFilters({title: []});
            return;
        }
        updateFilters({
            title: filters.title.includes(selectedTitle)
                ? filters.title.filter(t => t !== selectedTitle)
                : [...filters.title, selectedTitle]
        });
    };

    const updateRoles = (selectedRole) => {
        if (!selectedRole) {
            updateFilters({roles: []});
            return;
        }
        updateFilters({
            roles: filters.roles.includes(selectedRole)
                ? filters.roles.filter(r => r !== selectedRole)
                : [...filters.roles, selectedRole]
        });
    };

    const updateLocation = (selectedLocation) => {
        if (!selectedLocation) {
            updateFilters({locations: []});
            return;
        }
        updateFilters({
            locations: filters.locations.includes(selectedLocation)
                ? filters.locations.filter(l => l !== selectedLocation)
                : [...filters.locations, selectedLocation]
        });
    };

    const updateCollege = (colleges) => {
        updateFilters({college: colleges});
    };

    const handleDownloadCSV = async () => {
        setCsvLoading(true);
        const results = await fetchStudents(filters, 1, MAX_CSV_RESULTS, false);

        // Create CSV header
        let csv =
            "First Name,Last Name,Company,City,LinkedIn URL,Title,School\n";

        // Add data rows
        csv += results
            .map(
                (person) =>
                    `"${person.first_name || ""}","${
                        person.last_name || ""
                    }","${person.company_name || ""}","${person.city || ""}","${
                        person.linkedin || ""
                    }","${person.title || ""}","${person.school || ""}"`
            )
            .join("\n");

        // Create and trigger download
        const blob = new Blob([csv], {type: "text/csv"});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "industry_professionals.csv");
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        setCsvLoading(false);
    };

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <div className={'flex flex-row flex-1 overflow-hidden'}>
                <div className={'w-[250px] px-[12px] flex flex-col'}>
                    <FiltersSidebar
                        search={{
                            placeholder: "Search by name...",
                            value: filters.search,
                            onChange: handleSearchChange,
                        }}
                        industrySelect={{
                            label: "Industry",
                            value: filters.industry,
                            setValue: updateIndustry,
                            options: INDUSTRY_TAGS,
                            multiSelect: true,
                        }}
                        verticalSelect={{
                            label: "Vertical",
                            value: filters.roles,
                            setValue: updateRoles,
                            options: SECONDARY_TAGS,
                            multiSelect: true,
                        }}
                        titleSelect={{
                            label: "Title",
                            value: filters.title,
                            setValue: updateTitle,
                            options: TITLE_TAGS,
                            multiSelect: true,
                        }}
                        locationSelect={{
                            label: "Location",
                            value: filters.locations,
                            setValue: updateLocation,
                            options: LOCATION_TAGS,
                            multiSelect: true,
                        }}
                        classYearSelect={{
                            label: "Class Year",
                            value: filters.classYears,
                            setValue: setClassYears,
                            options: [
                                {title: "2015"},
                                {title: "2016"},
                                {title: "2017"},
                                {title: "2018"},
                                {title: "2019"},
                                {title: "2020"},
                                {title: "2021"},
                                {title: "2022"},
                                {title: "2023"},
                                {title: "2024"},
                                {title: "2025"},
                                {title: "2026"},
                                {title: "2027"},
                                {title: "2028"},
                            ],
                            multiSelect: true,
                        }}
                        clearFilters={canClearFilters ? clearFilters : undefined}
                        overrideCurrentCompanyInput={
                            <MultiSearchCompanies
                                selectedCompanies={filters.currentCompany}
                                setSelectedCompanies={setCurrentCompany}
                                placeholder={'Current companies'}
                            />
                        }
                        overridePreviousCompanyInput={
                            <MultiSearchCompanies
                                selectedCompanies={filters.previousCompany}
                                setSelectedCompanies={setPreviousCompany}
                                placeholder={'Previous companies'}
                            />
                        }
                        overrideSchoolInput={
                            <MultiSearchSchools
                                selectedSchools={filters.college}
                                setSelectedSchools={updateCollege}
                                placeholder={'Search by school...'}
                            />
                        }
                    />
                </div>
                <div className={'flex flex-col flex-1 overflow-hidden'}>
                    <div className='flex h-12 flex-row items-center gap-4 pr-[12px]'>
                        <p className={'text-lg font-semibold mr-auto'}>
                            Results
                        </p>
                        <div className="">
                            <div className="flex flex-row items-center justify-end gap-4">
                                {numItems > MAX_CSV_RESULTS ? (
                                    <AlertModal
                                        title="Download CSV"
                                        description="This will only download the first 1000 results."
                                        onConfirm={handleDownloadCSV}
                                    >
                                        <Button size="sm" disabled={csvLoading}>
                                            {csvLoading && <Spinner light/>}
                                            Download CSV
                                        </Button>
                                    </AlertModal>
                                ) : (
                                    <Button
                                        size="sm"
                                        onClick={handleDownloadCSV}
                                        disabled={csvLoading}
                                    >
                                        {csvLoading && <Spinner light/>}
                                        Download CSV
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <RecruiterDashboardTable
                        columns={SEARCH_INDUSTRY_PROFESSIONALS_TABLE_HEADERS}
                        containerStyle={'rounded-none rounded-tl-xl border-r-0 border-b-0'}
                        TableBody={
                            <div className="flex-1 flex flex-col mt-0 pt-2 gap-1 p-5 overflow-y-scroll">
                                {loading ? (
                                    <JobBoardLoading page="industry-professionals"/>
                                ) : persons.length === 0 ? (
                                    <div
                                        className="my-8 flex text-neutral-400 flex-col items-center justify-center flex-1">
                                        <SvgSearch/>
                                        <p className="body2 mt-2 mb-6">
                                            No professionals found...
                                        </p>
                                    </div>
                                ) : (
                                    <>
                                    {persons.map((item, index) => (
                                            <IndustryProfessionalItem
                                                key={item.id}
                                                item={item}
                                                columns={
                                                    SEARCH_INDUSTRY_PROFESSIONALS_TABLE_HEADERS
                                                }
                                                onSelectStudentChange={
                                                    onSelectPersonChange
                                                }
                                                isSelected={isPersonSelected(
                                                    item.id
                                                )}
                                            />
                                        ))}
                                    </>
                                )}
                                {!hideMoreStudents &&
                                    !loading &&
                                    persons.length !== 0 && (
                                        <div
                                            className="flex flex-row items-center justify-center mr-3 py-2 body2-bold"
                                            onClick={getNextStudentInfo}
                                        >
                                            <Button
                                                loading={moreStudentsLoading}
                                                variant={"ghost"}
                                            >
                                                Load more professionals
                                            </Button>
                                        </div>
                                    )}
                            </div>
                        }
                    />
                </div>
            </div>
            {/* <RecruiterDashboardTable
                columns={SEARCH_INDUSTRY_PROFESSIONALS_TABLE_HEADERS}
                className="mt-2"
                TableBody={
                    <div className="flex-1 flex flex-col mt-0 pt-2 gap-1 p-5 overflow-y-scroll">
                        {loading ? (
                            <JobBoardLoading page="industry-professionals" />
                        ) : persons.length === 0 ? (
                            <div className="my-8 flex text-neutral-400 flex-col items-center justify-center flex-1">
                                <SvgSearch />
                                <p className="body2 mt-2 mb-6">
                                    No professionals found...
                                </p>
                            </div>
                        ) : (
                            <>
                                {persons.map((item, index) => (

                                        <IndustryProfessionalItem
                                            item={item}
                                            columns={
                                                SEARCH_INDUSTRY_PROFESSIONALS_TABLE_HEADERS
                                            }
                                            onSelectStudentChange={
                                                onSelectPersonChange
                                            }
                                            isSelected={isPersonSelected(
                                                item.id
                                            )}
                                        />
                                ))}
                            </>
                        )}
                        {!hideMoreStudents &&
                            !loading &&
                            persons.length !== 0 && (
                                <div
                                    className="flex flex-row items-center justify-center mr-3 py-2 body2-bold"
                                    onClick={getNextStudentInfo}
                                >
                                    <Button
                                        loading={moreStudentsLoading}
                                        variant={"ghost"}
                                    >
                                        Load more professionals
                                    </Button>
                                </div>
                            )}
                    </div>
                }
            /> */}

            <SelectClubListModal
                items={Object.values(selectedStudents)}
                type={"user"}
                isOpen={isSelectListModalOpen}
                authUser={props.authUser}
                closeModal={() => setIsSelectListModalOpen(false)}
                onUpdate={() => setSelectedStudents({})}
            />
        </div>
    );
};

export default connect(mapStateToProps)(RecruiterIndustryProfessionalsSearch);
