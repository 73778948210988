import * as React from 'react';
import {Component, useCallback, useEffect} from 'react';
import {usePopperTooltip} from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import CollapseComponent from './CollapseComponent';

function DefaultTooltip(props) {
    const [controlledVisible, setControlledVisible] = React.useState(false);

    const offsetDelta = props.displayArrow ? 8 : 0

    const {
        getTooltipProps,
        getArrowProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip(
        {
            trigger: props.hover ? 'hover' : 'click',
            closeOnOutsideClick: true,
            closeOnTriggerHidden: true,
            visible: props.visible !== undefined ? props.visible : controlledVisible,
            onVisibleChange: props.toggle !== undefined ? props.toggle : setControlledVisible,
        },
        {
            placement: props.placement || 'right',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [props.offsetX || 0, (props.offsetY || 6) + offsetDelta]
                    }
                },
                {
                    name: 'preventOverflow',
                    options: {
                        boundary: props.node || document.body,
                        tether: true
                    }
                },
                {
                    name: 'flip',
                    options: {
                        flipVariations: false
                    }
                }
            ]
        }
    );

    useEffect(() => {
        const handleKeyDown = ({key}) => {
            if (key === 'Escape') {
                if (props.toggle !== undefined) {
                    props.toggle(false)
                } else {
                    setControlledVisible(false);
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props]);

    const stopPropagation = useCallback((event) => {
        event.stopPropagation();
    }, []);

    return (
        <>
            {props.ToggleWrapper ?
                <props.ToggleWrapper ref={setTriggerRef}>
                    {props.ToggleComponent}
                </props.ToggleWrapper>
                :
                <div style={{cursor:props.disabled && "not-allowed"}}>
                    <div className={props.toggleClassName} ref={setTriggerRef} style={{pointerEvents:props.disabled && 'none'}} onClick={stopPropagation}>
                        {props.ToggleComponent}
                    </div>
                </div>
            }
            {visible &&
                ReactDOM.createPortal(
                    props.label ?
                        <div
                            ref={setTooltipRef}
                            {...getTooltipProps({className: `${props.displayArrow ? 'tooltip-arrow-container body2-emphasized' : 'tooltip-container body2-medium'} !rounded-xl text-sm`})}
                        >
                            {props.displayArrow && <div {...getArrowProps({className: 'tooltip-arrow-down'})}/>}
                            {props.label}
                        </div>
                        :
                        <div style={{zIndex: 3000}}
                             ref={setTooltipRef} {...getTooltipProps({className: "tooltip-container-blank !rounded-xl text-sm"})}>
                            {props.ContentComponent}
                        </div>,
                    props.node || document.body
                )}
        </>
    );
}

function CollapseTooltip(props) {
    const [controlledVisible, setControlledVisible] = React.useState(false);
    const containerRef = React.useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                if (props.toggle) {
                    props.toggle(false);
                } else {
                    setControlledVisible(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [props.toggle]);

    return (
        <div className="relative" ref={containerRef}>
            {props.ToggleWrapper ?
                <props.ToggleWrapper onClick={() => props.toggle ? props.toggle(!props.visible) : setControlledVisible(!controlledVisible)}>
                    {props.ToggleComponent}
                </props.ToggleWrapper>
                :
                <div style={{cursor: props.disabled && "not-allowed"}}>
                    <div className={props.toggleClassName}
                         style={{pointerEvents: props.disabled && 'none'}}
                         onClick={() => props.toggle ? props.toggle(!props.visible) : setControlledVisible(!controlledVisible)}>
                        {props.ToggleComponent}
                    </div>
                </div>
            }
            <div className="absolute left-0 right-0 z-[99]" style={{top: props.collapseTop || '100%'}}>
                <CollapseComponent
                    isOpen={props.visible !== undefined ? props.visible : controlledVisible}
                    className={props.collapseClassName || "bg-white shadow-lg rounded-lg"}
                >
                    <div className="border border-neutral-200 rounded-lg">
                        {props.ContentComponent}
                    </div>
                </CollapseComponent>
            </div>
        </div>
    );
}

function TooltipComponent(props) {
  
    return props.hasCollapse ? <CollapseTooltip {...props} /> : <DefaultTooltip {...props} />;
}

// Common PropTypes for both implementations
const commonPropTypes = {
    ToggleComponent: PropTypes.object,
    ContentComponent: PropTypes.object,
    visible: PropTypes.bool,
    toggle: PropTypes.func,
    ToggleWrapper: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Component)]),
    toggleClassName: PropTypes.string,
    disabled: PropTypes.bool,
};

DefaultTooltip.propTypes = {
    ...commonPropTypes,
    hover: PropTypes.bool,
    placement: PropTypes.oneOf(['auto', 'auto-start', 'auto-end', 'top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'right-start', 'right-end', 'left', 'left-start', 'left-end']),
    offsetX: PropTypes.number,
    offsetY: PropTypes.number,
    node: PropTypes.node,
    label: PropTypes.string,
    displayArrow: PropTypes.bool,
};

CollapseTooltip.propTypes = {
    ...commonPropTypes,
    collapseClassName: PropTypes.string,
    collapseTop: PropTypes.string,
};

TooltipComponent.propTypes = {
    ...commonPropTypes,
    ...DefaultTooltip.propTypes,
    ...CollapseTooltip.propTypes,
    hasCollapse: PropTypes.bool,
};

export default TooltipComponent;
