import React, {PureComponent} from "react";
import {addNote, deleteNetwork, deleteNote, editNetwork} from "../../api/student/networks";
import {connect} from "react-redux";
import SvgRemove from "../icons/SvgRemove";
import {TOAST_OPTIONS, APP_ADMINS} from "../../utils/constants";
import {toast} from "react-toastify";
import {wait} from "@testing-library/user-event/dist/utils";
import {SIDEBAR_CARD} from "../../utils/dummy";
import SvgEdit from "../icons/SvgEdit";
import ContactDetails from "../ContactDetails";
import Checkbox from "../atoms/Checkbox";
import {Button} from "../atoms/Button";
import SidebarOverlayWrapper from "../atoms/SidebarOverlayWrapper";
import NotetakingWidget from "../../components-recruiter/atoms/NotetakingWidget";
import SectionTabs from "../atoms/SectionTabs";
import SvgAddCircle from "../icons/SvgAddCircle";
import EditContactModal from "../atoms/modals/EditContactModal";
import ProfilePageExperienceWidget from "../profilepage/ProfilePageExperienceWidget";
import SvgUserFeather from "../icons/SvgUserFeather";
import SvgLinkedIn from "../icons/SvgLinkedIn";
import ProfilePageEducationWidget from "../profilepage/ProfilePageEducationWidget";
import {getPerson} from "../../api/student/people";
import { createNetwork } from "../../api/student/networks";
import SvgLock from "../icons/SvgLock";
import ProfilePicture from "../../components-recruiter/atoms/ProfilePicture";
import RecruitPremium from "../atoms/RecruitPremium";
import Spinner from "../Spinner";
import { sendVerificationEmail } from "../../api/authentication";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        networks: state.userReducer.networks,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

class ScrapedNetworkingPeek extends PureComponent {
    state = {
        note: "",
        notes: [],
        activeTab: 'Summary',
        linkedin: {},
        editContactModalOpen: false,
        emailVerificationSent: false,
        loading: false
    }

    updateStatus = async () => {
        try {
            const newNetwork = {...this.props.network};
            const newStatus = newNetwork.status === "Contacted" ? "Not contacted" : "Contacted";
            newNetwork.status = newStatus;
            this.setState({status: newStatus});
            this.props.updateNetwork(newNetwork);
            await editNetwork(this.props.authUser, this.props.network.id, {status: newStatus});

            if (newStatus === "Contacted") {
              window.analytics.track("Contact Status Updated", {
                  "contact_id": this.props.network.person_id,
              })
            }

            toast.promise(
                Promise.resolve('Status updated!'),
                {
                    pending: 'Updating status...',
                    success: 'Status updated!',
                    error: 'Failed to update status!',
                },
                TOAST_OPTIONS
            );
        } catch (error) {
            toast.error('Failed to update status!', TOAST_OPTIONS);
        }
    };

    updateNote = (e) => {
        this.setState({note: e.target.value})
    }

    handleEmailVerification = () => {
        if (!this.props.user.email_verified) {
            this.setState({loading: true});
            const verifyPromise = sendVerificationEmail(this.props.authUser).then(() => {
                this.setState({emailVerificationSent: true});
            }).finally(() => {
                this.setState({loading: false});
            });
            toast.promise(
                verifyPromise,
                {
                    pending: 'Sending verification email...',
                    success: 'Verification email sent! Please check your inbox or spam folder.',
                    error: 'Failed to send verification email!'
                },
                TOAST_OPTIONS
            );
        }
    }

    saveNote = async (note) => {
        try {
            const savePromise = addNote(this.props.authUser, this.props.network.id, note);

            toast.promise(
                savePromise,
                {
                    pending: 'Adding note...',
                    success: 'Note added!',
                    error: 'Failed to add note!',
                },
                TOAST_OPTIONS
            );
            const saveResult = await savePromise;
            if (saveResult) {
                const newNetwork = {...this.props.network};
                newNetwork.notes.push({text: note, created_at: new Date()});
                this.props.updateNetwork(newNetwork);
                window.analytics.track("Contact Notes Saved", {
                  person_id: this.props.person.id
                })
            }
        } catch (error) {
            toast.error('Failed to add note!', TOAST_OPTIONS);
        }
    };

    deleteNote = async (index) => {
        try {
            const deletePromise = deleteNote(this.props.authUser, this.props.network.id, index);
            toast.promise(
                deletePromise,
                {
                    pending: 'Deleting note...',
                    success: 'Note deleted!',
                    error: 'Failed to delete note!',
                },
                TOAST_OPTIONS
            );

            const deleteResult = await deletePromise;
            if (deleteResult) {
                const newNetwork = {...this.props.network};
                newNetwork.notes.splice(index, 1);
                this.props.updateNetwork(newNetwork);
            }
        } catch (error) {
            console.error(error);
        }
    };

    componentDidMount = () => {
        this.setNotes();
        this.getInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (!this.props.open) {
            return
        }
        if (this.props.open === prevProps.open)
          return;
        if (prevProps.network !== this.props.network) {
            this.setNotes();
        }
        if (prevProps.person !== this.props.person) {
            this.getInfo();
        }
        if (!prevProps.open && this.props.open) {
            wait(10).then(() =>
                this.setState({opened: true})
            )
        }

        // segment tracking
        window.analytics.track("Contact Viewed", {
          person_id: this.props.person.id
        })

        if (prevProps.open && !this.props.open) {
            wait(10).then(() =>
                this.setState({opened: false})
            )
        }
    }

    getInfo = async () => {
        if (!!this.props?.person?.id) {
            const res = await getPerson(this.props.authUser, this.props?.person?.id);
            this.setState({linkedin: res.person?.linkedin || {}, activeTab: 'Summary'});
        } else {
            this.setState({linkedin: {}, activeTab: 'Summary'})
        }
    }

    setNotes = () => {
        const network = this.props.network || {};
        const notes = network.notes || [];
        this.setState({notes});
    }

    deleteNetwork = async () => {
        try {
            this.close();
            const networkPromise = deleteNetwork(this.props.authUser, this.props.network.id);
            toast.promise(
                networkPromise,
                {
                    pending: 'Deleting contact...',
                    success: 'Contact deleted.',
                    error: 'Failed to delete contact!',
                },
                TOAST_OPTIONS
            );
            const networkResult = await networkPromise;
            if (networkResult) {
                this.props.deleteNetwork(this.props.network.person_id);
            }
        } catch (error) {
            toast.error('Failed to remove network', TOAST_OPTIONS);
        }
    };

    addNetwork = async () => {
        this.setState({loading: true});

        if (Object.keys(this.props.networks).includes(this.props.person.id)) {
            throw new Error("Already have a network with this user.")
        }
        const network = await createNetwork(this.props.authUser, {person_id: this.props.person.id});
        this.props.updateNetwork(network.network);
        const newUser = {...this.props.user};
        newUser.credits = newUser.credits - 1;
        this.props.setUser(newUser);

        window.analytics.track("Saved Contact", {
            "contact_id": network.network.person_id
        })
        this.setState({loading: false})
    }

    close = () => {
        this.setState({opened: false})
        wait(150).then(() => {
            this.props.close()
        })
    }

    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    openEditContactModal = () => {
        this.setState({editContactModalOpen: true})
    }

    closeEditContactModal = () => {
        this.setState({editContactModalOpen: false})
    }


    render() {
        const network = this.props.network || {};
        const person = this.props.person || {};
        const linkedin = this.state.linkedin || {};
        const notes = this.state.notes || [];
        const source = person?.source === 'club' ? 'Club' : person?.source === 'recruitu.io' || person?.source === "apollo" ? "RecruitU" : "Personal"
        const TABS = ['Summary', 'Work', 'Education'];

        let logoUrl = linkedin.profile_pic_url ? linkedin.profile_pic_url : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media";
        if (logoUrl.includes("replaceme:")) {
            logoUrl = logoUrl.replace("replaceme:", "");
        }
        const finalLogoUrl = logoUrl ? logoUrl : "https://firebasestorage.googleapis.com/v0/b/recruit-u-f79a8.appspot.com/o/users%2Fblank-person.jpeg?alt=media";
        return (
            <SidebarOverlayWrapper label={'Contact Details'} onClose={this.close} open={this.props.open} width={'40vw'}>
                <div className='flex flex-col flex-1 py-3 overflow-y-scroll w-full gap-3'>
                    <div className="flex flex-row justify-between gap-10">
                        <div className='flex flex-row items-center gap-5'>
                            {this.props.isSaved || this.props.adminPortal ?
                                <div className="flex flex-row gap-4 text-neutral-400 body2">
                                    <ProfilePicture
                                        image={finalLogoUrl}
                                        userID={person.id}
                                        size={68}
                                        className={`w-full h-full rounded-full border-neutral-200 border bg-white object-contain`}                                />
                                </div>
                                :
                                <div
                                    className='w-16 h-16 border-slate-200 rounded-full border flex flex-col items-center justify-center bg-slate-50'>
                                    <SvgUserFeather className={'w-8 h-8 text-slate-400'}/>
                                </div>
                            }
                            <div>
                                <div className='flex flex-row items-center gap-2'>
                                    <p className='text-3xl font-bold'>{person?.first_name} {person?.last_name}</p>
                                    {this.props.isSaved &&
                                        <>
                                            {person?.linkedin && <a href={person?.linkedin} target="_blank"><SvgLinkedIn
                                                className='base-black-30 h-base-black-70 pointer w-5 h-5 mt-1'/></a>}
                                        </>
                                    }
                                </div>
                                {this.props.isSaved ?
                                    <p>
                                        {linkedin?.headline}
                                    </p>
                                    :
                                    <p className={`body1 ${person?.industry ? 'text-slate-800' : 'text-slate-300'}`}>{person?.industry || 'No industry info'}</p>
                                }
                            </div>
                        </div>
                        {this.props.clubPage && !this.props.isSaved &&
                            <div className='flex flex-row items-center gap-5'>
                                <Button variant={'secondary'} icon={SvgAddCircle} onClick={this.addNetwork}>
                                    Save
                                </Button>

                            </div>
                        }
                    </div>
                    <div className={`mt-5`}>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            setActiveTab={this.changeTab}
                            tabs={TABS}
                        />
                    </div>
                    <div className='relative flex flex-col gap-3'>
                        {this.state.activeTab === 'Summary' &&
                            <>
                                <ContactDetails
                                    hideHeader
                                    person={person}
                                    hideContact={!this.props.isSaved && !this.props.adminPortal}
                                />
                                {!this.props.adminPortal &&
                                    <>
                                        {!this.props.isSaved ?
                                            <div
                                                className='bg-primary rounded-xl mt-2 p-5 mx-1 text-white outline outline-primary/20 outline-offset-2'>
                                                <SvgLock className={'text-white mb-2'}/>
                                                <p className='text-xl font-bold w-2/3'>
                                                    {!this.props.user.email_verified ? 
                                                        'Verify your email to unlock contacts' :
                                                        'Unlock contact info & track your status'
                                                    }
                                                </p>
                                                {this.props.user.email_verified ? (
                                                    <>
                                                        {this.props.user.credits > 0 &&
                                                            <p className='mb-3 text-sm'>
                                                                Click below to unlock {person.first_name}'s full contact info, take
                                                                personal notes, and track your networking progress.
                                                            </p>
                                                        }
                                                        <p className='mb-3 text-sm'>
                                                            You have {this.props.user.credits} credits remaining today.
                                                        </p>
                                                        {this.props.user.credits > 0 ?
                                                            <Button variant={'white'} className='animate-pulse' onClick={this.addNetwork}>
                                                                {!this.state.loading ? 'Unlock contact info (1 credit)' :
                                                                    <Spinner />
                                                                }
                                                            </Button>
                                                            :
                                                            <RecruitPremium user={this.props.user} />
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className='mb-3 text-sm'>
                                                            Please verify your email address to access contact information.
                                                        </p>
                                                        <Button 
                                                            variant={'white'}
                                                            onClick={this.handleEmailVerification}
                                                            disabled={this.state.emailVerificationSent || this.state.loading}
                                                        >
                                                            {this.state.emailVerificationSent ? 'Verification email sent!' : 'Send verification email'}
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                            :
                                            <>
                                                <div className={`flex flex-col gap-2 ${SIDEBAR_CARD}`}>
                                                    <div className='flex flex-row items-center justify-between'>
                                                        <p className='text-lg font-bold'>
                                                            Status
                                                        </p>
                                                        <div className="">
                                                            <Checkbox
                                                                checked={network.status === "Contacted"}
                                                                onClick={this.updateStatus}
                                                                label={'Networking call'}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <NotetakingWidget
                                                    notes={notes}
                                                    onSaveNote={this.saveNote}
                                                    onDeleteNote={this.deleteNote}
                                                />
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                        {(this.state.activeTab === 'Work') &&
                            <div className={`${SIDEBAR_CARD}`}>
                                <p className='text-slate-800 text-lg font-bold'>
                                    Work History
                                </p>
                                {!linkedin?.experiences || linkedin?.experiences?.length === 0 ?
                                    <div className='flex flex-col items-center py-10'>
                                        <p className='text-xs text-slate-400'>
                                            {person.first_name} doesn't have any past experience listed
                                        </p>
                                    </div>
                                    :
                                    <ProfilePageExperienceWidget
                                        experiences={linkedin?.experiences}
                                        inPeek
                                        studentProfilePage
                                    />
                                }
                            </div>
                        }
                        {(this.state.activeTab === 'Education') &&
                            <div className={`${SIDEBAR_CARD}`}>
                                <p className='text-slate-800 text-lg font-bold'>
                                    Education History
                                </p>
                                {!linkedin?.education || linkedin?.education?.length === 0 ?
                                    <div className='flex flex-col items-center py-10'>
                                        <p className='text-xs text-slate-400'>
                                            {person.first_name} doesn't have any past education listed
                                        </p>
                                    </div>
                                    :
                                    <ProfilePageEducationWidget
                                        education={linkedin.education}
                                        inPeek
                                        studentProfilePage
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
                <div className='flex flex-row gap-3'>
                    {APP_ADMINS.includes(this.props.authUser?.uid) &&
                        <Button onClick={this.openEditContactModal} variant={'secondary'} icon={SvgEdit}
                                className='flex-1'>
                            Edit Details
                        </Button>
                    }
                    {this.props.isSaved && !this.props.adminPortal ?
                        <Button onClick={this.deleteNetwork} variant={'secondary'} icon={SvgRemove} className='flex-1'>
                            Remove
                        </Button>
                        :
                        <></>
                    }
                    <EditContactModal
                        open={this.state.editContactModalOpen}
                        person={person}
                        updateNetwork={this.props.updateNetwork}
                        network={network}
                        close={this.closeEditContactModal}
                    />
                </div>
            </SidebarOverlayWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrapedNetworkingPeek);
