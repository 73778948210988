export const ADMIN_STUDENT_MANAGEMENT_TABLE_HEADERS = [
    {
        label: "",
        key: "actions",
        width: "minmax(60px, 80px)",
        isCentered: true,
    },
    {
        label: "Verified",
        key: "verification",
        width: "minmax(60px, 80px)",
        isCentered: true,
    },
    {
        label: "User Identity",
        key: "identity",
        width: "minmax(200px, 320px)",
    },
    {
        label: "Account Details",
        key: "account",
        width: "minmax(150px, 250px)",
    },
    {
        label: "Education",
        key: "education",
        width: "minmax(200px, 320px)",
    },
    {
        label: "Profile Links",
        key: "links",
        width: "minmax(80px, 120px)",
    },
    {
        label: "Clubs",
        key: "clubs",
        width: "minmax(150px, 250px)",
    },
    {
        label: "Bio",
        key: "bio",
        width: "minmax(150px, 250px)",
    },
    {
        label: "Location",
        key: "location",
        width: "minmax(100px, 175px)",
    },
    {
        label: "Job Information",
        key: "job",
        width: "minmax(150px, 250px)",
    },
    {
        label: "Experience",
        key: "experience",
        width: "minmax(200px, 320px)",
    },
    {
        label: "Diversity Information",
        key: "diversity",
        width: "minmax(150px, 250px)",
    },
    // TODO: Add column for profile status once we propagate that to the database
];
