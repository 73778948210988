import React, { useState, useEffect } from "react";
import ProfilePageExperienceWidget from "../../components/profilepage/ProfilePageExperienceWidget";
import ProfilePageEducationWidget from "../../components/profilepage/ProfilePageEducationWidget";
import SectionTabs from "../../components/atoms/SectionTabs";
import { wait } from "@testing-library/user-event/dist/utils";
import Spinner from "../../components/Spinner";
import SidebarOverlayWrapper from "../../components/atoms/SidebarOverlayWrapper";
import { SIDEBAR_CARD } from "../../utils/dummy";
import { searchLateralRecruitingPeople } from "../../api/recruiter/lateral-recruiting";
import PersonPeekHeader from "../../components/atoms/PersonPeekHeader";

const PersonPeek = (props) => {
    const [activeTab, setActiveTab] = useState("Experience");
    const [loading, setLoading] = useState(false);
    const [personLinkedin, setPersonLinkedin] = useState({});

    const changeTab = (tab) => {
        setActiveTab(tab);
    };

    const close = () => {
        wait(150).then(() => {
            props.close();
        });
    };

    const getPerson = async () => {
        if (!props.person?.id || props.hideStats) return;

        setLoading(true);
        const res = await searchLateralRecruitingPeople(props.authUser, [
            props.person.id,
        ]);
        const resData = res?.results || {};
        const firstKey = Object.keys(resData)[0];
        setLoading(false);
        setPersonLinkedin(resData?.[firstKey]?.linkedin || {});
    };

    useEffect(() => {
        getPerson();
    }, [props.person?.id]);

    const education = personLinkedin.education || [];
    const experiences = personLinkedin.experiences || [];
    const majors =
        personLinkedin.education
            ?.filter(
                (e) =>
                    e.degree_name?.startsWith("B.S.") ||
                    e.degree_name?.startsWith("B.A.") ||
                    e.degree_name?.startsWith("B.Eng.") ||
                    e.degree_name?.startsWith("B.Tech.") ||
                    e.degree_name?.startsWith("Bachelor") ||
                    e.degree_name?.startsWith("B.A.S.")
            )
            .map((e) => e.field_of_study) || [];

    let college = {};
    if (education.length > 0) {
        college = education[0];
    }
    const TABS = ["Experience"];
    return (
        <>
            <SidebarOverlayWrapper
                label={"Professional Details"}
                open={props.open}
                onClose={close}
            >
                {loading ? (
                    <div className="flex flex-col items-center justify-center flex-1 mb-[15vh]">
                        <Spinner />
                    </div>
                ) : (
                    <div className="flex flex-1 overflow-hidden">
                        {props.person && (
                            <PersonPeekHeader
                                userId={props.person.id}
                                firstName={props.person.first_name}
                                lastName={props.person.last_name}
                                linkedinUrl={props.person.linkedin}
                                profilePicUrl={props.person.profile_pic_url}
                                school={college.school}
                                majors={majors}
                            />
                        )}
                        <div className="mt-5">
                            <SectionTabs
                                activeTab={activeTab}
                                setActiveTab={changeTab}
                                tabs={TABS}
                            />
                        </div>

                        {activeTab === "Experience" && (
                            <div className="flex-1 overflow-y-scroll hide-scrollbar pt-3 gap-3 flex flex-col">
                                <div className={`${SIDEBAR_CARD}`}>
                                    <p className="text-slate-800 text-lg font-semibold">
                                        Work
                                    </p>
                                    {experiences.length === 0 ? (
                                        <div className="flex flex-col items-center py-10">
                                            <p className="text-xs text-slate-400">
                                                {personLinkedin.first_name}{" "}
                                                hasn't added past work
                                                experience
                                            </p>
                                        </div>
                                    ) : (
                                        <ProfilePageExperienceWidget
                                            experiences={
                                                personLinkedin.experiences
                                            }
                                            inPeek
                                        />
                                    )}
                                </div>
                                <div className={`${SIDEBAR_CARD}`}>
                                    <p className="text-slate-800 text-lg font-semibold">
                                        Education
                                    </p>
                                    {education.length === 0 ? (
                                        <div className="flex flex-col items-center py-10">
                                            <p className="text-xs text-slate-400">
                                                {personLinkedin.first_name}{" "}
                                                hasn't added past education
                                                experience
                                            </p>
                                        </div>
                                    ) : (
                                        <ProfilePageEducationWidget
                                            education={personLinkedin.education}
                                            inPeek
                                            studentProfilePage
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </SidebarOverlayWrapper>
        </>
    );
};

export default PersonPeek;
