import React, {Component} from "react";
import RecruiterJobPreviewCard from "../RecruiterJobPreviewCard";
import {connect} from "react-redux";
import SvgPlus from "../../components/icons/SvgPlus";
import FadeInOnScroll from "../../components/atoms/FadeInOnScroll";
import NewJobOrEventCard from "../atoms/NewJobOrEventCard";
import Spinner from "../../components/Spinner";
import {getRecruiterJobs} from "../../api/recruiter/jobs";
import {Button} from "../../components/atoms/Button";
import SectionTabs from "../../components/atoms/SectionTabs";
import { DateTime } from "luxon";

function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: () => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
            })
        },
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

class RecruiterJobsPage extends Component {
    state = {
        jobs: [],
        jobsLoading: true,
        activeTab: 'Upcoming'
    }

    getFilteredJobs = () => {
        const now = DateTime.now();

        const sortedJobs = [...this.state.jobs].sort((a, b) => {
            const deadlineA = DateTime.fromISO(a.deadline);
            const deadlineB = DateTime.fromISO(b.deadline);
           
            // Push invalid dates to the end
            if (!deadlineA.isValid) return 1;
            if (!deadlineB.isValid) return -1;
            
            // Sort by deadline if both are valid
            return deadlineA - deadlineB;
        });


        if (this.state.activeTab === 'Upcoming') {
            return sortedJobs.filter(job => {
                const deadline = DateTime.fromISO(job.deadline);
                return (deadline >= now && job.scope !== 'expired') || !deadline.isValid;
            });
        } else {
            return sortedJobs.filter(job => {
                const deadline = DateTime.fromISO(job.deadline);
                return  deadline < now || job.scope === 'expired';
            });
        }
    }

    componentDidMount = async () => {
        const result = await getRecruiterJobs(this.props.authUser);
        this.setState({jobs: result.jobs, jobsLoading: false});
        window.analytics.page("Recruiter Jobs Page");
    }

    render() {
        const TABS = ['Upcoming', 'Past'];

        return (
            <div className={`flex flex-col flex-1 p-5 gap-8`}>
                <div>
                    <div className='flex flex-row items-center justify-between border-b border-slate-200 pb-5'>
                        <div>
                            <p className='text-slate-800 text-2xl font-semibold'>
                                Jobs
                            </p>
                        </div>
                        <div className='flex flex-row items-center gap-2'>
                            <Button
                                icon={SvgPlus}
                                onClick={this.props.toggleCreateJobModal}
                            >
                                New Job
                            </Button>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            setActiveTab={(tab) => this.setState({ activeTab: tab })}
                            tabs={TABS}
                            lg
                        />
                    </div>
                </div>
                {this.state.jobsLoading ?
                    <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                        <Spinner />
                    </div>
                    :
                    <div className='grid grid-cols-3 gap-5'>
                        {this.getFilteredJobs().map((item, index) =>
                            <FadeInOnScroll key={item.id} triggerImmediately
                                            delay={(index * 100) + 10}>
                                <RecruiterJobPreviewCard item={item}/>
                            </FadeInOnScroll>
                        )}
                        <NewJobOrEventCard
                            index={this.getFilteredJobs().length}
                            label={'Post a new job'}
                            onClick={this.props.toggleCreateJobModal}
                        />
                    </div>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterJobsPage);
