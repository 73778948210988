import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import SvgArrow from "../components/icons/SvgArrow";
import {Button} from "../components/atoms/Button";
import SvgBriefcase from "../components/icons/SvgBriefcase";


class SectionHeader extends Component {
    render() {
        return (
            <div className='flex flex-row items-center justify-between w-full'>
                <div className='flex flex-row items-center gap-3 flex-1 mr-auto'>
                    {this.props.icon &&
                        <this.props.icon className={'w-5 h-5'}/>
                    }
                    <p className='text-xl font-semibold text-slate-800'>
                        {this.props.label}
                    </p>
                </div>
                {this.props.rightLabel &&
                    <Button icon={SvgArrow} size={'sm'} variant={'ghost'}
                            onClick={() => this.props.route ? this.props.history.push(this.props.route) : this.props.onClick()}>
                        {this.props.rightLabel}
                    </Button>
                }
            </div>
        )
    }
}

export default withRouter(SectionHeader)
