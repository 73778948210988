'use client'

import { useState } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import * as Checkbox from '@radix-ui/react-checkbox'
import { MinusIcon,  Cross2Icon } from '@radix-ui/react-icons'
import * as Dialog from "@radix-ui/react-dialog";
import SvgCheck from "../../components/icons/SvgCheck"
import WrappedTextInput from "../../components/atoms/WrappedTextInput"
import SvgSearchFeather from "../../components/icons/SvgSearchFeather"
import {Button} from "../../components/atoms/Button"



export default function MajorAdvancedSelector({ majors, maxToShow = 3, selectedMajors, setSelectedMajors }) {
  const [searchTerms, setSearchTerms] = useState({})

  const toggleMajor = (major) => {
    setSelectedMajors(prev => {
      const newSet = new Set(prev)
      if (newSet.has(major)) {
        newSet.delete(major)
      } else {
        newSet.add(major)
      }
      return newSet
    })
  }

  const toggleCategory = (category) => {
    setSelectedMajors(prev => {
      const newSet = new Set(prev)
      const categoryMajors = majors[category]
      const allSelected = categoryMajors.every(major => prev.has(major))

      if (allSelected) {
        categoryMajors.forEach(major => newSet.delete(major))
      } else {
        categoryMajors.forEach(major => newSet.add(major))
      }

      return newSet
    })
  }

  const getCategoryState = (category) => {
    const categoryMajors = majors[category]
    const selectedCount = categoryMajors.filter(major => selectedMajors.has(major)).length

    if (selectedCount === 0) return 'none'
    if (selectedCount === categoryMajors.length) return 'all'
    return 'some'
  }

  const handleSearch = (category, term) => {
    setSearchTerms(prev => ({ ...prev, [category]: term }))
  }

  const filterMajors = (categoryMajors, searchTerm) => {
    if (!searchTerm) return categoryMajors
    return categoryMajors.filter(major => 
      major.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }


  const removeSelected = (item) => {
    setSelectedMajors(prev => {
      const newSet = new Set(prev)
      if (majors[item]) {
        // If it's a category, remove all majors in that category
        majors[item].forEach(major => newSet.delete(major))
      } else {
        // If it's a major, just remove that major
        newSet.delete(item)
      }
      return newSet
    })
  }

  return (
    <Dialog.Root>
      <div className="w-full">
        <Dialog.Trigger className="w-full">
          <div className="min-h-[40px] w-full border rounded-lg px-4 py-2 flex flex-wrap gap-2 items-center">
            {selectedMajors.size === 0 ? (
              <span className="text-[rgba(0,0,0,0.5)] text-sm body2">Select majors...</span>
            ) : (
              <>
                {Object.entries(majors)
                  .filter(([category, categoryMajors]) => 
                    categoryMajors.some(major => selectedMajors.has(major))
                  )
                  .slice(0, maxToShow)
                  .map(([category, categoryMajors]) => {
                    const selectedCount = categoryMajors.filter(major => selectedMajors.has(major)).length;
                    return (
                      <div key={category} className="flex flex-row items-center gap-1 bg-slate-100 rounded-full px-2 py-1">
                        <span className="text-sm flex items-center">
                          <span className="line-clamp-1 max-w-[90px] text-left">{category}</span>
                          <span className="flex-shrink-0 text-slate-500 text-xs">
                            ({selectedCount})
                          </span>
                        </span>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            removeSelected(category);
                          }}
                          className="text-slate-500 hover:text-red-400"
                        >
                          <Cross2Icon className="h-3 w-3" />
                        </button>
                      </div>
                    );
                  })}
                {Object.entries(majors).filter(([category, categoryMajors]) => 
                  categoryMajors.some(major => selectedMajors.has(major))
                ).length > maxToShow && (
                  <div className="flex flex-row items-center gap-1 bg-slate-100 rounded-full px-2 py-1">
                    <span className="text-sm text-slate-500">
                      +{Object.entries(majors).filter(([category, categoryMajors]) => 
                        categoryMajors.some(major => selectedMajors.has(major))
                      ).length - maxToShow} more
                    </span>
                  </div>
                )}
                
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedMajors(new Set());
                  }}
                  className="ml-auto text-slate-500 hover:text-red-400"
                >
                  <Cross2Icon className="h-4 w-4" />
                </button>
              </>
            )}
          </div>
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/50 !z-[9998]" />
          <Dialog.Content className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-lg shadow-2xl h-auto max-h-[85vh] w-[95vw] max-w-lg !z-[9999] flex flex-col">
            <div className="flex-grow overflow-y-auto py-10 px-8">
              <Accordion.Root type="multiple" className="w-full grid grid-cols-1 gap-2">
                {Object.entries(majors).map(([category, categoryMajors]) => (
                  category === 'Other' ? (
                    <div key={category} className="border rounded-lg">
                      <div className="flex items-center w-full p-4">
                        <div className="flex items-center space-x-2">
                          <Checkbox.Root
                            id={`category-${category}`}
                            checked={getCategoryState(category) === 'all'}
                            onClick={() => toggleCategory(category)}
                            className="flex items-center justify-center"
                          >
                            <div className={`${getCategoryState(category) === 'all' ? 'checkbox-active' : 'checkbox border-[#00000030]'} border-[1px] rounded-md w-5 h-5`}>
                              {getCategoryState(category) === 'all' && (
                                <SvgCheck className="base-white-100" />
                              )}
                              {getCategoryState(category) === 'some' && (
                                <MinusIcon className="w-4 h-4 text-black" />
                              )}
                            </div>
                          </Checkbox.Root>
                          <span>{category}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Accordion.Item 
                      value={category} 
                      key={category}
                      className="border rounded-lg"
                    >
                      <Accordion.Header>
                        <Accordion.Trigger className="flex items-center w-full p-4 justify-between hover:bg-gray-50 rounded-lg">
                          <div className="flex items-center space-x-2">
                            <Checkbox.Root
                              id={`category-${category}`}
                              checked={getCategoryState(category) === 'all'}
                              onClick={(e) => {
                                e.stopPropagation()
                                toggleCategory(category)
                              }}
                              className="flex items-center justify-center"
                            >
                              <div className={`${getCategoryState(category) === 'all' ? 'checkbox-active' : 'checkbox border-[#00000030]'} border-[1px] rounded-md w-5 h-5`}>
                                {getCategoryState(category) === 'all' && (
                                  <SvgCheck className="base-white-100" />
                                )}
                                {getCategoryState(category) === 'some' && (
                                  <MinusIcon className="w-4 h-4 text-black" />
                                )}
                              </div>
                            </Checkbox.Root>
                            <span>{category}</span>
                          </div>
                          <svg 
                            className="w-4 h-4 transition-transform duration-200 transform accordion-[data-state=open]:rotate-180" 
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <polyline points="6 9 12 15 18 9" />
                          </svg>
                        </Accordion.Trigger>
                      </Accordion.Header>
                      <Accordion.Content className="px-4 pb-4">
                        <div className="pl-6 space-y-4">
                          <div className="mt-2">
                            <WrappedTextInput
                              type="text"
                              placeholder={`Search ${category} majors...`}
                              value={searchTerms[category] || ''}
                              onChange={(e) => handleSearch(category, e.target.value)}
                              className="input-light body2 font-regular w-full pl-44"
                              icon={
                                <SvgSearchFeather 
                                  width={20} 
                                  height={20}
                                  className="absolute text-slate-500"
                                  style={{left: 12, top: 10}}
                                />
                              }
                            />
                          </div>
                          <div className="space-y-2">
                            {filterMajors(categoryMajors, searchTerms[category] || '').map((major) => (
                              <div key={major} className="flex items-center space-x-2">
                                <Checkbox.Root
                                  id={`major-${major}`}
                                  checked={selectedMajors.has(major)}
                                  onCheckedChange={() => toggleMajor(major)}
                                  className="flex items-center justify-center"
                                >
                                  <div className={`${selectedMajors.has(major) ? 'checkbox-active' : 'checkbox border-[#00000030]'} border-[1px] rounded-md w-5 h-5`}>
                                    {selectedMajors.has(major) && (
                                      <SvgCheck className="base-white-100" />
                                    )}
                                  </div>
                                </Checkbox.Root>
                                <label
                                  htmlFor={`major-${major}`}
                                  className="body2 text-slate-500"
                                >
                                  {major}
                                </label>
                              </div>
                            ))}
                            {filterMajors(categoryMajors, searchTerms[category] || '').length === 0 && (
                              <p className="text-sm text-slate-500">No majors found matching your search.</p>
                            )}
                          </div>
                        </div>
                      </Accordion.Content>
                    </Accordion.Item>
                  )
                ))}
              </Accordion.Root>
            </div>

            <div className="flex-shrink-0 border-t !p-4 bg-white flex justify-end gap-3">
              <Dialog.Close asChild>
                <Button >
                  Done
                </Button>
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </div>
    </Dialog.Root>
  )
} 