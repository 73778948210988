import React, {PureComponent} from "react";
import SvgChevronRight from "../icons/SvgChevronRight";
import SvgLocation from "../icons/SvgLocation";
import SvgMoney from "../icons/SvgMoney";
import SvgDiversity from "../icons/SvgDiversity";
import {green, greenLight} from "../../utils/colors";
import SvgWhitelisted from "../icons/SvgWhitelisted";
import SvgInfo from "../icons/SvgInfo";
import {Link} from "react-router-dom";
import SvgCalendarUpcoming from "../icons/SvgCalendarUpcoming";
import RuDirectLabel from "../atoms/RuDirectLabel";
import SvgEdit from "../icons/SvgEdit";
import EditJobModal from "../atoms/modals/EditJobModal";
import {APP_ADMINS} from "../../utils/constants";
import TagComponent from "../atoms/TagComponent";
import SvgFlag from "../icons/SvgFlag";
import ReportIssueModal from "../atoms/modals/ReportIssueModal";
import CompanyLogo from "../atoms/CompanyLogo";
import { DateTime } from 'luxon';

class JobPageTitleWidget extends PureComponent {
    state = {
        location: "",
        editJobModalOpen: false,
    }

    toggleEditJobModal = () => {
        this.setState({editJobModalOpen: !this.state.editJobModalOpen})
    }

    componentDidMount = () => {
        this.updateInfo();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.listing !== prevProps.listing) {
            this.updateInfo();
        }
    }

    updateInfo = () => {
        const listing = this.props.listing || {};
        if (!!listing.city && !!listing.city.length) {
            if (listing.city.length === 1) {
                this.setState({location: listing.city[0] + ", " + listing.state[0]})
            } else {
                this.setState({location: "Multiple Locations"})
            }
        }
    }

    navigateToListingExternal = () => {
        const listing = this.props.listing || {};
        window.open(listing.link, '_blank');
    }

    render() {
        const listing = this.props.listing || {};
        const company = listing.company || {};
        const tags = listing.tags || [];
        const createdAt = DateTime.fromISO(listing.created_at);
        return (
            <div className='card'>
                <div className='row-ac gap-4'>
                    {this.props.loading ?
                        <div
                            className='br-12 shimmer border border-neutral-200'
                            style={{width: 80, height: 80}}/>
                        :
                        <CompanyLogo id={listing.company_id} className={'w-20 h-20'}/>
                    }
                    <div className=''>
                        {this.props.loading ?
                            <div className='shimmer br-4 w-80' style={{height: 24}}/>
                            :
                            <p className='title2 m-0 blacks-1 flex flex-row text-neutral-900'>
                                {listing.name} <span>
                        </span>
                            </p>
                        }
                        {this.props.loading ?
                            <div className='shimmer br-4 w-40 mt-4' style={{height: 16}}/>
                            :
                            <>
                                {!!listing.company_id ? 
                                    <Link to={{pathname: '/company/'+listing.company_id, state: {company_id: listing.company_id}}} className='row-ac primary'>
                                        <p className='body2-bold m-0'>
                                            {listing.company_name}
                                        </p>
                                        <SvgChevronRight width={24} height={24}/>
                                    </Link>
                                    :
                                    <p className='body2-bold m-0'>
                                        {listing.company_name}
                                    </p>
                                }
                        </>
                        }
                        {this.props.loading ?
                            <div className='shimmer br-4 w-[200px] mt-8' style={{height: 16}}/>
                            :
                        <div className='body2 base-black-50 row-ac mt-8'>
                            <SvgLocation width={16} height={16}/>
                            <p className='m-0 ml-4'>
                                {this.state.location} {!!this.state.location && createdAt.isValid && " • "}&nbsp;{createdAt.isValid? `Posted ${createdAt.toRelative()}` : null}
                            </p>
                            {listing.exclusive &&
                                <RuDirectLabel className={'mt-1 ml-3'}/>
                            }
                        </div>
                        }
                    </div>
                </div>
                <div className='body2-bold blacks-1 mt-24 flex flex-row items-center flex-wrap gap-5'>
                    <div className='flex flex-row items-center gap-1'>
                        {APP_ADMINS.includes(this.props.authUser?.uid) && listing.id ?
                            <>
                                <TagComponent
                                    label={`${this.props.savedCount} saved`}
                                    type='job-type'
                                />
                                <TagComponent
                                    label={`${this.props.appliedCount} applied`}
                                    type='job-type'
                                />
                            </>
                             : null
                        }
                        <TagComponent
                            label={listing.type === "Job" ? "Full Time" : listing.type}
                            type={'job-type'}
                        />
                        {!!listing.secondary_specialties && listing.secondary_specialties.length > 0 ? 
                            <div className='flex flex-row gap-1 whitespace-nowrap flex-wrap'>
                                {listing?.secondary_specialties.map(specialty =>
                                    <TagComponent
                                        label={specialty}
                                        type={'industry'}
                                    />
                                )}
                            </div>
                            :
                            <TagComponent
                                label={listing.industry}
                                type={'industry'}
                            />
                        }
                        {/* {tags.map(tag =>
                            <TagComponent
                                id={tag}
                                label={tag}
                                type={'random'}
                            />
                        )} */}
                    </div>
                    {(!!listing.deadline && DateTime.fromISO(listing.deadline).isValid) ?
                        <div className='row-ac'>
                            <SvgCalendarUpcoming width={16} height={16}/>
                            <p className='m-0 ml-8'>
                                Due by {DateTime.fromISO(listing.deadline).toFormat('MMM dd')}
                            </p>
                        </div>
                        : null}
                    {!!listing.salary ? 
                        <div className='row-ac'>
                            <SvgMoney width={16} height={16}/>
                            <p className='m-0 ml-8'>
                                {listing.salary}
                            </p>
                        </div>
                    : null}
                    {!!listing.work_model ?
                        <div className='row-ac'>
                            <SvgDiversity width={16} height={16}/>
                            <p className='m-0 ml-8'>
                                {listing.work_model}
                            </p>
                        </div>
                        : null}
                    {!!company.num_employees ? 
                        <div className='row-ac'>
                            <SvgLocation width={16} height={16}/>
                            <p className='m-0 ml-8'>
                                {company.num_employees} employees
                            </p>
                        </div>
                        : null
                    }
                    {this.props.listing.id && (
                        <ReportIssueModal reportType={"jobEdit"} authUser={this.props.authUser} job_id={listing.id}>
                            <div className='row-ac text-neutral-400 hover:text-red-400 cursor-pointer'>
                                <SvgFlag width={16} height={16}/>
                                <p className='m-0 ml-8'>
                                    Report Issue
                                </p>
                            </div>
                        </ReportIssueModal>
                    )}
                    {APP_ADMINS.includes(this.props.user?.username) && listing.id ?
                            <div className='row-ac primary cursor-pointer' onClick={this.toggleEditJobModal}>
                                <SvgEdit width={16} height={16}/>
                                <p className='m-0 ml-8'>
                                    Edit
                                </p>
                            </div>
                        : null}
                </div>
                {listing.whitelisted &&
                    <div className=' w-100p row-ac-jc mt-24 pv-4 br-8'
                         style={{backgroundColor: greenLight, color: green}}>
                        <SvgWhitelisted width={20} height={20}/>
                        <p className='m-0 body2-bold mh-4'>
                            You're whitelisted
                        </p>
                        <SvgInfo width={16} height={16}/>
                    </div>
                }
                {this.props.fetchJob && this.props.constants && (
                    <EditJobModal
                        listing={listing}
                        isOpen={this.state.editJobModalOpen}
                        closeModal={this.toggleEditJobModal}
                        authUser={this.props.authUser}
                        fetchJob={this.props.fetchJob}
                        constants={this.props.constants}
                    />
                )}
            </div>
        )
    }
}

export default JobPageTitleWidget
