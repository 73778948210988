import axios from '../axios';


export const rsvpEvent = async (user, event_id, attending) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const body = {
            event_id,
            attending
        }
        const res = await axios.put("/events", body, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const createStudentEvent = async (user, params) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/events", {params}, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getRecommendedEvents = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            // params: {company_id}
        };
        const res = await axios.get("/events", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getUserEvents = async (user) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.get("/events/user", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const deleteEvent = async (user, event_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {event_id}
        };
        const res = await axios.delete("/events", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getCompanyEvents = async (user, company_id) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {company_id}
        };
        const res = await axios.get("/events/company", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

export const getEvent = async (event_id) => {
    try {
        const config = {
            headers: {mode: "no-cors", "Access-Control-Allow-Origin": "*"},
            params: {event_id}
        }
        const res = await axios.get("/events/public", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

;

export const discoverEvents = async (user, params, skipSegment=true) => {
    if (!user) {
        return
    }
    const token = await user.getIdToken();
    try {
        if (!skipSegment) {
            window.analytics.track("Events Searched", {
                ...params,
            });
        }
        const config = {
            headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        };
        const res = await axios.post("/events/discover", params, config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}