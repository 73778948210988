import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import SvgPin from "../components/icons/SvgPin";
import SvgVideo from "../components/icons/SvgVideo";
import SvgPlus from "../components/icons/SvgPlus";
import SvgCalendar from "../components/icons/SvgCalendar";
import ProfilePicture from "./atoms/ProfilePicture";
import { DateTime } from 'luxon';


class RecruiterEventPreviewCard extends Component {

    navigateToEventListing = (eventId) => {
        this.props.history.push({
            pathname: `/event-listing/${eventId}`,
            params: {
                name: this.props.item?.name,
            }
        })
    }

    render() {
        const event = this.props.item || {};
        const images = event.images || [];
        const attendees = event.attendees || [];
        let date = null;
        if (!!event.event_date) {
            date = DateTime.fromISO(event.event_date)
        }
        const classYears = event.class_years;
        let yearText = '';

        if (classYears.length === 1) {
            yearText = `Class of '${classYears[0].toString().slice(-2)}`;
        } else if (classYears.length > 1) {
            const minYear = classYears[0].toString().slice(-2);
            const maxYear = classYears[classYears.length - 1].toString().slice(-2);
            yearText = `Class of '${minYear} - '${maxYear}`;
        }
        return (
            <div key={event.id}
                 onClick={() => this.navigateToEventListing(event.id)}
                 className='bg-white flex flex-col justify-between cursor-pointer hover:bg-white/25 shadow-lg shadow-slate-200 p-5 border border-slate-200 rounded-xl'
            >
                <div className=''>
                    <div className='flex flex-row justify-between items-start gap-2'>
                        <div className='flex-1'>
                            <div className='flex flex-row text-primary items-center gap-1.5'>
                                <SvgCalendar className={'w-3.5 h-3.5'}/>
                                <p className='text-sm font-semibold'>
                                    {yearText}
                                </p>
                            </div>
                            <p className='text-lg font-semibold text-slate-800' style={{lineHeight: 1.3}}>
                                {event.name}
                            </p>
                        </div>
                        {!!date ?
                            <div
                                className='flex flex-col items-center rounded-lg border-neutral-200 border overflow-hidden'>
                                <p className='body4-bold uppercase p-0.5 px-2 bg-neutral-100'>
                                    {date.toFormat("MMM")}
                                </p>
                                <p className='body2-bold p-0.5'>
                                    {date.toFormat("d")}
                                </p>
                            </div>
                            : null}
                    </div>
                    {!!event.location ?
                        <div className='flex flex-row items-center gap-2 text-slate-500 wrap'>
                            <div className='flex flex-row items-center gap-1'>
                                {event.location === "Remote" ? <SvgVideo className={'w-3.5 w-3.5'}/> :
                                    <SvgPin className={'w-3.5 w-3.5'}/>}
                                <p className='text-md '>
                                    {event.location}
                                </p>
                            </div>
                            {event.location !== "Remote" && !!event.city && !!event.state ?
                                <>
                                    <p>
                                        •
                                    </p>
                                    <p className='text-md '>
                                        {event.city[0]}, {event.state[0]}
                                    </p>
                                </>
                                : null}
                            {/* <p>
                                •
                            </p>
                            <p className='text-md '>
                                3h
                            </p> */}
                        </div>
                        :
                        <div className='flex flex-row items-center gap-2 text-slate-500 wrap'>
                            <div className='flex flex-row items-center gap-1'>
                                <SvgVideo className={'w-3.5 w-3.5'}/>
                                <p className='text-md '>
                                    Virtual
                                </p>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <div className='flex flex-row justify-between mt-3 items-center'>
                        <div className='flex flex-row items-center relative gap-3'>
                            {images.length === 0 ?
                                <div
                                    className='w-8 h-8 text-xs text-slate-400 text-center flex flex-col items-center justify-center aspect-square border-slate-200 bg-slate-100 border rounded-full'
                                >
                                    <p>
                                        N/A
                                    </p>
                                </div>
                                :
                                <div className='flex flex-row items-center relative'>
                                    {images.map((item, index) =>
                                        <ProfilePicture
                                            image={item}
                                            className={`${index !== 0 && '-ml-5'} z-1 border border-slate-200`}
                                        />
                                    )}
                                    {!!images.length ?
                                        <div
                                            className='absolute z-[0] border border-white bg-primary bottom-[-2px] right-[-4px] rounded-full p-0.5 cursor-pointer'>
                                            <SvgPlus className={'w-3 h-3 text-white'}/>
                                        </div>
                                        : null}
                                </div>
                            }
                            <p className='text-sm font-semibold text-slate-500'>
                                {attendees.length} attending
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(RecruiterEventPreviewCard)
