import React, {Component} from "react";
import {connect} from "react-redux";
import CompanyPageNetworkingWidget from "../../components/companypage/CompanyPageNetworkingWidget";
import RecruiterCompanyPagePerks from "../../components-recruiter/companypage/RecruiterCompanyPagePerks";
import CompanyPageJobs from "../companypage/CompanyPageJobs"
import CompanyPageEvents from "../companypage/CompanyPageEvents"
import RecruiterCompanyPageUpdatesSection from "../../components-recruiter/companypage/RecruiterCompanyPageUpdatesSection";
import RecruiterCompanyPageOverview from "../../components-recruiter/companypage/RecruiterCompanyPageOverview";
import RecruiterCompanyPageDeals from "../../components-recruiter/companypage/RecruiterCompanyPageDeals";
import SvgAssignment from "../icons/SvgAssignnment";
import SvgCalendarUpcoming from "../icons/SvgCalendarUpcoming";
import SvgSearchFeather from "../icons/SvgSearchFeather";
import AnimatedTabs from "../atoms/AnimatedTabs";
import UpgradedCompanyPageAbout from "../companypage/UpgradedCompanyPageAbout";
import UpgradedCompanyPageHeader from "../companypage/UpgradedCompanyPageHeader";
import UpgradedCompanyPageLifeAtSection from "../companypage/UpgradedCompanyPageLifeAtSection";
import UpgradedCompanyPageTestimonials from "../companypage/UpgradedCompanyPageTestimonials";
import UpgradedCompanyPageUpdates from "../companypage/UpgradedCompanyPageUpdates";
import UpgradedCompanyPageOverview from "../companypage/UpgradedCompanyPageOverview";
import UpgradedCompanyPageDeals from "../companypage/UpgradedCompanyPageDeals";
import UpgradedCompanyPagePerks from "../companypage/UpgradedCompanyPagePerks";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}


class StudentUpgradedCompanyPage extends Component {
    state = {
        activeTab: 'About'
    }

    changeTab = (activeTab) => {
        this.setState({activeTab});
    }

    render() {
        const company = this.props.company || {};
        const hidden =  company.hidden || [];
        return (
            <div className={`flex flex-col flex-1 relative p-5 gap-8`}>
                <img
                    className='absolute left-0 right-0 top-0 h-60 w-full object-cover z-[0]'
                    src={company.cover_photo || 'https://www.morganstanley.com/content/dam/msdotcom/articles/finance-technology-at-morgan-stanley/tw-finance-technology-at-morgan-stanley.jpg'}/>
                <div className='z-[2] mt-100 flex flex-col gap-5'>
                    <UpgradedCompanyPageHeader company={company}/>
                    <AnimatedTabs
                        activeTab={this.state.activeTab}
                        changeTab={this.changeTab}
                        tabs={[
                            {title: 'About', path: '/', icon: SvgSearchFeather},
                            {title: 'Jobs', path: '/', icon: SvgAssignment},
                            {title: 'Events', path: '/', icon: SvgCalendarUpcoming},
                        ]}
                    />
                    <div className='flex flex-row gap-5 overflow-y-scroll max-h-[80vh] mb-24'>
                        <div className='flex-1 flex flex-col gap-5 '>
                            {this.state.activeTab === "About" ? 
                                <>
                                    {hidden.includes("description") ? null : <UpgradedCompanyPageAbout company={company}/>}
                                    {hidden.includes("images") ? null : <UpgradedCompanyPageLifeAtSection company={company}/>}
                                    {hidden.includes("quotes") ? null : <UpgradedCompanyPageTestimonials company={company}/>}
                                    {hidden.includes("updates") ? null : <UpgradedCompanyPageUpdates company={company} />}
                                </>
                            : this.state.activeTab === "Jobs" ?
                                <CompanyPageJobs jobs={this.props.jobs} previousPage={this.props.previousPage}/>
                            :
                                <CompanyPageEvents events={this.props.events} company={company}/>
                            }
                        </div>
                        <div className={'w-[400px] flex flex-col gap-5'}>
                                <>
                                    <UpgradedCompanyPageOverview company={company}/>
                                    {hidden.includes("deals") ? null : <UpgradedCompanyPageDeals company={company}/>}
                                    {hidden.includes("perks") ? null : <UpgradedCompanyPagePerks company={company}/>}
                                </>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default connect(mapStateToProps)(StudentUpgradedCompanyPage);
