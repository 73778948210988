// Libraries
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {AddToCalendarButton} from 'add-to-calendar-button-react';

// Components
import CompanyEventItem from '../jobboard/CompanyEventItem';
import EventPageAboutEventWidget from '../eventpage/EventPageAboutEventWidget';
import EventPageTitleWidget from '../eventpage/EventPageTitleWidget';
import EventPageYourNetworkWidget from '../eventpage/EventPageYourNetworkWidget';
import JobPageAboutCompanyWidget from '../jobpage/JobPageAboutCompanyWidget';
import PageContentWrapper from '../wrappers/PageContentWrapper';
import PageHeaderWrapper from '../wrappers/PageHeaderWrapper';
import PageLeftWrapper from '../wrappers/PageLeftWrapper';
import PageLTRWrapper from '../wrappers/PageLTRWrapper';
import PageRightWrapper from '../wrappers/PageRightWrapper';
import Spinner from '../Spinner';
import SvgRemove from '../icons/SvgRemove';
import {APP_ADMINS} from '../../utils/constants';

// Api
import {deleteEvent, getEvent} from '../../api/student/events';
import RsvpNowButton from "../RsvpNowButton";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import Tooltip from "../atoms/modals/Tooltip";
import {Button} from "../atoms/Button";
import GoBack from "../atoms/GoBack";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        user: state.userReducer.user,
        constants: state.userReducer.constants
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}


function EventPage({authUser, user, setUser, history, constants}) {
    const {eventId} = useParams();
    const [loading, setLoading] = useState(true);
    const [event, setEvent] = useState({})
    const [validId, setValidId] = useState(false);


    useEffect(() => {
        fetchEvent();
    }, []);

    const fetchEvent = async () => {
        const event = await getEvent(eventId);

        // If event out of scope go back
        if (!event || event.deprecated) {
            setLoading(false);
            return;
        } else if (event.scope === "club") {
            const userClubs = user.clubs.map(club => club.id)
            const eventClubs = event.clubs.map(club => club.id);
            if (!userClubs.some(clubId => eventClubs.includes(clubId))) {
                setLoading(false);
                return;
            }
        } else if (event.scope === "personal") {
            if (user.username !== event.creator) {
                setLoading(false);
                return;
            }
        } else if (event.scope === 'expired') {
            setLoading(false);
            setValidId(false);
            return;
        }

        setValidId(true);
        setEvent(event);
        window.analytics.page("Event Page Viewed", {
            "event_id": eventId,
        })
        setLoading(false);
    }

    const delEvent = async () => {
        await deleteEvent(authUser, event.id);
        history.goBack();
    }

    const company = event.company || {};
    if (loading) {
        return (
            <div className='flex flex-col items-center justify-center min-h-[60vh]'>
                <Spinner/>
            </div>
        )
    }
    return (
        <PageContentWrapper>
            {(!validId) ?
                    <div className='flex h-full justify-center items-center'>
                        <p>404: Event does not exist or you do not have access</p>
                    </div>
                    :
                    <div className='flex flex-1 flex-col overflow-y-scroll py-5'>
                        <div className={'pt-2 w-full max-w-[1100px] mx-auto px-4'}>
                            <div className='flex flex-row items-center gap-3 mb-3'>
                                <GoBack/>
                            </div>
                            <EventPageTitleWidget listing={event} loading={loading} user={user} authUser={authUser}
                                                  fetchEvent={fetchEvent} constants={constants}/>
                            <div className='card flex flex-row gap-3 flex-1 w-full'>
                                <div className='flex-1 w-full'>
                                    <RsvpNowButton
                                        event={event}
                                    />
                                </div>
                                {!!event.event_date && !!event.attendees && event.attendees.includes(authUser.uid) ?
                                    <div className='flex flex-row flex-1 items-center gap-3'>
                                        <Tooltip
                                            toggleComponent={
                                                <Button
                                                    disabled={true}
                                                    variant={'secondary'}
                                                    icon={SvgCheckCircle}
                                                    className='w-full'
                                                >
                                                    Saved to Calendar!
                                                </Button>
                                            }
                                            label={'You already have this event saved'}
                                        />
                                        <div className='min-w-[150px]'>
                                            <AddToCalendarButton
                                                name={event.name}
                                                options={["Google"]}
                                                startDate={event.event_date.slice(0, 10)}
                                                startTime={!!event.start_time ? event.start_time : ""}
                                                endTime={!!event.end_time ? event.end_time : ""}
                                                timeZone="currentBrowser"
                                                buttonStyle={"neumorphism"}
                                                label={'Sync to Google'}
                                                size='2'
                                            >
                                            </AddToCalendarButton>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }

                                {APP_ADMINS.includes(authUser.uid) && false ?
                                    <Button
                                        variant={'secondary'}
                                        icon={SvgRemove}
                                        onClick={delEvent}>
                                        Delete Event
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                            <EventPageAboutEventWidget listing={event} loading={loading}/>
                            {!!company.name ? <JobPageAboutCompanyWidget authUser={authUser} listing={event} user={user}
                                                                         setUser={setUser}/> : null}
                        </div>
                    </div>
            }
        </PageContentWrapper>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
