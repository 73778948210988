import React, {PureComponent} from "react";
import RenderHtml from "../atoms/RenderHtml";
import TextBlockShimmer from "../loading/TextBlockShimmer";

function EventPageAboutEventWidget({listing, loading}) {
    return (
        <div className='card'>
            <div className='flex flex-row items-center gap-0.5 mb-2'>
                <h3 className='mt-0 title2 text-neutral-900'>
                    About this event
                </h3>
                {/* <div className='text-neutral-400 hover:text-neutral-900 cursor-pointer mb-1'
                     onClick={this.navigateToListingExternal}>
                    <SvgArrowOutward className={'w-5 h-5 mt-1.5 ml-1'}/>
                </div> */}
            </div>
            {loading ?
                <>
                    <TextBlockShimmer/>
                    <TextBlockShimmer/>
                    <TextBlockShimmer/>
                    <TextBlockShimmer/>
                    <TextBlockShimmer/>
                    <TextBlockShimmer/>
                </>
                :
                <p className='body2 base-black-70'>
                    <RenderHtml htmlString={listing.description || ""} />
                </p>
            }
        </div>
    )
}

export default EventPageAboutEventWidget
