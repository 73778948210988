import React, {useState, useEffect} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {Button} from "../../components/atoms/Button";
import WrappedTextInput from "../../components/atoms/WrappedTextInput";
import {filteredCollegeSearch} from "../../utils/typesense";
import CollapseComponent from "../../components/atoms/CollapseComponent";
import SvgSchool from "../../components/icons/SvgSchool";
import MultiSelectComponent from "../../components/atoms/MultiSelectComponent";
import Spinner from "../../components/Spinner";
import SelectComponent from "../../components/atoms/SelectComponent";
import SvgCheck from "../../components/icons/SvgCheck";
import SvgClose from "../../components/icons/SvgClose";
import { createSmartList } from "../../api/recruiter/lists";
import MultiSearchCompanies from "../../components/search/MultiSearchCompanies";
import MajorAdvancedSelector from "../atoms/MajorAdvancedSelector";
import { editList } from "../../api/recruiter/lists";


const SmartListModal = ({
    isOpen, 
    closeModal, 
    authUser, 
    constants, 
    tier,
    mode = 'create',
    initialData = null,
    onSave
}) => {
    const [schools, setSchools] = useState([]);
    const [schoolSearch, setSchoolSearch] = useState('');
    const [schoolSearchResults, setSchoolSearchResults] = useState([]);
    const [openSchoolCollapse, setOpenSchoolCollapse] = useState(false);
    const [classYears, setClassYears] = useState([]);
    const [selectedMajors, setSelectedMajors] = useState(new Set())
    const [name, setName] = useState("")
    const [gpa, setGpa] = useState(null);
    const [industries, setIndustries] = useState([]);
    const [gender, setGender] = useState(null);
    const [race, setRace] = useState([]);
    const [veteran, setVeteran] = useState(null);
    const [lgbtq, setLgbtq] = useState(null);
    const [disabled, setDisabled] = useState(null);
    const [authorized, setAuthorized] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentCompanies, setCurrentCompanies] = useState([]);
    const [previousCompanies, setPreviousCompanies] = useState([]);
    const [isIb, setIsIb] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const {major_categories} = constants ?? {}

    useEffect(() => {
        if (isOpen) {
            if (mode === 'edit' && initialData) {
                setName(initialData.name || '');
                setSchools(initialData.colleges || []);
                setClassYears(initialData.grades || []);
                setSelectedMajors(new Set(initialData.majors || []));
                setGpa(initialData.gpa || null);
                setIndustries(initialData.industries || []);
                setGender(initialData.gender || null);
                setRace(initialData.race || []);
                setVeteran(initialData.veteran || null);
                setLgbtq(initialData.lgbtq || null);
                setDisabled(initialData.disabled || null);
                setAuthorized(initialData.authorized || null);
                setCurrentCompanies(initialData.current_companies || []);
                setPreviousCompanies(initialData.previous_companies || []);
                setIsIb(initialData.is_ib || null);
            } else {
                setSchools([]);
                setClassYears([]);
                setSelectedMajors(new Set());
                setGpa(null);
                setIndustries([]);
                setGender(null);
                setRace([]);
                setVeteran(null);
                setLgbtq(null);
                setDisabled(null);
                setAuthorized(null);
                setCurrentCompanies([]);
                setPreviousCompanies([]);
                setIsIb(null);
            }
        }
    }, [isOpen, mode, initialData]);

    const handleSchoolSearchChange = async (event) => {
        const text = event.target.value;
        setSchoolSearch(text);
        setOpenSchoolCollapse(true);
        const results = await filteredCollegeSearch(text);
        setSchoolSearchResults(results);
    };


    const updateClassYears = (year) => {
        const newClassYears = classYears.includes(year)
            ? classYears.filter((item) => item !== year)
            : [...classYears, year];
        setClassYears(newClassYears);
    };

    const updateRace = (newRace) => {
        const newRaces = race.includes(newRace)
            ? race.filter((item) => item !== newRace)
            : [...race, newRace];
        setRace(newRaces);
    };

    const updateIndustries = (newIndustry) => {
        const newIndustries = industries.includes(newIndustry)
            ? industries.filter((item) => item !== newIndustry)
            : [...industries, newIndustry];
        setIndustries(newIndustries);
    };

    const updateGpa = (gpa) => {
        setGpa(gpa);
    };

    const addSchool = (school) => {
        if (!schools.includes(school)) {
            setSchools([...schools, school]);
        }
        setSchoolSearch('');
        setSchoolSearchResults([]);
        setOpenSchoolCollapse(false);
    };

    const removeSchool = (school) => {
        setSchools(schools.filter(item => item !== school));
    };

    const handleSave = async () => {
        const params = {
            name,
            grades: classYears,
            majors: Array.from(selectedMajors),
            colleges: schools,
            gpa,
            gender,
            race,
            industries,
            lgbtq,
            veteran,
            disabled,
            authorized,
            current_companies: currentCompanies,
            previous_companies: previousCompanies,
            is_ib: isIb
        }
        
        if (mode === 'edit') {
            setShowConfirmation(true);
        } else {
            await createSmartList(authUser, params);
            await onSave?.();
            closeModal();
        }
    };

    const handleConfirmedEdit = async () => {
        const params = {
            name,
            grades: classYears,
            majors: Array.from(selectedMajors),
            colleges: schools,
            gpa,
            gender,
            race,
            industries,
            lgbtq,
            veteran,
            disabled,
            authorized,
            current_companies: currentCompanies,
            previous_companies: previousCompanies,
            is_ib: isIb
        }
        
        await editList(authUser, initialData.id, 'smart', params);
        onSave?.();
        setShowConfirmation(false);
        closeModal();
    };

    const checkboxes = [
        {
            label: 'Veteran',
            active: veteran,
            onClick: () => setVeteran(!veteran)
        },
        {
            label: 'LGBTQ',
            active: lgbtq,
            onClick: () => setLgbtq(!lgbtq)
        },
        {
            label: 'Disability',
            active: disabled,
            onClick: () => setDisabled(!disabled)
        },
        {
            label: 'Authorized to work',
            active: authorized,
            onClick: () => setAuthorized(!authorized)
        },
        {
            label: 'IB',
            active: isIb,
            onClick: () => setIsIb(!isIb)
        }
    ]

    const INDUSTRY_TAGS = constants.industry_tags.map(tag => ({title: tag, value: tag}));
    return (
        <>
            <ModalComponent
                isOpen={isOpen}
                backgroundColor={'white'}
                header={mode === 'create' ? 'Create a Smart List' : 'Edit Smart List'}
                headerToggle
                size={'lg'}
                toggle={closeModal}
                showScrollBar
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={closeModal} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={handleSave}>
                            {mode === 'create' ? 'Create' : 'Save'}
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
                id={'smart-list-modal'}
            >
                <p>
                    Describe the kinds of candidates you're looking for, and we'll automatically generate a list of students
                    that match your criteria.
                </p>
                <div className='mt-5 flex flex-col gap-4'>
                    <div className='relative'>
                        <WrappedTextInput
                            type="text"
                            placeholder='List name'
                            className='input-light px-3 body2 w-full mb-4'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <div className='relative'>
                            <WrappedTextInput
                                type="text"
                                placeholder='Search by school...'
                                className='pl-44 input-light px-3 body2 w-full'
                                value={schoolSearch}
                                onChange={handleSchoolSearchChange}
                                icon={<SvgSchool width={20} height={20} className='absolute text-slate-500'
                                                 style={{left: 12, top: 10}}/>}
                            />
                            <div className='absolute top-12 left-0 right-0 z-[99]'>
                                <CollapseComponent
                                    isOpen={schoolSearch.length !== 0 && openSchoolCollapse}
                                    className='bg-white shadow-lg border border-neutral-200 rounded-lg'>
                                    {schoolSearchResults.map((res) => (
                                        <div
                                            key={res.id}
                                            onClick={() => addSchool(res.name)}
                                            className='flex flex-row items-center gap-3 py-2 cursor-pointer hover:bg-slate-100 rounded-xl px-2'>
                                            <div className='flex flex-col'>
                                                <p className='text-sm text-slate-800 line-clamp-2'>
                                                    {res.name}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </CollapseComponent>
                            </div>
                        </div>
                    </div>
                    {schools.length > 0 &&
                        <div className='flex flex-row flex-wrap gap-2'>
                            {schools.map(school => (
                                <div key={school} className='flex items-center gap-2 bg-slate-100 p-2 rounded'>
                                    <span className='text-sm font-medium'>{school}</span>
                                    <div className='rounded-full p-1 bg-slate-200 hover:bg-slate-300 cursor-pointer' onClick={() => removeSchool(school)} >
                                        <SvgClose className={'w-4 h-4'}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    <MultiSelectComponent
                        label='Class Year'
                        light
                        value={classYears}
                        scrollable={true}
                        setValue={updateClassYears}
                        clearFilter={() => setClassYears([])}
                        options={[{title: "2025"}, {title: "2026"}, {title: "2027"}, {title: "2028"}]}
                        node={document.getElementById('smart-list-modal')}
                    />
                    <MultiSearchCompanies
                        outerLabel='Current Companies'
                        selectedCompanies={currentCompanies}
                        setSelectedCompanies={setCurrentCompanies}
                        placeholder='Search for companies...'
                        limit={5}
                    />
                    <MultiSearchCompanies
                        outerLabel='Previous Companies'
                        selectedCompanies={previousCompanies}
                        setSelectedCompanies={setPreviousCompanies}
                        placeholder='Search for companies...'
                        limit={5}
                    />
                    <MajorAdvancedSelector 
                        majors={major_categories} 
                        selectedMajors={selectedMajors} 
                        setSelectedMajors={setSelectedMajors}
                    />
                    <SelectComponent
                        label='Min GPA'
                        light
                        value={gpa}
                        scrollable={true}
                        setValue={updateGpa}
                        clearFilter={() => setGpa(null)}
                        options={[{title: "3.9"}, {title: "3.8"}, {title: "3.7"}, {title: "3.6"}, {title: "3.5"}, {title: "3.4"}, {title: "3.3"}, {title: "3.2"}, {title: "3.1"}, {title: "3.0"}]}
                        node={document.getElementById('smart-list-modal')}
                    />
                    {/* <MultiSelectComponent
                        label='Industries'
                        light
                        value={industries}
                        scrollable={true}
                        setValue={updateIndustries}
                        clearFilter={() => setIndustries([])}
                        options={INDUSTRY_TAGS}
                        node={document.getElementById('smart-list-modal')}
                    /> */}
                   
                    <div className='flex flex-col gap-4'>
                        <div className='grid grid-cols-2 gap-2'>
                            <SelectComponent
                                label='Gender'
                                light
                                value={gender}
                                scrollable={true}
                                setValue={setGender}
                                clearFilter={() => setGender(null)}
                                options={[{title: "Male"}, {title: "Female"}, {title: "Prefer Not to Specify"}]}
                                node={document.getElementById('smart-list-modal')}
                            />
                            <MultiSelectComponent
                                label='Race'
                                light
                                value={race}
                                scrollable={true}
                                setValue={updateRace}
                                clearFilter={() => setRace([])}
                                options={[{title: "White",}, {title: "Hispanic or Latino",}, {title: "Black or African American",}, {title: "American Indian or Native American",},  {title: "Asian or Pacific Islander",}, {title: "Other",}, {title: "Prefer Not to Specify",}]}
                                node={document.getElementById('smart-list-modal')}
                            />
                        </div>
                        <div className='flex flex-row gap-2'>
                            {checkboxes.map(checkbox => {
                                return (
                                    <button onClick={checkbox.onClick}
                                            className={`flex flex-row items-center px-3 base-white-100`}
                                    >
                                        <div
                                            className={`${checkbox.active ? 'checkbox-active' : 'checkbox border-slate-400'} mr-12`}>
                                            {checkbox.active &&
                                                <SvgCheck className={'base-white-100'}/>
                                            }
                                        </div>
                                        <p className='body2 text-slate-500'>
                                            {checkbox.label}
                                        </p>
                                    </button>
                                )
                                })
                            }
                        </div>
                    </div>
                    {loading && (
                        <div className='mt-5 flex justify-center'>
                            <Spinner size={40}/>
                        </div>
                    )}
                </div>
            </ModalComponent>

            <ModalComponent
                isOpen={showConfirmation}
                backgroundColor={'white'}
                header={'Confirm Edit'}
                headerToggle
                size={'sm'}
                toggle={() => setShowConfirmation(false)}
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button onClick={() => setShowConfirmation(false)} variant='secondary'>
                            Cancel
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={handleConfirmedEdit}>
                            Proceed
                        </Button>
                    </div>
                }
                footerAlignment={'right'}
            >
                <p className="text-slate-700">
                    By editing this smart list, any jobs and events associated with this smart list will be updated as well. Are you sure you want to proceed?
                </p>
            </ModalComponent>
        </>
    );
};

export default SmartListModal;
