import React, {PureComponent} from "react";
import EducationListItem from "./EducationListItem";
import { COLLEGE_LOGOS_MAP } from "../../utils/constants";

class ProfilePageExperienceWidget extends PureComponent {

    render() {
        const student = this.props.student || {};
        const isStudentValid = !!student && Object.keys(student).length !== 0;
        let education = {
            starts_at: isStudentValid ? {year: !!student.grade ? student.grade - 4 : null} : null,
            ends_at: isStudentValid ? {year: student.grade} : null,
            school: student.college,
            degree_name: "",
            field_of_study: isStudentValid? student?.major.join(", ") : null,
            logo_url: "",
        }
        if (student.college in COLLEGE_LOGOS_MAP) {
            education.logo_url = COLLEGE_LOGOS_MAP[student.college]
        }
        if (this.props.education.length) {
            for (const edu of this.props.education) {
                if (String(edu?.ends_at?.year) === String(student.grade) || edu.school === student.college) {
                    education.starts_at = edu.starts_at;
                    education.ends_at = edu.ends_at;
                    education.degree_name = edu.degree_name;
                    
                    if (!education.logo_url) education.logo_url = edu.logo_url
                    if (!education.school) education.school = edu.school
                }
            }
        }

        return (
            <div className={!this.props.inPeek && 'card'}>
                {!this.props.inPeek &&
                    <div className='flex flex-row items-center justify-between'>
                        <h3 className={`mt-0 ${this.props.inPeek ? 'body1-bold' : 'title2'} text-neutral-900 mb-2`}>
                            Education
                        </h3>
                    </div>
                }
                {!this.props.student ? 
                    <>
                    {this.props.education?.map((education, i) =>
                        <EducationListItem
                            key={education}
                            education={education}
                            last={true}
                            sm={this.props.inPeek}
                            i={i}
                            studentProfilePage={this.props.studentProfilePage}
                            recruiter={this.props.recruiter}/>
                        )}
                    </>
                    :
                    <EducationListItem
                        key={education}
                        education={education}
                        last={true}
                        sm={this.props.inPeek}
                        i={0}
                        studentProfilePage={this.props.studentProfilePage}
                        recruiter={this.props.recruiter}/>
                }
                
            </div>
        )
    }
}

export default ProfilePageExperienceWidget
