import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import {logout} from "../../api/authentication";
import SvgUsers from "../icons/SvgUsers";
import Avatar from "boring-avatars";
import ProfilePicture from "../../components-recruiter/atoms/ProfilePicture";

class TopNavItem extends Component {
    navigateToRoute = (route) => {
        this.props.history.push(route)
    }

    render() {
        const item = this.props.item
        if (item.label === 'divider') {
            return (
                <div className={'w-full h-[1px] my-5 bg-white/10'}/>
            )
        }
        if (item.user) {
            return (
                <div
                    className={`group relative py-3 ml-1 pointer px-1 flex flex-row items-center h-10 my-1.5 hover:bg-white/10 active:bg-white/20 rounded-lg cursor-pointer`}
                >
                    <div className='border-1 border-slate-500 rounded-full'>
                        <ProfilePicture
                            image={item.img} userID={item.user.id} size={28}/>
                    </div>
                    <div
                        className='hidden w-[200px] group-hover:block absolute p-1 top-[40px] right-0 bg-white shadow-xl border border-slate-300 rounded-xl'>
                        <div className=' w-full hover:text-primary hover:bg-slate-100 rounded-lg'>
                            <Link to={'/profile'} className={'block w-full px-3 py-2'}>
                                Profile
                            </Link>
                        </div>
                        <div className='w-full hover:text-primary hover:bg-slate-100 rounded-lg'>
                            <a href={'mailto:support@recruitu.com'} target={'_blank'} className='block px-3 py-2 w-full'>
                                Contact Support
                            </a>
                        </div>
                        <div className='w-full hover:text-primary'>
                            <div onClick={logout} className='px-3 py-2 hover:bg-slate-100 rounded-lg'>
                                Logout
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (item.logout) {
            return (
                <div
                    onClick={() => logout()}
                    className={`py-3 pointer px-4 flex flex-row items-center h-10 my-1.5 hover:bg-white/10 active:bg-white/20 rounded-lg cursor-pointer`}
                >
                    <p className={`text-sm font-semibold mt-0.5`}>{item.label}</p>
                </div>
            );
        }
        if (item.linkToClub) {
            return (
                <Link to={{pathname: '/club/' + item.id, state: {club_id: item.id}}}
                      className={`py-1.5 px-2 flex flex-row items-center hover:bg-primary/10 active:bg-white/20 cursor-pointer ${this.props.groupActive && 'bg-slate-200'}`}
                >
                    {item.img ?
                        <img src={item.img}
                             style={{minWidth: 32}}
                             className={`w-8 h-8 aspect-square object-contain bg-white rounded-md border-[1px] bg-base-white-20 ${this.props.groupActive ? 'border-primary' : 'border-slate-200'}`}/>
                        :
                        <div
                            className="w-8 h-8 object-contain bg-white rounded-md border-[1px] bg-base-white-20 border-white/20">
                            <div
                                className={`rounded-xl relative overflow-hidden bg-slate-50 aspect-square h-min border border-slate-200`}>
                                <div
                                    className='absolute cover-parent bg-white/80 flex flex-col items-center justify-center'>
                                    <SvgUsers className={'w-6 h-6 mx-auto'}/>
                                </div>
                                <Avatar
                                    size={40}
                                    name={item.label}
                                    variant="marble"
                                    colors={['#9ba5fd', '#303a8a', '#10123b', '#264aff', '#3D4CB6']}
                                />
                            </div>
                        </div>
                    }
                </Link>
            );
        }
        return (
            <div
                onClick={() => item.onClick ? item.onClick() : this.navigateToRoute(item.route)}
                className={`py-3 lg:py-[9px] px-2 lg:px-4 flex flex-row items-center ${this.props.isActive ? 'bg-primary text-white' : 'hover:bg-primary/10'} ${item.label === "New Club" ? 'h-full py-[12px]' : 'rounded-lg'} cursor-pointer ${this.props.groupActive && 'bg-primary/10'}`}
            >
                <div className={`flex-col ${item.label !== 'New Club' && 'hidden lg:flex'}`}>
                    {item.icon &&
                        <div
                            className={` ${item.label === "New Club" && 'py-1'}`}>
                            <item.icon className={`w-5 h-5 mr-1 mt-0.5 ${item.label === "Lists" && 'scale-125'} `}/>
                        </div>
                    }
                    {item.user &&
                        <div className={`w-6 h-6 scale-[1.3]`}>
                            <ProfilePicture userID={item.user.username} image={item.img} size={24}
                                            className={'border border-slate-200'}/>
                        </div>
                    }
                </div>
                {(item.label !== 'New Club' && !!item.label) &&
                    <div
                        className={''}
                        style={{transition: 'width 300ms ease'}}
                    >
                        <p className={`text-xs lg:text-sm font-semibold mt-0.5`}>{item.label}</p>
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(TopNavItem);
