import React, {Component} from "react";
import CompanyLogo from "../atoms/CompanyLogo";
import {truncateStringFullWord} from "../../utils/strings";
import SvgCalendar from "../icons/SvgCalendar";
import TagComponent from "../atoms/TagComponent";
import {DateTime} from 'luxon';
import Tooltip from "../atoms/modals/Tooltip";
import {editTracker} from "../../api/student/trackers";
import RuDirectLabel from "../atoms/RuDirectLabel";
import RsvpNowButton from "../RsvpNowButton";
import Checkbox from "../atoms/Checkbox";
import {Button} from "../atoms/Button";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
    };
}

const TAGS = {
    1: "Recommended for your club",
    2: "Exclusive to your club"
}

class CompanyEventItem extends Component {
    toggleCheckbox = async () => {
        const newTracker = {...this.props.tracker};
        const newStatus = !newTracker.status
        newTracker.status = newStatus
        this.props.updateTracker(newTracker);
        await editTracker(this.props.authUser, newTracker.id, {status: newStatus});
    }

    htmlToPlainText(html) {
        // Create a temporary DOM element to parse the HTML string
        let tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
    
        // Use textContent to extract only the plain text
        return tempDiv.textContent || tempDiv.innerText || "";
    }

    render() {
        const event = this.props.item || {};
        const deadline = DateTime.fromISO(event.event_date);
        const createdAt = DateTime.fromISO(event.created_at);
        const rsvpDate = event.rsvp_date ? DateTime.fromISO(event.rsvp_date) : null;

        const classYears = event.class_years || [];
        classYears.sort();
        let yearText = '';

        if (classYears.length === 1) {
            yearText = `Class of '${classYears[0].toString().slice(-2)}`;
        } else if (classYears.length > 1) {
            const minYear = classYears[0].toString().slice(-2);
            const maxYear = classYears[classYears.length - 1].toString().slice(-2);
            yearText = `Class of '${minYear} - '${maxYear}`;
        }
        return (
            <div onClick={() => {
                if (!event.deprecated) this.props.openEventPage(event)
            }}
                 className={` ${event.deprecated ? "opacity-50" : ""}
                 ${this.props.inPost ?
                     'rounded-lg p-3 shadow-none border-slate-200 border min-w-[350px]  w-full hover:border-slate-300'
                     :
                     this.props.list ?
                         'border card mb-0 bg-white border-slate-200 rounded-xl p-3'
                         :
                         'card mb-0  hover-card gap-2 flex flex-col justify-between p-3'} 
                     cursor-pointer ${this.props.noShadow && 'shadow-none'}`}
            >
                <div>
                    {event.exclusive && !this.props.inPost &&
                        <RuDirectLabel className={'ml-auto mb-2'} label={`RecruitU ${event.company_name === "The Carlyle Group" ? "Exclusive": "Partner"}`}/>
                    }
                    <div className='flex flex-row justify-between'>
                        <div className='flex flex-row items-center gap-3'>
                            <div className='w-20 h-20 relative'>
                                <CompanyLogo id={event.company_id} className={'w-20 h-20'}/>
                            </div>
                            <div className='flex flex-col'>
                                <div className='flex flex-row items-center'>
                                    {!!event.deprecated ?
                                        <div className='flex flex-row text-primary items-center gap-1.5'>
                                            <Tooltip
                                                toggleComponent={
                                                    <span
                                                        className='bg-slate-200 px-2 rounded-md inline mr-a my-auto inline'>
                                            <p className='text-xs text-slate-500'>
                                                Expired
                                            </p>
                                        </span>
                                                }
                                                label={'This event has been removed from RecruitU. You can no longer navigate to the full event page.'}
                                            />
                                        </div>
                                        :
                                        !!yearText ?
                                            <div className='flex flex-row text-primary items-center gap-1.5'>
                                                <SvgCalendar className={'w-3 h-3'}/>
                                                <p className='text-xs font-semibold'>
                                                    {yearText}
                                                </p>
                                            </div>
                                            : null}
                                    {!!this.props.tag ?
                                        <div className={this.props.grid && 'absolute top-0 right-0'}>
                                            <div
                                                className='flex flex-col items-center rounded-lg border-slate-200 border'>
                                                <TagComponent
                                                    label={TAGS[this.props.tag]}
                                                    type={'reason'}
                                                />
                                            </div>
                                        </div>
                                        : null}
                                </div>
                                <p className='text-md font-semibold'>
                                    {event.name}
                                </p>
                                <p className='text-sm text-slate-500'>
                                    {event.company_name}
                                </p>
                                <div className='flex flex-row items-center gap-2 flex-1 truncate mb-1'>
                                        {createdAt.isValid &&
                                            <div className='flex flex-row gap-1 flex-1 truncate'>
                                                <p className='text-xs text-slate-500'>
                                                    Posted: {createdAt.toRelative() || ''}
                                                </p>
                                            </div>
                                        }
                                        {/* <div className='mt-0.5'>
                                            <SourceDisplay item={event}/>
                                        </div> */}
                                    </div>
                                <div className='mr-a mt-1 flex flex-row items-center gap-2 wrap mb-3'>
                                    <TagComponent label={event.type}/>
                                    <TagComponent label={event.industry}/>
                                    
                                </div>
                                
                            </div>
                        </div>
                        <div className='flex flex-row items-center gap-5'>
                            <div className='flex flex-row-reverse gap-2'>
                                {deadline.isValid ?
                                    <div className='flex flex-col gap-1 items-center'>
                                        <div className='flex flex-col items-center bg-white rounded-lg border-slate-200 border'>
                                            <p className='text-xs font-semibold uppercase px-3 bg-slate-100'>
                                                {deadline.toFormat('MMM')}
                                            </p>
                                            <p className='body2-bold p-0.5'>
                                                {deadline.toFormat('dd')}
                                            </p>
                                        </div>
                                        <p className='text-neutral-500 text-2xs'>
                                            Event Date
                                        </p>
                                    </div>
                                    :
                                    null
                                }
                                {rsvpDate &&
                                    <div className='flex flex-col gap-1 items-center'>
                                        <div className='flex flex-col items-center bg-white rounded-lg border-slate-200 border'>
                                            <p className='text-xs font-semibold uppercase px-3 bg-slate-100'>
                                                {rsvpDate.toFormat('MMM')}
                                            </p>
                                            <p className='body2-bold p-0.5'>
                                                {rsvpDate.toFormat('dd')}
                                            </p>
                                        </div>
                                        <p className='text-neutral-500 text-2xs'>
                                            RSVP By
                                        </p>
                                    </div>
                                }
                            </div>
                        {(event.attendees?.includes(this.props.authUser.uid) && !this.props.tracker) && !this.props.isAdmin &&
                                <Button
                                    disabled={true}
                                    variant='secondary'
                                    icon={SvgCheckCircle}
                                >
                                    Saved
                                </Button>
                            }
                            {!this.props.inPost &&
                                <>
                                    {!!this.props.tracker ?
                                        <Checkbox
                                            checked={this.props.tracker.status}
                                            onClick={this.toggleCheckbox}
                                            label={'Registered'}
                                        />
                                        :
                                        <RsvpNowButton
                                            event={event}
                                        />
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <p className='text-sm text-slate-500 mb-auto line-clamp-2 mt-2'>
                        {truncateStringFullWord(this.htmlToPlainText(event.description), this.props.list ? 500 : 200)}
                    </p>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(CompanyEventItem)
