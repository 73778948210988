import React, {PureComponent} from "react";
import JobPageAboutCompanyWidget from "../jobpage/JobPageAboutCompanyWidget";
import JobPageAboutJobWidget from "../jobpage/JobPageAboutJobWidget";
import JobPageTitleWidget from "../jobpage/JobPageTitleWidget";
import {getJob} from "../../api/student/jobs";
import {createApplication, editApplication, getApplicationCount, getJobApplication} from "../../api/student/applications";
import {getCompanyPeople} from "../../api/student/people";
import {connect} from "react-redux";
import SvgArrow from "../icons/SvgArrow";
import {Link} from "react-router-dom";
import {wait} from "@testing-library/user-event/dist/utils";
import json from "../../img/lottie/check.json";
import LottieWrapper from "../atoms/LottieWrapper";
import ModalComponent from "../atoms/modals/ModalComponent";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import ApplyNowButton from "../ApplyNowButton";
import Tooltip from "../atoms/modals/Tooltip";
import SvgCheckCircle from "../icons/SvgCheckCircle";
import SvgAddCircle from "../icons/SvgAddCircle";
import {toast} from "react-toastify";
import {TOAST_OPTIONS, APP_ADMINS} from "../../utils/constants";
import {Button} from "../atoms/Button";
import GoBack from "../atoms/GoBack";
import DownloadPdfButton from "../atoms/DownloadPdfButton";
import PlainTextInputModal from "../modals/PlainTextInputModal";
import { isUserQualified } from "../../utils/profileUtils";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        user: state.userReducer.user,
        constants: state.userReducer.constants
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        setPath: (path) => {
            dispatch({
                type: "SET_PATH",
                path,
            })
        },
    }
}

class JobPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            job: {},
            application: {},
            previousPage: null,
            processingPrev: false,
            processingNext: false,
            people: [],
            jobLoading: true,
            noAccess: false,
            successModalOpen: false,
            codeModalOpen: false,
            appliedCount: 0,
            savedCount: 0,
        };
    }

    componentDidMount() {
        this.props.setPath("");
        this.fetchJob();
        this.getPossibleApplication();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.fetchJob();
            this.getPossibleApplication();
        }
    }

    fetchJob = async () => {
        this.setState({jobLoading: true})
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const jobId = lastArg.split("?")[0];
        const result = await getJob(jobId);

        const user = this.props.user;
        const job = result.job;
        if (!job || job.deprecated) {
            this.setState({noAccess: true});
            return;
        } else if (job.scope === "club") {
            const userClubs = user.clubs.map(club => club.id)
            const jobClubs = job.clubs.map(club => club.id);
            if (!userClubs.some(clubId => jobClubs.includes(clubId))) {
                this.setState({noAccess: true});
                return;
            }
        } else if (job.scope === "personal") {
            if (!!job.creator && user.username !== job.creator) {
                this.setState({noAccess: true});
                return;
            }
        } else if (job.scope === 'expired') {
            this.setState({noAccess: true});
            return;
        } 
        
        // Check job permissions to view
        this.setState({noAccess: job.qualified_only, codeModalOpen: !!job.code});
        const qualified = isUserQualified(job, user);
        if (qualified) {
            this.setState({noAccess: false, codeModalOpen: false});
        } 

        this.getPeople(job.company_id);
        this.setState({job, jobLoading: false});
        window.analytics.page("Job Page Viewed", {
            "job_id": jobId,
        })

        if (APP_ADMINS.includes(user.id)) {
            const counts = await getApplicationCount(this.props.authUser, jobId);
            this.setState({savedCount: counts.to_apply, appliedCount: counts.applied});
        }
    }

    submitCode = (e) => {
        if (e === this.state.job.code) {
            this.setState({noAccess: false});
        }
        this.setState({codeModalOpen: false});
    }

    getPossibleApplication = async () => {
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const jobId = lastArg.split("?")[0];
        const app = await getJobApplication(this.props.authUser, jobId);
        this.setState({application: app.application});
    }

    getPeople = async (companyId) => {
        const result = await getCompanyPeople(this.props.authUser, companyId);
        this.setState({people: result.people});
    }

    startApplication = async (answers = null, personal_status = "To apply") => {
        try {
            const job = this.state.job;
            const result = await createApplication(this.props.authUser, {
                company_id: job.company_id,
                job_id: job.id,
                answers,
                personal_status,
            });
            this.props.updateApplication(result.application);
            if (personal_status === "To apply") { 
                toast.promise(
                    Promise.resolve('Application saved'),
                    {
                        pending: 'Adding application...',
                        success: 'Application saved',
                        error: 'Failed to save application',
                    },
                    TOAST_OPTIONS
                );
            } else {
                this.setState({successModalOpen: true})
            }
            window.analytics.track("Saved Job", {
                "job_id": job.id
            })
        } catch (error) {
            toast.error('Failed to save application to tracker', TOAST_OPTIONS);
        }
    };

    closeAllModals = () => {
        this.setState({successModalOpen: false})
    };

    navigateNextJob = () => {
        this.setState({processingNext: true})
        wait(600).then(() =>
            this.setState({processingNext: false})
        )
        const applications = Object.values(this.props.applications);
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const currentJobId = lastArg.split("?")[0];
        const currentJobIndex = applications.findIndex((app) => app.job_id === currentJobId);
        let nextJob = applications[currentJobIndex + 1];
        if (!nextJob) {
            nextJob = applications[0];
        }
        this.props.history.push({
            pathname: `/job/` + nextJob.job_id,
            state: {
                previousPage: {
                    location: "home",
                    origin: "home",
                }
            }
        })
    }

    navigatePrevJob = () => {
        this.setState({processingPrev: true})
        wait(600).then(() =>
            this.setState({processingPrev: false})
        )
        const applications = Object.values(this.props.applications);
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const currentJobId = lastArg.split("?")[0];
        const currentJobIndex = applications.findIndex((app) => app.job_id === currentJobId);
        let nextJob = applications[currentJobIndex - 1];
        if (!nextJob) {
            nextJob = applications[applications.length - 1];
        }
        this.props.history.push({
            pathname: `/job/` + nextJob.job_id,
            state: {
                previousPage: {
                    location: "home",
                    origin: "home",
                }
            }
        })
    }

    editApplication = async (applicationId, jobId, params) => {
        const newApplication = {...this.props.applications[jobId]}
        for (const key of Object.keys(params)) {
            newApplication[key] = params[key];
        }
        this.props.updateApplication(newApplication)
        await editApplication(this.props.authUser, applicationId, params);
        this.setState({successModalOpen: params["personal_status"] === "Applied"});

    }

    determineHeaderTitle = () => {
        const previousPage = this.props.location.state?.previousPage.location
        const origin = this.props.location.state?.previousPage?.origin
        if (previousPage === "home") {
            return (
                <div className='row-ac'>
                    <p className='m-0 base-white-50 body3'>
                        <Link to={{pathname: "/"}}> Home</Link> / <span className='base-white-100 body3-bold'>Job</span>
                    </p>
                </div>)
        } else if (previousPage === "jobboard") {
            return (<div className='row-ac'>
                <p className='m-0 base-white-50 body3'>
                    <Link to={{pathname: "/jobs"}}>Job Board</Link> / <span
                    className='base-white-100 body3-bold'>Job</span>
                </p>
            </div>)
        } else if (previousPage === "company") {
            const companyData = this.props.location.state?.previousPage?.data.company;
            return (<div className='row-ac'>
                <p className='m-0 base-white-50 body3'>
                    <Link to={{pathname: "/jobs"}}>Job Board</Link> / <Link
                    to={{
                        pathname: '/company/' + companyData.id,
                        state: {company: companyData.id}
                    }}>Company </Link> / <span
                    className='base-white-100 body3-bold'>Job</span>
                </p>
            </div>)
        }
    }
    

    render() {
        this.determineHeaderTitle()
        const listing = this.state.job || {};
        const classYears = listing.class_years || [];
        const enabled = !listing.qualified_only || classYears.includes(this.props.user.grade)
        const previousPage = this.props.location.state?.previousPage.location;
        const application = this.props.applications[this.state.job.id];
        const jobPdfPath = listing.job_pdf_path
        if (this.state.noAccess) {
            return (
                <div className='flex h-full justify-center items-center'>
                    <p>404: This job does not exist or you don't have access to it.</p>
                    <PlainTextInputModal
                        isOpen={this.state.codeModalOpen}
                        title={'What is the access code for this job?'}
                        placeholder={'...'}
                        value={this.state.code}
                        closeModal={() => this.setState({codeModalOpen: false})}
                        save={this.submitCode}
                        disabled={!this.state.code}
                    />
                </div>
            )
        }
        return (
            <PageContentWrapper>
                <div className={'pt-2 w-full max-w-[1100px] mx-auto px-4 pt-5 overflow-y-scroll pb-5'}>
                    <div className='flex flex-row items-center gap-3 mb-3'>
                        <GoBack/>
                    </div>
                    <JobPageTitleWidget listing={listing} previousPage={previousPage} user={this.props.user}
                                        loading={this.state.jobLoading} constants={this.props.constants}
                                        authUser={this.props.authUser} fetchJob={this.fetchJob} savedCount={this.state.savedCount}
                                        appliedCount={this.state.appliedCount}/>
                    <div className='card flex flex-row gap-3'>
                        {(application && !listing.link && application.personal_status !== "To apply")? null :
                            <div className='flex flex-1'>
                                <ApplyNowButton
                                    startApplication={this.startApplication}
                                    editApplication={this.editApplication}
                                    job={listing}
                                    applications={this.props.applications}

                                />
                            </div>
                        }
                        {application ?
                            <div className="flex flex-1">
                                <Tooltip
                                    toggleComponent={
                                        <Button
                                            variant={'secondary'}
                                            icon={SvgCheckCircle}
                                            className='opacity-50 cursor-not-allowed'>
                                            {this.state.job.apply_direct && application?.personal_status != "To apply" ? "Application Submitted!" : "Saved"}
                                        </Button>
                                    }
                                    label={this.state.job.apply_direct && application?.personal_status != "To apply" ? 'You have already applied to this job on RecruitU' : 'You already have this job saved'}
                                />
                            </div>
                            :
                            <>
                                <div className="flex flex-1">
                                    <Tooltip
                                        toggleComponent={
                                            <Button
                                                variant={'secondary'}
                                                icon={SvgAddCircle}
                                                onClick={() => enabled && this.startApplication()}
                                                className={!enabled && 'opacity-50'}
                                            >
                                                Save
                                            </Button>
                                        }
                                        label={enabled ? 'Save this to your saved jobs' : 'Your class year is not eligible to apply to this job'}
                                    />
                                </div>
                            </>
                        }
                        {this.props.applications[this.state.job.id] &&
                            <Link to={'/jobs?activeSubTab=Saved'}>
                                <Button variant={'secondary'} icon={SvgArrow}>
                                    View in Saved Jobs
                                </Button>
                            </Link>
                        }
                        {jobPdfPath &&
                            <DownloadPdfButton pdfPath={jobPdfPath} />
                        }
                    </div>
                    <JobPageAboutJobWidget listing={listing} loading={this.state.jobLoading}/>
                    {/* {listing.scope === "public" && clubs.length &&
                            <JobPagePostJobWidget listing={listing}/>} */}
                    {!!listing.company_id ?
                        <JobPageAboutCompanyWidget listing={listing} authUser={this.props.authUser}
                                                   user={this.props.user} setUser={this.props.setUser}/>
                        : null}
                    <ModalComponent
                        showScrollBar
                        isOpen={this.state.successModalOpen}
                        backgroundColor={"white"}
                        header={"Application Submitted!"}
                        headerToggle
                        size={"sm"}
                        toggle={this.closeAllModals}
                        FooterComponent={
                            <div className="flex flex-row w-full justify-center items-center gap-3">
                                <Button variant={"secondary"} onClick={this.closeAllModals}>
                                    Close
                                </Button>
                            </div>
                        }
                    >
                        <div className="hue-rotate-[120deg]">
                            <LottieWrapper
                                loop={false}
                                json={json}
                                width={100}
                                height={100}
                            />
                        </div>
                    </ModalComponent>
                </div>


            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobPage);
