import React, {PureComponent} from "react";
import SvgSearch from "../icons/SvgSearch";
import {connect} from "react-redux";
import JobBoardLoading from "../loading/JobBoardLoading";
import {SEARCH_STUDENT_TABLE_HEADERS, MAJOR_OPTIONS,} from "../../utils/dummy";
import {NUM_STUDENTS_SHOWN} from "../../utils/constants";
import StudentSearchListItem from "../StudentSearchListItem";
import {filteredUserSearch} from "../../utils/typesense";
import SearchHeaderWrapper from "../../components-recruiter/atoms/SearchHeaderWrapper";
import RecruiterDashboardTable from "../../components-recruiter/dashboard/RecruiterDashboardTable";
import {Button} from "../atoms/Button";
import SvgChevronDown from "../icons/ChevronDown";
import SvgTrash from "../icons/SvgTrash";
import {createSavedSearch, deleteSearch, getSavedSearches} from "../../api/recruiter/saved_searches";
import SelectClubListModal from "../../components-recruiter/modals/SelectClubListModal";
import {pluralizeString} from "../../utils/strings";
import {searchUsers} from "../../api/recruiter/users";
import {BsSortAlphaDown, BsSortAlphaDownAlt} from "react-icons/bs";
import FiltersSidebar from "../../components-recruiter/atoms/FiltersSidebar";
import MultiSearchSchools from "../search/MultiSearchSchools";
import MultiSearchCompanies from "../search/MultiSearchCompanies";
import MultiSearchClubs from "../search/MultiSearchClubs";
import MultiSearchRace from "../search/MultiSearchRace";
import DashboardLoading from "../../components-recruiter/atoms/DashboardLoading";


function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        constants: state.userReducer.constants,
        tier: state.userReducer.tier,
    };
}

const initialFilters = {
    classYears: [],
    majors: [],
    gpa: null,
    college: [],
    gender: null,
    race: [],
    veteran: null,
    lgbtq: null,
    disabled: null,
    authorized: null,
    isIb: null,
    search: "",
    sortBy: false,
    clubIds: [],
    currentCompanies: [],
    previousCompanies: [],
    page: 1,
    searchSortState: 0,
    schoolSortState: 0,
    verticals: [],
};


class RecruiterStudentSearch extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            studentPreview: {},
            shortlisted: false,
            students: [],
            originalStudents: [],
            loading: true,
            moreStudentsLoading: false,
            studentPreviewOpen: false,
            grid: false,
            page: 1,
            hideMoreStudents: false,
            selectedCandidateId: null,
            isViewingSavedSearches: false,
            isSavingSearch: false,
            savedSearches: [],
            selectedStudents: {},
            isSelectListModalOpen: false,
            selectAllChecked: false,
            verticals: [],
            ...initialFilters
        };
    }

    previewStudent = (student) => {
        this.setState({studentPreview: student, studentPreviewOpen: true})
    }

    closeStudentPreview = () => {
        this.setState({studentPreviewOpen: false})
    }

    onSelectStudentChange = (student) => {
        if (this.state.selectedStudents[student.id]) {
            const {[student.id]: _, ...selectedStudents} = this.state.selectedStudents;
            this.setState({selectedStudents})
        } else {
            this.setState({selectedStudents: {...this.state.selectedStudents, [student.id]: student}})
        }
    }

    isStudentSelected = (studentId) => {
        return !!this.state.selectedStudents[studentId]
    }

    componentDidMount = async () => {
        this.setState({loading: true})

        const clubIds = this.props.tier === "blk" ? ["baeZVpmndkm8fCNGFIuZ"] : [];
        const results = await searchUsers(this.props.authUser, this.state.search, 1, clubIds);
        this.setState({loading: false, students: results.students, originalStudents: results.students, clubIds: clubIds});

        const queryParameters = new URLSearchParams(window.location.search);
        const searchId = queryParameters.get('searchId', "");
        this.getSearches(searchId);

    }

    getSearches = async (searchId) => {
        const res = await getSavedSearches(this.props.authUser);
        this.setState({savedSearches: res.searches});

        if (!!searchId) {
            const items = res.searches.filter(search => search.id === searchId);
            if (items.length) {
                this.applySearch(items[0]);
            }
        }
    }

    handleSearchChange = (event) => {
        if (!this.props.showPaywall) {
            const text = event.target.value;
            this.setState({search: text});

            this.search(text, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
        }
    }

    updateClassYears = (year) => {
        if (year === null) {
            this.setState({classYears: []});
            this.search(this.state.search, [], this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
            return
        }

        let newClassYears = [...this.state.classYears];
        if (this.state.classYears.includes(year)) {
            const index = this.state.classYears.indexOf(year);
            newClassYears.splice(index, 1);
        } else {
            newClassYears.push(year);
        }
        this.setState({classYears: newClassYears});
        this.search(this.state.search, newClassYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
    }

    updateMajor = (category) => {
        if (category === null) {
            this.setState({majors: []});
            this.search(this.state.search, this.state.classYears, [], this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
            return;
        }

        let newMajors = [...this.state.majors];
        const categoryMajors = this.props.constants.major_categories[category] || [];
        
        if (this.state.majors.includes(category)) {
            // Remove the category and all its majors
            newMajors = newMajors.filter(major => major !== category); // !categoryMajors.includes(major) &&
        } else {
            // Add the category and all its majors
            newMajors = [...newMajors, category]; // ...categoryMajors
        }

        this.setState({majors: newMajors});

        const allMajors = [];
        for (const major of newMajors) {
            allMajors.push(...this.props.constants.major_categories[major]);
        }
        this.search(this.state.search, this.state.classYears, [...allMajors, ...newMajors], this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
    }

    updateGpa = (gpa) => {
        if (!this.props.showPaywall) {
            this.setState({gpa});

            this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
        }
    }

    updateSortBy = (sortBy) => {
        if (!this.props.showPaywall) {
            this.setState({sortBy});

            this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, this.state.clubIds, sortBy);
        }
    }

    updateDiversity = (field, value) => {
        if (!this.props.showPaywall) {
            // console.log(field, value, value ? value : null)
            value = value ? value : null;
            this.setState({[field]: value});

            if (field === "gender") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, value);
            else if (field === "veteran") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, value);
            else if (field === "lgbtq") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, value);
            else if (field === "disabled") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, value);
            else if (field === "authorized") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, value);
            else if (field === "isIb") this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, this.state.clubIds, this.state.sortBy, this.state.companyId, this.state.previousCompanies, value);
        }
    }

    updateCollege = (colleges) => {
        if (!this.props.showPaywall) {
            this.setState({college: colleges});
            this.search(this.state.search, this.state.classYears, this.state.majors, colleges, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
        }
    }

    setSchool = (result) => {
        if (!result) {
            this.setState({page: 1})
        }
        this.setState({
            college: !!result ? [result] : []
        })
        this.search(this.state.search, this.state.classYears, this.state.majors, !!result ? [result] : [], this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
    }

    setClubs = (clubs) => {
        this.setState({
            clubIds: clubs
        })
        this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, clubs);
    }

    setCurrentCompany = (companies) => {
        this.setState({currentCompanies: companies});
        this.search(
            this.state.search,
            this.state.classYears,
            this.state.majors,
            this.state.college,
            this.state.gpa,
            this.state.gender,
            this.state.race,
            this.state.veteran,
            this.state.lgbtq,
            this.state.disabled,
            this.state.authorized,
            this.state.clubIds,
            this.state.sortBy,
            companies,
            this.state.previousCompanies
        );
    }

    setPreviousCompany = (companies) => {
        this.setState({previousCompanies: companies});
        this.search(
            this.state.search,
            this.state.classYears,
            this.state.majors,
            this.state.college,
            this.state.gpa,
            this.state.gender,
            this.state.race,
            this.state.veteran,
            this.state.lgbtq,
            this.state.disabled,
            this.state.authorized,
            this.state.clubIds,
            this.state.sortBy,
            this.state.currentCompanies,
            companies
        );
    }

    updateVerticals = (verticals) => {
        if (!this.props.showPaywall) {
            if (verticals === null) {
                this.setState({verticals: []});
                this.search(
                    this.state.search,
                    this.state.classYears,
                    this.state.majors,
                    this.state.college,
                    this.state.gpa,
                    this.state.gender,
                    this.state.race,
                    this.state.veteran,
                    this.state.lgbtq,
                    this.state.disabled,
                    this.state.authorized,
                    this.state.clubIds,
                    this.state.sortBy,
                    this.state.currentCompanies,
                    this.state.previousCompanies,
                    this.state.isIb,
                    this.state.searchSortState,
                    this.state.schoolSortState,
                    []
                );
                return;
            }

            let newVerticals = [...this.state.verticals];
            if (this.state.verticals.includes(verticals)) {
                const index = this.state.verticals.indexOf(verticals);
                newVerticals.splice(index, 1);
            } else {
                newVerticals.push(verticals);
            }

            this.setState({verticals: newVerticals});
            this.search(
                this.state.search,
                this.state.classYears,
                this.state.majors,
                this.state.college,
                this.state.gpa,
                this.state.gender,
                this.state.race,
                this.state.veteran,
                this.state.lgbtq,
                this.state.disabled,
                this.state.authorized,
                this.state.clubIds,
                this.state.sortBy,
                this.state.currentCompanies,
                this.state.previousCompanies,
                this.state.isIb,
                this.state.searchSortState,
                this.state.schoolSortState,
                newVerticals
            );
        }
    }

    search = async (
        text,
        classYears,
        majors,
        college,
        gpa,
        gender,
        race,
        veteran,
        lgbtq,
        disabled,
        authorized,
        clubIds,
        sortBy,
        currentCompanies,
        previousCompanies,
        isIb,
        searchSortState,
        schoolSortState,
        verticals
    ) => {
        // Set default values from state
        text = text ?? this.state.search;
        classYears = classYears ?? this.state.classYears;
        majors = majors ?? this.state.majors;
        college = college ?? this.state.college;
        gpa = gpa ?? this.state.gpa;
        gender = gender ?? this.state.gender;
        race = race ?? this.state.race;
        // veteran = veteran ?? this.state.veteran;
        // lgbtq = lgbtq ?? this.state.lgbtq;
        // disabled = disabled ?? this.state.disabled;
        // authorized = authorized ?? this.state.authorized;
        clubIds = clubIds ?? this.state.clubIds;
        sortBy = sortBy ?? this.state.sortBy;
        currentCompanies = currentCompanies ?? this.state.currentCompanies;
        previousCompanies = previousCompanies ?? this.state.previousCompanies;
        // isIb = isIb ?? this.state.isIb;
        searchSortState = searchSortState ?? this.state.searchSortState;
        schoolSortState = schoolSortState ?? this.state.schoolSortState;
        verticals = verticals ?? this.state.verticals;

        if (
            text === "" &&
            !classYears.length &&
            !majors.length &&
            !college.length &&
            !gpa &&
            !gender &&
            !race.length &&
            !veteran &&
            !lgbtq &&
            !disabled &&
            !authorized &&
            !clubIds.length &&
            !sortBy &&
            !currentCompanies.length &&
            !previousCompanies.length &&
            !isIb &&
            !verticals.length &&
            searchSortState === 0 &&
            schoolSortState === 0
        ) {
            this.setState({students: this.state.originalStudents, hideMoreStudents: false, page: 1});
            return;
        }
        window.analytics.track("Recruiter Student Search Performed", {
            query: text
        });

        const results = await searchUsers(
            this.props.authUser,
            text,
            1,
            clubIds,
            classYears,
            majors,
            college,
            gpa,
            gender,
            race,
            veteran,
            lgbtq,
            disabled,
            authorized,
            [],
            sortBy,
            currentCompanies,
            previousCompanies,
            isIb,
            searchSortState,
            schoolSortState,
            verticals
        );
        this.setState({
            students: results.students,
            loading: false,
            hideMoreStudents: results.students.length < NUM_STUDENTS_SHOWN,
            page: 1
        });
    };

    getNextStudentInfo = async () => {
        if (!this.props.showPaywall) {
            this.setState({moreStudentsLoading: true});
            const newStudents = await searchUsers(
                this.props.authUser,
                this.state.search,
                this.state.page + 1,
                this.state.clubIds,
                this.state.classYears,
                this.state.majors,
                this.state.college,
                this.state.gpa,
                this.state.gender,
                this.state.race,
                this.state.veteran,
                this.state.lgbtq,
                this.state.disabled,
                this.state.authorized,
                [],
                this.state.sortBy,
                this.state.currentCompanies,
                this.state.previousCompanies,
                this.state.isIb,
                this.state.searchSortState,
                this.state.schoolSortState,
                this.state.verticals
            );
            this.setState({
                students: [...this.state.students, ...newStudents.students],
                moreStudentsLoading: false,
                page: this.state.page + 1,
                hideMoreStudents: newStudents.students.length < NUM_STUDENTS_SHOWN,
            })
        }
    }

    clearFilters = () => {
        this.setState({...initialFilters});
        this.setState({students: this.state.originalStudents});
    }

    saveSearch = async () => {
        this.setState({isSavingSearch: true});
        const {classYears, majors, gpa, college} = this.state;
        const savedSearch = {
            grades: classYears,
            majors,
            gpa,
            college,
        };
        await createSavedSearch(this.props.authUser, savedSearch);
        this.setState({isSavingSearch: false})
    };

    toggleIsViewingSavedSearches = () => {
        this.setState({isViewingSavedSearches: !this.state.isViewingSavedSearches})
    }

    deleteSearch = async (searchId) => {
        await deleteSearch(this.props.authUser, searchId);
        this.toggleIsViewingSavedSearches();
        window.location.reload();
    }

    // TODO: applySearch currently is not compatible with major categories
    // it will be once we add the advanced category/major selector
    applySearch = (params) => {
        this.setState({isViewingSavedSearches: false});
        this.setState({
            college: params.college,
            classYears: params.grades,
            majors: params.majors,
            gpa: params.gpa,
            search: ""
        })
        this.search("", params.grades, params.majors, !!params.college ? [params.college] : [], params.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized);
    }

    toggleSelectAll = async () => {
        // Deselect all case
        if (this.state.selectAllChecked) {
            this.setState({
                selectAllChecked: false,
                selectedStudents: {}
            });
            return;
        }

        // First, immediately select all currently visible students
        const initialSelectedStudents = {};
        this.state.students?.forEach(student => {
            initialSelectedStudents[student.id] = student;
        });

        // Update state immediately with visible students
        this.setState(() => ({
            selectAllChecked: true,
            selectedStudents: initialSelectedStudents
        }));

        try {
            // Create array of promises for pages 1-10
            const promises = Array.from({length: 10}, (_, i) =>
                searchUsers(
                    this.props.authUser,
                    this.state.search,
                    i + 1,
                    this.state.clubIds,
                    this.state.classYears,
                    this.state.majors,
                    this.state.college,
                    this.state.gpa,
                    this.state.gender,
                    this.state.race,
                    this.state.veteran,
                    this.state.lgbtq,
                    this.state.disabled,
                    this.state.authorized,
                    [],
                    this.state.sortBy,
                    this.state.currentCompanies,
                    this.state.previousCompanies,
                    this.state.isIb,
                    this.state.searchSortState,
                    this.state.schoolSortState,
                    this.state.verticals
                )
            );

            // Execute all promises in parallel
            const results = await Promise.all(promises);

            // Build complete selectedStudents object
            const allSelectedStudents = {...initialSelectedStudents};
            results.forEach(result => {
                if (result.students.length === 0) return;

                result.students.forEach(student => {
                    allSelectedStudents[student.id] = student;
                });
            });

            // Update state with all selected students
            this.setState(() => ({
                selectedStudents: allSelectedStudents
            }));
        } catch (error) {
            console.error('Error fetching additional students:', error);
        }
    }

    toggleSearchSort = () => {
        const newSortState = (this.state.searchSortState + 1) % 3;
        this.setState({searchSortState: newSortState});
        this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, this.state.clubIds, this.state.sortBy, this.state.currentCompanies, this.state.previousCompanies, this.state.isIb, newSortState, this.state.schoolSortState);
    }

    updateRace = (race) => {
        this.setState({race});
        this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, this.state.clubIds, this.state.sortBy, this.state.currentCompanies, this.state.previousCompanies, this.state.isIb, this.state.searchSortState, this.state.schoolSortState);
    }

    toggleSchoolSort = () => {
        const newSortState = (this.state.schoolSortState + 1) % 3;
        this.setState({schoolSortState: newSortState});
        this.search(this.state.search, this.state.classYears, this.state.majors, this.state.college, this.state.gpa, this.state.gender, this.state.race, this.state.veteran, this.state.lgbtq, this.state.disabled, this.state.authorized, this.state.clubIds, this.state.sortBy, this.state.currentCompanies, this.state.previousCompanies, this.state.isIb, this.state.searchSortState, newSortState);
    }

    getSortIcon = (sortState) => {
        switch (sortState) {
            case 1:
                return <BsSortAlphaDown className="text-blue-500" size={20}/>;
            case 2:
                return <BsSortAlphaDownAlt className="text-blue-500" size={20}/>;
            default:
                return <BsSortAlphaDown className="text-slate-400" size={20}/>;
        }
    };

    render() {
        const canClearFilters =
            this.state.classYears.length > 0 ||
            this.state.majors.length > 0 ||
            this.state.college.length > 0 ||
            this.state.gpa !== null ||
            this.state.gender !== null ||
            this.state.race.length > 0 ||
            this.state.veteran !== null ||
            this.state.lgbtq !== null ||
            this.state.disabled !== null ||
            this.state.authorized !== null ||
            this.state.search !== "" ||
            this.state.sortBy ||
            this.state.clubIds.length > 0 ||
            this.state.currentCompanies.length > 0 ||
            this.state.previousCompanies.length > 0 ||
            this.state.isIb ||
            this.state.verticals.length > 0 ||
            this.state.searchSortState !== 0 ||
            this.state.schoolSortState !== 0

        const SECONDARY_TAGS = this.props.constants.secondary_tags.map((tag) => ({
            title: tag,
            value: tag,
        }));

        // Convert major_categories object keys to options format
        const MAJOR_CATEGORY_OPTIONS = Object.keys(this.props.constants.major_categories).map(category => ({
            title: category,
            value: category,
        }));

        return (
            <div className='flex flex-col flex-1 overflow-hidden'>
                <div className={'flex flex-row flex-1 overflow-hidden'}>
                    <div className={'w-[250px] px-[12px] flex flex-col'}>
                        <FiltersSidebar
                            search={{
                                placeholder: 'Search by name...',
                                value: this.state.search,
                                onChange: this.handleSearchChange,
                                rightIcon: (
                                    <div className="absolute right-3 top-2.5 cursor-pointer"
                                         onClick={this.toggleSearchSort}>
                                        {this.getSortIcon(this.state.searchSortState)}
                                    </div>
                                )
                            }}
                            overrideSchoolInput={
                                <MultiSearchSchools
                                    selectedSchools={this.state.college}
                                    setSelectedSchools={this.updateCollege}
                                    placeholder={'Search by school...'}
                                    rightIcon={
                                    <div className="cursor-pointer"
                                        onClick={this.toggleSchoolSort}>
                                       {this.getSortIcon(this.state.schoolSortState)}
                                   </div>}
                                />
                            }
                            overrideClubInput={
                                <MultiSearchClubs
                                    selectedClubs={this.state.clubIds}
                                    setSelectedClubs={this.setClubs}
                                    placeholder={'Search by club...'}
                                />
                            }
                            overrideCurrentCompanyInput={
                                <MultiSearchCompanies
                                    selectedCompanies={this.state.currentCompanies}
                                    setSelectedCompanies={this.setCurrentCompany}
                                    placeholder={'Current companies'}
                                />
                            }
                            overridePreviousCompanyInput={
                                <MultiSearchCompanies
                                    selectedCompanies={this.state.previousCompanies}
                                    setSelectedCompanies={this.setPreviousCompany}
                                    placeholder={'Previous companies'}
                                />
                            }
                            clearFilters={canClearFilters ? this.clearFilters : undefined}
                            majorSelect={{
                                label: 'Major Categories',
                                value: this.state.majors,
                                setValue: this.updateMajor,
                                options: MAJOR_CATEGORY_OPTIONS,
                                multiSelect: true,
                            }}
                            classYearSelect={{
                                label: 'Class Year',
                                value: this.state.classYears,
                                setValue: this.updateClassYears,
                                options: [{title: "2025",}, {title: "2026",}, {title: "2027",}, {title: "2028",}],
                                multiSelect: true,
                            }}
                            gpaSelect={{
                                label: 'Min GPA',
                                value: this.state.gpa,
                                setValue: this.updateGpa,
                                options: [{title: "3.9",}, {title: "3.8",}, {title: "3.7",}, {title: "3.6",}, {title: "3.5",}, {title: "3.4",}, {title: "3.3",}, {title: "3.2",}, {title: "3.1",}, {title: "3.0",}],
                            }}
                            genderSelect={{
                                label: 'Gender',
                                value: this.state.gender,
                                setValue: this.updateDiversity,
                                options: [{title: "Male",}, {title: "Female",}, {title: "Prefer Not to Specify",}],
                                field: 'gender'
                            }}
                            overrideRaceSelect={
                                <MultiSearchRace
                                    selectedValues={this.state.race}
                                    setSelectedValues={this.updateRace}
                                />
                            }
                            verticalSelect={{
                                label: 'Vertical',
                                value: this.state.verticals,
                                setValue: this.updateVerticals,
                                options: SECONDARY_TAGS,
                                multiSelect: true,
                            }}
                            checkboxes={[
                                // {
                                //     label: 'Is IB',
                                //     active: this.state.isIb,
                                //     onClick: () => this.updateDiversity("isIb", !this.state.isIb)
                                // },
                                {
                                    label: 'Veteran',
                                    active: this.state.veteran,
                                    onClick: () => this.updateDiversity("veteran", !this.state.veteran)
                                },
                                {
                                    label: 'LGBTQ',
                                    active: this.state.lgbtq,
                                    onClick: () => this.updateDiversity("lgbtq", !this.state.lgbtq)
                                },
                                {
                                    label: 'Disability',
                                    active: this.state.disabled,
                                    onClick: () => this.updateDiversity("disabled", !this.state.disabled)
                                },
                                {
                                    label: 'Authorized to work',
                                    active: this.state.authorized,
                                    onClick: () => this.updateDiversity("authorized", !this.state.authorized)
                                }
                            ]}
                        />
                    </div>
                    <div className={'flex flex-col flex-1 overflow-hidden'}>
                        <div className='flex h-12 flex-row items-center gap-4  pr-[12px]'>
                            <p className={'text-lg font-semibold mr-auto'}>
                                Results
                            </p>
                            <p className='text-sm text-slate-500'>
                                {Object.keys(this.state.selectedStudents).length} {pluralizeString('student', Object.keys(this.state.selectedStudents).length)} selected
                            </p>
                            <Button
                                size="sm"
                                onClick={() => this.setState({isSelectListModalOpen: true})}
                                disabled={Object.values(this.state.selectedStudents).length === 0}
                            >
                                Add to list
                            </Button>
                        </div>
                        <RecruiterDashboardTable
                            columns={SEARCH_STUDENT_TABLE_HEADERS}
                            hasCheckbox={true}
                            checked={this.state.selectAllChecked}
                            toggleCheckbox={this.toggleSelectAll}
                            containerStyle={'rounded-none rounded-tl-xl border-r-0 border-b-0'}
                            TableBody={
                                <div className='flex-1 flex flex-col mt-0 pt-2 gap-1 p-5 overflow-y-scroll'>
                                    {this.state.loading ?
                                        <DashboardLoading copy={"search results"}/>
                                        :
                                        this.state.students.length === 0 ?
                                            <div
                                                className='my-8 flex text-neutral-400 flex-col items-center justify-center flex-1'>
                                                <SvgSearch/>
                                                <p className='body2 mt-2 mb-6'>
                                                    No students found...
                                                </p>
                                            </div>
                                            :
                                            <>
                                                {this.state.students.map((item, index) =>
                                                    <StudentSearchListItem
                                                        key={item.id}
                                                        item={item}
                                                        previewStudent={this.previewStudent}
                                                        previewingID={this.state.studentPreview.id}
                                                        columns={SEARCH_STUDENT_TABLE_HEADERS}
                                                        onSelectStudentChange={this.onSelectStudentChange}
                                                        isSelected={this.isStudentSelected(item.id)}
                                                    />
                                                )}
                                            </>
                                    }
                                    {(!this.state.hideMoreStudents && (!this.state.loading && this.state.students.length !== 0)) &&
                                        <div
                                            className='flex flex-row items-center justify-center mr-3 py-8 ml-2 mb-40 body2-bold'
                                            onClick={this.getNextStudentInfo}>
                                            <Button loading={this.state.moreStudentsLoading} variant={'ghost'}>
                                                Load more students
                                            </Button>
                                        </div>
                                    }
                                </div>
                            }
                        />
                    </div>
                </div>
                <SelectClubListModal
                    items={Object.values(this.state.selectedStudents)}
                    type={"user"}
                    isOpen={this.state.isSelectListModalOpen}
                    authUser={this.props.authUser}
                    closeModal={() => this.setState({isSelectListModalOpen: false})}
                    onUpdate={() => this.setState({selectedStudents: {}})}
                />

                <div
                    className={`fixed bottom-12 left-1/2 ${this.state.isViewingSavedSearches ? 'h-auto' : 'h-0 overflow-hidden'}`}>
                    <div
                        className='-translate-x-1/4 overflow-hidden bg-white min-w-[30vw] flex flex-col border w-full border-slate-200 shadow-xl absolute bottom-10 rounded-xl'>
                        <div className={'p-[12px] py-2 bg-slate-100'}>
                            <p className='text-base font-semibold '>
                                Saved Searches
                            </p>
                        </div>
                        <div className='flex flex-col  max-h-[30vh] overflow-y-scroll'>
                            {this.state.savedSearches.length === 0 ?
                                <div className='flex flex-col items-center justify-center p-5'>
                                    <p className='text-slate-500'>
                                        No saved searches yet
                                    </p>
                                </div>
                                : this.state.savedSearches.map((item, index) =>
                                    <div key={index}
                                         onClick={() => this.applySearch(item)}
                                         className='border-b last:border-none flex flex-row items-center justify-between border-slate-200 hover:border-slate-300 hover:bg-slate-50 cursor-pointer p-3 text-sm '>
                                        <div>
                                            {!!item.grades.length &&
                                                <p><strong>Class Years:</strong> {item.grades.join(", ")}</p>}
                                            {!!item.majors.length &&
                                                <p><strong>Majors:</strong> {item.majors.join(", ")}</p>}
                                            {!!item.gpa &&
                                                <p><strong>GPA:</strong> {item.gpa !== null ? item.gpa : "N/A"}</p>}
                                            {!!item.college && <p><strong>College:</strong> {item.college}</p>}
                                        </div>
                                        <div className='flex flex-row gap-2 ml-auto'>
                                            <Button variant='ghost' onClick={() => this.deleteSearch(item.id)}
                                                    icon={SvgTrash}/>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className='fixed bottom-8 left-1/2 translate-x-1/4'>
                    <div className='flex flex-row gap-2 items-center relative'>
                        <Button onClick={this.toggleIsViewingSavedSearches} variant='secondary' className='mr-3'>
                            Saved searches <SvgChevronDown
                            className={`${this.state.isViewingSavedSearches ? 'rotate-[180deg]' : 'rotate-0'} duration-150`}/>
                        </Button>
                        {(this.state.classYears?.length > 0 || this.state?.gpa !== null || this.state.majors?.length > 0 || this.state.college?.length > 0) &&
                            <Button onClick={this.saveSearch} loading={this.state.isSavingSearch} className='mr-3'>
                                Save this search
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(RecruiterStudentSearch);
