import React, {Component} from "react";
import {page_h_padding_class} from "../utils/constants";
import SvgPeople from "../components/icons/SvgPeopleFeather"
import WrappedTextInput from "../components/atoms/WrappedTextInput";
import SelectComponent from "../components/atoms/SelectComponent";
import SvgCheck from "../components/icons/SvgCheck";
import {filteredCollegeSearch, searchClubs, searchCompanies} from "../utils/typesense";
import MultiSelectComponent from "../components/atoms/MultiSelectComponent";
import SvgSearchFeather from "../components/icons/SvgSearchFeather";
import SvgSchool from "../components/icons/SvgSchool";
import CollapseComponent from "../components/atoms/CollapseComponent";
import FadeInOnScroll from "../components/atoms/FadeInOnScroll";
import {Button} from "../components/atoms/Button";
import SvgClose from "../components/icons/SvgClose";
import SvgCompanyBuilding from "../components/icons/SvgCompanyBuilding";
import DropdownSearchSelect from "../components-recruiter/atoms/DropdownSearchSelect";

const initialState = {
    openSchoolCollapse: false,
    schoolSearchResults: [],
    schoolSearch: "",
    clubSearch: "",
    clubOptions: [],
    currentCompanySearch: "",
    currentCompanyOptions: [],
    previousCompanySearch: "",
    previousCompanyOptions: []
}

class AdminFiltersSidebar extends Component {
    state = initialState


    handleSchoolSearchChange = (event) => {
        const text = event.target.value;
        if (!text) {
            this.setSchool(null)
        }
        this.setState({schoolSearch: text, openSchoolCollapse: true});
        this.schoolSearchChanged(text);
    }

    schoolSearchChanged = async (text) => {
        const schoolSearchResults = await filteredCollegeSearch(text);
        this.setState({schoolSearchResults})
    }

    handleClubSearchChange = async (event) => {
        const text = event.target.value;
        this.setState({clubSearch: text});

        if (!text) {
            this.setClub({name: "", id: null});
            return
        }
        const clubs = await searchClubs(text, 1, 5);
        this.setState({clubOptions: clubs});
    }

    setSchool = (result) => {
        this.setState({
            schoolSearchResults: [],
            schoolSearch: result
        })
        this.props.school.setSchool(result);
    }

    setClub = (result) => {
        this.setState({
            clubOptions: [],
            clubSearch: result.name
        })
        this.props.setClub(result.id);
    }

    handleCurrentCompanySearchChange = async (event) => {
        const text = event.target.value;
        this.setState({currentCompanySearch: text});

        if (!text) {
            this.setCurrentCompany({name: "", id: null});
            return;
        }
        const companies = await searchCompanies(text);
        this.setState({currentCompanyOptions: companies});
    }

    handlePreviousCompanySearchChange = async (event) => {
        const text = event.target.value;
        this.setState({previousCompanySearch: text});

        if (!text) {
            this.setPreviousCompany({name: "", id: null});
            return;
        }
        const companies = await searchCompanies(text);
        this.setState({previousCompanyOptions: companies});
    }

    setCurrentCompany = (result) => {
        this.setState({
            currentCompanyOptions: [],
            currentCompanySearch: result.name
        })
        this.props.setCurrentCompany(this.props.companyMode === "name" ? result.name : result.id);
    }

    setPreviousCompany = (result) => {
        this.setState({
            previousCompanyOptions: [],
            previousCompanySearch: result.name
        })
        this.props.setPreviousCompany(this.props.companyMode === "name" ? result.name : result.id);
    }

    handleClearFilters = () => {
        this.setState(initialState)
        this.props.clearFilters();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.school?.schoolSearch && this.props.school.schoolSearch !== prevProps.school?.schoolSearch) {
            this.setState({schoolSearch: this.props.school.schoolSearch, openSchoolCollapse: false})
        }
    }

    render() {
        return (
            <div className={`flex flex-col flex-1 overflow-hidden relative`}>
                <div className={'h-12 flex flex-row items-center justify-between'}>
                    <p className={'text-lg font-semibold'}>
                        Filters
                    </p>
                    {this.props.clearFilters && (
                        <div className={'ml-a'}>
                            <Button
                                variant={'secondary'}
                                onClick={this.handleClearFilters}
                                icon={SvgClose}
                                iconPosition={'left'}
                                size={'sm'}
                                className={'w-full'}
                            >
                                Clear
                            </Button>
                        </div>
                    )}
                </div>
                <div
                    className={`flex flex-col flex-1 pb-20 relative overflow-y-auto ${this.props.className}`}>
                    <div className='flex flex-col gap-0 flex-1'>
                        <div className='flex flex-row items-center justify-between w-full'>
                            <div className='flex flex-col w-full gap-2 flex-wrap'>
                                <p className={'text-xs opacity-50'}>
                                    Name
                                </p>
                                {this.props.search &&
                                    <WrappedTextInput
                                        type="text"
                                        placeholder={this.props.search.placeholder}
                                        className={`pl-44 input-light body2 font-regular w-full ${this.props.search.rightIcon ? 'pr-12' : ''}`}
                                        value={this.props.search.value}
                                        onChange={this.props.search.onChange}
                                        icon={<SvgSearchFeather width={20} height={20}
                                                                className={'absolute text-slate-500'}
                                                                style={{left: 12, top: 10}}/>}
                                        rightIcon={this.props.search.rightIcon}
                                    />
                                }
                                {(this.props.school?.schoolSearch != null || this.props.gpaSelect || this.props.classYearSelect) && (
                                    <p className={'text-xs opacity-50 mt-2'}>
                                        Education
                                    </p>
                                )}
                                {this.props.overrideSchoolInput ? 
                                    this.props.overrideSchoolInput :
                                    (!!this.props.school?.schoolSearch != null &&
                                        <DropdownSearchSelect
                                            placeholder={'Search by school...'}
                                            value={this.state.schoolSearch}
                                            onChange={this.handleSchoolSearchChange}
                                            options={this.state.schoolSearchResults}
                                            onSelect={this.props.school.setSchool}
                                            selectResName={true}
                                            InputIcon={<SvgSchool width={16} height={16} className="absolute text-slate-500" style={{left: 14, top: 12}}/>}
                                            rightIcon={this.props.school.rightIcon}
                                        />
                                    )
                                }
                                {this.props.overrideClubInput ? 
                                    this.props.overrideClubInput :
                                    (!!this.props.setClub &&
                                        <DropdownSearchSelect
                                            placeholder={'Search by club...'}
                                            value={this.state.clubSearch}
                                            onChange={this.handleClubSearchChange}
                                            options={this.state.clubOptions}
                                            onSelect={this.setClub}
                                            InputIcon={<SvgPeople width={16} height={16} className="absolute text-slate-500" style={{left: 14, top: 12}}/>}
                                        />
                                    )
                                }
                                {this.props.majorSelect && (
                                    <div className={'w-full'}>
                                        <MultiSelectComponent
                                            label={this.props.majorSelect.label}
                                            light
                                            value={this.props.majorSelect.value}
                                            scrollable={true}
                                            setValue={this.props.majorSelect.setValue}
                                            clearFilter={() => this.props.majorSelect.setValue(null)}
                                            options={this.props.majorSelect.options}
                                        />
                                    </div>
                                )}
                                {this.props.classYearSelect && (
                                    <div className={'w-full'}>
                                        {this.props.classYearSelect.multiSelect ?
                                            <MultiSelectComponent
                                                label={this.props.classYearSelect.label}
                                                light
                                                value={this.props.classYearSelect.value}
                                                scrollable={true}
                                                setValue={this.props.classYearSelect.setValue}
                                                clearFilter={() => this.props.classYearSelect.setValue(null)}
                                                options={this.props.classYearSelect.options}
                                            />
                                            :
                                            <SelectComponent
                                                label={this.props.classYearSelect.label}
                                                light
                                                value={this.props.classYearSelect.value}
                                                scrollable={true}
                                                setValue={this.props.classYearSelect.setValue}
                                                clearFilter={() => !!this.props.classYearSelect.field ? this.props.classYearSelect.setValue(this.props.classYearSelect.field, null) : this.props.classYearSelect.setValue(null)}
                                                options={this.props.classYearSelect.options}
                                                stateName={this.props.classYearSelect.field}
                                            />
                                        }
                                    </div>
                                )}
                                {this.props.gpaSelect && (
                                    <div className={'w-full'}>
                                        <SelectComponent
                                            label={this.props.gpaSelect.label}
                                            light
                                            value={this.props.gpaSelect.value}
                                            scrollable={true}
                                            setValue={this.props.gpaSelect.setValue}
                                            clearFilter={() => !!this.props.gpaSelect.field ? this.props.gpaSelect.setValue(this.props.gpaSelect.field, null) : this.props.gpaSelect.setValue(null)}
                                            options={this.props.gpaSelect.options}
                                            stateName={this.props.gpaSelect.field}
                                        />
                                    </div>
                                )}
                                {(this.props.setCurrentCompany || this.props.setCurrentCompany) && (
                                    <p className={'text-xs opacity-50 mt-2'}>
                                        Experience
                                    </p>
                                )}
                                {this.props.overrideCurrentCompanyInput ? 
                                    this.props.overrideCurrentCompanyInput :
                                    (!!this.props.setCurrentCompany &&
                                        <DropdownSearchSelect
                                            placeholder={'Search by current company...'}
                                            value={this.state.currentCompanySearch}
                                            onChange={this.handleCurrentCompanySearchChange}
                                            options={this.state.currentCompanyOptions}
                                            onSelect={this.setCurrentCompany}
                                            InputIcon={<SvgCompanyBuilding width={16} height={16} className="absolute text-slate-500" style={{left: 12, top: 12}}/>}
                                        />
                                    )
                                }

                                {this.props.overridePreviousCompanyInput ? 
                                    this.props.overridePreviousCompanyInput :
                                    (!!this.props.setPreviousCompany &&
                                        <DropdownSearchSelect
                                            placeholder={'Search by prev. company...'}
                                            value={this.state.previousCompanySearch}
                                            onChange={this.handlePreviousCompanySearchChange}
                                            options={this.state.previousCompanyOptions}
                                            onSelect={this.setPreviousCompany}
                                            InputIcon={<SvgCompanyBuilding width={16} height={16} className="absolute text-slate-500" style={{left: 12, top: 12}}/>}
                                        />
                                    )
                                }
                                {this.props.industrySelect && (
                                    <div className={'w-full'}>
                                        <SelectComponent
                                            label={this.props.industrySelect.label}
                                            light
                                            value={this.props.industrySelect.value}
                                            scrollable={true}
                                            setValue={this.props.industrySelect.setValue}
                                            clearFilter={() => !!this.props.industrySelect.field ? this.props.industrySelect.setValue(this.props.industrySelect.field, null) : this.props.industrySelect.setValue(null)}
                                            options={this.props.industrySelect.options}
                                            stateName={this.props.industrySelect.field}
                                        />
                                    </div>
                                )}
                                {this.props.verticalSelect && (
                                    <div className={'w-full'}>
                                        <SelectComponent
                                            label={this.props.verticalSelect.label}
                                            light
                                            value={this.props.verticalSelect.value}
                                            scrollable={true}
                                            setValue={this.props.verticalSelect.setValue}
                                            clearFilter={() => !!this.props.verticalSelect.field ? this.props.verticalSelect.setValue(this.props.verticalSelect.field, null) : this.props.verticalSelect.setValue(null)}
                                            options={this.props.verticalSelect.options}
                                            stateName={this.props.verticalSelect.field}
                                        />
                                    </div>
                                )}
                                {this.props.titleSelect && (
                                    <div className={'w-full'}>
                                        <SelectComponent
                                            label={this.props.titleSelect.label}
                                            light
                                            value={this.props.titleSelect.value}
                                            scrollable={true}
                                            setValue={this.props.titleSelect.setValue}
                                            clearFilter={() => !!this.props.titleSelect.field ? this.props.titleSelect.setValue(this.props.titleSelect.field, null) : this.props.titleSelect.setValue(null)}
                                            options={this.props.titleSelect.options}
                                            stateName={this.props.titleSelect.field}
                                        />
                                    </div>
                                )}
                                {(this.props.locationSelect) && (
                                    <p className={'text-xs opacity-50 mt-2'}>
                                        Location
                                    </p>
                                )}
                                {this.props.locationSelect && (
                                    <div className={'w-full'}>
                                        <SelectComponent
                                            label={this.props.locationSelect.label}
                                            light
                                            value={this.props.locationSelect.value}
                                            scrollable={true}
                                            setValue={this.props.locationSelect.setValue}
                                            clearFilter={() => !!this.props.locationSelect.field ? this.props.locationSelect.setValue(this.props.locationSelect.field, null) : this.props.locationSelect.setValue(null)}
                                            options={this.props.locationSelect.options}
                                            stateName={this.props.locationSelect.field}
                                        />
                                    </div>
                                )}
                                {(this.props.raceSelect || this.props.genderSelect || this.props.checkboxes?.length > 0) && (
                                    <p className={'text-xs opacity-50 mt-2'}>
                                        {this.props.overrideDemographicsLabel || 'Demographics'}
                                    </p>
                                )}
                                {this.props.overrideRaceSelect ? 
                                    this.props.overrideRaceSelect :
                                    (this.props.raceSelect && (
                                        <div className={'w-full'}>
                                            <SelectComponent
                                                label={this.props.raceSelect.label}
                                                light
                                                value={this.props.raceSelect.value}
                                                scrollable={true}
                                                setValue={this.props.raceSelect.setValue}
                                                clearFilter={() => !!this.props.raceSelect.field ? this.props.raceSelect.setValue(this.props.raceSelect.field, null) : this.props.raceSelect.setValue(null)}
                                                options={this.props.raceSelect.options}
                                                stateName={this.props.raceSelect.field}
                                            />
                                        </div>
                                    ))
                                }
                                {this.props.genderSelect && (
                                    <div className={'w-full'}>
                                        <SelectComponent
                                            label={this.props.genderSelect.label}
                                            light
                                            value={this.props.genderSelect.value}
                                            scrollable={true}
                                            setValue={this.props.genderSelect.setValue}
                                            clearFilter={() => !!this.props.genderSelect.field ? this.props.genderSelect.setValue(this.props.genderSelect.field, null) : this.props.genderSelect.setValue(null)}
                                            options={this.props.genderSelect.options}
                                            stateName={this.props.genderSelect.field}
                                        />
                                    </div>
                                )}
                                {this.props.selects && this.props.selects.map(select => {
                                    if (!select.multiSelect) {
                                        return (
                                            <SelectComponent
                                                label={select.label}
                                                light
                                                value={select.value}
                                                scrollable={true}
                                                setValue={select.setValue}
                                                clearFilter={() => !!select.field ? select.setValue(select.field, null) : select.setValue(null)}
                                                options={select.options}
                                                stateName={select.field}
                                            />
                                        )
                                    } else return (
                                        <MultiSelectComponent
                                            label={select.label}
                                            light
                                            value={select.value}
                                            scrollable={true}
                                            setValue={select.setValue}
                                            clearFilter={() => select.setValue(null)}
                                            options={select.options}
                                        />
                                    )
                                })}
                                
                                {this.props.checkboxes && (
                                    <>
                                        {this.props.checkboxesLabel && (
                                            <p className={'text-xs opacity-50 mt-2'}>
                                                {this.props.checkboxesLabel}
                                            </p>
                                        )}
                                        <div className={'grid grid-cols-1 gap-2 mt-1'}>
                                            {this.props.checkboxes.map((checkbox, index) => {
                                                return (
                                                    <button onClick={checkbox.onClick}
                                                            className={`flex flex-row items-center base-white-100 ${(index === 4 || checkbox.label === 'Authorized to work') && 'col-span-2'}`}
                                                    >
                                                        <div
                                                            className={`${checkbox.active ? 'checkbox-active' : 'checkbox border-slate-300'} border-[1px] mr-12`}>
                                                            {checkbox.active &&
                                                                <SvgCheck className={'base-white-100'}/>
                                                            }
                                                        </div>
                                                        <p className='body2 text-slate-500'>
                                                            {checkbox.label}
                                                        </p>
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminFiltersSidebar
