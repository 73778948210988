import React, {Component} from "react";
import Spinner from "../../components/Spinner";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import {Button} from "../../components/atoms/Button";
import { getLists, editList } from "../../api/recruiter/lists";
import SvgCheckCircle from "../../components/icons/SvgCheckCircle";
import { pluralizeString } from "../../utils/strings";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../utils/constants";

const ZERO_STATE = {
    lists: [],
    selectedIds: [],
    loading: true,
    originalIds: [],
}

class SelectClubListModal extends Component {
    state = ZERO_STATE;

    componentDidUpdate = (prevProps) => {
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            console.log('getting lists')
            this.getAllLists();
        }
    }

    getAllLists = async () => {
        const type = this.props.type;
        const lists = await getLists(this.props.authUser);
        const currentLists = type === "club" ? lists.club_lists : lists.student_lists;

        const originalIds = [];
        for (const list of currentLists) {
            if (this.props.items.some(item => list[`${type}_ids`].includes(item.id))) {
                originalIds.push(list.id)
            }
        }
        this.setState({
            lists: currentLists,
            originalIds: originalIds,
            // because a multiple student user select can belong to multiple lists, we don't want to pre-select any lists
            selectedIds: this.props.items.length === 1 ? originalIds : [],
            loading: false
        });
    }

    update = async () => {
        try {
            const { selectedIds, originalIds } = this.state;
            let updatePromises = [];
    
            if (this.props.items.length === 1) {
                const listsToUpdate = [...new Set([...selectedIds, ...originalIds])]
                    .filter(listId => 
                        (selectedIds.includes(listId) && !originalIds.includes(listId)) || 
                        (!selectedIds.includes(listId) && originalIds.includes(listId))
                    );
                
                
                updatePromises = listsToUpdate.map(listId => {
                    const payload = {
                        [`${this.props.type}_ids`]: this.props.items.map(item => item.id)
                    };
                    
                    return editList(this.props.authUser, listId, this.props.type, payload)
                        .then(result => {
                            return result;
                        })
                        .catch(error => {
                            throw error; // Re-throw to be caught by Promise.all
                        });
                });
            } else if (this.props.items.length > 1) {
                updatePromises = selectedIds
                    .map(selectedListId => {
                        const originalList = this.state.lists.find(list => list.id === selectedListId);
                        if (!originalList) {
                            return null;
                        }
                        const itemsNotInOriginalList = this.props.items.filter(
                            item => !originalList[`${this.props.type}_ids`].includes(item.id)
                        );
                        
                        if (itemsNotInOriginalList.length) {
                            const payload = {[`${this.props.type}_ids`]: itemsNotInOriginalList.map(item => item.id)};                            
                            return editList(this.props.authUser, selectedListId, this.props.type, payload)
                                .then(result => {
                                    return result;
                                })
                                .catch(error => {
                                    throw error;
                                });
                        }
                        return null;
                    })
                    .filter(Boolean);
            }
        
            if (updatePromises.length === 0) {
                console.log('No updates to perform');
                this.setState(ZERO_STATE);
                this.props.closeModal();
                if (this.props.onUpdate) this.props.onUpdate();
                return;
            }
    
            const timeoutPromise = new Promise((_, reject) => {
                setTimeout(() => reject(new Error('Update timeout')), 30000); // 30 second timeout
            });
    
            const updateResult = await Promise.race([
                Promise.all(updatePromises),
                timeoutPromise
            ]);

            toast.success("Lists updated successfully", TOAST_OPTIONS);
            
            this.setState(ZERO_STATE);
            this.props.closeModal();
            if (this.props.onUpdate) this.props.onUpdate();
        } catch (error) {
            console.error('Error in update function:', error);
            toast.error("Failed to update lists", TOAST_OPTIONS);
            this.props.closeModal();
        }
    };

    pickList = (listId) => {
        const newList = [...this.state.selectedIds];
        if (newList.includes(listId)) {
            const index = newList.indexOf(listId);
            newList.splice(index, 1);
        } else {
            newList.push(listId);
        }
        this.setState({selectedIds: newList});
    }

    render() {
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={
                    `Add ${this.props.items.length} ${this.props.type === "user" ? pluralizeString('student', this.props.items.length) : pluralizeString('club', this.props.items.length)} to your lists`
                }
                headerToggle
                size={'xl'}
                toggle={this.props.closeModal}
                showScrollBar
                FooterComponent={
                    <div className='flex flex-row items-center gap-3'>
                        <Button variant={'secondary'} onClick={this.props.closeModal}>
                            Cancel
                        </Button>
                        {this.state.lists.length ?
                            <Button onClick={this.update}>
                                Confirm
                            </Button>
                            : null
                        }
                    </div>
                }
                footerAlignment={'right'}
            >
                {this.state.loading ?
                    <div className='ml-2 mt-8'>
                        <Spinner size={40}/>
                    </div>
                    :
                    <div className='flex flex-col gap-3 max-h-[40vh] overflow-y-scroll w-full'>
                        {this.state.lists.map((list, i) =>
                            <div key={list.id}
                                onClick={() => this.pickList(list.id)}
                                className='card mb-0 flex flex-row items-center justify-between hover-card gap-2'
                            >
                                <p className='body1-bold'>
                                    {list.name}
                                </p>
                                {this.state.selectedIds.includes(list.id) &&
                                    <SvgCheckCircle className={'w-5 h-5 text-primary'}/>
                                }
                            </div>
                        )}
                        {!this.state.lists.length ?
                            <p>You haven't created any lists yet.</p>
                        : null}
                    </div>
                }
            </ModalComponent>
        )
    }
}

export default SelectClubListModal
