import React, {PureComponent} from "react";
import {connect} from "react-redux";
import ProfilePageTitleWidget from "../../../src/components/profilepage/ProfilePageTitleWidget";
import ProfilePageAboutWidget from "../../../src/components/profilepage/ProfilePageAboutWidget";
import ProfilePageExperienceWidget from "../../../src/components/profilepage/ProfilePageExperienceWidget";
import ProfilePageEducationWidget from "../../../src/components/profilepage/ProfilePageEducationWidget";
import ProfilePageClubsWidget from "../../../src/components/profilepage/ProfilePageClubsWidget";
import {getUser} from "../../../src/api/recruiter/users";
import PageContentWrapper from "../../../src/components/wrappers/PageContentWrapper";
import PageLTRWrapper from "../../../src/components/wrappers/PageLTRWrapper";
import PageRightWrapper from "../../../src/components/wrappers/PageRightWrapper";
import ProfilePageDemographics from "../../components/profilepage/ProfilePageDemographics";
import PageLeftWrapper from "../../../src/components/wrappers/PageLeftWrapper";
import Spinner from "../../../src/components/Spinner";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
    }
}

class RecruiterStudentProfilePage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            user: {},
            loading: true,
            recruiter: true,
        };
    }

    componentDidMount = async () => {
        this.setState({loading: true})
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const profileId = lastArg.split("?")[0];

        const user = await getUser(this.props.authUser, profileId);
        this.setState({user: user.user, loading: false});
    }


    render() {
        if (this.state.loading) {
            return <div className={"flex flex-row items-center justify-center my-auto"}>
                <Spinner size={50}/>
            </div>
        }
        const bodyHeight = this.state.height + 69
        const user = this.state.user || {};
        return (
            <PageContentWrapper>
                <PageLTRWrapper>
                    <PageLeftWrapper className={'overflow-y-scroll pt-5'}>
                        <ProfilePageTitleWidget user={user} recruiter={this.state.recruiter} studentProfilePage/>
                        <ProfilePageAboutWidget user={user} recruiter={this.state.recruiter} studentProfilePage/>
                        <ProfilePageExperienceWidget experiences={user.experiences} studentProfilePage/>
                        <ProfilePageEducationWidget education={user.education} student={user} studentProfilePage/>
                    </PageLeftWrapper>
                    <PageRightWrapper open>
                        <div className='flex flex-col flex-1 overflow-y-scroll pt-5'>
                            {/*<ProfilePageSeekingRoleWidget user={user} editSeekingRole={this.editSeekingRole}/>*/}
                            <ProfilePageClubsWidget user={user} recruiter={this.state.recruiter} studentProfilePage/>
                            {"veteran" in user?.diversity && <ProfilePageDemographics user={user} recruiter={this.state.recruiter}/>}
                        </div>
                    </PageRightWrapper>
                </PageLTRWrapper>
            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterStudentProfilePage);
