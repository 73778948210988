import React, {Component} from "react";
import ModalComponent from "../../components/atoms/modals/ModalComponent";
import CreateJobDetailsStep from "./create-job/CreateJobDetailsStep";
import ProgressStepper from "../atoms/ProgressStepper";
import CreateJobDateLocationStep from "./create-job/CreateJobDateLocationStep";
import CreateJobQualificationStep from "./create-job/CreateJobQualificationStep";
import {editJob} from "../../api/recruiter/jobs";
import SectionTabs from "../../components/atoms/SectionTabs";
import { toast } from "react-toastify";
import { EditorState } from "draft-js";
import {convertFromHtml, DefaultEditorState} from "../../utils/richEditor";
import { stateToHTML } from "draft-js-export-html";
import AlertModal from "../../components/atoms/modals/AlertModal";
import { sanitizeHtml } from "../../utils/strings";

const ZERO_STATE = {
    activeTab: 'Job Details',
    jobTitle: '',
    jobDescription: DefaultEditorState,
    industry: null,
    jobType: null,
    workEnvironment: "On-site",
    city: '',
    state: '',
    directOnRecruit: true,
    postToday: true,
    postOn: null,
    hasDeadline: false,
    deadline: null,
    classYears: [],
    qualifiedOnly: false,
    majors: [],
    clubs: [],
    schools: [],
    openings: null,
    gender: null,
    race: [],
    veteran: false,
    lgbtq: false,
    disabled: false,
    authorized: false,
    smartListIds: [],
    clubListIds: [],
    listNames: [],
    gpa: "",
    scope: "public",
    jobPdfPath: null,
};

class RecruiterEditJobModal extends Component {
    state = ZERO_STATE;

    submit = async () => {

        const params = {
            name: this.state.jobTitle,
            description: sanitizeHtml(stateToHTML(this.state.jobDescription.getCurrentContent())),
            type: this.state.jobType,
            city: !!this.state.city ? [this.state.city] : [],
            state: !!this.state.state ? [this.state.state] : [],
            work_model: this.state.workEnvironment,
            class_years: this.state.classYears,
            link: this.state.url,
            industry: this.state.industry,
            apply_direct: this.state.directOnRecruit,
            deadline: this.state.hasDeadline ? this.state.deadline : "",
            // schools: this.state.schools,
            // clubs: combinedClubIds,
            // majors: this.state.majors,
            // gpa: this.state.gpa,
            qualified_only: this.state.qualifiedOnly,
            openings: this.state.openings,
            // smart_list_ids: this.state.smartListIds,
            // club_list_ids: this.state.clubListIds,
            // diversity: {
            //     gender: this.state.gender,
            //     race: this.state.race,
            //     veteran: this.state.veteran,
            //     lgbtq: this.state.lgbtq,
            //     disabled: this.state.disabled,
            //     authorized: this.state.authorized,
            // }
            job_pdf_path: this.state.jobPdfPath
        }
        toast.promise(editJob(this.props.authUser, this.props.job.id, params).then(() => {
            window.location.reload();
        }), {
            pending: "Saving changes...",
            success: "Changes saved successfully!",
            error: "Failed to save changes."
        });
    }

    archive = async () => {
        const params = {
            scope: "expired"
        }
        toast.promise(editJob(this.props.authUser, this.props.job.id, params).then(() => {
            window.location.reload();
        }), {
            pending: "Archiving job...",
            success: "Job archived successfully!",
            error: "Failed to archive job."
        });
    }

    unarchive = async () => {
        const params = {
            scope: "public"
        }
        toast.promise(editJob(this.props.authUser, this.props.job.id, params).then(() => {
            window.location.reload();
        }), {
            pending: "Unarchiving job...",
            success: "Job unarchived successfully!",
            error: "Failed to unarchive job."
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.job !== this.props.job) {
            this.setInfo();
        }
    }

    componentDidMount = () => {
        this.setInfo();
    }

    setInfo = () => {
        const job = this.props.job || {};
        this.setState({
            jobTitle: job.name,
            jobDescription: EditorState.createWithContent(convertFromHtml(job.description)),
            jobType: job.type,
            city: !!job.city ? job.city[0] : "",
            state: !!job.state ? job.state[0] : "",
            workEnvironment: job.work_model,
            classYears: job.class_years,
            url: job.link,
            industry: job.industry,
            directOnRecruit: job.apply_direct,
            hasDeadline: !!job.deadline,
            deadline: !!job.deadline ? new Date(job.deadline) : null,
            schools: job.schools,
            clubs: job.clubs,
            majors: job.majors,
            gpa: job.gpa,
            qualifiedOnly: job.qualified_only,
            openings: job.openings,
            gender: job.diversity?.gender || null,
            race: job.diversity?.race || [],
            veteran: job.diversity?.veteran || false,
            lgbtq: job.diversity?.lgbtq || false,
            disabled: job.diversity?.disabled || false,
            authorized: job.diversity?.authorized || false,
            smartListIds: job.smart_list_ids,
            clubListIds: job.club_list_ids,
            listNames: job.list_names,
            scope: job.scope,
            jobPdfPath: job.job_pdf_path ?? null
        })
    }

    nextStep = () => {
        if (this.state.step === 3) {
            this.submit();
            return;
        }
        this.setState({step: this.state.step + 1})
    }

    prevStep = () => {
        if (this.state.step === 1) {
            this.props.closeModal();
            return;
        }
        this.setState({step: this.state.step - 1})
    }


    handleInputChange = (e, inputName) => {
        this.setState({[inputName]: e.target.value});
    };

    handleSelectChange = (inputName, value) => {
        this.setState({[inputName]: value});
    };

    handleWorkEnvironmentChange = (event) => {
        this.setState({
            workEnvironment: event.target.value,
        });
    };

    handleQualifiedOnlyChange = (boolean) => {
        this.setState({
            qualifiedOnly: boolean,
        });
    };

    toggleInput = (stateKey) => {
        this.setState((prevState) => ({
            [stateKey]: !prevState[stateKey],
        }));
    }

    handleDateChange = (e, stateKey) => {
        this.setState({[stateKey]: e})
    };


    handleQualificationCheckbox = (inputName) => {
        this.setState({[inputName]: !this.state[inputName]});
    }


    changeTab = (tab) => {
        this.setState({activeTab: tab});
    };

    setEditorState = (editorState) => {
        this.setState({jobDescription: editorState})
    };

    selectSmartList = (res) => {
        this.setState({
            listId: res.id,
            listName: res.name,
            schools: res.colleges,
            gpa: res.gpa,
            classYears: res.grades,
            majors: res.majors,
            gender: res.gender,
            race: res.race,
            lgbtq: res.lgbtq,
            disabled: res.disabled,
            veteran: res.veteran,
            authorized: res.authorized,
        })
    }

    onJobPdfUpload = (path) => {
        this.setState({jobPdfPath: path});
    }

    render() {
        const numSteps = 3
        const TABS = ['Job Details', 'Date and Location', 'Candidate Qualification'];
        return (
            <ModalComponent
                isOpen={this.props.isOpen}
                backgroundColor={'white'}
                header={'Edit This Job'}
                headerToggle
                size={'lg'}
                toggle={this.props.closeModal}
                FooterComponent={
                    <div className='flex flex-row items-between gap-3'>
                        <div onClick={this.props.closeModal} className='secondary-button body1-bold'>
                            Cancel
                        </div>
                        <AlertModal
                            title={this.state.scope === 'expired' ? 'Unarchive Job' : 'Archive Job'}
                            description={this.state.scope === 'expired' ? 'Are you sure you want to unarchive this job?' : 'Are you sure you want to archive this job?'}
                            onConfirm={this.state.scope === 'expired' ? this.unarchive : this.archive}
                        >
                            <div onClick={this.state.scope === 'expired' ? this.unarchive : this.archive} className='secondary-button body1-bold'>
                                {this.state.scope === 'expired' ? 'Unarchive' : 'Archive'}
                            </div>
                        </AlertModal>
                        <div onClick={this.submit} className='primary-button body1-bold'>
                            Save Changes
                        </div>
                    </div>
                }
                footerAlignment={'right'}
                showScrollBar
            >
                <div id={'add-joblisting-modal'}>
                    <div className='mb-5'>
                        <SectionTabs
                            activeTab={this.state.activeTab}
                            setActiveTab={this.changeTab}
                            tabs={TABS}
                        />
                    </div>
                    {this.state.activeTab === TABS[0] &&
                        <CreateJobDetailsStep
                            hideTutorial
                            jobTitle={this.state.jobTitle}
                            jobDescription={this.state.jobDescription}
                            jobType={this.state.jobType}
                            industry={this.state.industry}
                            openings={this.state.openings}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            constants={this.props.constants}
                            setEditorState={this.setEditorState}
                            editorState={this.state.jobDescription}
                            jobId={this.props.job.id}
                            jobPdfPath={this.state.jobPdfPath}
                            onJobPdfUpload={this.onJobPdfUpload}
                        />
                    }
                    {this.state.activeTab === TABS[1] &&
                        <CreateJobDateLocationStep
                            hideTutorial
                            jobTitle={this.state.jobTitle}
                            jobDescription={this.state.jobDescription}
                            handleInputChange={this.handleInputChange}
                            handleSelectChange={this.handleSelectChange}
                            workEnvironment={this.state.workEnvironment}
                            city={this.state.city}
                            state={this.state.state}
                            toggleInput={this.toggleInput}
                            directOnRecruit={this.state.directOnRecruit}
                            postToday={this.state.postToday}
                            postOn={this.state.postOn}
                            hasDeadline={this.state.hasDeadline}
                            deadline={this.state.deadline}
                            handleDateChange={this.handleDateChange}
                            handleWorkEnvironmentChange={this.handleWorkEnvironmentChange}
                        />
                    }
                    {this.state.activeTab === TABS[2] &&
                        <CreateJobQualificationStep
                            hideTutorial
                            qualifiedOnly={this.state.qualifiedOnly}
                            handleQualifiedOnlyChange={this.handleQualifiedOnlyChange}
                            handleQualificationCheckbox={this.handleQualificationCheckbox}
                            editing
                        />
                    }
                </div>
            </ModalComponent>
        )
    }
}

export default RecruiterEditJobModal
