import React from 'react';
import he from 'he';
class HTMLRenderer extends React.Component {
    render() {
        // Decode the double-escaped HTML string
        const decodedHtmlString = he.decode(this.props.htmlString);

        return (
            <div
                dangerouslySetInnerHTML={{ __html: decodedHtmlString }}
            />
        );
    }
}

export default HTMLRenderer;