import React, {useEffect, useState} from "react";
import RecruiterListGroup from "../RecruiterListGroup";
import {Button} from "../../components/atoms/Button";
import SvgPlus from "../../components/icons/SvgPlus";
import EditListModal from "../modals/EditListModal";
import Spinner from "../../components/Spinner";
import {connect} from "react-redux";
import {getLists} from "../../api/recruiter/lists";
import SvgStars from "../../components/icons/SvgStars";
import SectionTabs from "../../components/atoms/SectionTabs";
import SmartListModal from "../modals/SmartListModal";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        constants: state.userReducer.constants,
        tier: state.userReducer.tier,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleCreateJobModal: (clubs) => {
            dispatch({
                type: "TOGGLE_CREATE_JOB_MODAL",
                clubs
            })
        },
        toggleCreateEventModal: (clubs) => {
            dispatch({
                type: "TOGGLE_CREATE_EVENT_MODAL",
                clubs
            })
        },
    }
}

function RecruiterSavedClubsPage({
                                     authUser,
                                     toggleCreateEventModal,
                                     toggleCreateJobModal,
                                     history,
                                     constants,
                                     location,
                                     tier
                                 }) {
    const [clubs_list, setClubLists] = useState([]);
    const [students_list, setStudentLists] = useState([]);
    const [smart_list, setSmartList] = useState([]);
    const [isEditClubListModalOpen, setIsEditClubListModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('Student Lists');
    const [selectedButton, setSelectedButton] = useState('user');
    const [loading, setLoading] = useState(false);
    const [isCreateSmartListModalOpen, setIsCreateSmartListModalOpen] = useState(false);

    const changeTab = (tab) => {
        setActiveTab(tab);
        setSelectedButton(tab === "Student Lists" ? "user" : "club");


        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("active_tab", tab);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        history.replace(newUrl);
    };

    useEffect(() => {
        getAllLists();

        const queryParams = new URLSearchParams(window.location.search);
        const activeTab = queryParams.get("active_tab");
        if (!!activeTab && activeTab !== "Students") changeTab(activeTab);
    }, [])

    const getAllLists = async () => {
        setLoading(true);
        const lists = await getLists(authUser);
        setClubLists(lists.club_lists);
        setStudentLists(lists.student_lists);
        setSmartList(lists.smart_lists);
        setLoading(false);
    }

    useEffect(() => {
        const createFlowToOpen = location.state?.openCreate
        if (createFlowToOpen) {
            changeTab(createFlowToOpen);
            setIsEditClubListModalOpen(true);
        }
    }, [location.state])

    const TABS = ['Student Lists', 'Club Lists', 'Smart Lists'];
    if (loading) {
        return (
            <div className='flex flex-col items-center justify-center flex-1 mb-[15vh]'>
                <Spinner size={40}/>
            </div>
        )
    }
    return (
        <div className={`flex flex-col flex-1 gap-2 overflow-y-hidden`}>
            <div className='flex flex-row items-center justify-between px-[12px] pt-[12px] pb-0'>
                <div className={'flex-1'}>
                    <SectionTabs
                        activeTab={activeTab}
                        setActiveTab={changeTab}
                        tabs={TABS}
                        lg
                    />
                </div>
                <div className="flex flex-row gap-3 ml-5">
                    <Button
                        variant={'blueLight'}
                        icon={SvgStars}
                        onClick={() => {
                            setIsCreateSmartListModalOpen(true);
                        }}
                    >
                        Create a <span className='text-bold'>Smart</span> List
                    </Button>
                    {activeTab !== "Smart Lists" &&
                        <Button
                            variant={'default'}
                            icon={SvgPlus}
                            onClick={() => {
                                setIsEditClubListModalOpen(true);
                            }}
                        >
                            New {activeTab === "Student Lists" ? "Student" : "Club"} List
                        </Button>
                    }
                </div>

            </div>
            <div className={'flex flex-1 flex-col overflow-hidden'}>
                {(clubs_list.length === 0 && activeTab === "Club Lists") || (students_list.length === 0 && activeTab === "Student Lists") || (smart_list.length === 0 && activeTab === 'Smart Lists') ?
                    <div className='text-center py-12 w-full flex flex-col gap-5'>
                        <img src={require('../../img/illustrations/techny-searching-for-a-file-in-a-folder.gif')}
                             className='w-80 h-80 mx-auto'
                        />
                        <p className='text-xl font-semibold'>
                            You haven't created any lists yet
                        </p>
                        <p className='text-slate-500 w-1/2 mx-auto text-center'>
                            Get started by creating your first. Lists make it easier to send out jobs and
                            events to specific audiences.
                        </p>


                        <div className='cursor-pointer'>
                            {activeTab === "Smart Lists" ?
                                <Button
                                    variant={'blueLight'}
                                    icon={SvgStars}
                                    onClick={() => {
                                        setIsCreateSmartListModalOpen(true);
                                    }}
                                >
                                    Create a <span className='text-bold'>Smart</span> List
                                </Button>
                                :
                                <Button
                                    icon={SvgPlus}
                                    onClick={() => {
                                        setIsEditClubListModalOpen(true)
                                    }}
                                >
                                    Create your first list
                                </Button>
                            }
                           
                        </div>
                    </div>
                    :
                    <div className={'flex flex-1 flex-col overflow-y-scroll px-[12px] pb-10'}>
                        <div className='flex flex-col gap-2'>
                            {activeTab === "Student Lists" &&
                                <>
                                    {(students_list).map((list) =>
                                        <RecruiterListGroup
                                            key={list.id}
                                            list={list}
                                            authUser={authUser}
                                            type={"user"}
                                            toggleCreateEventModal={toggleCreateEventModal}
                                            toggleCreateJobModal={toggleCreateJobModal}
                                            getLists={getAllLists}/>
                                    )}
                                </>
                            }
                            {activeTab === "Club Lists" &&
                                <>
                                    {(clubs_list).map((list) =>
                                        <RecruiterListGroup
                                            key={list.id}
                                            list={list}
                                            authUser={authUser}
                                            type={"club"}
                                            toggleCreateEventModal={toggleCreateEventModal}
                                            toggleCreateJobModal={toggleCreateJobModal}
                                            getLists={getAllLists}/>
                                    )}
                                </>
                            }
                            {activeTab === "Smart Lists" &&
                                <>
                                    {(smart_list).map((list) =>
                                        <RecruiterListGroup
                                            key={list.id}
                                            list={list}
                                            authUser={authUser}
                                            type={"smart"}
                                            toggleCreateEventModal={toggleCreateEventModal}
                                            toggleCreateJobModal={toggleCreateJobModal}
                                            getLists={getAllLists}/>
                                    )}
                                </>
                            }
                        </div>
                    </div>

                }
            </div>
            <EditListModal
                isOpen={isEditClubListModalOpen}
                isNewList
                type={selectedButton}
                getLists={getAllLists}
                closeModal={() => setIsEditClubListModalOpen(false)}
            />
            <SmartListModal
                isOpen={isCreateSmartListModalOpen}
                closeModal={() => setIsCreateSmartListModalOpen(false)}
                authUser={authUser}
                constants={constants}
                tier={tier}
                onSave={() => {
                    setIsCreateSmartListModalOpen(false);
                    getAllLists();
                }}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecruiterSavedClubsPage);
