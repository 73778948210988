import React, {PureComponent} from "react";
import JobPageAboutCompanyWidget from "../jobpage/JobPageAboutCompanyWidget";
import JobPageAboutJobWidget from "../jobpage/JobPageAboutJobWidget";
import JobPageTitleWidget from "../jobpage/JobPageTitleWidget";
import {getJob} from "../../api/student/jobs";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {wait} from "@testing-library/user-event/dist/utils";
import PageContentWrapper from "../wrappers/PageContentWrapper";
import SvgArrowOutward from "../icons/SvgArrowOutward";
import {Button} from "../atoms/Button";
import GoBack from "../atoms/GoBack";

function mapStateToProps(state) {
    return {
        authUser: state.userReducer.authUser,
        applications: state.userReducer.applications,
        user: state.userReducer.user,
        constants: state.userReducer.constants,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateApplication: (application) => {
            dispatch({
                type: "UPDATE_APPLICATION",
                application,
            })
        },
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        updateNetwork: (network) => {
            dispatch({
                type: "UPDATE_NETWORK",
                network,
            })
        },
        setPath: (path) => {
            dispatch({
                type: "SET_PATH",
                path,
            })
        },
    }
}

class PublicJobPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            job: {},
            application: {},
            previousPage: null,
            processingPrev: false,
            processingNext: false,
            people: [],
            jobLoading: true,
            noAccess: false,
        };
    }

    componentDidMount() {
        this.fetchJob();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.fetchJob();
        }
    }

    fetchJob = async () => {
        this.setState({jobLoading: true})
        const parts = window.location.pathname.split("/");
        const lastArg = parts[2];
        const jobId = lastArg.split("?")[0];
        const result = await getJob(jobId);
        this.setState({job: result.job, jobLoading: false, noAccess: result?.job?.deprecated}); //noAccess: result?.job?.scope === 'expired' || !result?.job || result?.job?.exclusive});
    }
   
    goAuthPage = () => {
        this.props.history.push(`/authentication?path=job/${this.state.job.id}`);
    }

    determineHeaderTitle = () => {
        const previousPage = this.props.location.state?.previousPage.location
        const origin = this.props.location.state?.previousPage?.origin
        if (previousPage === "home") {
            return (
                <div className='row-ac'>
                    <p className='m-0 base-white-50 body3'>
                        <Link to={{pathname: "/"}}> Home</Link> / <span className='base-white-100 body3-bold'>Job</span>
                    </p>
                </div>)
        } else if (previousPage === "jobboard") {
            return (<div className='row-ac'>
                <p className='m-0 base-white-50 body3'>
                    <Link to={{pathname: "/jobs"}}>Job Board</Link> / <span
                    className='base-white-100 body3-bold'>Job</span>
                </p>
            </div>)
        } else if (previousPage === "company") {
            const companyData = this.props.location.state?.previousPage?.data.company;
            return (<div className='row-ac'>
                <p className='m-0 base-white-50 body3'>
                    <Link to={{pathname: "/jobs"}}>Job Board</Link> / <Link
                    to={{
                        pathname: '/company/' + companyData.id,
                        state: {company: companyData.id}
                    }}>Company </Link> / <span
                    className='base-white-100 body3-bold'>Job</span>
                </p>
            </div>)
        }
    }

    render() {
        this.determineHeaderTitle()
        const listing = this.state.job || {};
        const previousPage = this.props.location.state?.previousPage.location;
        const application = this.props.applications[this.state.job.id];
        return (
            <PageContentWrapper>
                {this.state.noAccess ?
                    <div className='flex h-full justify-center items-center flex-col'>
                        <p>404: This job does not exist or you don't have access to it.</p>
                        <div className={`flex flex-row gap-3 justify-center mt-24`}>
                            <Button size={'lg'} variant={'secondary'} className='whitespace-nowrap' onClick={this.goAuthPage}>
                                Sign up or log in to view
                            </Button>
                        </div>
                    </div>
                    :
                    <div className={'pt-2 w-full max-w-[1100px] mx-auto px-4 pt-5 overflow-y-scroll'}>
                        <div className='flex flex-row items-center gap-3 mb-3'>
                            <GoBack/>
                        </div>
                        <JobPageTitleWidget listing={listing} previousPage={previousPage} user={this.props.user}
                                            loading={this.state.jobLoading} constants={this.props.constants}
                                            authUser={this.props.authUser} fetchJob={this.fetchJob}/>
                        <div className='card flex flex-row gap-3'>
                            <div className='flex flex-1'>
                                <Link to={`/signup?companyId=${listing.company_id}&type=job`}>
                                    <Button
                                        icon={SvgArrowOutward}
                                        className={`primary-button body1-bold w-full flex flex-row gap-1`}>
                                        Apply
                                    </Button>
                                </Link>
                            </div>
                            
                        </div>
                        <JobPageAboutJobWidget listing={listing} loading={this.state.jobLoading}/>
                        {!!listing.company_id ?
                            <JobPageAboutCompanyWidget listing={listing} authUser={this.props.authUser}
                                                    user={this.props.user} setUser={this.props.setUser} hideFollowButton/>
                            : null}
                    </div>
                }
               

            </PageContentWrapper>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicJobPage);
