import React, { forwardRef } from "react";

const WrappedTextInput = forwardRef((props, ref) => {
    return (
        <div className={`flex flex-col gap-1.5 text-sm ${props.containerClassName || ''}`}>
            {props.outerLabel && (
                <div className="flex flex-row items-center gap-1">
                    <div className="text-xs font-medium text-slate-500">
                        {props.outerLabel}
                    </div>
                </div>
            )}
            <div className="flex flex-col gap-0.5">
                <div
                    className={`text-input-wrapper ${
                        props.disabled && "cursor-not-allowed"
                    } disabled:bg-slate-200`}
                >
                    {props.icon}
                    {props.textarea ? (
                        <textarea ref={ref} className={`${props.className} pt-3`} {...props} min={0} style={{paddingTop:8, minHeight:100}} />
                    ) : (
                        <input
                            ref={ref}
                            type={props.type || "text"}
                            {...props}
                            min={0}
                        />
                    )}
                        {props.rightIcon}
                </div>
                {!!props.subText && (
                    <p className="text-xs text-slate-500">{props.subText}</p>
                )}
            </div>
        </div>
    );
});

export default WrappedTextInput;
