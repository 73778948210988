import React, {Component} from "react";
import WrappedTextInput from "../../../components/atoms/WrappedTextInput";
import SelectComponent from "../../../components/atoms/SelectComponent";
import AddJobCompany from "../../../components/addjobflow/SearchCompany";
import RichTextEditor from "../../../components/atoms/inputs/RichTextEditor";

class CreateEventDetailsStep extends Component {

    render() {
        const ROLES_TAGS = this.props.constants.secondary_tags.map(tag => ({title: tag, value: tag}));

        return (
            <div className='flex flex-col gap-5'>
                {!this.props.hideTutorial &&
                    <p className='text-lg text-slate-800 font-semibold'>
                       What is this event about?
                    </p>
                }
                <WrappedTextInput
                    type="text"
                    name="eventTitle"
                    outerLabel={'Event Title (*)'}
                    placeholder={'ie. 2024 Career Exploration Program'}
                    className='input-light px-3 w-full'
                    value={this.props.eventTitle}
                    onChange={(e) => this.props.handleInputChange(e, 'eventTitle')}
                />
                <SelectComponent
                    className={'flex-1'}
                    outerLabel={'Industry (*)'}
                    label={'ie. Investment Banking'}
                    value={this.props.role}
                    light
                    setValue={this.props.handleSelectChange}
                    clearFilter={() => this.props.handleSelectChange('role', null)}
                    stateName={'role'}
                    options={ROLES_TAGS}
                    node={document.getElementById('add-joblisting-modal')}
                    scrollable
                />
                {!!this.props.inPostFlow ? 
                <AddJobCompany setCompany={this.props.setCompany}
                    companyChanged={this.props.companyChanged} searchResults={this.props.searchResults}/>
                : null}
                <div className={`flex flex-col gap-1.5`}>
                    <div className='text-xs font-medium text-slate-500'>
                        Description
                    </div>
                    <div>
                        <RichTextEditor editorState={this.props.editorState} setEditorState={this.props.setEditorState}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateEventDetailsStep
