import React from "react";

const SectionTabs = ({ tabs, dark, xl, lg, activeTab, setActiveTab }) => {
    return (
        <div
            className={`flex flex-row items-center gap-6 border-b ${
                dark ? "border-transparent" : " border-slate-200"
            }`}
        >
            {tabs.map((tab) => {
                if (tab) {
                    return (
                        <div
                            onClick={() => setActiveTab(tab)}
                            key={tab}
                            className={`cursor-pointer flex flex-col items-center border-b-2 ${
                                xl && "mr-3"
                            } ${
                                activeTab === tab
                                    ? "primary"
                                    : "text-neutral-500 hover:text-neutral-700"
                            } ${
                                activeTab === tab
                                    ? "border-neutral-500"
                                    : "border-white"
                            } pb-2`}
                            style={{
                                borderColor: activeTab === tab && "#4848F2",
                            }}
                        >
                            <p
                                className={
                                    xl
                                        ? "text-lg font-semibold"
                                        : lg
                                        ? "body1-bold"
                                        : "body3-bold"
                                }
                            >
                                {tab}
                            </p>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default SectionTabs;
