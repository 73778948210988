import React, {useState, useEffect} from 'react';
import SvgLink from '../../components/icons/SvgLink';
import SvgCopy from '../../components/icons/SvgCopy';
import SvgCheck from '../../components/icons/SvgCheck';
import InputWrapper from "../../components/atoms/inputs/InputWrapper";
import {Button} from "../../components/atoms/Button";


function RecruitPremium({user}) {
    const [inviteLink, setInviteLink] = useState("https://app.recruitu.com/authentication?activeTab=signup&referred=" + user.username);
    const [copyStatus, setCopyStatus] = useState('idle'); // 'idle', 'copied'

    const handleCopyLink = () => {
        navigator.clipboard.writeText(inviteLink)
            .then(() => setCopyStatus('copied'))
            .catch(() => setCopyStatus('idle'));

        setTimeout(() => setCopyStatus('idle'), 3000); // Reset after 3 seconds
        window.analytics.track("Referral Link Clicked")
    };

    return (
            <div className='flex flex-row items-stretch gap-12 flex-1 mt-3'>
                <div className='flex flex-col gap-2 flex-1'>
                    <p className='font-semibold text-white'>
                        Earn RecruitU Premium for free!
                    </p>
                    <p className='text-sm opacity-70'>
                        Refer <span className='font-bold'>3 friends</span> to the platform using your link to unlock premium for the next 3 months and:
                    </p>
                    <ul className='ml-3 opacity-70 text-sm font-semibold'>
                        <li>
                            • &nbsp; Save up to 10 contacts a day (instead of 3)
                        </li>
                        <li>
                            • &nbsp; Unlock all interview sets
                        </li>
                    </ul>
                    <div className='flex flex-row items-center gap-2 mt-auto'>
                        <SvgLink className={'w-5 h-5 text-white'}/>
                        <p className='text-sm font-medium'>Your Invite Link</p>
                    </div>
                    <div className='flex relative cursor-pointer group' onClick={handleCopyLink}>
                        <div className='absolute right-2 top-1/2 -translate-y-1/2 flex flex-row items-stretch'>
                            <div
                                className='w-12'
                                style={{background: "linear-gradient(to right, #3043A700, #3043A7)"}}
                            />
                            <Button
                                onClick={handleCopyLink}
                                size={'sm'}
                                variant={'white'}
                                aria-label="Copy invite link"
                            >
                                {copyStatus === 'copied' ? <SvgCheck className={'w-5 h-5'}/> :
                                    <SvgCopy className={'w-5 h-5'}/>}
                                <span className='text-sm'>
                        {copyStatus === 'copied' ? 'Copied!' : 'Copy'}
                    </span>
                            </Button>
                        </div>
                        <div className='w-full pointer-events-none'>
                            <InputWrapper
                                type='text'
                                className={'w-full group-hover:bg-primary/10 rounded-xl'}
                                inputClassName={'!text-white'}
                                value={inviteLink}
                                disabled
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default RecruitPremium;
